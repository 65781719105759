import { styled } from "styled-components"
import { theme } from "../../../styles"
import Layout from "../../Layout/Layout"
import { ICardProps } from "../../ui/Card/Card"
import PhaseItems, { IPhaseItemsProps } from "../../ui/PhaseItems/PhaseItems"
import SubNav from "../../ui/SubNav/SubNav"
import DetailHeader from "./DetailHeader"
import DetailViewBodyContainer from "./DetailViewBodyContainer"
import ListPhaseItemsContainer from "./ListPhaseItemsContainer"

interface IDetailViewProps extends ICardProps {
  phaseItems: IPhaseItemsProps[]
}

export const StyledDetailMainContainer = styled.section`
  width:100%;
  max-height: 100%;
  padding-bottom: 40px;
  overflow: auto;
  background: ${theme.colors.backgroundLight};
  
  @media screen and (max-width: 834px) { 
    // min-height: 100%;
    flex-grow: 1;
  }
  @media screen and (max-width: 430px) { 
    padding-bottom: 0;
  }
`

const DetailView = ({ phaseItems, tasksCompleted: completedTasks, componentName, phaseTitle, tasksTotal: totalTasks }: IDetailViewProps) => {

  return (
    <Layout >
      <StyledDetailMainContainer>
        <SubNav />
        <DetailViewBodyContainer>
          <DetailHeader tasksCompleted={completedTasks} tasksTotal={totalTasks} componentName={componentName} phaseTitle={phaseTitle} />
          <ListPhaseItemsContainer>
            {phaseItems.map((phaseItemsData, idx: number) => <PhaseItems
              key={idx}
              checkedItems={phaseItemsData.checkedItems}
              unCheckedItems={phaseItemsData.unCheckedItems}
              phaseTitle={phaseItemsData.phaseTitle}
              textColor="#2AB514"
              bgColor="#2AB514"
              isActive={false}
            />)}
          </ListPhaseItemsContainer>
        </DetailViewBodyContainer>
      </StyledDetailMainContainer>

    </Layout>
  )
}

export default DetailView