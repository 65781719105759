import styled from "styled-components";
import { theme } from "../../../styles";

export interface INavCategoryProps {
  title: string;
}

const StyledNavCategory = styled.div`
  display: flex;
  align-items: center;
  width: 100%
`;

const StyledNavTitle = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: "rooney-sans";
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`;

const NavCategory = ({ title = "" }: INavCategoryProps) => {
  return (
    <StyledNavCategory>
      <StyledNavTitle>{title}</StyledNavTitle>
    </StyledNavCategory>
  );
};

export default NavCategory;