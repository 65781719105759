import { useState } from 'react'
import Logo from '../components/assets/images/logo'
import {
  StyledConfirmType,
  StyledCreateFooterContainer,
  StyledHeaderContainer,
  StyledHeaderText,
  StyledInputContainer,
  StyledMainContainer,
  StyledPageContainer,
  StyledParagraphText
} from '../components/ui'
import Button from '../components/ui/Button/Button'
import InputField from '../components/ui/InputField/InputField'

const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT
const STAGING_PASS_CODE = import.meta.env.VITE_APP_STAGING_PASS_CODE

interface PassCodeWrapperProps {
  children: React.ReactNode
}

const PassCodeWrapper: React.FC<PassCodeWrapperProps> = ({ children }) => {
  const [passCode, setPassCode] = useState<string>('')
  const [authenticated, setAuthenticated] = useState<boolean>(() => {
    // Directly check localStorage on initialization
    const storedPassCode = localStorage.getItem('passCode')
    return storedPassCode === STAGING_PASS_CODE
  })
  const [error, setError] = useState<string>('')

  const handlePassCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassCode(e.target.value.trim())
    setError('') // Clear error on input change
  }

  const handlePassCodeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (passCode !== STAGING_PASS_CODE) {
      setError('Invalid passcode')
      return
    }
    localStorage.setItem('passCode', passCode)
    setAuthenticated(true)
  }

  // Skip passcode logic if not in staging
  if (APP_ENVIRONMENT !== 'staging') return <>{children}</>

  // Render children if authenticated
  if (authenticated) return <>{children}</>

  return (
    <StyledMainContainer>
      <form onSubmit={handlePassCodeSubmit} style={{ margin: 'auto' }}>
        <StyledPageContainer>
          <StyledHeaderContainer>
            <Logo />
            <StyledHeaderText>Locked</StyledHeaderText>
            <StyledParagraphText className="text-center">
              The app is locked and only accessible for selected members
            </StyledParagraphText>
          </StyledHeaderContainer>
          <StyledInputContainer>
            <InputField
              label="Enter passcode"
              type="password"
              name="passCode"
              required
              fullWidth
              onChange={handlePassCodeChange}
              value={passCode}
              feedbackMessageType="error"
              feedbackMessage={!!error}
              feedbackMessageContent={error}
            />
          </StyledInputContainer>
          <StyledCreateFooterContainer>
            <Button
              disabled={!passCode.trim()}
              label="Unlock"
              fullWidth
              icon="unlock"
              type="submit"
            />
          </StyledCreateFooterContainer>
          <StyledConfirmType>
            To access the staging <i>&apos;&apos;Compfeed&apos;&apos;</i>, you
            must enter the passcode.
          </StyledConfirmType>
        </StyledPageContainer>
      </form>
    </StyledMainContainer>
  )
}

export default PassCodeWrapper
