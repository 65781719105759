// File: SingleChoiceQuestion.tsx

import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

interface SingleChoiceQuestionProps {
  question: {
    id: number
    text: string
    options: Array<{
      label?: string
      text?: string
      description?: string[]
    }>
  }
  fieldName: string
}

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
`

const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

const StyledLabel = styled.label`
  cursor: pointer;
`

const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
`

const CardContent = styled.div`
  padding-top: 1.5rem;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const RadioRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .radio {
    margin: 0;
  }

  .label {
    font-size: 1.25rem;
    font-weight: 500;
  }
`

const Description = styled.p`
  font-size: 1rem;
  color: var(--muted-foreground, #6b7280);
`
export function SingleChoiceQuestion({
  question,
  fieldName
}: SingleChoiceQuestionProps) {
  const { control } = useFormContext()

  return (
    <Wrapper>
      <Title>{question.text}</Title>
      <Grid>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={
            question.options[0].label || question.options[0].text || ''
          }
          render={({ field: { onChange, value } }) => (
            <>
              {question.options.map((option, index) => (
                <StyledLabel key={index}>
                  <Card>
                    <CardContent>
                      <OptionWrapper>
                        <RadioRow>
                          <input
                            type="radio"
                            className="radio"
                            value={option.label || option.text || ''}
                            checked={
                              value === (option.label || option.text || '')
                            } // Check if this option is selected
                            onChange={(e) => onChange(e.target.value)} // Update the value in the form
                          />
                          <span className="label">
                            {option.label || option.text}
                          </span>
                        </RadioRow>
                        {option.description && (
                          <Description>
                            {option.description.join(', ')}
                          </Description>
                        )}
                      </OptionWrapper>
                    </CardContent>
                  </Card>
                </StyledLabel>
              ))}
            </>
          )}
        />
      </Grid>
    </Wrapper>
  )
}
