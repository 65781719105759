// check all the roles are available or not
export const containsAllRoles = (
  rolesWeWantToCheck: string[],
  user: { roles: string[] }
) => {
  let rolesUserHave = user?.roles || []
  return rolesWeWantToCheck?.every((i) =>
    rolesUserHave?.includes(i?.toLowerCase())
  )
}

// Formate 1st letter of string into capitalize
export const capitalFirstLetter = (str: string = '') => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()
}

// convert string into capitalize
export const capitalizeString = (str: string = '') => {
  return str?.toUpperCase()
}

// convert string into lowercase
export const lowerString = (str: string = '') => {
  return str?.toLocaleLowerCase()
}

// copy text
export const copyToClipBoard = async (copyMe: string) => {
  try {
    await navigator.clipboard.writeText(copyMe)
    // setCopySuccess('Copied!');
  } catch (err) {
    // setCopySuccess('Failed to copy!');
  }
}

// formate file size
export const formatBytes = (a:number, b = 2) => {
  if (!+a) return '0 Bytes'
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024))
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`
}

// count words
export const wordCount = (text:string = '') => {
  var count = 0
  var split = text?.split(' ') || ''
  for (var i = 0; i < split?.length; i++) {
    if (split[i] !== '') {
      count++
    }
  }
  return count
}

// Sort Data who's added recently
export const getSortByTransactionDate = (a:any, b:any, key:string) => {
  if (a && b) {
    return new Date(b[key]).valueOf() - new Date(a[key]).valueOf()
  } else {
    console.log(
      'Did not get array of objects to sorting data check your data and also sorting method....'
    )
  }
}

export const getTimeStamp = () => new Date().toISOString()

// Find the most recent object based on the 'createdAt' timestamp
export const mostRecentItem = (list:{createdAt: string}[] = []) =>
  list?.reduce((currentMostRecent:any, currentObject) => {
    const currentObjectCreatedAt = new Date(currentObject.createdAt)
    const mostRecentCreatedAt = currentMostRecent
      ? new Date(currentMostRecent.createdAt)
      : null

    // Compare timestamps and update if the current object is more recent
    if (!mostRecentCreatedAt || currentObjectCreatedAt > mostRecentCreatedAt) {
      return currentObject
    } else {
      return currentMostRecent
    }
  }, null)

// Sort the data based on the 'order' property in descending order
export const sortedDataByOrder = (data:any[] = [], key:string = '', type?:string) =>
  type === 'asc'
    ? [...data]?.sort((a, b) => a?.[key] - b?.[key])
    : [...data]?.sort((a, b) => b?.[key] - a?.[key])

export const sortDataByDate = (data: any[] = [], type?: 'asc' | 'desc', dateKey?:string) =>
  type === 'asc'
    ? [...data]?.sort(
        (a, b) =>
          new Date(a?.[dateKey || "updatedAt"]).getTime() - new Date(b?.[dateKey || "updatedAt"]).getTime()
      )
    : [...data]?.sort(
        (a, b) =>
          new Date(b?.[dateKey || "updatedAt"]).getTime() - new Date(a?.[dateKey || "updatedAt"]).getTime()
      )

// get current host with formatted
export const currentHost = () => {
  return window.location.protocol + '//' + window.location.host
}

// random id generator
export function generateRandomNumber(length:number) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// Validate beta code
export const validateBetaCode = (betaCodeVal:string = '', list:string[] = []) => {
  const betaCode = betaCodeVal?.toString()?.trim()?.replaceAll(',', '')
  if (!betaCode) return false
  // Validate the beta code
  if (
    /^\d+$/.test(betaCode) &&
    betaCode.length === 8 &&
    list?.find((v) => v?.replaceAll(',', '') === betaCode)
  )
    return true
  return false
}

// Function to darken a color
export const darkenColor = (color:string, percent:number) => {
  const hex = color.replace(/#/, '')
  const num = parseInt(hex, 16)
  const amt = Math.round(2.55 * percent)
  const r = (num >> 16) - amt
  const g = ((num >> 8) & 0x00ff) - amt
  const b = (num & 0x0000ff) - amt
  return (
    '#' +
    (
      0x1000000 +
      (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
      (g < 255 ? (g < 1 ? 0 : g) : 255) * 0x100 +
      (b < 255 ? (b < 1 ? 0 : b) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

// Function to lighten a color
export const lightenColor = (color:string, percent:number) => {
  const hex = color.replace(/#/, '')
  const num = parseInt(hex, 16)
  const amt = Math.round(2.55 * percent)
  const r = (num >> 16) + amt
  const g = ((num >> 8) & 0x00ff) + amt
  const b = (num & 0x0000ff) + amt
  return (
    '#' +
    (
      0x1000000 +
      (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
      (g < 255 ? (g < 1 ? 0 : g) : 255) * 0x100 +
      (b < 255 ? (b < 1 ? 0 : b) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

export const isInvitationExpired = (invitedDateStr: any) => {
  if(!invitedDateStr) return true;
  // Parse the invited date
  const invitedDate = new Date(invitedDateStr);

  // Get the current date and time in UTC
  const now = new Date();

  // Calculate the difference in milliseconds
  const diff = now.getTime() - invitedDate.getTime();

  // Convert the difference from milliseconds to hours
  // const minsDifference = diff / (1000 * 60);
  const hoursDifference = diff / (1000 * 60 * 60);

  // Check if the difference is greater than 48 hours
  // return minsDifference > 5;
  return hoursDifference > 48;
}

export const isMinsPassed = (minStr: any, time:number) => {
  if(!minStr) return true;
  const invitedDate = new Date(minStr);

  const now = new Date();

  // Calculate the difference in milliseconds
  const diff = now.getTime() - invitedDate.getTime();

  // Convert the difference from milliseconds to mins
  const minsDifference = diff / (1000 * 60);

  // Check if the difference
  return minsDifference > time;
}

// reorder the list of data when dragged
export const reorderList = (
  list: any,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const formateSimpleCounting = (num: number, digits: number = 2): string => {
  return num.toString().padStart(digits, '0');
};

export const isValidFigmaURL = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);

    // Ensure the URL uses the correct scheme and domain
    if (parsedUrl.protocol !== 'https:' || parsedUrl.hostname !== 'www.figma.com') {
      return false;
    }

    // Ensure the pathname starts with /file/, /design/, or /board/ for valid Figma and FigJam links
    const validPaths = ['/file/', '/design/', '/board/', '/slides/', '/deck/', '/proto/'];
    return validPaths.some((path) => parsedUrl.pathname.startsWith(path));
  } catch (e) {
    return false;
  }
};


type FigmaURLDetails = {
  url: string;
  title?: string;
  type?: string; // e.g., file, design, board, proto
  fileKey?: string; // Figma file key (ID)
  nodeId?: string; // Node ID
  token?: string; // Optional token in the URL
};

export const extractDetailFromLink = (url: string): FigmaURLDetails => {
  const regex =
    /https:\/\/[\w\.-]+\.?figma.com\/(file|design|board|slides|deck|proto)\/([0-9a-zA-Z]{22,128})(?:\/([^?]*))?\??(.*)/;

  // Use the regular expression to extract the file type, file key, title, and query params
  const match = url?.match(regex);

  if (!match) {
    return {
      url,
    };
  }

  const [, type, fileKey, titleEncoded = "", query] = match;
  const title = decodeURIComponent(titleEncoded); // Decode title from URL

  // Extract node-id, node-type, and token from query params
  const params = new URLSearchParams(query);
  const nodeId = params.get('node-id') || "";
  const token = params.get('t') || "";

  return {
    url,
    title,
    type, // e.g., file, design, board, proto, etc.
    fileKey, // Extracted file key (ID)
    nodeId, // Node ID
    token, // Optional token if present
  };
};

export const getFigmaFileTypes = (type:string = "") => {
  switch (type) {
    case 'file':
    case 'design':
      return 'Figma';
    case 'board':
      return 'FigJam';
    case 'slides':
      return 'Presentation';
    case 'deck':
      return 'Slides';
    case 'proto':
      return 'Prototype';
    default:
      return 'File';
  }
} 

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};