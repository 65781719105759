import { styled } from 'styled-components'
import { theme } from '../../../styles'
import Button from '../../ui/Button/Button'

export const StyledMainContainer = styled.main`
  background-color: ${theme.colors.white};
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
`

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  flex-shrink: 0;
  width: 100%;
  max-width: 568px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${theme.colors.white};
  margin: 2rem auto;

  @media screen and (min-width: 834px) {
    padding: 40px 80px;
    border: 1px solid ${theme.colors.black};
  }
`

export const StyledHeaderAction = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 80px;
  background: ${theme.colors.backgroundLight};
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  padding-bottom: 32px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`

export const StyledHeaderText = styled.h3`
  color: ${theme.colors.copy};
  font-size: 48px;
  font-weight: 500;
  line-height: 140%;
  font-family: rooney;

  @media screen and (min-width: 430px) {
    font-size: 64px;
  }
`
export const StyledDescription = styled.p`
  color: ${theme.colors.copy};
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`

const TermsOfService = () => {
  return (
    <StyledMainContainer>
      <StyledPageContainer>
        <StyledHeaderAction>
          <Button variant="tertiary" icon="chevron-left" size="medium">
            Go back
          </Button>
        </StyledHeaderAction>

        <StyledHeaderContainer>
          <StyledHeaderText>Terms Of Service</StyledHeaderText>
        </StyledHeaderContainer>
        <StyledDescription>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti,
          provident dolores incidunt, at sunt voluptates facere quos voluptate
          iure aperiam hic. Praesentium sequi necessitatibus est! Quisquam magni
          excepturi enim numquam. Doloremque et quae molestias nostrum beatae
          dolore hic ab reprehenderit quisquam, optio, est sed voluptatibus
          illo, distinctio fuga delectus in. Optio, reprehenderit! Iste animi
          esse enim tempora ut, dignissimos aut. Temporibus sed provident
          delectus commodi nemo tenetur veritatis dignissimos facilis dolor
          numquam aperiam in minima accusantium nesciunt, sequi dolores
          molestiae rerum veniam molestias, mollitia voluptas. Labore aliquam a
          laborum! Dolorum? Sunt magni eius, sapiente animi possimus reiciendis
          cumque totam quas esse quae. Neque necessitatibus beatae, ex, debitis,
          deserunt laborum distinctio rem optio et magnam veritatis at enim
          voluptate cum dolores. Fuga recusandae quae corrupti magnam fugit
          facere dolores? Ipsum veritatis dicta harum animi vel numquam maxime
          consequatur, eos aspernatur itaque dolores, voluptatibus sint. Non
          officiis voluptatem ab sapiente distinctio libero! Nobis aspernatur
          animi quidem amet iusto, delectus earum reiciendis modi impedit sequi
          numquam, fuga asperiores exercitationem, est nam eveniet tempora
          consequatur. Voluptatibus at amet suscipit recusandae assumenda magnam
          illo eos. Quisquam assumenda quasi commodi, est, iste pariatur minima
          ullam ratione similique vel praesentium molestias expedita, id
          voluptas quo cumque dolore. Iusto quia consectetur repellendus
          molestiae optio laborum expedita, debitis excepturi. Voluptates amet
          aspernatur distinctio cum hic iure, reprehenderit maxime quibusdam
          suscipit ut voluptas ad modi, et nulla magnam earum vel ex rerum
          accusamus eligendi, eaque voluptatem minima nisi. Amet, tempore. Sed
          aliquid culpa, ipsum ratione impedit eaque distinctio id amet fugit
          quam magnam? Totam voluptatem nisi illo iste alias ad commodi, sed
          enim id deleniti est, mollitia voluptatum quas esse.
        </StyledDescription>
      </StyledPageContainer>
    </StyledMainContainer>
  )
}

export default TermsOfService
