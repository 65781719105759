import styled from 'styled-components'
import { theme } from '../../../styles'
import AccountItem from '../AccountItem/AccountItem'
import { Dispatch, SetStateAction, useState } from 'react'
import Modal from '../Modal/Modal'

interface IAccountMenuProps {
  fullWidth?: boolean
  logout?: () => void
  onClick1?: () => void
  onClick2?: () => void
  setLogoutConfirmModal: Dispatch<SetStateAction<boolean>>
  setSwitchTeamModal: Dispatch<SetStateAction<boolean>>
  profileImageSrc?: string
  profileEmail?: string
  teamName?: string
  teamImageSrc?: string
}

const StyledAccountMenu = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '248px')};
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: ${theme.colors.surfaceNormalActive};
`

const StyledDivider = styled.div`
  border-bottom: 1px solid ${theme.colors.borderDefault};
  width: 100%;
`

const AccountMenu = ({
  logout,
  onClick1,
  onClick2,
  profileImageSrc,
  profileEmail,
  teamName,
  teamImageSrc,
  setLogoutConfirmModal,
  setSwitchTeamModal,
  fullWidth
}: IAccountMenuProps) => {
  // JSX
  return (
    <>
      <StyledAccountMenu fullWidth={fullWidth}>
        <AccountItem
          accountItemTitle={teamName}
          src={teamImageSrc ? teamImageSrc + '?id=' + new Date().getTime() : ''}
          onClick={() => (onClick1 ? onClick1() : null)}
          team
          onClickIcon={() => setSwitchTeamModal(true)}
        />
        <StyledDivider />
        <AccountItem
          accountItemTitle={profileEmail}
          src={profileImageSrc}
          profile
          onClick={() => (onClick2 ? onClick2() : null)}
        />
        <AccountItem
          accountItemTitle="Logout"
          icon="log-out"
          onClick={() => setLogoutConfirmModal(true)}
        />
      </StyledAccountMenu>
    </>
  )
}

export default AccountMenu
