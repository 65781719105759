import { ReactNode } from "react"
import { styled } from "styled-components"

const StyledListPhaseItemsContainer = styled.div`
  display:grid;
  gap:16px;
  width: 100%;
  // max-height: 100%;
`

const ListPhaseItemsContainer = ({ children }: { children: ReactNode }) => {
    return (
        <StyledListPhaseItemsContainer>{children}</StyledListPhaseItemsContainer>
    )
}

export default ListPhaseItemsContainer