import { ref, get, Database } from 'firebase/database'

interface ProjectLink {
  name: string
  url: string
}

interface Project {
  id: string
  title: string
  type: string
  links?: {
    title: string
    url: string
  }[]
}

export const generateProjectLinks = async (
  db: Database,
  teamId: string,
  projectId: string
): Promise<{ projectName: string; links: ProjectLink[] } | null> => {
  try {
    const snapshot = await get(ref(db, `components/${teamId}/${projectId}`))
    if (!snapshot.exists()) {
      console.log('project - No project found.')
      return null
    }
    const projectData: Project | null = snapshot.val()

    if (!projectData || projectData.type !== 'project') {
      console.log('project - No project found or the type is not "project".')
      return null
    }

    const projectLinks: ProjectLink[] = (projectData?.links || []).map(
      (link) => ({
        name: link.title,
        url: link.url
      })
    )

    return {
      projectName: projectData.title,
      links: projectLinks
    }
  } catch (error) {
    console.error('project - Error generating project links:', error)
    return null
  }
}
