import styled from "styled-components";
import { theme } from "../../styles/theme";

export const StyledSingleTemplateContainer = styled.section`
   width: 100%;
    background-color: ${theme.colors.white};
    overflow-y: scroll;
    padding-bottom: 32px;
`;

export const StyledSingleTemplateBody = styled.div`
    display: flex;
    padding: 40px 40px 16px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    max-width: 832px;
    margin-inline: auto;
    width: 100%;

    @media screen and (max-width: 430px) {
    padding: 24px 16px 16px 16px;
  }
`

export const StyledSingleTemplateHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
`

export const StyledPhaseListContainer = styled.div<{ phaseReorderState?: boolean }>`
display: flex;
flex-direction: column;
gap: 32px;
align-self: stretch;
width: 100%;
@media screen and (max-width: 390px) {
   gap: 24px;
}

${props => props.phaseReorderState && `
    gap: 16px;
    `}

`