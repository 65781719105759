import { HTMLAttributes } from "react"
import { css, styled } from "styled-components";
import { phaseTypeColorMap, theme } from '../../../styles/theme';

export type IPhaseType = keyof (typeof phaseTypeColorMap)


interface IPhaseTypeProps extends HTMLAttributes<HTMLDivElement> {
    phaseType?: IPhaseType;
    phaseProgress: number | "none";
    disabled?: boolean;
    bgColor?: string;
}


const StyledPhaseType = styled.div<IPhaseTypeProps>`

    width: 8px;
    height: 8px;
    flex-shrink: 0;
    border-radius:8px;

     ${({ phaseProgress, phaseType, bgColor, disabled }) => {
        const width = phaseProgress !== "none" ? `${phaseProgress * 1}%` : "8px";
    // const bgColor = disabled ? theme.colors.copySubtle : phaseTypeColorMap[phaseType];
    const background = disabled ? theme.colors.copySubtle : bgColor || phaseTypeColorMap[phaseType ?? 1];
        return css`
            background-color: ${background};
            width: ${width};
        `
    }
    }
`

const PhaseType = ({ phaseProgress = 100, phaseType = 1, disabled = false, bgColor }: IPhaseTypeProps) => {
    return (
        <StyledPhaseType phaseProgress={phaseProgress} phaseType={phaseType} bgColor={bgColor} disabled={disabled} />
    )
}

export default PhaseType
