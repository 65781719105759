import './libs/sentry'
import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import { Slide } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from './styles'
import { Provider } from 'react-redux'
import { store } from './store'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './config/AppRouter'
// css
import 'react-toastify/dist/ReactToastify.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { getQueryClient } from './getQueryClient'
import { HelmetProvider } from 'react-helmet-async'
import * as Sentry from '@sentry/react'
import FallbackComponent from './components/ui/FallbackComponent'

const root = ReactDOM.createRoot(
  document.getElementById('root')! as HTMLElement
)

const queryClient = getQueryClient()

root.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            theme="dark"
            newestOnTop
            pauseOnHover
            closeOnClick
            pauseOnFocusLoss
            transition={Slide}
          />
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <AppRouter />
            </QueryClientProvider>
            <GlobalStyle />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  </Sentry.ErrorBoundary>
)
