import React from 'react'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import { CustomSlider } from './CustomSlider'
import { FormValues } from '../form.validations'

interface ScaleQuestionProps {
  question: {
    id: number
    text: string
    scale: {
      min: number
      max: number
      labels: Array<{
        label: string
        description: string[]
      }>
    }
  }
  fieldName: any
}

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
`

const SliderWrapper = styled.div`
  width: 100%;
`

const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const LabelWrapper = styled.div<{ width: number }>`
  text-align: center;
  width: ${({ width }) => `${width}%`};

  .label {
    font-size: 1.125rem;
    font-weight: 500;
  }

  .description {
    font-size: 1rem;
    color: var(--muted-foreground, #6b7280);
  }
`

export function ScaleQuestion({ question, fieldName }: ScaleQuestionProps) {
  const { control } = useFormContext<FormValues>()
  const [value, setValue] = React.useState(50)

  return (
    <Wrapper>
      <div style={{ padding: '20px' }}>
        <h1>Custom Slider</h1>
        <CustomSlider
          value={value}
          onChange={setValue}
          min={0}
          max={100}
          step={1}
        />
        <p>Value: {value}</p>
      </div>
      <Title>{question.text}</Title>
      <SliderWrapper>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={7}
          render={({ field: { value, onChange } }) => (
            <CustomSlider
              value={value}
              min={question.scale.min}
              max={question.scale.max}
              step={1}
              onChange={onChange}
            />
          )}
        />
      </SliderWrapper>
      <LabelsContainer>
        {question.scale.labels.map((label, index) => (
          <LabelWrapper key={index} width={100 / question.scale.labels.length}>
            <div className="label">{label.label}</div>
            <div className="description">{label.description.join(', ')}</div>
          </LabelWrapper>
        ))}
      </LabelsContainer>
    </Wrapper>
  )
}
