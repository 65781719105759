import React, {
  useEffect,
  useRef,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  StyledAccountMainContainer,
  StyledAccountOptionsContainer,
  StyledAccountOptionTitle,
  StyledDangerTitle,
  StyledDangerZoneContainer,
  StyledDangerZoneDescription,
  StyledDangerZoneHeader,
  StyledInputLabel,
  StyledInputLabelContainer,
  StyledOptionInputsContainer,
  StyledTitle
} from '../../components/screens/MyAccount/MyAccount'
import MyAccountBodyContainer from '../../components/screens/MyAccount/MyAccountBodyContainer'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import { auth, db, storage } from '../../config/config'
import { RootState } from '../../store'
import axios from 'axios'
import { FileWithPreview } from '../../hooks/useImageUpload'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { update, ref as dbRef } from 'firebase/database'
import { useDispatch } from 'react-redux'
import { setProfileData } from '../../store/reducers/authReducerSlice'
import Modal from '../../components/ui/Modal/Modal'
import ModalUpload from '../../components/ui/FileUpload/ModalUpload'
import ProfilePic from '../../components/ui/ProfilePic/ProfilePic'
import useIsMobileDevice from '../../hooks/useIsMobileDevice'

const DELETE_COUNT_DOWN = 5

// ------------------ User profile --------------------------------
const Profile: React.FC = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((store: RootState) => store.auth)
  // account delete loading
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  // Edit profile image
  const [editProfileImgModal, setEditProfileImgModal] = useState(false)
  const [uploadingProfileImg, setUploadingProfileImg] = useState(false)
  const [uploadingProfileImgError, setUploadingProfileImgError] = useState('')
  const [uploadPercent, setUploadPercent] = useState(0)
  const [uploadTask, setUploadTask] = useState<any>(null)
  // name
  const [name, setName] = useState(user?.displayName || '')
  const [saving, setSaving] = useState(false)

  // delete user
  const deleteAccount = async () => {
    if (deleting) return
    if (!user?.id) return
    setDeleting(true)
    try {
      await axios.delete(
        `${import.meta.env.VITE_APP_SERVER_URL}/auth/removeMyAccount/?id=${user?.id}`
      )

      localStorage.removeItem('passCode')
      localStorage.removeItem('betaCode')
      localStorage.removeItem('widgetLoginEmail')
      localStorage.removeItem('loginEmail')
      localStorage.removeItem('checklist_team_id')
      localStorage.removeItem('checklist_template_id')

      toast.success('Your account has been deleted successfully!')
      setTimeout(() => {
        window.location.replace('/')
      }, 1300)
    } catch (error: any) {
      console.log('Error while deleting user account', error)
      toast.error(error?.message || 'Something went wrong.')
      setDeleting(false)
    }
  }

  // update profile image on firebase storage cloud
  const updateProfileImg = (acceptedFiles: FileWithPreview[]) => {
    const file = acceptedFiles[0]
    const storageRef = ref(storage, `images/profile/${file?.name}`)
    setUploadingProfileImg(true)
    const task = uploadBytesResumable(storageRef, file)
    setUploadTask(task)
    task.resume()
    task.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot?.bytesTransferred / snapshot?.totalBytes) * 100
        setUploadPercent(progress)
      },
      (error) => {
        console.log(error?.message)
        if (error?.code === 'storage/canceled') {
          setUploadingProfileImgError('')
        } else {
          setUploadingProfileImgError(error?.message || 'Something went wrong.')
        }
        setUploadingProfileImg(false)
        setUploadTask(null)
        setUploadPercent(0)
      },
      () => {
        getDownloadURL(task?.snapshot?.ref).then((downloadURL) => {
          setUploadTask(null)
          setUploadPercent(100)
          // Update in database
          update(dbRef(db, `users/${user?.id}`), {
            profileImg: downloadURL || user?.profileImg
          })
            .then(() => {
              dispatch(
                setProfileData({
                  user: {
                    ...user,
                    profileImg: downloadURL || user?.profileImg
                  }
                })
              )
              setUploadingProfileImgError('')
              setEditProfileImgModal(false)
              toast.success('Profile image updated successfully!')
            })
            .catch((error) => {
              console.log('Error while updating profile image', error?.message)
              setUploadingProfileImgError('Something went wrong.')
            })
            .finally(() => {
              setUploadTask(null)
              setUploadingProfileImg(false)
              setUploadPercent(0)
            })
        })
      }
    )
  }

  // cancel uploading/changing profile image
  const cancelUpload = () => {
    if (uploadTask) {
      uploadTask.cancel()
    }
  }

  // update name
  const updateName = (e: React.FormEvent) => {
    e.preventDefault()
    if (saving || name?.trim() === user?.displayName?.trim()) return
    setSaving(true)
    update(dbRef(db, `users/${user?.id}`), {
      displayName: name?.trim() || ''
    })
      .then(() => {
        dispatch(
          setProfileData({
            user: {
              ...user,
              displayName: name
            }
          })
        )
        toast.success('Your changes are saved.')
        setSaving(false)
      })
      .catch((err) => {
        setSaving(false)
        toast.error(err.message || 'Something went wrong.')
        console.log('Error while saving name', err)
      })
  }

  const isMobile = useIsMobileDevice(768)

  // JSX
  return (
    <StyledAccountMainContainer>
      <MyAccountBodyContainer>
        <div>
          <StyledTitle>My Account</StyledTitle>
        </div>
        <StyledAccountOptionsContainer>
          <StyledAccountOptionTitle>Basic Information</StyledAccountOptionTitle>
          <div style={{ width: '100%' }}>
            <StyledOptionInputsContainer>
              <ProfilePic
                src={user?.profileImg || ''}
                size={isMobile ? "s" : "m"}
                buttonAction={() => {
                  if (uploadingProfileImg || deleting) return
                  setEditProfileImgModal((prev) => !prev)
                }}
                disabled={uploadingProfileImg || deleting}
                type={user?.profileImg ? "photo" : "default"}
              />
              <ModalUpload
                title="Upload Profile"
                btnLabel="Upload profile"
                isLoading={uploadingProfileImg}
                isDisabled={uploadingProfileImg}
                modalOpen={editProfileImgModal}
                setModalOpen={setEditProfileImgModal}
                onSubmit={updateProfileImg}
                cancelUpload={cancelUpload}
                canCancel={uploadTask && uploadPercent < 100 ? true : false}
                uploadPercent={uploadPercent}
                error={uploadingProfileImgError}
              />
              <form onSubmit={updateName} style={{ width: '100%' }}>
                <StyledOptionInputsContainer>
                  <StyledInputLabelContainer>
                    <StyledInputLabel>Name</StyledInputLabel>
                    <InputField
                      required
                      fullWidth
                      label="Name"
                      placeholder="Name"
                      name="displayName"
                      type="text"
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        saving ? null : setName(e?.target?.value)
                      }
                      disabled={deleting || saving}
                    />
                  </StyledInputLabelContainer>
                  <StyledInputLabelContainer>
                    <StyledInputLabel>Email address</StyledInputLabel>
                    <InputField
                      required
                      fullWidth
                      label="Email address"
                      placeholder="Email address"
                      name="email"
                      type="email"
                      value={user?.email || auth?.currentUser?.email || ''}
                      onChange={(e) => e.preventDefault()}
                      disabled
                    />
                  </StyledInputLabelContainer>
                  <Button
                    disabled={
                      name?.trim()?.length < 1 ||
                      name?.trim() === user?.displayName?.trim()
                        ? true
                        : deleting || saving
                    }
                    type="submit"
                    label={saving ? 'SAVING...' : 'UPDATE'}
                    variant="primary"
                    // onClick={(e: React.FormEvent) => handleSave(e)}
                  />
                </StyledOptionInputsContainer>
              </form>
            </StyledOptionInputsContainer>
          </div>
        </StyledAccountOptionsContainer>
        {/* --------------------------------------------------- */}
        {/*------------- Remove/Delete account  ------------ */}
        <StyledDangerZoneContainer>
          <StyledDangerZoneHeader>
            <StyledDangerTitle>Danger Zone</StyledDangerTitle>
            <StyledDangerZoneDescription>
              Are you sure you want to delete your account? This action is
              irreversible and will permanently erase all your data. If you're
              sure, tap 'Delete account' below.
            </StyledDangerZoneDescription>
          </StyledDangerZoneHeader>
          <Button
            disabled={deleting}
            label={deleting ? 'Deleting...' : 'Delete account'}
            // onClick={deleteAccount}
            onClick={() => setDeleteModal(true)}
            title="Delete account"
            variant="danger"
          />
          <Modal
            showModal={deleteModal}
            setShowModal={setDeleteModal}
            title="Delete account"
            buttonVariant1="danger"
            buttonLabel1="Confirm & Delete"
            buttonVariant2="tertiary"
            buttonLabel2="Cancel"
            buttonOnClick1={deleteAccount}
            buttonOnClick2={() => setDeleteModal(false)}
            buttonDisabled1={deleting}
            buttonDisabled2={deleting}
            onclose={() => {
              if (deleting) return
              setDeleteModal(false)
            }}
            isLoading={deleting}
            signal="alert-triangle"
            signalIconType="warning"
            slotContent={
              <>
                <StyledDangerZoneDescription>
                  Are you sure you want to delete your account? This action is
                  irreversible and will permanently erase all your data. If
                  you're sure, tap 'Confirm & Delete' below.
                </StyledDangerZoneDescription>
              </>
            }
          />
          {/* <LongPressButton
            disabled={deleting}
            label={deleting ? 'Deleting...' : 'Delete account'}
            onClick={deleteAccount}
            title="Delete account"
          /> */}
        </StyledDangerZoneContainer>
      </MyAccountBodyContainer>
    </StyledAccountMainContainer>
  )
}

// --------------- Long press button ------------
interface LongPressButtonProps {
  disabled?: boolean
  label?: string
  onClick?: () => void
  title?: string
}

//
const LongPressButton: React.FC<LongPressButtonProps> = ({
  disabled,
  label,
  onClick,
  title
}) => {
  const [isPressed, setIsPressed] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const [countdown, setCountdown] = useState(DELETE_COUNT_DOWN)

  const handlePress = () => {
    setIsPressed(true)
    setCountdown(DELETE_COUNT_DOWN)

    timerRef.current = setInterval(() => {
      setCountdown((prevCount) => {
        const newCount = prevCount - 1

        if (newCount === 0) {
          clearInterval(timerRef.current as NodeJS.Timeout)
          if (onClick) {
            onClick()
          }

          // Reset state and timer
          setIsPressed(false)
        }

        return newCount
      })
    }, 1000) // 1000 milliseconds = 1 second
  }

  const handleRelease = () => {
    setIsPressed(false)
    setCountdown(DELETE_COUNT_DOWN)
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [])
  //
  return (
    <Button
      disabled={disabled}
      type="button"
      label={isPressed ? countdown + ' Press and hold to delete' : label}
      variant="danger"
      title={title || ''}
      onMouseDown={handlePress}
      onTouchStart={handlePress}
      onMouseUp={handleRelease}
      onMouseLeave={handleRelease}
      onTouchEnd={handleRelease}
      onTouchCancel={handleRelease}
      className={`long-press-button ${isPressed ? ' pressed' : ''}`}
    />
  )
}

export default Profile