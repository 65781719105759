import styled from "styled-components";
import { theme } from "../../../styles";

const TeamAndUserDetailBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-inline: auto;
  width: 100%;
  max-width: 800px;
  gap: 16px;
  padding: 16px 16px 40px 16px;

  background: ${theme.colors.white};

  @media screen and (min-width: 430px) {
    gap: 32px;
    padding: 32px 32px 32px 32px;
    border-radius: 8px;
    margin-top: 40px;
    border: 1px solid ${theme.colors.borderDefault};
    background: ${theme.colors.white};
  }
`;

export default TeamAndUserDetailBodyContainer;
