import styled from 'styled-components'
import { theme } from '../../../styles'
import Button from '../Button/Button'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import SignalIcon from '../SignalIcon/SignalIcon'
import IllustrationComponent, {
  IIllustrationType
} from '../IllustrationComponent/IllustrationComponent'

interface IBannerProps {
  variant?: 'icon' | 'illustration'
  type?: 'callout' | 'information' | 'warning' | 'error' | 'success'
  body: string
  title?: string
  showButton?: boolean
  showTitle?: boolean
  showIcon?: boolean
  showIllustration?: boolean
  illustrationType?: IIllustrationType
  illustrationSize?: 'xs' | 's' | 'm' | 'l' | 'xl'
  buttonLabel?: string
  handleButtonClick?: () => void
  isOpen?: boolean,
  isCloseable?: boolean
  handleClose?: () => void
}

const StyledBanner = styled.div<{ bgColor: string }>`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: ${({ bgColor }) => bgColor};

  @media screen and (min-width: 834px) {
    flex-direction: row;
    padding: 24px;
  }

  & > svg {
    fill: ${({ bgColor }) => bgColor};
  }
`

const StyledContainer = styled.div<{ variant?: 'icon' | 'illustration' }>`
  display: flex;
  align-items: ${({ variant }) =>
    variant === 'icon' ? 'flex-start' : 'center'};
  gap: 16px;
  align-self: stretch;

  @media screen and (min-width: 834px) {
    flex: 1 0 0;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: center;
`

const StyledTitle = styled.h3`
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.small};
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
  align-self: stretch;

  @media screen and (min-width: 834px) {
    font-size: ${theme.font.sizes.medium};
  }
`

const StyledBodyContent = styled.p`
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  align-self: stretch;

  @media screen and (min-width: 834px) {
    font-size: ${theme.font.sizes.large};
  }
`

const Banner = ({
  type = 'success',
  body,
  title,
  variant = 'icon',
  showButton = true,
  showTitle = true,
  showIcon = true,
  showIllustration = true,
  illustrationType = 'govern',
  illustrationSize = 's',
  buttonLabel,
  handleButtonClick,
  isCloseable = false,
  isOpen = true,
  handleClose
}: IBannerProps) => {
  const isMobile = useIsMobileDevice(834)

  const bgColorMap = {
    callout: theme.colors.actionPrimaryLight,
    information: theme.colors.signalInformationLight,
    warning: theme.colors.signalWarningLight,
    error: theme.colors.signalErrorLight,
    success: theme.colors.signalSuccessLight
  }

  const illustrationColorMap = {
    callout: theme.colors.actionPrimaryLightActive,
    information: theme.colors.signalInformationLightHover,
    warning: theme.colors.signalWarningLightHover,
    error: theme.colors.signalErrorLightHover,
    success: theme.colors.signalSuccessLightHover
  }

  if(!isOpen) return
  return (
    <StyledBanner bgColor={bgColorMap[type]}>
      {variant === 'illustration' && showIllustration && (
        <IllustrationComponent
          size={illustrationSize}
          type={illustrationType}
          bgColor={illustrationColorMap[type]}
        />
      )}
      <StyledContainer variant={variant}>
        {variant === 'icon' && showIcon && (
          <SignalIcon signal={type} background={false} />
        )}
        <StyledContent>
          {showTitle && title && <StyledTitle>{title}</StyledTitle>}
          <StyledBodyContent>{body}</StyledBodyContent>
        </StyledContent>
      </StyledContainer>
      {showButton && (
        <Button
          size={isMobile ? 'small' : 'medium'}
          label={buttonLabel}
          fullWidth={isMobile}
          onClick={handleButtonClick}
        />
      )}
      {isCloseable && (
        <Button
          iconOnly
          icon="x"
          size={isMobile ? 'small' : 'medium'}
          variant='utility'
          style={{
            background: 'transparent',
          }}
          onClick={handleClose}
        />
      )}
    </StyledBanner>
  )
}

export default Banner