import { Link } from "react-router-dom";
import {
  StyledHeaderContainer,
  StyledHeaderText,
  StyledMainContainer,
  StyledPageContainer,
} from "../../components/ui";
import Button from "../../components/ui/Button/Button";
import Label from "../../components/ui/Label/Label";

// *********** 404 notfound page *************
const NotFoundPage = () => {
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <StyledHeaderContainer>
          <Label text="Not found" style={{ alignSelf: "flex-end" }} />
          <StyledHeaderText>Oops! 404</StyledHeaderText>
        </StyledHeaderContainer>
        <p style={{ fontSize: "17px" }}>
          The page you are looking for isn’t here.
        </p>
        <Link to="/dashboard/">
          <Button type="button" icon="chevron-left" size="medium">
            Go back
          </Button>
        </Link>
      </StyledPageContainer>
    </StyledMainContainer>
  );
};

export default NotFoundPage;