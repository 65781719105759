import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ref, update } from 'firebase/database'
import { db } from '../../config/config'
import { toast } from 'react-toastify'
import { RootState } from '../../store'
import {
  ComponentObj,
  setComponentsData
} from '../../store/reducers/componentsReducerSlice'
import { PhaseObj, TaskObj } from '../../store/reducers/phaseReducerSlice'
import { TemplateObj } from '../../store/reducers/templateReducerSlice'
import { getTimeStamp, sortedDataByOrder } from '../../utils/helpers'
import useSetRecentlyViewedComponent from '../../hooks/useSetRecentlyViewedComponent'
import DetailHeader from '../../components/screens/Detail/DetailHeader'
import { StyledDetailMainContainer } from '../../components/screens/Detail/DetailView'
import DetailViewBodyContainer from '../../components/screens/Detail/DetailViewBodyContainer'
import ListPhaseItemsContainer from '../../components/screens/Detail/ListPhaseItemsContainer'
import PhaseItems from '../../components/ui/PhaseItems/PhaseItems'
import SubNav from '../../components/ui/SubNav/SubNav'
import Spinner from '../../components/ui/Spinner'
import Banner from '../../components/ui/Banner/Banner'
import useLocalStorage from '../../hooks/useLocalStorage'

// --------------- Component detail page --------------------
const ComponentDetails: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, pageId } = useParams()

  const [details, setDetails] = useState<ComponentObj | undefined>()
  const [currentTemplate, setCurrentTemplate] = useState<TemplateObj>()

  const { isLoading: isLoadingTemplates, data: templateList } = useSelector(
    (store: RootState) => store.template
  )

  const { data: components, isLoading: isLoadingComponents } = useSelector(
    (store: RootState) => store.component
  )

  const { currentTeam } = useSelector((store: RootState) => store.team)

  const { data: allPhases, isLoading: isLoadingPhases } = useSelector(
    (store: RootState) => store.phase
  )

  // sort based on order number
  const sortedUnArchivedPhaseByOrder: PhaseObj[] = useMemo(() => {
    const phases =
      allPhases?.filter((phase) => phase?.templateId === details?.templateId) ||
      []
    const unArchivedPhases = phases?.filter((phase) => !phase?.archived) || []
    return sortedDataByOrder(unArchivedPhases || [], 'order', 'asc')
  }, [allPhases, details])

  // count the total number of un-archived tasks
  const totalPhasesTasks = useMemo(() => {
    const phases =
      allPhases?.filter((phase) => phase?.templateId === details?.templateId) ||
      []
    const unArchivedPhases = Object.values(
      phases?.filter((phase) => !phase?.archived) || []
    )
    const totalUnArchivedTasks = unArchivedPhases?.reduce((acc, phase) => {
      const unArchivedTasksInPhase =
        phase?.tasks?.filter((task) => !task.archived).length || 0
      return acc + unArchivedTasksInPhase
    }, 0)
    return totalUnArchivedTasks
  }, [allPhases, details])

  const totalCompletedTasks = useMemo(() => {
    const allCompletedTasksIds = details?.completedTasks
    if (!allCompletedTasksIds) return 0

    const phases =
      allPhases?.filter((phase) => phase?.templateId === details?.templateId) ||
      []
    const unArchivedPhases = Object.values(
      phases?.filter((phase) => !phase?.archived) || []
    )
    const allUnArchivedTasks = unArchivedPhases?.flatMap(
      (phase) => phase?.tasks?.filter((task) => !task?.archived) || []
    )
    const completedTasks = allUnArchivedTasks?.filter((task) =>
      allCompletedTasksIds.includes(task?.id)
    )
    return completedTasks?.length || 0
  }, [details, allPhases])

  const sortedDataTaskList = (data: TaskObj[]) =>
    sortedDataByOrder(Object.values(data || []), 'order', 'asc')

  //
  const getTasksCompleted = (phs: PhaseObj) => {
    const list: TaskObj[] = []
    details?.completedTasks?.map((v) => {
      const obj: TaskObj =
        sortedDataTaskList(phs?.tasks || [])?.find((b) => b?.id === v) || {}
      // let obj = sortedDataTaskList(phs?.tasks || [])?.find((b) => b?.id === v)?.title || ""
      if (obj?.id) {
        list.push(obj)
      }
      return
    })
    return sortedDataByOrder(
      [...list]?.filter((v) => !v.archived),
      'order',
      'asc'
    )
  }

  const getTasksInCompleted = (phs: PhaseObj) => {
    const checkedItems = sortedDataTaskList(phs?.tasks || [])?.filter(
      (item) => !details?.completedTasks?.includes(item?.id)
    )
    const listNotCompleted: TaskObj[] = checkedItems
      ? [...checkedItems]?.map((item) => item)
      : []
    return sortedDataByOrder(
      [...listNotCompleted].filter((task) => !task.archived),
      'order',
      'asc'
    )
  }

  // handle task toggle check/uncheck
  const onTaskToggle = (taskId: string, uncheck?: any) => {
    if (!currentTeam?.id || !pageId) {
      toast.error('The current team is missing in action.')
      return
    }
    const timestamp = getTimeStamp()

    let newList = Object.values(details?.completedTasks || [])
    if (uncheck) {
      newList = newList?.filter((v) => v !== taskId)
    } else {
      newList.push(taskId)
    }

    if (details?.id) {
      update(ref(db, `components/${currentTeam?.id}/${pageId}`), {
        completedTasks: newList,
        updatedAt: timestamp
      }).catch((err) => {
        toast.error('Task status couldn’t be updated.')
        console.log('Error while toggling the task', err)
      })

      setDetails({
        ...details,
        updatedAt: timestamp,
        completedTasks: [...newList]
      })
      dispatch(
        setComponentsData({
          data: components?.map((page) => {
            if (page?.id === pageId) {
              return {
                ...page,
                updatedAt: timestamp,
                completedTasks: newList
              }
            }
            return page
          })
        })
      )
    }
  }

  // activate an phase
  const activatePhase = (phaseData: PhaseObj) => {
    if (!details?.id || details?.activePhase === phaseData?.id) return
    if (!currentTeam?.id || !id || !pageId) {
      toast.error('The current team is missing in action.')
      return
    }

    const timestamp = getTimeStamp()
    
    update(ref(db, `components/${currentTeam?.id}/${pageId}`), {
      activePhase: phaseData?.id || '',
      updatedAt: timestamp
    }).catch((err) => {
      toast.error('Phase couldn’t be activated.')
      console.log('Error while activating a phase with id ', phaseData?.id, err)
    })

    setDetails({
      ...details,
      updatedAt: timestamp,
      activePhase: phaseData?.id || ''
    })
    dispatch(
      setComponentsData({
        data: components?.map((page) => {
          if (page?.id === pageId) {
            return {
              ...page,
              updatedAt: timestamp,
              activePhase: phaseData?.id || ''
            }
          }
          return page
        })
      })
    )
  }

  const getActivePhase = useMemo((): PhaseObj | undefined => {
    const phases =
      allPhases?.filter((phase) => phase?.templateId === details?.templateId) ||
      []
    return phases
      ?.filter((phase) => !phase.archived)
      ?.find((v) => v?.id === details?.activePhase)
  }, [allPhases, details])

  // get details
  useEffect(() => {
    if (!isLoadingComponents) {
      const comDetails = components?.find((item) => item?.id === pageId)
      if (!comDetails) {
        // toast.info('No details available for this component.')
        navigate('/dashboard/components', { replace: true })
        return
      }
      setDetails(comDetails)
    }
  }, [id, pageId, isLoadingComponents, components])

  //get the current template of the component
  useEffect(() => {
    if (isLoadingTemplates || !details) return
    const template = templateList?.find(
      (tmp) => tmp?.id === details?.templateId
    )
    if (template) setCurrentTemplate(template)
  }, [details, isLoadingTemplates, templateList])

  useEffect(() => {
    const ele = document.getElementById('list-wrapper-ele')
    if (ele) {
      ele.scrollTop = 0
    }
  }, [id, pageId])

  const activePhaseColor =
    currentTemplate?.colors[getActivePhase?.colorId || '']

  // // add the current component to the top of the recently viewed list
  useSetRecentlyViewedComponent(id ?? '', pageId ?? '')

  // JSX
  return (
    <StyledDetailMainContainer id="list-wrapper-ele">
      <SubNav
        title="Component Status"
        onBtnClick={() => navigate('/dashboard/components')}
      />
      <DetailViewBodyContainer>
        {isLoadingTemplates || isLoadingComponents || isLoadingPhases ? (
          <div className="mx-auto">
            <Spinner type="spinner" />
          </div>
        ) : (
          <>
            <DetailHeader
              tasksCompleted={totalCompletedTasks}
              tasksTotal={totalPhasesTasks}
              componentName={
                details?.linkedElement?.name || details?.title || ''
              }
              componentId={details?.id || ''}
              phaseTitle={getActivePhase?.title || ''}
              figmaFileUrl={`https://www.figma.com/design/${details?.fileId}/${details?.linkedElement?.name || details?.title}?type=design&node-id=${details?.linkedElement?.id || pageId}&mode=design`}
              bgColor={activePhaseColor?.bg}
              textColor={activePhaseColor?.text || '#000'}
            />
            <ListPhaseItemsContainer>
              {!currentTemplate ? (
                <Banner
                  body="This component is linked to a template that has been removed. Please link the component to another template or remove this entry by clicking on the ellipsis."
                  title="Template removed"
                  type="warning"
                  variant="icon"
                  showButton={false}
                />
              ) : (
                sortedUnArchivedPhaseByOrder?.map((phs, i) => {
                  const color = currentTemplate?.colors[phs?.colorId || '']
                  if (!phs?.visible) return
                  return (
                    <PhaseItems
                      key={i}
                      checkedItems={getTasksCompleted(phs)}
                      unCheckedItems={getTasksInCompleted(phs)}
                      phaseTitle={phs?.title || ''}
                      activatePhase={() => activatePhase(phs)}
                      onTaskToggle={onTaskToggle}
                      textColor={color?.text || ''}
                      bgColor={color?.bg || ''}
                      isActive={details?.activePhase === phs?.id}
                    />
                  )
                })
              )}
            </ListPhaseItemsContainer>
          </>
        )}
      </DetailViewBodyContainer>
    </StyledDetailMainContainer>
  )
}

export default ComponentDetails