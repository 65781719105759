import React from 'react'
import styled from 'styled-components'
import { ConditionallyRender } from '../../../utils/ConditionallyRender'

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10rem;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
`

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
`

type FooterProps = {
  allQuestions: any[]
  currentQuestionIndex: number
  setCurrentQuestionIndex: (index: number) => void
  isPending: boolean
  onSubmit: any
}

export const Footer: React.FC<FooterProps> = ({
  allQuestions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  isPending,
  onSubmit
}) => {
  return (
    <FooterContainer>
      <Button
        onClick={() =>
          setCurrentQuestionIndex(Math.max(0, currentQuestionIndex - 1))
        }
        disabled={currentQuestionIndex === 0}
      >
        Previous
      </Button>
      <ConditionallyRender
        condition={currentQuestionIndex === allQuestions.length - 1}
        show={
          <Button type="submit" onClick={onSubmit} disabled={isPending}>
            {isPending ? 'Loading...' : 'Submit'}
          </Button>
        }
        elseShow={
          <Button
            onClick={() =>
              setCurrentQuestionIndex(
                Math.min(allQuestions.length - 1, currentQuestionIndex + 1)
              )
            }
          >
            Next
          </Button>
        }
      />
    </FooterContainer>
  )
}
