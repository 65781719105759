import styled, { css } from "styled-components"
import { theme } from "../../styles/theme"

export const StyledTemplatesMainContainer = styled.section`
    display: flex;
    padding: 40px 32px 40px 32px;
    flex-direction: column;
    align-items: flex-start;
    margin-inline: auto;
    width: 100%;
    max-width: 832px;
    gap:40px;
    overflow-y: auto;
    background: ${theme.colors.white};

    @media screen and (max-width: 430px) {
        gap:16px;
        padding: 24px 16px;
  }
`

export const StyledTemplateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`


export const StyledListItemContainer = styled.div<{
  disabled?: boolean
  isActionable?: boolean
  active?: boolean
}>`
  display: flex;
  width: 100%;
  max-width: 320px;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid ${theme.colors.borderDefault};
  cursor: pointer;
  @media screen and (min-width: 430px) {
    max-width: 480px;
    padding: 16px;
    gap: 16px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ active }) =>
    active &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colors.actionPrimaryLight};
    `}

  ${({ disabled, isActionable, active }) =>
    !disabled &&
    css`
      cursor: ${isActionable ? 'pointer' : 'default'};
      border-color: ${active
        ? theme.colors.actionPrimaryActive
        : theme.colors.borderDefault};
      &:hover {
        background-color: ${active ? theme.colors.actionPrimaryLight : theme.colors.actionPrimaryLightHover};
        border-color: ${active ? theme.colors.actionPrimaryActive :  theme.colors.actionPrimaryLightHover};
      }

      &:active {
        background-color: ${theme.colors.actionPrimaryLightActive};
        border-color: ${theme.colors.actionPrimaryLightActive};
      }
    `}
`

export const StyledItemContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
  user-select: none;
`

export const StyledItemTitle = styled.h4`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  text-overflow: ellipsis;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.body};
    font-weight: 420;
  }
`

export const StyledItemDescription = styled.p`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.colors.copySubtle};
  font-family: 'rooney-sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
  text-overflow: ellipsis;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.body};
    font-weight: 380;
  }
`
