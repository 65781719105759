import styled from 'styled-components'
import InputField from '../InputField/InputField'
import ButtonGroup from '../ButtonGroup/ButtonGroup'
import React, { useEffect, useState } from 'react'
import ColorPaletteItem from './ColorPaletteItem'
import HexColorPreview from './HexColorPreview'
import { PhaseObj } from '../../../store/reducers/phaseReducerSlice'
import { lightenColor } from '../../../utils/helpers'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { SECONDARY_ROW_COLORS } from '../../../constant'
import { determineTemplateNumber } from '../../../utils/determineTemplateNumber'

const StyledAddColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  flex-shrink: 0;
  width: 100%;
`

const StyledAddColorTitle = styled.h4`
  color: ${({ theme }) => theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`

const StyledColorPaletteItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  width: 100%;
`

interface IAddColorProps {
  onCancelHandler?: () => void
  onAddColorHandler?: () => void
  hexCode?: { hexCode: string; bg: string }
  onChangeHexCode?: (e: React.ChangeEvent<HTMLInputElement>) => void
  hexCodeSelected?: boolean
  setHexCode?: React.Dispatch<
    React.SetStateAction<{
      hexCode: string
      bg: string
    }>
  >
  formData?: PhaseObj
  templateId?: string
}

type ColorPickerItem = {
  hex: string
  selected?: boolean
}

const AddColor = ({
  onAddColorHandler,
  onCancelHandler,
  hexCode,
  onChangeHexCode,
  hexCodeSelected,
  setHexCode,
  formData,
  templateId
}: IAddColorProps) => {
  const [colorPickerList, setColorPickerList] = useState<ColorPickerItem[]>([])
  const { currentTeam } = useSelector((store: RootState) => store.team)

  useEffect(() => {
    const secondaryColors: Record<number, ColorPickerItem[]> = {
      ...SECONDARY_ROW_COLORS
    }

    const assignTemplateColors = async () => {
      if (!currentTeam?.id) {
        toast.error('Team ID not found.')
        return
      }

      const { templateNumber } = await determineTemplateNumber(
        currentTeam.id,
        templateId || ''
      )

      if (templateNumber > 3) {
        toast.info(
          `You have reached the maximum limit of 3 templates. Defaulting to template 1 colors.`
        )
      }

      // Set colorPickerList based on the determined template number
      setColorPickerList(secondaryColors[templateNumber])
    }

    assignTemplateColors()
  }, [currentTeam, templateId])

  return (
    <StyledAddColorContainer>
      <StyledAddColorTitle>ADD COLOR</StyledAddColorTitle>
      <StyledColorPaletteItemContainer>
        {colorPickerList?.map((color) => {
          return (
            <ColorPaletteItem
              key={color?.hex}
              color={color?.hex}
              selected={color?.selected}
              onClick={() => {
                setHexCode &&
                  setHexCode({
                    hexCode: color?.hex,
                    bg: lightenColor(color?.hex, 40)
                  })
                setColorPickerList(
                  colorPickerList.map((item) => ({
                    ...item,
                    selected: item.hex === color.hex
                  }))
                )
              }}
            />
          )
        })}
      </StyledColorPaletteItemContainer>
      <HexColorPreview
        hexCode={hexCode}
        selected={hexCodeSelected}
        formData={formData}
      />
      <InputField
        label="#HEX value"
        fullWidth
        onChange={onChangeHexCode}
        value={hexCode?.hexCode}
        type="text"
      />
      <ButtonGroup
        buttonLabel1="ADD COLOR"
        buttonVariant1="primary"
        buttonLabel2="Cancel"
        buttonVariant2="tertiary"
        buttonOnClick1={onAddColorHandler}
        buttonOnClick2={onCancelHandler}
      />
    </StyledAddColorContainer>
  )
}

export default AddColor
