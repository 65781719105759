import styled, { css } from 'styled-components'
import IconComponent from '../IconComponent/IconComponent'

const StyledColorState = styled.button<
    Omit<IColorStateProps, 'handleDeleteColor'>
>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  background-color: ${({ color }) => color || '#FFF'};

  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 100%;
  border: 1px solid
    ${({ theme, selected }) =>
        selected ? theme.colors.actionPrimaryHover : 'transparent'};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.actionPrimaryHover};
  }
  & > span {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  ${({ removable }) =>
        removable &&
        css`
      &:hover > #remove {
        display: flex;
        padding: 2px;
        border-radius: 24px;
        border: 1px solid ${({ theme }) => theme.colors.actionPrimaryHover};
        align-items: flex-start;
        gap: 8px;
      }
    `}

  ${({ disabled }) =>
        disabled &&
        css`
      cursor: not-allowed;
    `}
`

const StyledRemoveBtn = styled.div<{ removable?: boolean }>`
  display: flex;
  width: 24px;
  height: 24px;
  align-items: flex-start;
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: white;
  border-radius: 24px;
  color: ${({ theme }) => theme.colors.actionPrimary};
`

interface IColorStateProps {
    selected?: boolean
    locked?: boolean
    handleDeleteColor?: () => void
    color: string
    removable?: boolean
    onClick?: () => void
    disabled?: boolean
}

const ColorState = ({
    selected,
    locked,
    color,
    removable = true,
    handleDeleteColor,
    onClick,
    disabled
}: IColorStateProps) => {
    const stateIcon = selected ? 'check' : 'lock'

    return (
        <StyledColorState
        type='button'
            selected={selected}
            locked={locked}
            color={color}
            disabled={disabled}
        >
            <span onClick={disabled ? undefined : onClick}>
                {(selected || locked) && (
                    <IconComponent
                        color="white"
                        icon={stateIcon}
                        iconSize={locked ? 's' : 'm'}
                        disabled={disabled}
                    />
                )}
            </span>
            {removable && (
                <StyledRemoveBtn
                    onClick={disabled ? undefined : handleDeleteColor}
                    id="remove"
                    role="button"
                    tabIndex={0}
                >
                    <IconComponent icon="x" iconSize="s" disabled={disabled} />
                </StyledRemoveBtn>
            )}
        </StyledColorState>
    )
}

export default ColorState