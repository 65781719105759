import styled from 'styled-components'
import { theme } from '../../../styles'
import { ComponentTypes } from '../../../store/reducers/componentsReducerSlice'
import { Link } from 'react-router-dom'

export const StyledPhaseDeleteContentContainer = styled.div`
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media screen and (min-width: 834px) {
    font-size: ${theme.font.sizes.large};
  }
`

const StyledRemoveContainer = styled.p`
  display: inline;
  justify-content: center;
`

const StyledRemoveComponentText = styled(Link)`
  color: ${theme.colors.actionPrimary};
  cursor: pointer;
`

export const TemplateDeleteContent = ({
  componentNumber,
  type = 'component',
  templateId
}: {
  componentNumber: number
  type: ComponentTypes
  templateId: string
}) => {
  const getMessage = (componentNumber: number) => {
    return (
      <StyledRemoveContainer>
        Are you sure you want to delete this template? <br /> <br />
        {componentNumber > 0 && (
          <p>
            Removing the template will affect {componentNumber} {type}s.{' '}
            <StyledRemoveComponentText
              to={{
                pathname: '/dashboard/components',
                search: `?template=${templateId}`
              }}
            >
              You can see the components using this template here.
            </StyledRemoveComponentText>
          </p>
        )}
      </StyledRemoveContainer>
    )
    if (componentNumber < 1) {
      return `Are you sure you want to delete this template? By deleting this
          template, no component will be affected.`
    } else if (componentNumber === 1) {
      return `Are you sure you want to delete this template? By deleting this template, you will lose the data of one component using this template`
    }
    return `Are you sure you want to delete this template? By deleting this template, you will lose the data of the ${componentNumber} components using this template.`
  }

  return (
    <StyledPhaseDeleteContentContainer>
      {getMessage(componentNumber)}
    </StyledPhaseDeleteContentContainer>
  )
}

export const PhaseDeleteContent = () => {
    return (
        <StyledPhaseDeleteContentContainer>
            <p>
                Are you sure you want to delete this phase? Removing the phase will also
                remove:
            </p>
            <ul
                style={{
                    paddingLeft: '16px'
                }}
            >
                <li
                    style={{
                        listStyle: 'inside'
                    }}
                >
                    all included tasks
                </li>
                <li
                    style={{
                        listStyle: 'inside'
                    }}
                >
                    all registered data on this template
                </li>
            </ul>
        </StyledPhaseDeleteContentContainer>
    )
}

export const TaskDeleteContent = () => {
    return (
      <StyledPhaseDeleteContentContainer>
          <p>
              Are you sure you want to delete this task. Removing the task will also
              remove the action to carry out.
          </p>
      </StyledPhaseDeleteContentContainer>
  )
}
