import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducerSlice";
import templateReducer from "./reducers/templateReducerSlice";
import teamReducerSlice from "./reducers/teamReducerSlice";
import componentReducer from "./reducers/componentsReducerSlice";
import projectsReducer from "./reducers/projectsReducerSlice";
import phaseReducerSlice from "./reducers/phaseReducerSlice";

//
export const store = configureStore({
  reducer: {
    auth: authReducer,
    template: templateReducer,
    component: componentReducer,
    project: projectsReducer,
    team: teamReducerSlice,
    phase: phaseReducerSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, null, any>;
export type AppDispatch = typeof store.dispatch;
