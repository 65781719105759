import { styled } from 'styled-components'
import { theme } from '../../styles'
import GoogleSVG from '../assets/images/googleIcon.svg'

export const StyledMainContainer = styled.main`
  background-color: ${theme.colors.white};
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding: 25px 0;
  
  @media screen and (min-width: 834px) {
    background-color: ${theme.colors.white};
  }

  @media screen and (max-width: 768px) {
    height: 100%;
    min-height: -webkit-fill-available;
  }
`

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  flex-shrink: 0;
  width: 100%;
  max-width: 568px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${theme.colors.white};
  margin: 2rem auto;

  @media screen and (min-width: 834px) {
    padding: 40px 80px;
    border: 1px solid ${theme.colors.black};
  }
`

export const StyledHeaderAction = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 80px;
  background: ${theme.colors.backgroundLight};
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  & > a {
    color: ${theme.colors.actionPrimary};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`

export const StyledHeaderLabel = styled.p`
  color: ${theme.colors.copy};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  padding-bottom: 32px;
  // border-bottom: 4px solid ${theme.colors.borderDefault};
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`

export const StyledHeaderText = styled.h3`
  color: ${theme.colors.copy};
  font-size: 48px;
  font-weight: 500;
  line-height: 140%;
  font-family: rooney;

  @media screen and (min-width: 430px) {
    font-size: 64px;
  }
`

export const StyledCreateAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  align-self: stretch;
`

export const StyledSecondaryText = styled.p`
  color: ${theme.colors.copy};
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
`

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
export const StyledConfirmType = styled.p`
  color: ${theme.colors.copy};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  & > a {
    color: ${theme.colors.actionPrimary};
  }
`

export const StyledCreateFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`

export const StyledLink = styled.a`
  color: ${theme.colors.actionPrimary};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`
export const StyledParagraphText = styled.h3`
  color: ${theme.colors.copy};
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  align-self: flex-start;
  margin-top: 16px;

  @media screen and (min-width: 430px) {
    font-size: 24px;
  }
`

export const GoogleIcon = () => {
  return <img src={GoogleSVG} alt="google" />
}
