import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import Nav from '../Nav/Nav'

interface IMobileMenuDrawerProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  logout?: () => void
}

const MobileMenuDrawer = ({
  isOpen = true,
  setIsOpen,
  logout
}: IMobileMenuDrawerProps) => {
  const [logoutConfirmModal, setLogoutConfirmModal] = useState<boolean>(false)
  const [switchTeamModal, setSwitchTeamModal] = useState<boolean>(false)

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      size={280}
      className="fill-available-height mobile-drawer"
    >
      <Nav
        logout={logout}
        setLogoutConfirmModal={setLogoutConfirmModal}
        setSwitchTeamModal={setSwitchTeamModal}
      />
    </Drawer>
  )
}

export default MobileMenuDrawer
