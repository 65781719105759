import styled, { css } from 'styled-components'
import { theme } from '../../../styles'

// ------------------------Color Palette Item Component ------------------------

const StyledColorPaletteItem = styled.button<
    Pick<IColorPaletteItemProps, 'color' | 'selected'>
>`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 2px;
  border-radius: 5px;
  border: 1px solid transparent;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: ${({ color }) => color || '#FFF'};

  ${({ selected }) =>
        selected &&
        css`
      border: 1px solid ${theme.colors.actionPrimaryActive};
    `};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.actionPrimaryHover};
  }
`

interface IColorPaletteItemProps {
    color?: string
    onClick?: () => void
    selected?: boolean
}

const ColorPaletteItem = ({
    color,
    selected,
    onClick
}: IColorPaletteItemProps) => {
    return (
        <StyledColorPaletteItem
            color={color}
            onClick={onClick}
            selected={selected}
        type='button'
        />
    )
}

export default ColorPaletteItem