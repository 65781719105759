import { HTMLAttributes, RefObject } from 'react'
import { css, styled } from 'styled-components'
import IconComponent from '../IconComponent/IconComponent'
import { theme } from '../../../styles'
import { FeatherIconNames } from 'feather-icons'

interface IFeedBackMessageProps {
  feedbackMessage?: boolean
  feedbackMessageType?: 'success' | 'information' | 'warning' | 'error'
  feedbackMessageContent?: string
}

interface IInputField extends HTMLAttributes<HTMLInputElement> {
  autoFocus?: boolean
  label: string
  name?: string
  fullWidth?: boolean
  required?: boolean
  readOnly?: boolean
  type?: 'text' | 'password' | 'number' | 'email'
  disabled?: boolean
  value?: string | number
  defaultValue?: string | number
  placeholder?: string
  icon?: FeatherIconNames
  inputRef?: RefObject<HTMLInputElement>
}

type IInputFieldProps = IInputField & IFeedBackMessageProps

const StyledInputFieldContainer = styled.div<
  Pick<IInputField, 'fullWidth' | 'readOnly' | 'disabled'>
>`
  gap: 8px;
  border: 1px solid ${theme.colors.copySubtle};
  padding: 16px;
  border-radius: 4px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  border: ${({ readOnly }) =>
    readOnly
      ? '1px solid transparent'
      : `1px solid ${theme.colors.borderDefault}`};
  background-color: ${({ disabled }) =>
    disabled ? `${theme.colors.actionDisabled}` : 'transparent'};

  &:focus-within {
    border: ${({ readOnly }) =>
      readOnly ? 'none' : `1px solid ${theme.colors.actionPrimary}`};
  }
`

const StyledInputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: auto 0;
`

const StyledInputField = styled.input<IInputField>`
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  color: ${theme.colors.copy};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 100%;
  &:focus-within {
    text-overflow: unset;
    overflow: auto;
    white-space: normal;
  }

   &::placeholder{
    color:${theme.colors.copySubtle}
  }
`

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 32px;
  max-height: 32px;
  position: relative;
`

const StyledIcon = styled(IconComponent)`
  position: absolute;
  left: 8px;
  z-index: 1;
`

export interface IStyledFeedbackMessageProps
  extends HTMLAttributes<HTMLDivElement> {
  messageType?: 'information' | 'success' | 'warning' | 'error'
  messageText?: string
}

const StyledFeedbackMessageBackground = styled.div<IStyledFeedbackMessageProps>`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  gap: 8px;
  overflow: hidden;
  width: fit-content;
  margin-top: 8px;

  ${({ theme, messageType }) => {
    let _bg = messageType && theme.colors.label[messageType].background
    return css`
      background: ${_bg};
    `
  }}
`

const StyledFeedbackMessageText = styled.p<IStyledFeedbackMessageProps>`
  flex: 1 0 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 100%;

  ${({ theme, messageType }) => {
    let _color = messageType && theme.colors.label[messageType].labelText
    return css`
      color: ${_color};
    `
  }}
`

export const FeedbackMessage = ({
  messageText,
  messageType = 'success'
}: IStyledFeedbackMessageProps) => {
  return (
    <StyledFeedbackMessageBackground messageType={messageType}>
      <StyledFeedbackMessageText messageType={messageType}>
        {messageText}
      </StyledFeedbackMessageText>
    </StyledFeedbackMessageBackground>
  )
}

const InputField = ({
  label,
  feedbackMessage = false,
  feedbackMessageContent,
  feedbackMessageType = 'success',
  fullWidth = false,
  required = true,
  readOnly = false,
  type = 'text',
  name,
  value,
  defaultValue,
  autoFocus = false,
  disabled = false,
  onChange, // New prop for the onChange handler
  icon,
  inputRef
}: IInputFieldProps) => {
  return (
    <>
      <StyledInputFieldContainer
        fullWidth={fullWidth}
        readOnly={readOnly}
        disabled={disabled}
      >
        <StyledInputFieldWrapper>
          {['password', 'number', 'email'].includes(type!) ? (
            <StyledIconWrapper>
              <StyledIcon
                icon={
                  type === 'password'
                    ? 'key'
                    : type === 'number'
                      ? 'phone'
                      : 'mail'
                }
                iconSize="m"
                color="gray"
                disabled={false}
              />
            </StyledIconWrapper>
          ) : icon ? (
            <StyledIconWrapper>
              <StyledIcon
                icon={icon}
                iconSize="m"
                color="gray"
                disabled={false}
              />
            </StyledIconWrapper>
          ) : null}
          <StyledInputField
            placeholder={label}
            fullWidth={fullWidth}
            label={label}
            required={required}
            readOnly={readOnly}
            type={type}
            autoFocus={autoFocus}
            disabled={disabled}
            onChange={onChange}
            name={name}
            value={value}
            defaultValue={defaultValue || ''}
            ref={inputRef}
          />
        </StyledInputFieldWrapper>
      </StyledInputFieldContainer>

      {feedbackMessage && (
        <FeedbackMessage
          messageText={feedbackMessageContent}
          messageType={feedbackMessageType}
        />
      )}
    </>
  )
}

export default InputField
