import { Link } from "react-router-dom";
import {
  StyledDescription,
  StyledHeaderAction,
  StyledHeaderContainer,
  StyledHeaderText,
  StyledMainContainer,
  StyledPageContainer,
} from "../../components/screens/PrivacyPolicy/PrivacyPolicy";
import Button from "../../components/ui/Button/Button";

const CookiePolicy = () => {
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <StyledHeaderAction>
          <Link to="/dashboard/profile">
            <Button variant="tertiary" icon="chevron-left" size="medium">
              Go back
            </Button>
          </Link>
        </StyledHeaderAction>
        <StyledHeaderContainer>
          <StyledHeaderText>COOKIE POLICY</StyledHeaderText>
        </StyledHeaderContainer>
        <StyledDescription>
          <p>Thank you for using Compfeed</p>
          <br />
          <p>Compfeed is owned and operated by Paksa.</p>
          <br />
          <br />
          <p>
            This cookie policy explains how and why cookies and other similar
            technologies may be stored on and accessed from your device when you
            use or visit:
          </p>
          <br />
          <br />
          <ul>
            <li>
              <a href="https://www.govern.design">https://www.govern.design</a>
            </li>
            <li>
              <a href="https://app.govern.design">https://app.govern.design</a>
            </li>
          </ul>
          <br />
          <p>(Here in after referred to as “Compfeed”).</p>
          <br />
          <p>
            The information collected through cookies will be under the
            responsibility and in charge of:
          </p>
          <br />
          <ul>
            <li>
              <strong>Paksa</strong>
            </li>
            <li>
              <strong>wart@paksastudios, ‭+1 (929) 577-4084</strong>
            </li>
          </ul>
          <br />
          <p>
            The cookie policy presented here should be considered in conjunction
            with our privacy policy and terms and conditions. By using our
            website, you consent to using cookies by Compfeed as set
            out in this policy. Please read our policy carefully.
          </p>
          <br />

          <h3>1. WHAT ARE COOKIES?</h3>
          <br />
          <p>
            When you visit a website from your computer or mobile device, small
            text files known as cookies are stored. These files allow the
            website to recognize your device and remember your previous visits.
            The implementation of cookies is a common practice in web technology
            and has been adopted by most websites for many years. Their main use
            is to optimize the efficiency of the site. In addition, cookies are
            essential to analyze the sections of the site that users visit most
            frequently and to adapt the browsing experience to their
            preferences. They also provide valuable information that assists us
            in analyzing and continuously improving the performance of the
            website.
          </p>
          <br />
          <br />
          <h3>2. CONSENT</h3>
          <br />
          <p>
            When you access our website, you will be presented with a notice
            about using cookies. By clicking 'Accept' on this notice, you
            provide your explicit consent to using cookies on your device. These
            cookies are used to enhance your browsing experience, personalize
            content, analyze our site traffic, and provide specific
            functionality related to our online operations.
            <br />
            <br />
            Your consent lets us give you a more personalized and efficient user
            experience. If you wish to withdraw your consent at any time, you
            may change the cookie settings in your browser. However, please note
            that disabling certain cookies may limit the site’s functionality
            and affect your user experience.
          </p>
          <br />

          <h3>3. LEGAL BASIS AND LEGITIMATE INTERESTS</h3>
          <br />
          <br />
          <p>
            Our website uses cookies based on our legitimate interests to ensure
            the best user experience and continuously improve our website. These
            interests include optimizing our online offering, personalizing
            content, and advertising, and ensuring the security and efficiency
            of our website.
            <br />
            <br /> Cookies help us understand how users interact with our
            website, which allows us to adjust and improve our services and
            functionalities according to their needs and preferences.
            <br />
            <br /> While these interests do not outweigh your fundamental rights
            and freedoms, you can refuse or manage cookies through your browser
            settings or the tools we provide on our website.
          </p>
          <br />
          <br />
          <h3>4. WITHDRAWAL AND OBJECTION (OPT-OUT)</h3>
          <br />
          <br />
          <p>
          By accessing our website, you agree to use cookies that enhance the browsing experience and allow the personalization of content and advertisements. However, we understand and respect your right to privacy. If you wish to opt out or exercise your choice not to participate (Opt-Out), you can set your browser to refuse all cookies or indicate when a cookie is being sent.
<br/><br/>Please note that some site features may not function properly without cookies. In addition, we provide links or tools on our site that allow you to decline the use of certain cookies specifically.
          </p>
          <br /><br />
          <h3>5. STORAGE DURATION OF COOKIES</h3>
          <br /><br />
          <p>
          The cookies used on our website have different storage periods, depending on their purpose. Essential cookies, necessary for the basic operation of the website, are kept as long as necessary to provide the requested services. 
          <br /><br />Functionality cookies, which remember your preferences and settings, are stored for a period that can vary from the duration of your visit (session cookies) to several years to ensure a constant user experience. Analytics and advertising cookies have a maximum storage duration of 24 months, after which they will be automatically deleted. 
          <br /><br />You can delete or manage these cookies anytime through your browser settings. Deleting or rejecting cookies may affect the functionality of the site and the quality of your experience on our website.
          </p>
          <br /><br />
          <h3>6. COOKIE SETTINGS</h3>
          <br /><br />
          <p>
          If you do not want cookies to be dropped on your device, you can adjust your Internet browser’s settings to reject the setting of all cookies and alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help,’ ‘tool,’ or ‘edit’ section. Please note that if you use your browser settings to block all cookies, including strictly necessary cookies, you may not be able to access or use all or parts of the functionalities of Compfeed.
          <br /><br />If you want to remove previously stored cookies, you can manually delete the cookies at any time. However, this will not prevent Compfeed from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.
          </p>
          <br /><br />
          <p>
          We provide the links for the management and blocking of cookies depending on the browser you use:
          </p>
          <br /><br />
          <ul>
            <li>
              <strong>
              Microsoft Edge: 
              </strong>
              <a href="https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us" target="_blank">View more</a>
            </li>
            <li>
              <strong>
              Firefox: 
              </strong>
              <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target="_blank">View more</a>
            </li>
            <li>
              <strong>
              Chrome: 
              </strong>
              <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">View more</a>
            </li>
            <li>
              <strong>
              Safari: 
              </strong>
              <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">View more</a>
            </li>
            <li>
              <strong>
              Opera: 
              </strong>
              <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">View more</a>
            </li>
          </ul>
          <br /><br />
        <p>
        In cases where you access the website through an iOS or Android mobile device, please follow the instructions below to delete or block cookies on your device:
        </p>
          <br /><br />
          <ul>
            <li><strong>Android: </strong>
            <a href="https://support.google.com/answer/32050" target="_blank">View more</a>
            </li>
            <li><strong>IOS: </strong>
            <a href="https://support.apple.com/en-us/HT201265" target="_blank">View more</a>
            </li>
          </ul>
          <br /><br />
          <h3>
          7. COOKIES WE USE
          </h3>
          <br /><br />
          <p>
          We use the following cookies on our website:
          </p>
          <br />
          <img src={"./cookie-policy.png"} alt="" style={{width: "100%", userSelect: "none", pointerEvents: "none"}} />
          <br /><br />
          <h3>8. PURPOSES OF OUR COOKIES</h3>
          <br /><br />
          <p>
          Our cookies are used for the following purposes:
          </p>
          <br />
          <p>
          <strong>Necessary:</strong> Necessary cookies are essential files that enable basic functions on the website, such as navigation and security. They do not collect personal information.<br /><br />
<strong>Functional:</strong> We use functional cookies to enhance your online experience by remembering user preferences and settings and maintaining consistency across the website without tracking activities.<br /><br />
<strong>Analytics and Performance:</strong> These cookies collect information about how users interact with our website, including the most visited pages and other analytical data. We use this data to improve how our website works and to understand how users interact with the website.
          </p>
          <br /><br />
          <h3>9. CONTACT US</h3>
          <br /><br />
          <p>If you have questions or concerns about this cookie policy and the handling and security of your data, please contact us through our contact forms or by using the contact information below:
          </p><br/>
          <p><strong>Govern, Inc - Compfeed.</strong></p><br />
          <p><strong><a href="mailto:wart@govern.design" target="_blank">wart@govern.design</a></strong></p><br />
          <p><strong><a href="tel:+1929577-4084‬">+1 (929) 577-4084‬</a></strong></p>

          {/* <p><strong>Information you provide to us.</strong> You provide information when you request access to the beta version of the platform, register as a user, purchase a subscription, use the functionalities available on the platform, subscribe to our newsletter and/or communicate with us through our contact details and contact form and provide us with personal information to communicate with you. As a result of those actions, you may provide us with the following information:</p><br/>

            <ul>
              <li>Name</li>
              <li>E-mail address</li>
              <li>Any additional information related to you that you provide to us directly or indirectly through our platform.</li>
            </ul>

            <p>Compfeed will not collect personally identifiable information about you unless you provide it.</p><br/>

            <p><strong>Information Collected Automatically:</strong> By accessing and using the platform, you automatically provide us with the following information:</p><br/>

            <ul>
            <li>Your IP address</li>
            <li>Referring URLs</li>
            </ul>

            <p>If you access the platform through a mobile phone, we will collect the following information:</p><br/>

            <ul>
            <li>Mobile device ID</li>
            <li>IP address</li>
            </ul>

            <p><strong>Payment Information:</strong> Your payment details will be processed by the payment processors available on this platform, who will process and store your data securely and solely to process the purchase of subscriptions. Compfeed reserves the right to engage any payment processor available.</p><br/>

            <p>See Stripe's privacy policy here:</p><br/>

            <ul>
            <li><a href="https://stripe.com/privacy">https://stripe.com/privacy</a></li>
            </ul>

            <p><strong>GOOGLE Analytics.</strong> We use Google Analytics provided by Google, Inc., USA (“Google”). These tools and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics data collection to enhance the platform and improve our service.</p><br/>

            <p>Please consult Google's privacy policy here:</p><br/>

            <ul>
            <li><a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></li>
            </ul>

            <p><strong>Social Media:</strong> Our website offers a variety of links and functionalities associated with various social networks, giving you the option to share your information on them. We recommend that you carefully review the privacy and data protection policies of each of the social networks you use through our website.</p><br/>

            <ul>
            <li>X (Twitter): <a href="https://twitter.com/privacy">https://twitter.com/privacy</a></li>
            </ul>

            <p><strong>Contact information:</strong> We may access personal information about the user, such as name and email address, when the user or any third party communicates with us through our contact information. Personal information provided through our contact information is not stored on any Compfeed server and will be stored on the respective server of our email service.</p><br/>

            <h3>4. HOW LONG WE KEEP YOUR DATA</h3>

            <p>Personal data provided by users through the platform will be retained for as long as necessary to deliver the functionalities available on the platform, provide subscriptions, fulfill the legitimate purposes described in this policy, or until the user closes the user account or requests the deletion of their data. Compfeed may retain personal data for a longer period provided the user has consented to such processing, as long as such consent is not withdrawn. Compfeed may need to retain personal data for an extended period if required to do so in order to comply with a legal obligation or by order of a competent authority. After this retention period, we will delete the personal data. Consequently, once this time has elapsed, it will no longer be possible to exercise the rights of access, deletion, rectification, and data portability.</p><br/>

            <h3>5. HOW WE USE YOUR INFORMATION (LEGITIMATE PURPOSES)</h3>

            <p>In general, we use the data we collect primarily to provide, maintain, protect, and improve our platform and appropriately provide the functionality available on the platform. We use the data collected through our platform as described below:</p><br/>

            <ul>
            <li>Provide the beta version of the platform.</li>
            <li>User registration.</li>
            <li>Provide the functionalities available in the platform.</li>
            <li>Provide the subscriptions.</li>
            <li>Processing payments through our payment processor.</li>
            <li>Understand and improve your experience using our platform.</li>
            <li>Respond to your comments or questions through our contact information.</li>
            <li>Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.</li>
            <li>Send you relevant information about Compfeed.</li>
            <li>Compfeed marketing purposes.</li>
            <li>Link or combine your information with other data we obtain from third parties to help us understand your needs and provide you with better service.</li>
            <li>Protect, investigate, and deter fraudulent, unauthorized, or illegal activities.</li>
            </ul>

            <h3>6. LEGAL BASIS</h3>

            <p>In accordance with European data protection regulations, the processing of users' personal data on our platform is based on specific legal grounds or legitimate purposes. These legitimate purposes are described in section 5 of this policy.</p><br/>

            <p>In certain situations, we request explicit user consent for specific data processing activities, such as sending marketing communications. In addition, we use our legitimate purposes to improve our services, conduct market analysis, and ensure the security of our platform and user information while ensuring that these purposes do not infringe on users' rights.</p><br/>

            <p>This data processing is carried out with full respect for the rights of users under the General Data Protection Regulation (GDPR) and other data protection laws applicable in Europe.</p><br/>

            <h3>7. HOW WE SHARE INFORMATION</h3>

            <p>Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below.</p><br/>

            <p><strong>Third-Party Service Providers.</strong> We use third-party services to perform certain functions on our platform. Some of these functions and services are Building and hosting the platform, processing payments (Stripe), sending emails (MailChimp), and analyzing data (Google Analytics).</p><br/>

            <p>These third-party services and tools may have access to personal information needed to perform their functions but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy.</p><br/>

            <p><strong>Business Transfers.</strong> If Compfeed creates, merges with, or is acquired by another entity, your information will likely be transferred. Compfeed will send you an email or post a prominent notice on our platform before your information becomes subject to another privacy policy.</p><br/>

            <p><strong>Protection of Compfeed and Others.</strong> We release personal information when we believe it is appropriate to comply with the law, enforce or apply our terms and conditions and other agreements, or protect the rights, property, or safety of Compfeed, our users, or others; this includes exchanging information for fraud protection and credit risk reduction with other companies and organizations.</p><br/>

            <p><strong>Anonymous Information.</strong> Compfeed uses anonymous browsing information collected automatically by our servers primarily to help us administer and improve the platform. We may also use aggregated anonymous information to provide information about the Platform to potential business partners and other unaffiliated entities. This information is not personally identifiable.</p><br/>

            <h3>8. DATA BREACH NOTIFICATIONS</h3>

            <p>In the event of a security breach that compromises the confidentiality of our user’s personal data, Compfeed undertakes to notify those affected promptly. This notification will be made through the means of contact that the user on our platform has provided. We will take all reasonable measures to protect the information and remedy any situation that compromises the security of your data.</p><br/>

            <h3>9. INTERNATIONAL DATA TRANSFER</h3>

            <p>By using our platform, you agree that your personal data may be transferred and processed outside the European Economic Area, where data protection legislation may differ from that of your country. Compfeed is committed to taking the necessary steps to ensure that your data is treated in accordance with applicable privacy protection rules and is adequately protected during any international transfer.</p><br/>

            <h3>10. PROTECTION OF YOUR INFORMATION</h3>

            <p>We grant access to your personal information only to those outside persons or services that have a legitimate need to know it and in accordance with our privacy policy. We adhere to industry-recognized security standards to protect your personal information during transmission and in storage. However, it is important to note that no method of transmission over the Internet or electronic storage is foolproof and 100% secure. Therefore, while we at Compfeed strive to implement commercially viable data protection methods, we cannot ensure the absolute security of your personal information. We undertake not to sell, distribute or transfer your personal data to unauthorized third parties unless we have your explicit consent or are required by law to do so.</p><br/>

            <h3>11. RIGHTS</h3>

            <p>Users who provide information through our platform as data subjects have the right to access, rectify, download, or delete their information and restrict and oppose specific processing of their information. While some of these rights apply generally, others only apply in certain limited circumstances. These rights are described below:</p><br/>

            <ul>
            <li><strong>Access and portability:</strong> to access and know what information is stored on our servers, you can send us your request through our contact information.</li>
            <li><strong>Rectification, restriction, limitation, and deletion:</strong> You may also rectify, restrict, limit or delete much of your information.</li>
            <li><strong>Right to be informed:</strong> Users of our platform will be informed, upon request, about what data we collect, how it is used, how long it is kept, and whether it is shared with third parties.</li>
            <li><strong>Object:</strong> Where we process your data based on our legitimate interests, as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have legitimate grounds to continue processing it or, where necessary, for legal reasons.</li>
            <li><strong>Withdraw consent:</strong> Where you have previously given your consent, for example, to allow us to process and store your personal information, you have the right to withdraw your consent to the processing and storing of your data at any time. For example, you can withdraw your consent by updating your settings. In some instances, we may continue to process your information after you have withdrawn your consent if we have a lawful basis for doing so or if your withdrawal of consent was limited to certain processing activities.</li>
            <li><strong>Complaint:</strong> If you wish to lodge a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users may exercise these rights by contacting us via the contact information or contact page.</li>
            <li><strong>Rights related to automated decision-making, including profiling:</strong> Users may request that we provide them with a copy of our automated processing activities if they believe that data is being unlawfully processed.</li>
            </ul>

            <p>Users or holders of personal data provided through the platform may exercise these rights over their personal data at any time and without limitation by sending their request through our contact information. The request to exercise their rights will be attended to and answered within a maximum period of 10 working days.</p><br/>

            <h3>12. PROTECTION OF CHILDREN'S ONLINE PRIVACY</h3>

            <p>We comply with the requirements of national and international data protection regulations regarding the protection of minors’ personal data. Although the platform is available to all ages, we do not collect any information from children under the age of 13 without the respective permission of their parents or legal guardians. If we become aware that a child under the age of 13 has provided us with personal information without the permission of his or her parent or legal guardian, we will take the necessary steps to delete that information.</p><br/>

            <h3>13. THIRD PARTIES</h3>

            <p>Except as expressly included in this privacy policy, this document addresses only the use and disclosure of information Compfeed collects from you. If you disclose your information to others, whether other users or Compfeed vendors, different rules may apply to their use or disclosure of the information you disclose. Compfeed does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Compfeed is not responsible for the privacy or security practices of other Internet platforms, including those linked to or from the Compfeed platform. Please review the privacy policies of any third-party websites or services you access through our platform.</p><br/>

            <h3>14. CONTACT</h3>

            <p>If you have questions or concerns about this privacy policy and the treatment and security of your data, please contact us using the contact information below:
            Paksa - Compfeed.
            (<a href="mailto:support@govern.design">support@govern.design</a> - +1 (929) 577-4084)</p><br/> */}
        </StyledDescription>
      </StyledPageContainer>
    </StyledMainContainer>
  );
};

export default CookiePolicy;
