import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

// Handling action url instead of firebase generated
function CheckModePage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const mode = searchParams.get('mode')
  const oobCode = searchParams.get('oobCode')
  const apiKey = searchParams.get('apiKey')
  const continueUrl = searchParams.get('continueUrl') || ''

  useEffect(() => {
    if (!continueUrl) return navigate('/dashboard')
    // the URL string
    const urlString = continueUrl
    // Parsing the URL and extracting the query parameters
    const url = new URL(urlString)
    const queryParams = new URLSearchParams(url?.search)

    // Convert query parameters to an object
    const paramsObj: any = {
      oobCode,
      apiKey,
      mode
    }

    queryParams.forEach((value, key) => {
      paramsObj[key] = value
    });

    const { webUrl = '' } = paramsObj

    const newUrlObj = new URL("/verify-login", webUrl);
    delete paramsObj.webUrl;
    delete paramsObj.i;
    const newQueryParams = new URLSearchParams(paramsObj)
    newUrlObj.search = newQueryParams.toString()

    const redirectLink = newUrlObj.toString();

    // return
    if (!mode || !oobCode || !apiKey) {
      if (webUrl) {
        window.location.replace(webUrl)
      } else {
        navigate('/dashboard')
      }
      return
    }
    if (mode === 'signIn') {
      const signInUrl = `/verify-login?apiKey=${apiKey}&mode=${mode}&oobCode=${oobCode}`
      if (webUrl) {
        window.location.replace(redirectLink)
      } else {
        navigate(signInUrl)
      }
    } else {
      if (webUrl) {
        window.location.replace(webUrl)
      } else {
        navigate('/dashboard')
      }
    }
  }, [mode, oobCode, apiKey])

  return null
}

export default CheckModePage
