import { useEffect } from 'react'
// import * as CookieConsent from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
// import { COOKIE_CONSENT_CONFIGS } from './constant'
import ScrollTop from './utils/ScrollTop'
import { Outlet, useLocation } from 'react-router-dom'
// import Hotjar from '@hotjar/browser'
import { checkAuth } from './store/reducers/authReducerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from './store'
// import BetaCodeRequest from './pages/BetaCodeRequest'
import DemoVideo from './components/ui/DemoVideo/DemoVideo'


// const BETA_CLOSED = import.meta.env.VITE_APP_BETA_CLOSED === 'true'

// ------------------------- Main App wrapper ------------------------
const App = () => {
  const dispatch = useDispatch<AppDispatch>()
  const pathname = useLocation().pathname
  // const [betaCode, setBetaCode] = useState<string>('')
  // const isDevelopmentEnv = process.env.NODE_ENV === 'development'
  const { isAuthenticated } = useSelector((store: RootState) => store.auth)

  // cookie consent
  // useEffect(() => {
  //   if (isDevelopmentEnv) return
  // CookieConsent.run({
  //   ...COOKIE_CONSENT_CONFIGS,
  //   onConsent: ({ cookie }) => {
  //     let cate = cookie?.categories
  //     if (cate?.includes('analytics')) {
  //       const siteId = import.meta.env.VITE_APP_HOTJAR_ID || ''
  //       const hotjarVersion = 6
  //       if (!siteId) return
  //       Hotjar.init(Number(siteId), hotjarVersion)
  //     }
  //   }
  // })
  // }, [])

  // check auth state change
  useEffect(() => {
    dispatch(checkAuth())
  }, [])

  // get beta code from local storage
  // useEffect(() => {
  //   let code = localStorage.getItem('betaCode')
  //   setBetaCode(code || '')
  // }, [])

  // JSX
  // Ask beta code only when app is set to beta closed
  // in development mode, beta code is not required
  // if (BETA_CLOSED && !betaCode && !isDevelopmentEnv)
  //   return <BetaCodeRequest setBetaCode={setBetaCode} />
  console.log(pathname)
  return (
    <>
      <ScrollTop />
      <Outlet />
      {isAuthenticated && pathname !== '/ai-template' ? (
        <DemoVideo
          title="Quick Onboarding"
          videoId="Yg4aFrzJ8dg"
          videoDuration="2 min"
        />
      ) : null}
    </>
  )
}

export default App
