import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { Navigate, NavLink } from 'react-router-dom'
import {
  StyledConfirmType,
  StyledMainContainer,
  StyledPageContainer
} from '../../components/ui'
import { StyledHeaderText2 } from './GetAuthPage'
import Button from '../../components/ui/Button/Button'
import Logo from '../../components/assets/images/logo'

const WidgetAuthSuccess: React.FC = () => {
  return <Navigate to="/dashboard/components?modal=widget-success" replace />
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <div
          className="flex"
          style={{
            width: '100%',
            padding: '28px 0 18px 0',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px'
          }}
        >
          <FeatherIcon icon="figma" size={90} style={{ flexShrink: 0 }} />
          <FeatherIcon icon="link-2" size={58} style={{ flexShrink: 0 }} />
          <Logo width={92} />
        </div>
        <StyledHeaderText2>Figma is now connected</StyledHeaderText2>
        <StyledConfirmType>
          You have successfully connected Figma to Compfeed. You can continue
          using the widget in Figma.
        </StyledConfirmType>
        <div style={{ width: '100%' }}>
          <a href="figma://open" style={{ display: 'block', width: '100%' }}>
            <Button fullWidth variant="primary">
              OPEN FIGMA
            </Button>
          </a>
          <NavLink
            to="/dashboard"
            replace
            style={{ margin: "15px auto 0 auto", width: "max-content", display: "block"  }}
          >
            <Button
              variant="tertiary"
              style={{ }}
            >
              VIEW DASHBOARD
            </Button>
          </NavLink>
        </div>
        <StyledConfirmType>
          <a href={import.meta.env.VITE_APP_HELP_URL} target="_blank">
            Need help?
          </a>
        </StyledConfirmType>
      </StyledPageContainer>
    </StyledMainContainer>
  )
}

export default WidgetAuthSuccess
