import {
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithPopup
} from 'firebase/auth'
import { push, ref, set, update } from 'firebase/database'
import React, { FormEvent, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { auth, db } from '../../config/config'
import { toast } from 'react-toastify'
import {
  StyledConfirmType,
  StyledHeaderContainer,
  StyledInputContainer,
  StyledMainContainer,
  StyledPageContainer,
  StyledParagraphText
} from '../../components/ui'
import Button from '../../components/ui/Button/Button'
import Logo from '../../components/assets/images/logo'
import FeatherIcon from 'feather-icons-react'
import { theme } from '../../styles'
import styled from 'styled-components'
import GoogleSVG from '../../components/assets/images/googleIcon.svg'
import { BY_DEFAULT_TEMPLATE_COLORS, getPhaseSchema } from '../../constant'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import Spinner from '../../components/ui/Spinner'
import InputField from '../../components/ui/InputField/InputField'
import axios from 'axios'

// --------- For widget auth initialization --------------------------------
const GetAuthPage: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const verify_code = searchParams.get('code')
  const { isLoading, isAuthenticated, user } = useSelector(
    (store: RootState) => store.auth
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [selectDifferentMethod, setSelectDifferentMethod] =
    useState<boolean>(false)
  const [continueWithEmail, setContinueWithEmail] = useState<boolean>(false)
  const [formSent, setFormSent] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [resent, setResent] = useState<boolean>(false)

  const AUTH_URL = `https://www.figma.com/oauth?client_id=${import.meta.env.VITE_FIGMA_CLIENT_ID}&redirect_uri=${import.meta.env.VITE_FIGMA_REDIRECT_URI}&scope=file_read&state=${verify_code}&response_type=code`

  // Google login
  const sigInWithGoogle = async () => {
    if (loading) return
    setLoading(true)
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user
        const additionalUserInfo = getAdditionalUserInfo(result)
        const isNewUser = additionalUserInfo?.isNewUser
        const nowDate = new Date().toISOString()
        await update(ref(db, `/oauth-session/${verify_code}`), {
          userId: user.uid
        })
        if (isNewUser) {
          set(ref(db, `/users/${user.uid}`), {
            email: user.email,
            id: user.uid,
            displayName: user?.displayName || '',
            profileImg: user?.photoURL || '',
            status: 'active',
            createdAt: nowDate,
            integrations: {
              google: {
                email: user.email,
                id: user.uid,
                handle: user?.displayName || '',
                profileImg: user?.photoURL || ''
              }
            },
            teamsJoined: { [user.uid]: user.uid },
          })
            .then(async () => {
              // Create user team by default
              const userTeamRef = ref(db, `/teams/${user.uid}`)
              await set(userTeamRef, {
                id: user.uid,
                title: user?.displayName || 'Personal',
                members: [
                  {
                    id: user.uid,
                    email: user.email,
                    role: 'owner',
                    invitationDate: nowDate,
                    profileImg: user?.photoURL || '',
                    pendingInvite: false,
                    invitationStatus: 'accepted',
                    invitedBy: user.uid
                  }
                ],
                createdAt: nowDate
              })

              // Create a template by default
              const tempID = push(ref(db, `/templates/${user.uid}`)).key
              const templateRef = ref(db, `/templates/${user.uid}/${tempID}`)
              await set(templateRef, {
                id: tempID,
                title: 'My first template',
                description: 'Start editing your template',
                teamId: user.uid,
                visible: true,
                type: "component",
                colors: BY_DEFAULT_TEMPLATE_COLORS,
                order: 0,
                createdBy: user.uid,
                createdAt: nowDate,
                updatedAt: nowDate
              })

              // Create template phases by default
              const phaseRef = ref(db, `/phases/${tempID}`)
              await set(
                phaseRef,
                getPhaseSchema(tempID || '', user.uid, user.uid)
              )

              // Send welcome email to the user after successful registration
              axios
                .get(
                  `${import.meta.env.VITE_APP_SERVER_URL}/email/welcome?email=${user.email}`
                )
                .then((res) => {
                  if (res.status === 200) {
                    // console.log('Welcome Email sent successfully!')
                  } else {
                    console.log('Error sending welcome email!')
                  }
                })
                .catch((err) => {
                  console.log('Error sending welcome email!', err)
                })

              window.location.replace(AUTH_URL)
            })
            .catch((er: any) => {
              toast.error(er.message || 'Something went wrong!')
              console.log(er)
              setLoading(false)
            })
        } else {
          update(ref(db, `/users/${user.uid}/integrations`), {
            google: {
              email: user.email,
              id: user.uid,
              handle: user?.displayName || '',
              profileImg: user?.photoURL || ''
            }
          })
            .then(() => {
              window.location.replace(AUTH_URL)
            })
            .catch((err) => console.log(err))
        }
      })
      .catch((error) => {
        if (
          error.code !== 'auth/cancelled-popup-request' &&
          error.code !== 'auth/popup-closed-by-user'
        ) {
          toast.error(error.message)
        }
        console.log(error)
        setLoading(false)
      })
  }

  // Email login
  const sendEmailLink = async (
    e: FormEvent | null,
    resend?: boolean | null
  ) => {
    if (e) {
      e.preventDefault()
    }
    if (loading) return

    let prevEmail = localStorage.getItem('widgetLoginEmail')
    let emailToUse = resend ? prevEmail : email

    if (resend && !prevEmail) {
      setLoading(false)
      setContinueWithEmail(true)
      setEmail('')
      setFormSent(false)
      setResent(false)
      return
    }

    if (!emailToUse) {
      toast.error('Please enter your email address')
      return
    }

    setLoading(true)
    try {
      const response = await axios(
        `${import.meta.env.VITE_APP_SERVER_URL}/auth/sendEmail?email=${emailToUse}&type=widget&verify_code=${verify_code}`
      )

      if (response.status !== 200) {
        toast.error('Error sending email: ' + response.statusText)
        return
      }

      setFormSent(true)
      setLoading(false)
      setEmail('')
      setResent(false)
      window.localStorage.setItem('widgetLoginEmail', emailToUse)
      if (resend) {
        setResent(true)
      }
      toast.success(
        resend
          ? 'Email resent successfully!'
          : 'Email with login link sent successfully!'
      )
    } catch (error: any) {
      toast.error('Error sending email: ' + error.response.data.message)
      setLoading(false)
    }
  }

  // continue with existing account
  const signInWithExisting = async () => {
    await update(ref(db, `/oauth-session/${verify_code}`), {
      userId: user?.id || ''
    })
    window.location.replace(AUTH_URL)
  }

  useEffect(() => {
    if (!verify_code) {
      navigate('/', { replace: true })
    }
  }, [verify_code])

  // JSX
  if (formSent) {
    return (
      <StyledMainContainer>
        <StyledPageContainer style={{ margin: 'auto' }}>
          <StyledHeaderContainer style={{ alignItems: 'flex-start' }}>
            <Logo />
            <StyledHeaderText2
              style={{ textAlign: 'left', width: '100%', marginTop: '25px' }}
            >
              Magic Awaits! 📩
            </StyledHeaderText2>
            <div style={{ width: '100%', marginTop: '20px' }}>
              <StyledConfirmType
                style={{ textAlign: 'left', fontSize: '21px' }}
              >
                We’ve sent a magic link to{' '}
                <b>{localStorage?.getItem('widgetLoginEmail') || ' - '}</b>{' '}
                check it out.
              </StyledConfirmType>
              {resent ? null : (
                <StyledConfirmType
                  style={{
                    textAlign: 'left',
                    fontSize: '16px',
                    alignItems: 'center',
                    marginTop: '15px',
                    gap: '4px'
                  }}
                  className="flex"
                >
                  Can’t find it? Check Spam or
                  <Button
                    disabled={loading}
                    onClick={() => sendEmailLink(null, true)}
                    variant="tertiary"
                    color="primary"
                    style={{ padding: '0' }}
                    size="medium"
                  >
                    Resend Link.
                  </Button>
                </StyledConfirmType>
              )}
              <Button
                onClick={() => {
                  setContinueWithEmail(true)
                  setFormSent(false)
                }}
                fullWidth
                variant="secondary"
                style={{ marginTop: '20px', textTransform: 'uppercase' }}
              >
                Use another account
              </Button>
            </div>
          </StyledHeaderContainer>
        </StyledPageContainer>
      </StyledMainContainer>
    )
  }
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <div
          className="flex"
          style={{
            width: '100%',
            padding: '28px 0 18px 0',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '31px'
          }}
        >
          <FeatherIcon icon="figma" size={90} style={{ flexShrink: 0 }} />
          <FeatherIcon icon="arrow-right" size={69} style={{ flexShrink: 0 }} />
          <Logo width={92} />
        </div>
        <div>
          <StyledHeaderText2>Connect your account</StyledHeaderText2>
          <StyledParagraphText className="text-center">
            Connect your Figma file with Compfeed to get started
          </StyledParagraphText>
        </div>
        <StyledInputContainer style={{ marginTop: '20px' }}>
          {isLoading ? (
            <Spinner type="spinner" />
          ) : isAuthenticated && !selectDifferentMethod ? (
            <>
              <p className="text-center" style={{ marginBottom: '20px' }}>
                You're already signed in with <b>{user?.email}</b>
              </p>
              <Button
                disabled={loading}
                fullWidth
                variant="primary"
                style={{ textTransform: 'uppercase' }}
                onClick={signInWithExisting}
              >
                CONTINUE WITH EXISTING ACCOUNT
              </Button>
              <Button
                disabled={loading}
                fullWidth
                variant="tertiary"
                style={{ textTransform: 'uppercase' }}
                onClick={() => setSelectDifferentMethod(true)}
              >
                SELECT A DIFFERENT ACCOUNT
              </Button>
            </>
          ) : (
            <>
              {isAuthenticated ? (
                <Button
                  disabled={loading}
                  fullWidth
                  variant="secondary"
                  style={{ textTransform: 'uppercase' }}
                  icon="box"
                  onClick={signInWithExisting}
                >
                  CONTINUE WITH {user?.email}
                </Button>
              ) : null}
              <Button
                onClick={sigInWithGoogle}
                disabled={loading}
                fullWidth
                variant="secondary"
                customIcon={<img src={GoogleSVG} alt="google" />}
              >
                CONTINUE WITH GOOGLE
              </Button>
              {formSent ? (
                <>
                  <StyledConfirmType
                    style={{ fontSize: '21px', marginTop: '17px' }}
                  >
                    A magic link has been sent to <br />
                    <b>{localStorage?.getItem('widgetLoginEmail') || ' - '}</b>
                  </StyledConfirmType>
                  {resent ? null : (
                    <StyledConfirmType
                      style={{
                        textAlign: 'left',
                        fontSize: '16px',
                        alignItems: 'center',
                        marginTop: '15px',
                        gap: '4px'
                      }}
                      className="flex"
                    >
                      Did not receive it?
                      <Button
                        disabled={loading}
                        onClick={() => sendEmailLink(null, true)}
                        variant="tertiary"
                        color="primary"
                        style={{ padding: '0' }}
                        size="medium"
                      >
                        Resend link
                      </Button>
                    </StyledConfirmType>
                  )}
                  <Button
                    onClick={() => {
                      setContinueWithEmail(true)
                      setFormSent(false)
                    }}
                    fullWidth
                    variant="secondary"
                    style={{ marginTop: '20px' }}
                  >
                    Use another account
                  </Button>
                </>
              ) : continueWithEmail ? (
                <form
                  onSubmit={sendEmailLink}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: '12px',
                    padding: '0 0 0 4px'
                  }}
                >
                  <InputField
                    type="email"
                    name="email"
                    label="Type your email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      loading ? null : setEmail(e.target.value)
                    }
                    autoFocus
                    disabled={loading}
                    fullWidth
                    required
                  />
                  <Button
                    label="Send Link"
                    disabled={loading}
                    type="submit"
                    variant="secondary"
                  />
                </form>
              ) : (
                <Button
                  onClick={() => setContinueWithEmail(true)}
                  disabled={loading}
                  fullWidth
                  variant="secondary"
                  icon="mail"
                >
                  CONTINUE WITH EMAIL
                </Button>
              )}
            </>
          )}
        </StyledInputContainer>
        <StyledConfirmType>
          By proceeding you agree to the{' '}
          <Link to="https://docs.compfeed.com/legal/terms_and_conditions" target="_blank">
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link to="https://docs.compfeed.com/legal/privacy_policy" target="_blank">
            Privacy Policy
          </Link>
          . We value your trust and ensure a safe, transparent experience.
        </StyledConfirmType>
      </StyledPageContainer>
    </StyledMainContainer>
  )
}

export const StyledHeaderText2 = styled.h3`
  color: ${theme.colors.copy};
  font-size: 22px;
  font-weight: 500;
  line-height: 140%;
  font-family: 'rooney';
  text-align: center;

  @media screen and (min-width: 430px) {
    font-size: 32px;
  }
`

export default GetAuthPage
