import styled from "styled-components";
import { theme } from "../../../styles";
import Button from "../Button/Button";

export interface IMenuHeaderProps {
  title: string;
  state?: "default" | "nestedItem";
  handleClose?: () => void;
}

const StyledMenuHeader = styled.div<{ state: "default" | "nestedItem" }>`
  display: flex;
  width: 100%;
  padding: 0px 0px 4px 16px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${theme.colors.borderDefault};

  ${({ state }) =>
    state === "nestedItem" &&
    `
    padding: 0px 0px 4px 0px;
`}
`;

const StyledMenuHeaderTitle = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: "rooney-sans";
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`;

const MenuHeader = ({ title = "", state = "default", handleClose }: IMenuHeaderProps) => {
  return (
    <StyledMenuHeader state={state}>
      {state === "default" && (
        <>
          <StyledMenuHeaderTitle>{title}</StyledMenuHeaderTitle>
          <Button iconOnly icon="x" variant="tertiary" size="medium" onClick={handleClose} />
        </>
      )}
      {state === "nestedItem" && (
        <>
          <Button iconOnly icon="chevron-left" variant="tertiary" size="medium" onClick={handleClose} />
          <StyledMenuHeaderTitle>{title}</StyledMenuHeaderTitle>
        </>
      )}
    </StyledMenuHeader>
  );
};

export default MenuHeader;