import styled from "styled-components"
import SelectorStepper from "../../ui/SelectorStepper/SelectorStepper"
import { theme } from "../../../styles"
import { IListerProps } from "./Lister"

export const StyledListHeaderContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap:16px;

    @media screen and (min-width: 834px) {
        flex-direction: row;
        // padding:0 32px;
    }

`

export const StyledComponentsTitle = styled.p`
    color: ${theme.colors.copy};
    font-family: "rooney";
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @media screen and (min-width: 430px) {
        font-size: 64px;
    }
  
`
type IListHeaderProps = Omit<IListerProps, 'listCardData' | 'count'>

const ListHeader = ({ componentName, stepperData }: IListHeaderProps) => {
    return (
        <StyledListHeaderContainer>
            <StyledComponentsTitle>{componentName}</StyledComponentsTitle>
            <SelectorStepper currentPhase={stepperData.currentPhase} fullWidth={stepperData.fullWidth} phaseTitles={stepperData.phaseTitles} border />
        </StyledListHeaderContainer>
    )
}

export default ListHeader