import React, { useState } from 'react'
import Modal from '../../../components/ui/Modal/Modal'
import {
  StyledInputLabel,
  StyledInputLabelContainer
} from '../../../components/screens/MyAccount/MyAccount'
import InputField from '../../../components/ui/InputField/InputField'
import ModalUpload from '../../../components/ui/FileUpload/ModalUpload'
import { FileWithPreview } from '../../../hooks/useImageUpload'
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable
} from 'firebase/storage'
import { db, storage } from '../../../config/config'
import { push, ref, set, update } from 'firebase/database'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setTeams } from '../../../store/reducers/teamReducerSlice'
import { BY_DEFAULT_TEMPLATE_COLORS, getPhaseSchema } from '../../../constant'

type CreateTeamProps = {
  isOpen?: boolean
  onClose?: () => void
}

const CreateTeamModal: React.FC<CreateTeamProps> = ({
  isOpen = false,
  onClose = () => {}
}) => {
  const dispatch = useDispatch()
  const { teams } = useSelector((store: RootState) => store.team)
  const { user } = useSelector((store: RootState) => store.auth)
  const [formData, setFormData] = useState<{
    title: string
    description: string
  }>({ title: '', description: '' })
  const [selectedIcon, setSelectedIcon] = useState<FileWithPreview | null>(null)
  const [creating, setCreating] = useState<boolean>(false)
  const [uploadPercent, setUploadPercent] = useState(0)

  // close modal
  const closeModal = () => {
    if (creating) return
    if (onClose) {
      onClose()
    }
  }
  // update profile image on firebase storage cloud
  const uploadProfileImg = (teamId: string) => {
    return new Promise((resolve) => {
      const file = selectedIcon
      if (!file || !teamId) return resolve('')

      const storageREF = storageRef(storage, `images/team/profile/${teamId}`)
      const task = uploadBytesResumable(storageREF, file)
      task.resume()
      task.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot?.bytesTransferred / snapshot?.totalBytes) * 100
          setUploadPercent(progress)
        },
        // error
        () => {
          return resolve('')
        },
        () => {
          getDownloadURL(task?.snapshot?.ref).then((downloadURL) => {
            return resolve(downloadURL)
          })
        }
      )
    })
  }

  // create a new team
  const createTeam = () => {
    if (creating || !formData?.title) return
    setCreating(true)
    const newID = push(ref(db, 'teams')).key || new Date().getTime().toString()
    const nowDate = new Date().toISOString()

    const newTeamObj = {
      id: newID,
      createdAt: new Date().toISOString(),
      ...formData,
      members: [
        {
          role: 'owner',
          email: user?.email,
          id: user?.id,
          displayName: user?.displayName,
          profileImg: user?.profileImg,
          pendingInvite: false,
          invitationDate: nowDate,
          invitationStatus: 'accepted',
          invitedBy: user?.id,
          inviteSentCount: 0
        }
      ]
    }
    update(ref(db, `teams/${newID}`), newTeamObj)
      .then(async () => {
        const profileURL = await uploadProfileImg('teamId')
        await update(ref(db, `teams/${newID}`), {
          icon: profileURL || ''
        })

        await update(ref(db, `users/${user?.id}/teamsJoined`), {
          [newID as string]: newID
        })

        // Create a template by default
        const tempID = push(ref(db, `/templates/${newID}`)).key
        const templateRef = ref(db, `/templates/${newID}/${tempID}`)
        await set(templateRef, {
          id: tempID,
          title: 'My first template',
          description: 'Create your own template',
          teamId: newID,
          visible: true,
          colors: BY_DEFAULT_TEMPLATE_COLORS,
          order: 0,
          type: 'component',
          createdBy: user?.id,
          createdAt: nowDate,
          updatedAt: nowDate
        })

        // Create template phases by default
        const phaseRef = ref(db, `/phases/${tempID}`)
        await set(phaseRef, getPhaseSchema(tempID || '', newID, user?.id as string))

        dispatch(
          setTeams({
            data: [...teams, { ...newTeamObj, icon: profileURL || '' }],
            isLoading: false
          })
        )
        setFormData({
          title: '',
          description: ''
        })
        if (onClose) {
          onClose()
        }
        toast.success('Awesome, You just created a new team!')
      })
      .catch((error) => {
        console.log('Error while creating a team', error?.message)
      })
      .finally(() => {
        setCreating(false)
        setUploadPercent(0)
      })
  }

  // JSX
  if (!isOpen) return null
  return (
    <Modal
      showModal={isOpen}
      setShowModal={closeModal}
      title="Create Team"
      signal="users"
      buttonVariant1="primary"
      buttonLabel1={creating ? 'CREATING' : 'CREATE NEW TEAM'}
      buttonOnClick1={createTeam}
      buttonOnClick2={closeModal}
      buttonDisabled1={creating || !formData?.title?.trim()}
      buttonDisabled2={creating}
      onclose={closeModal}
      isLoading={creating}
      overflow="auto"
      slotContent={
        <StyledInputLabelContainer style={{ gap: '16px' }}>
          <InputField
            disabled={creating}
            required
            fullWidth
            label="Team Name"
            placeholder="Team Name"
            name="title"
            type="text"
            value={formData?.title}
            readOnly={creating}
            autoFocus
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (creating) return
              setFormData((prev) => {
                const val = e.target?.value
                return {
                  ...prev,
                  title: val?.length < 120 ? val : prev?.title
                }
              })
            }}
          />
          <InputField
            disabled={creating}
            fullWidth
            label="Team Description"
            placeholder="Team Description"
            name="description"
            type="text"
            value={formData?.description}
            readOnly={creating}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (creating) return
              setFormData((prev) => {
                const val = e.target?.value
                return {
                  ...prev,
                  description: val?.length < 120 ? val : prev?.description
                }
              })
            }}
          />
          <StyledInputLabel>Upload team logo (optional)</StyledInputLabel>
          <ModalUpload
            modalOpen={isOpen}
            title="Upload Team Logo"
            btnLabel="Upload Logo"
            isLoading={creating}
            onSubmit={() => {}}
            canCancel={false}
            uploadPercent={uploadPercent}
            cancelUpload={() => {}}
            hideCancelButton
            isDisabled={creating}
            error=""
            onFileChange={(files) => {
              setSelectedIcon(files[0])
            }}
            modalWrapper={false}
          />
        </StyledInputLabelContainer>
      }
    />
  )
}

export default CreateTeamModal
