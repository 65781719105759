import { css, styled } from "styled-components";
import PhaseType, { IPhaseType } from "../PhaseType/PhaseType";
import { theme } from "../../../styles/theme";

interface IPhaseStatusProps {
  phaseTitle: string;
  phaseType?: IPhaseType;
  bgColor?: string;
  textColor?: string;
  onClick?: () => void;
  isActive?: boolean;
  isClickable?: boolean;
  noStatus?: boolean;
  fullWidth?: boolean;
}
interface IPhaseTitleTextProps {
  textColor?: string;
}

const StyledPhaseStatus = styled.div<Omit<IPhaseStatusProps, "phaseTitle">>`
  display: flex;
  padding: 8px 16px 8px 8px;
  border-radius: 6px;
  align-items: center;
  gap: 8px;
  width: ${({ fullWidth }) => fullWidth ? "100%" : "fit-content"};
  cursor: ${({ isClickable }) => isClickable ? "pointer" : "default"};
  ${({ bgColor }) => {
    return css`
        background-color: transparent;
        border: 1px solid ${bgColor};
        & > div{
          border: 1px solid ${bgColor};
          background-color: transparent;
        }
    `;
  }}

  ${({ isActive, textColor, bgColor }) => {
    if (isActive) {
      return css`
          border: 1px solid ${bgColor};
          background-color: ${bgColor};

          & > div {
            border: 1px solid ${textColor};
          background-color: ${textColor};
          }

    `;
    }
  }}

 ${({ noStatus }) => {
    if (noStatus) {
      return css`
          border: 1px dashed ${theme.colors.copy};
          background-color: transparent;

          & > div {
            border: 1px solid ${theme.colors.copy};
          background-color: transparent;
          }

    `;
  }
}}
`;

const StyledPhaseTitle = styled.p<
  Omit<IPhaseTitleTextProps, "StyledPhaseTitle">
>`
  color: ${theme.colors.copy};

  font-family: rooney-sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
  user-select:none;
  ${({ textColor }) => {
    return css`
      color: ${textColor || theme.colors.copy};
    `;
  }}
`;

const PhaseStatus = ({
  phaseTitle,
  phaseType = 1,
  bgColor,
  textColor,
  onClick,
  isActive,
  isClickable = true,
  noStatus = false,
  fullWidth = true
}: IPhaseStatusProps) => {
  return (
    <StyledPhaseStatus
      phaseType={phaseType}
      bgColor={bgColor}
      onClick={onClick}
      textColor={textColor}
      isActive={isActive}
      isClickable={isClickable}
      noStatus={noStatus}
      fullWidth={fullWidth}
    >
      <PhaseType phaseProgress="none" phaseType={phaseType} bgColor={textColor} />
      <StyledPhaseTitle >{phaseTitle}</StyledPhaseTitle>
    </StyledPhaseStatus>
  );
};

export default PhaseStatus;
