import React, { useState, HTMLAttributes, useEffect } from 'react'
import { css, styled } from 'styled-components'
import PhaseType, { IPhaseType } from '../PhaseType/PhaseType'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '../../../config/config'
import { ref, update, remove } from 'firebase/database'
import { DraggableProvided } from 'react-beautiful-dnd/index'
import { RootState } from '../../../store'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import { phaseTypeColorMap, theme } from '../../../styles/theme'
import { setPhasesData } from '../../../store/reducers/phaseReducerSlice'
import { toast } from 'react-toastify'
import { getTimeStamp } from '../../../utils/helpers'
import useGetCurrentPhases from '../../../hooks/useGetCurrentPhases'
import { MenuBottomSheet } from '../BottomSheet/BottomSheet'
import PhaseStatus from '../PhaseStatus/PhaseStatus'
import Button from '../Button/Button'
import DragIcon from '../../assets/icons/DragIcon'
import PopOverMenu from '../PopOverMenu/PopOverMenu'
import Modal from '../Modal/Modal'
import { PhaseDeleteContent } from '../Modal/ModalSlotContents'
import { PhaseMenuItems, ArchivedItemMenuItems } from '../MenuItems/MenuItems'
import { ConditionallyRender } from '../../../utils/ConditionallyRender'
import EditPhaseTitle from './_components/EditPhaseTitle'

export interface IPhaseProgress extends HTMLAttributes<HTMLDivElement> {
  phaseTitle: string
  textColor: string
  phaseProgress: number
  phaseType?: IPhaseType
  hasProgressBar?: boolean
  activatePhase?: () => void
  onButtonClick?: () => void
  showButton?: boolean
  draggable?: boolean
  bgColor?: string
  dragProps?: DraggableProvided
  dragged?: boolean
  templateId?: string
  phaseId?: string
  phaseState?: 'archived' | 'default'
  index?: number
  isActive?: boolean
  setIsTaskEditModeOnCheck?: React.Dispatch<React.SetStateAction<boolean>>
  isAnyTaskDragging?: boolean
  setIsAnyTaskDragging?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface IPhaseProgressBar extends HTMLAttributes<HTMLDivElement> {
  textColor: string
  phaseProgress: number
  phaseType: IPhaseType
}

const StyledPhaseProgress = styled.div<IPhaseProgress>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  overflow: hidden;
  width: 100%;

  ${({ theme, phaseType, textColor }) => {
    return css`
      & > * h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;

        color: ${textColor || phaseTypeColorMap[phaseType ?? 1]};

        font-size: ${theme.font.sizes.header3};
        font-weight: 420;
        line-height: 140%;
        font-family: 'rooney-sans';
        padding-right: 4px;
      }
    `
  }}
`

const StyledProgressTrack = styled.div<IPhaseProgressBar>`
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 8px;
  border-radius: 4px;
  background: #fff;
  align-items: center;
  border: ${({ phaseType, textColor }) =>
    `1px solid ${textColor || phaseTypeColorMap[phaseType]}`};
`

const StyledPhaseTitleButtonContainer = styled.div<
  Pick<IPhaseProgress, 'draggable'>
>`
  display: flex;
  align-items: center;
  gap: ${({ draggable }) => (draggable ? '8px' : '16px')};
  width: 100%;
`

const ProgressBar = ({
  phaseProgress,
  phaseType,
  textColor
}: IPhaseProgressBar) => {
  return (
    <StyledProgressTrack
      phaseType={phaseType}
      textColor={textColor}
      phaseProgress={phaseProgress}
    >
      <PhaseType
        phaseProgress={phaseProgress}
        phaseType={phaseType}
        bgColor={textColor}
      />
    </StyledProgressTrack>
  )
}

const PhaseProgress = ({
  phaseProgress,
  phaseTitle,
  phaseType = 1,
  textColor,
  bgColor,
  hasProgressBar = true,
  showButton = true,
  activatePhase,
  draggable,
  dragProps,
  dragged,
  templateId,
  phaseId,
  phaseState = 'default',
  index,
  isActive,
  setIsTaskEditModeOnCheck,
  isAnyTaskDragging
}: IPhaseProgress) => {
  if (draggable) {
    hasProgressBar = false
  }

  const [isEditModeOn, setIsEditModeOn] = useState(false)

  const isMobile = useIsMobileDevice()
  const dispatch = useDispatch()

  const [inProgress, setInProgress] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const phaseRef = ref(db, `phases/${templateId}/${phaseId}`)

  const { data: allPhases, isLoading: isPhaseLoading } = useSelector(
    (store: RootState) => store.phase
  )
  const { phases } = useGetCurrentPhases(allPhases, templateId)

  useEffect(() => {
    setIsTaskEditModeOnCheck && setIsTaskEditModeOnCheck(isEditModeOn)
  }, [isEditModeOn, setIsTaskEditModeOnCheck])

  const handleToggleArchivePhase = async (action: 'archive' | 'unarchive') => {
    if (inProgress || !templateId || !phaseId || isPhaseLoading) return

    const unArchivedPhases = phases?.filter((phase) => !phase?.archived)
    //check if the there are more than 1 phases
    // only apply this check for phase with default (un-archived) state
    if (phaseState === 'default' && unArchivedPhases?.length === 1) {
      toast.error('Sorry, you can not archive the last phase in the template.')
      return
    }

    setInProgress(true)
    const timestamp = getTimeStamp()
    try {
      if (action === 'archive') {
        await update(phaseRef, {
          archived: true,
          updatedAt: timestamp
        })

        dispatch(
          setPhasesData({
            data: allPhases?.map((phase) => {
              if (phase.id === phaseId) {
                return {
                  ...phase,
                  archived: true,
                  updatedAt: timestamp
                }
              }
              return phase
            })
          })
        )

        toast.success('Phase archived successfully.')
      } else if (action === 'unarchive') {
        await update(phaseRef, {
          archived: false,
          updatedAt: timestamp
        })

        dispatch(
          setPhasesData({
            data: allPhases?.map((phase) => {
              if (phase.id === phaseId) {
                return {
                  ...phase,
                  archived: false,
                  updatedAt: timestamp
                }
              }
              return phase
            })
          })
        )
        toast.success('Phase un-archived successfully.')
      }
    } catch (error) {
      console.log('Error archiving the phase', error)
      toast.error('Something went wrong.')
    } finally {
      setInProgress(false)
    }
  }

  const handleRemovePhase = async () => {
    if (!templateId || !phaseId || isPhaseLoading || inProgress) return

    const unArchivedPhases = phases?.filter((phase) => !phase?.archived)
    //check if the there are more than 1 phases
    // only apply this check for phase with default (un-archived) state
    if (phaseState === 'default' && unArchivedPhases?.length === 1) {
      toast.error('Sorry, you can not delete the last phase in the template.')
      return
    }

    setInProgress(true)

    try {
      await remove(phaseRef)
      dispatch(
        setPhasesData({
          data: allPhases?.filter((phase) => phase.id !== phaseId)
        })
      )
      toast.success('The phase has been removed.')
    } catch (error) {
      toast.error('Something went wrong.')
      console.log('Error while deleting a phase', error)
    } finally {
      setInProgress(false)
    }
  }

  const phaseActionHandlers = {
    handleEdit: () => setIsEditModeOn(true),
    handleArchive: () => handleToggleArchivePhase('archive'),
    handleRemove: () => setShowModal(true)
  }

  const archivedPhaseActionHandler = {
    handleUnArchive: () => handleToggleArchivePhase('unarchive'),
    handleRemove: () => setShowModal(true)
  }

  return (
    <ConditionallyRender
      condition={isEditModeOn}
      show={
        <EditPhaseTitle
          templateId={templateId}
          phaseId={phaseId}
          setIsEditModeOn={setIsEditModeOn}
          phaseTitle={phaseTitle}
          isAnyTaskDragging={isAnyTaskDragging}
        />
      }
      elseShow={
        <>
          <StyledPhaseProgress
            phaseTitle={phaseTitle}
            phaseProgress={phaseProgress}
            phaseType={phaseType}
            textColor={textColor}
            dragged={dragged}
          >
            <StyledPhaseTitleButtonContainer draggable={draggable}>
              {draggable && showButton ? (
                <Button
                  iconOnly
                  customIcon={<DragIcon color={theme.colors.actionTertiary} />}
                  variant="tertiary"
                  size="small"
                  {...dragProps?.dragHandleProps}
                />
              ) : (
                <div
                  style={{ display: 'none' }}
                  {...dragProps?.dragHandleProps}
                ></div>
              )}
              <PhaseStatus
                phaseTitle={phaseTitle}
                phaseType={phaseType}
                onClick={activatePhase}
                bgColor={bgColor}
                textColor={textColor}
                isClickable={hasProgressBar}
                isActive={index === 0 || isActive}
              />
              {!draggable &&
                showButton &&
                (isMobile ? (
                  <MenuBottomSheet
                    title="Options"
                    menuItems={
                      phaseState === 'archived' ? (
                        <ArchivedItemMenuItems
                          actionHandlers={archivedPhaseActionHandler}
                        />
                      ) : (
                        <PhaseMenuItems
                          actionHandlers={phaseActionHandlers}
                          path={`/dashboard/templates/${templateId}/phase/edit/${phaseId}`}
                        />
                      )
                    }
                  />
                ) : (
                  <PopOverMenu
                    menuItems={
                      phaseState === 'archived' ? (
                        <ArchivedItemMenuItems
                          actionHandlers={archivedPhaseActionHandler}
                        />
                      ) : (
                        <PhaseMenuItems
                          actionHandlers={phaseActionHandlers}
                          path={`/dashboard/templates/${templateId}/phase/edit/${phaseId}`}
                        />
                      )
                    }
                  />
                ))}
            </StyledPhaseTitleButtonContainer>
            {hasProgressBar && (
              <ProgressBar
                phaseProgress={phaseProgress}
                phaseType={phaseType}
                textColor={textColor}
              />
            )}
          </StyledPhaseProgress>
          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              signal="alert-triangle"
              signalIconType="warning"
              title="Remove Phase"
              slotContent={<PhaseDeleteContent />}
              buttonLabel1="REMOVE PHASE"
              buttonLabel2="CANCEL"
              buttonVariant1="danger"
              buttonVariant2="tertiary"
              buttonOnClick1={handleRemovePhase}
            />
          )}
        </>
      }
    />
  )
}

export default PhaseProgress
