import styled from 'styled-components'
import React from 'react'
import { sortedDataByOrder } from '../../../utils/helpers'
import EditPhaseItem from '../EditPhaseItem/EditPhaseItem'
import { theme } from '../../../styles'
import { PhaseObj } from '../../../store/reducers/phaseReducerSlice'
import PhaseProgress from '../PhaseProgress/PhaseProgress'

interface ArchivedPhaseProps {
    templateId: string
    phaseId: string
    data: PhaseObj
    phaseColor?: { bg: string; text: string }
}

export const StyledArchivedPhaseComponent = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${theme.colors.borderDefault};
`

export const StyledPhaseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const StyledTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

// Phase component wrapper
const ArchivedPhaseComponent: React.FC<ArchivedPhaseProps> = ({
    data,
    templateId,
    phaseId,
    phaseColor,
}) => {

    // sort the task list by order
    const sortedDataTaskList = sortedDataByOrder(
        Object.values(data?.tasks || []),
        'order',
        'asc'
    )

    // JSX
    return (
        <StyledArchivedPhaseComponent>
            <StyledPhaseContentContainer>
                <PhaseProgress
                    hasProgressBar={false}
                    phaseProgress={1}
                    phaseTitle={data?.title}
                    textColor={phaseColor?.text ?? ''}
                    bgColor={phaseColor?.bg ?? ''}
                    templateId={templateId}
                    phaseId={phaseId}
                    phaseState='archived'
                />

                <StyledTaskContainer>
                    {sortedDataTaskList?.map((task) => (

                        <EditPhaseItem
                            key={task?.id}
                            phaseItemName={task?.title}
                            visible
                            templateId={templateId}
                            phaseId={phaseId}
                            taskId={task?.id}
                            disabled={true}
                        />
                    ))}

                </StyledTaskContainer>

            </StyledPhaseContentContainer>
        </StyledArchivedPhaseComponent>

    )
}

export default ArchivedPhaseComponent
