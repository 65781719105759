import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_APP_ENVIRONMENT || 'staging',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes
    })
  ],
  beforeSend(event, hint) {
    if (event.exception) {
      console.warn('Sentry captured an exception:', hint?.originalException)
    }
    return event
  }
})
