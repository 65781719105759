import React from 'react'
import styled from 'styled-components'

const SidebarWrapper = styled.div`
  width: 100%;
  @media (min-width: 640px) {
    width: 12rem;
  }
  flex-shrink: 0;
  margin-bottom: 1.5rem;
  @media (min-width: 640px) {
    margin-bottom: 0;
  }
  position: sticky;
  top: 1rem;
`

const SidebarList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SidebarItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${({ isActive }) => (isActive ? '#6B46C1' : '#A0AEC0')};
`

const SidebarIcon = styled.div<{ isActive: boolean }>`
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? '#FAF5FF' : 'transparent')};
  border: ${({ isActive }) => (isActive ? 'none' : '1px solid #E2E8F0')};
`

const SidebarTitle = styled.span`
  white-space: nowrap;
`

type SidebarProps = {
  surveyData: any
  currentSection: any
}

const Sidebar: React.FC<SidebarProps> = ({ surveyData, currentSection }) => {
  return (
    <SidebarWrapper>
      <SidebarList>
        {surveyData.survey.sections.map((section: any, index: number) => (
          <SidebarItem
            key={index}
            isActive={section.title === currentSection?.title}
          >
            <SidebarIcon isActive={section.title === currentSection?.title}>
              {index + 1}
            </SidebarIcon>
            <SidebarTitle>{section.title}</SidebarTitle>
          </SidebarItem>
        ))}
      </SidebarList>
    </SidebarWrapper>
  )
}

export default Sidebar
