import { HTMLAttributes } from 'react'
import { css, styled } from 'styled-components';
import CheckMark from '../CheckMark/CheckMark';

interface IPhaseItemProps extends HTMLAttributes<HTMLDivElement> {
    checkMarkChecked: boolean;
    phaseItemName: string;
    onClick?: () => void;
}

interface IStyledPhaseItemLabelProps extends HTMLAttributes<HTMLSpanElement> {
    checked: boolean;
}


const StyledPhaseItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const StyledPhaseItemLabel = styled.span <IStyledPhaseItemLabelProps>`
    ${({ theme, checked }) => {
        let color = checked ? theme.colors.copy : theme.colors.actionDisabled
        return css`
            color: ${color} ;
            font-size: ${theme.font.large};
            font-weight: 400;
            line-height: 140%;
        `
    }
    }
`


const PhaseItem = ({ checkMarkChecked, phaseItemName, onClick }: IPhaseItemProps) => {
    return (
        <StyledPhaseItemContainer>
            <CheckMark checked={checkMarkChecked} onClick={() => onClick ?  onClick() : null} />
            <StyledPhaseItemLabel checked={checkMarkChecked}>{phaseItemName}</StyledPhaseItemLabel>
        </StyledPhaseItemContainer>
    )
}

export default PhaseItem