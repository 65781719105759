import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import ButtonGroup, { ButtonGroupProps } from '../ButtonGroup/ButtonGroup'
import { theme } from '../../../styles'
import SignalIcon from '../SignalIcon/SignalIcon'
import Button from '../Button/Button'
import { FeatherIconNames } from 'feather-icons'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'

interface ModalProps extends ButtonGroupProps {
  title: string
  signal?: FeatherIconNames
  signalBackground?: string
  slotContent?: React.ReactNode
  showModal: boolean
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>
  onclose?: () => void
  buttonDisabled1?: boolean
  buttonDisabled2?: boolean
  isLoading?: boolean
  overflow?: "auto" | "hidden" | "visible" | "scroll"
  signalIconType?: 'success' | 'warning' | 'danger' | 'info' | 'primary'
}

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: rgba(188, 187, 187, 0.8);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 999999;
`

const StyledModal = styled.div<{ overflow: "auto" | "hidden" | "visible" | "scroll" }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 16px;
  gap: 16px;
  max-width: 358px;
  border-radius: 12px;
  background-color: #fff;
  max-height: 96vh;
  overflow: ${({ overflow }) => overflow};
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.25);
  margin: auto;
  @media screen and (min-width: 834px) {
    max-width: 500px;
    padding-bottom: 24px;
    gap: 24px;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  padding: 4px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid ${theme.colors.borderDefault};
  background-color: ${theme.colors.white};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @media screen and (min-width: 834px) {
    padding: 8px 16px;
  }
`

export const StyledTitleContainer = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 16px;
`

export const StyledMobileTitle = styled.h4`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: "rooney";
  font-size: 20px;
  font-style: normal;
  font-weight: 380;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
`;

export const StyledDesktopTitle = styled.h3`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: "rooney";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
`;

export const StyledBodyContainer = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  @media screen and (min-width: 834px) {
    padding: 0px 24px;
    gap: 24px;
  }
`

export const SlotContainer = styled.div`
  width: 100%;
`

const Modal: React.FC<ModalProps> = ({
  showModal,
  setShowModal,
  signal,
  title,
  slotContent,
  buttonLabel1,
  buttonLabel2,
  buttonVariant1,
  buttonVariant2,
  buttonOnClick1,
  buttonOnClick2,
  onclose,
  buttonDisabled1,
  buttonDisabled2,
  isLoading,
  overflow = 'auto',
  signalBackground,
  signalIconType
}) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobileDevice();

  // Close the bottom sheet when clicking outside the container
  useEffect(() => {
    if (isLoading) return
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        if (setShowModal) {
          setShowModal(false)
        }
      }
    }

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showModal, setShowModal, isLoading])

  if(!showModal) return
  return (
    <>
        <StyledModalOverlay>
          <StyledModal ref={modalRef} overflow={overflow}>
            <StyledHeader>
              <StyledTitleContainer>
                {!signal ? null : <SignalIcon background signal={signal} type={signalIconType} />}
                {isMobile ? (
                  <StyledMobileTitle>{title}</StyledMobileTitle>
                ) : (
                  <StyledDesktopTitle>{title}</StyledDesktopTitle>
                )}
              </StyledTitleContainer>
              <Button
                variant="tertiary"
                size="small"
                iconOnly
                icon="x"
                onClick={() => {
                  if (onclose) {
                    onclose()
                  } else if (setShowModal) {
                    setShowModal(false)
                  }
                }}
              />
            </StyledHeader>
            <StyledBodyContainer>
              <SlotContainer>{slotContent}</SlotContainer>
             {!buttonLabel1 && !buttonLabel2 ? null : <ButtonGroup
                buttonLabel1={buttonLabel1}
                buttonLabel2={buttonLabel2}
                buttonVariant1={buttonVariant1}
                buttonVariant2={buttonVariant2}
                style={{ width: '100%' }}
                buttonOnClick1={buttonOnClick1}
                buttonOnClick2={() => {
                  if (setShowModal) {
                    setShowModal(false)
                  }
                  buttonOnClick2 && buttonOnClick2()
                }}
                showSecondButton={buttonLabel2 ? true : false}
                buttonDisabled1={buttonDisabled1}
                buttonDisabled2={buttonDisabled2}
              />}
            </StyledBodyContainer>
          </StyledModal>
        </StyledModalOverlay>
    </>
  )
}

export default Modal
