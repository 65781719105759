import ILLUSTRATIONS from "../../assets/illustrations";

export type IIllustrationType = 'clap' | 'govern' | 'check' | 'widget' | 'highfive'
export interface IIllustrationComponentProps {
    size?: 'xs' | 's' | 'm' | 'l' | 'xl';
    type?: IIllustrationType
    bgColor?: string
}

const sizeMap = {
    'xs': 80,
    's': 96,
    'm': 160,
    'l': 240,
    'xl': 360
}

const IllustrationComponent = ({ size = 'm', type = 'clap', bgColor = "#EFE3FF" }: IIllustrationComponentProps) => {

    const SelectedIllustration = ILLUSTRATIONS[type]

    return (
        <SelectedIllustration height={sizeMap[size]} width={sizeMap[size]} bgColor={bgColor} />
    )
}

export default IllustrationComponent