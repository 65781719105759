import { useState } from "react";
import styled, { css } from "styled-components";
import { theme } from "../../../styles";
import ExpandCollapse from "../ExpandCollapse/ExpandCollapse";

export interface IToggleRecentlyViewedProps {
  isActive?: boolean;
  disabled?: boolean;
  collapsed?: boolean;
  onClick?: () => void;
}

const StyledToggleRecentlyViewed = styled.div<
  Pick<IToggleRecentlyViewedProps, "isActive" | "disabled">
>`
  display: flex;
  width: 100%;
  padding: 16px 8px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-top: 2px solid ${theme.colors.borderDefault};
  color: ${theme.colors.copySubtle};
  cursor: pointer;
  user-select: none;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-within {
        color: ${theme.colors.actionPrimaryHover};
      }
    `}

  ${({ isActive, disabled }) =>
    isActive &&
    !disabled &&
    css`
      color: ${theme.colors.actionPrimaryActive};
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;
      color: ${theme.colors.actionDisabled};
    `}
`;

const StyledText = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;

  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "rooney-sans";
  font-size: ${theme.font.sizes.small};
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`;

const ToggleRecentlyViewed = ({
  isActive = false,
  disabled = false,
  collapsed = true,
  onClick,
}: IToggleRecentlyViewedProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const getColor = () => {
    if (isActive) return theme.colors.actionPrimaryActive;
    else if (disabled) return theme.colors.actionDisabled;
    else if (isHovered) return theme.colors.actionPrimaryHover;
    else return theme.colors.copySubtle;
  };
  return (
    <StyledToggleRecentlyViewed
      isActive={isActive}
      disabled={disabled}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <StyledText>recently viewed</StyledText>
      <ExpandCollapse
        collapse={collapsed}
        color={getColor()}
        type="default"
        disabled={disabled}
      />
    </StyledToggleRecentlyViewed>
  );
};

export default ToggleRecentlyViewed;
