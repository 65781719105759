import { styled } from "styled-components";

export const StyledLayout = styled.main`
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
  // overflow-x: hidden;
  display: flex;
  flex-direction: row;
  position: relative;

  @media screen and (max-width: 834px) {
    flex-direction: column;
    min-height: 100%
  }

  @media screen and (max-width: 768px) {
      height: -webkit-fill-available;
      min-height: -webkit-fill-available;
      max-height: -webkit-fill-available;
      overflow: hidden;
  }
`;
