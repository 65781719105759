import React from 'react'
import styled, { css } from 'styled-components'
import { type LucideIcon } from 'lucide-react'

interface DesignSystemStatusCardProps {
  icon: LucideIcon
  title: string
  value: string
  onChange: (value: string) => void
}

// Styled components
const Card = styled.div`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
`

const CardContent = styled.div`
  padding-top: 1.5rem;
`

const IconWrapper = styled.div`
  margin-bottom: 1rem;
`

const Title = styled.h3`
  font-size: 2.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

const RadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.label<{ selected: boolean; color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  .circle {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    ${(props) =>
      props.selected
        ? css`
            background-color: ${props.color};
          `
        : css`
            border: 2px solid ${props.color};
          `}
  }

  span {
    font-size: 1.875rem;
    font-weight: 500;
  }
`

const RadioGroupItem = styled.input`
  position: absolute;
  opacity: 0;
`

export function DesignSystemStatusCard({
  icon: Icon,
  title,
  value,
  onChange
}: DesignSystemStatusCardProps) {
  return (
    <Card>
      <CardContent>
        <IconWrapper>
          <Icon size={24} color="purple" />
        </IconWrapper>
        <Title>{title}</Title>
        <RadioGroup>
          {['done', 'some', 'none'].map((status) => {
            const colors = {
              done: 'green',
              some: 'yellow',
              none: 'red'
            }

            return (
              <Label
                key={status}
                htmlFor={`${title}-${status}`}
                selected={value === status}
                color={colors[status as 'done' | 'some' | 'none']}
              >
                <RadioGroupItem
                  type="radio"
                  value={status}
                  id={`${title}-${status}`}
                  checked={value === status}
                  onChange={() => onChange(status)}
                />
                <div className="circle" />
                <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
              </Label>
            )
          })}
        </RadioGroup>
      </CardContent>
    </Card>
  )
}
