import { ReactNode } from "react";
import { styled } from "styled-components";

const StyledListCardContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  width: 100%;
  margin: 24px 0 48px 0;

  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ListCardContainer = ({ children }: { children: ReactNode }) => {
  return <StyledListCardContainer>{children}</StyledListCardContainer>;
};

export default ListCardContainer;
