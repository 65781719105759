import { ref, get } from 'firebase/database'
import { db } from '../config/config'
import {
  BY_DEFAULT_TEMPLATE_COLORS,
  BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_2,
  BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_3,
  BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_4
} from '../constant'

const colorsMap = {
  primary: BY_DEFAULT_TEMPLATE_COLORS,
  secondary: BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_2,
  tertiary: BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_3,
  quaternary: BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_4
}

/**
 * Dynamically determines the next color type to assign.
 * @param teamId - The ID of the team.
 * @returns An object containing the next color type to assign and the list of assigned color types.
 */
export const determineNextColorType = async (teamId: string) => {
  try {
    const templatesRef = ref(db, `templates/${teamId}`)
    const snapshot = await get(templatesRef)

    if (!snapshot.exists()) {
      // If no templates exist, assign "primary" as the first color type
      return { nextColorType: 'primary', usedTypes: [] }
    }

    const templates = snapshot.val()

    // Extract all used color types (primary, secondary, tertiary)
    const usedTypes = Object.values(templates).flatMap((template: any) =>
      Object.values(template?.colors || {}).map((color: any) => color.type)
    )

    // Determine the next color type by finding the missing one
    const allTypes = ['primary', 'secondary', 'tertiary', 'quaternary']
    const nextColorType =
      allTypes.find((type) => !usedTypes.includes(type)) || 'primary'

    return { nextColorType, usedTypes }
  } catch (error) {
    console.error('Error determining next color type:', error)
    throw error
  }
}

/**
 * Dynamically assigns an available color based on its type (primary, secondary, or tertiary).
 * @param teamId - The ID of the team.
 * @returns An available color object or null if no colors are available.
 */
export const assignAvailableColor = async (teamId: string) => {
  try {
    // Fetch the next color type to assign
    const { nextColorType } = await determineNextColorType(teamId)

    // Fetch all templates to extract used colors
    const templatesRef = ref(db, `templates/${teamId}`)
    const templatesSnapshot = await get(templatesRef)

    const usedColors = templatesSnapshot.exists()
      ? Object.values(templatesSnapshot.val()).flatMap((template: any) =>
          Object.values(template?.colors || {}).map((color: any) => color.text)
        )
      : []

    // Fetch the palette for the determined color type
    const palette =
      colorsMap[nextColorType as 'primary' | 'secondary' | 'tertiary']

    // Find the first available color from the palette
    const availableColor = Object.values(palette).find(
      (color: any) => !usedColors.includes(color.text)
    )

    if (!availableColor) {
      console.warn(`No available colors in the ${nextColorType} palette.`)
      return null
    }

    // Return the available color along with its type
    return {
      currentColor: {
        id: availableColor.id,
        text: availableColor.text,
        bg: availableColor.bg,
        type: nextColorType
      },
      colorType: nextColorType,
      palette
    }
  } catch (error) {
    console.error('Error assigning available color:', error)
    return null
  }
}
