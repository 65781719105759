import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface ProgressCircleProps {
  percent?: number;
  positive?: boolean;
  customStroke?: string;
  noProgressive?: boolean;
  noBg?: boolean;
  label?: string;
  text?: string;
  textSize?: string;
  maxValue?: number;
  noFill?: boolean;
  strokeWidth?: number | string;
  customFill?: string;
  textColor?: string;
  size?: number; // Custom size option
}

const CircleWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  @media screen and (max-width: 768px) {
    width: ${({ size }) => size - 10}px;
    height: ${({ size }) => size - 10}px;
  }
`;

const SvgWrapper = styled.svg`
  width: 100%;
  height: 100%;
`;

const CircleText = styled.text<{ fontSize: string }>`
  fill: #333;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: calc(${({ fontSize }) => fontSize} - 1px);
  }
`;

const Circle = styled.circle`
  transition: stroke-dashoffset 500ms ease-in-out;
`;

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  percent = 0,
  positive,
  customStroke,
  noProgressive,
  label,
  text,
  textSize = "15px",
  maxValue = 100,
  noFill = false,
  noBg = true,
  strokeWidth = 4,
  customFill,
  textColor,
  size = 77, // Default size
}) => {
  const radius = (size / 2) - (Number(strokeWidth) / 2) - 1; // Adjust radius to account for stroke width
  const circumference = 2 * Math.PI * radius;
  const [strokeDashoffset, setStrokeDashoffset] = useState(circumference);

  const progress = percent >= maxValue ? 1 : percent / maxValue;
  const strokeColor = customStroke ? customStroke : noProgressive ? "#FDAF48" : positive ? "#2AB514" : "#FF1A5E";

  useEffect(() => {
    const offset = circumference * (1 - progress) || 0;
    setTimeout(() => setStrokeDashoffset(offset), 150);
  }, [progress, circumference]);

  return (
    <CircleWrapper size={size}>
      <SvgWrapper viewBox={`0 0 ${size} ${size}`}>
        {/* Background circle */}
        {!noBg && (
          <Circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill={customFill ? customFill : "transparent"}
            strokeWidth="4.4"
            stroke="transparent"
          />
        )}
        {/* Empty progress circle (background) */}
        <Circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          strokeWidth={strokeWidth || "4.4"}
          stroke="#E6E6E6"
          opacity={0.5}
        />
        {/* Foreground progress circle */}
        <Circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeDasharray={circumference}
          strokeWidth={strokeWidth || "4.4"}
          stroke={noFill ? "black" : strokeColor}
          style={{
            strokeDashoffset,
            transform: 'rotate(-90deg)',
            transformOrigin: 'center',
            transition: 'stroke-dashoffset 500ms ease-in-out'
          }}
        />
        {/* Text */}
        <CircleText
          x="50%"
          y="50%"
          fontSize={textSize}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          <tspan
            fontSize={textSize || "15px"}
            fill={textColor ? textColor : noFill ? "black" : strokeColor}
            x="50%"
            dy={label ? "-0.6em" : "0"}
          >
            {text}
          </tspan>
          {label && <tspan x="50%" dy="1.3em">{label}</tspan>}
        </CircleText>
      </SvgWrapper>
    </CircleWrapper>
  );
};

export default ProgressCircle;