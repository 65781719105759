import React from "react";
import Layout from "../../Layout/Layout";
import SubNav from "../../ui/SubNav/SubNav";
import styled from "styled-components";
import MyAccountBodyContainer from "./MyAccountBodyContainer";
import { theme } from "../../../styles";
import InputField from "../../ui/InputField/InputField";
import Button from "../../ui/Button/Button";

export const StyledAccountMainContainer = styled.div`
  width: 100%;
  overflow: auto;
  padding-bottom: 40px;
  max-height: 100%;
  
  @media screen and (max-width: 834px) {
    // min-height: 100%;
    flex-grow: 1;
  }
  @media screen and (max-width: 430px) {
    padding-bottom: 0;
  }
`;

export const StyledTitle = styled.h2`
  color: ${theme.colors.copy};
  font-family: "rooney";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
`;

export const StyledAccountOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const StyledAccountOptionTitle = styled.p`
  color: ${theme.colors.copy};
  font-family: "rooney-sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

export const StyledOptionInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const StyledInputLabel = styled.label`
  color: ${theme.colors.copy};

  font-family: "rooney-sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`;

export const StyledInputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const StyledDangerZoneContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid rgba(255, 0, 0, 0.1);
  margin-top: 24px;
`;

export const StyledDangerZoneHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const StyledDangerTitle = styled.h4`
  color: ${theme.colors.copy};
  font-family: "rooney-sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

export const StyledDangerZoneDescription = styled.p`
  color: ${theme.colors.copy};
  font-family: "rooney-sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
`;

const MyAccount = () => {
  return (
    <Layout>
      <StyledAccountMainContainer>
        {/* <SubNav title="Component Status" /> */}
        <MyAccountBodyContainer>
          <div>
            <StyledTitle>My Account</StyledTitle>
          </div>
          <StyledAccountOptionsContainer>
            <StyledAccountOptionTitle>
              Basic Information
            </StyledAccountOptionTitle>
            <StyledOptionInputsContainer>
              <StyledInputLabelContainer>
                <StyledInputLabel>Email address</StyledInputLabel>

                <InputField label="Email address" fullWidth type="email" />
              </StyledInputLabelContainer>
              <Button label="Update" disabled />
            </StyledOptionInputsContainer>
          </StyledAccountOptionsContainer>
          <StyledAccountOptionsContainer>
            <StyledAccountOptionTitle>Change Password</StyledAccountOptionTitle>
            <StyledOptionInputsContainer>
              <StyledInputLabelContainer>
                <StyledInputLabel>Verify current password</StyledInputLabel>
                <InputField label="Password" fullWidth type="password" />
              </StyledInputLabelContainer>
              <StyledInputLabelContainer>
                <StyledInputLabel>New Password</StyledInputLabel>
                <InputField label="Password" fullWidth type="password" />
              </StyledInputLabelContainer>
              <StyledInputLabelContainer>
                <StyledInputLabel>Confirm New Password</StyledInputLabel>
                <InputField label="Password" fullWidth type="password" />
              </StyledInputLabelContainer>
              <Button label="Update" disabled />
            </StyledOptionInputsContainer>
          </StyledAccountOptionsContainer>
          <StyledDangerZoneContainer>
            <StyledDangerZoneHeader>
              <StyledDangerTitle>Danger Zone</StyledDangerTitle>
              <StyledDangerZoneDescription>
                Are you sure you want to delete your account? This action is
                irreversible and will permanently erase all your data. If you're
                sure, tap 'Delete Account' below.
              </StyledDangerZoneDescription>
            </StyledDangerZoneHeader>
            <Button label="Delete Account" variant="danger" />
          </StyledDangerZoneContainer>
        </MyAccountBodyContainer>
      </StyledAccountMainContainer>
    </Layout>
  );
};

export default MyAccount;
