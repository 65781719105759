import { createSlice } from '@reduxjs/toolkit'
import { ComponentObj } from './componentsReducerSlice';

interface ProjectState {
  data: ComponentObj[]
  isLoading: boolean
  recentlyViewedProjectIds: Array<{ id: string; pageId: string }>
}

//
const initialState: ProjectState = {
  data: [],
  recentlyViewedProjectIds: [
    {
      id: '',
      pageId: ''
    }
  ],
  isLoading: true
}

//
export const projectReducerSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    setProjectSlice: (state, action) => {
      state.data = action.payload.data as ComponentObj[]
      state.isLoading = action.payload.isLoading
    },
    setProjectsData: (state, action) => {
      state.data = action.payload.data
    },
    resetProjects: (state) => {
      state.data = initialState.data
      state.isLoading = false
    },
    setRecentlyViewedProjectIds: (state, action) => {
      state.recentlyViewedProjectIds = action.payload
    }
  },
  extraReducers: (builder) => {}
})

export const {
  setProjectSlice,
  setProjectsData,
  resetProjects,
  setRecentlyViewedProjectIds
} = projectReducerSlice.actions

export default projectReducerSlice.reducer