import React from 'react'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import { NumberStepper } from './NumberStepper'
import { FormValues } from '../form.validations'

interface GroupQuestionProps {
  question: {
    id: number
    text: string
    fields: Array<{
      label: string
      type: string
      max_length?: number
    }>
  }
  fieldName: any
}

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Title = styled.h2`
  font-size: 2.125rem;
  font-weight: 600;
`

const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
`

const CardContent = styled.div`
  padding: 1.5rem;
`

const FieldsGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Label = styled.label`
  font-size: 1.875rem;
  font-weight: 500;
`

const StyledTextarea = styled.textarea<{ maxLength?: number }>`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;
  &:focus {
    outline: none;
    border-color: #666;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
`

export function GroupQuestion({ question, fieldName }: GroupQuestionProps) {
  const { control } = useFormContext<FormValues>()
  return (
    <Wrapper>
      <Title>{question.text}</Title>
      <Card>
        <CardContent>
          <FieldsGrid>
            {question.fields.map((field, index) => {
              const fieldNameQuestion = `${fieldName}.${field.label}`
              return (
                <FieldWrapper key={index}>
                  {field.type === 'stepper' ? (
                    <NumberStepper
                      label={field.label}
                      name={fieldNameQuestion}
                      min={1}
                      max={100}
                    />
                  ) : field.type === 'textarea' ? (
                    <Controller
                      name={fieldNameQuestion as any}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <Label htmlFor={`textarea-${index}`}>
                            {field.label}
                          </Label>
                          <StyledTextarea
                            id={`textarea-${index}`}
                            placeholder="Type your answer here"
                            maxLength={field.max_length}
                            onChange={onChange}
                            value={value}
                          />
                        </>
                      )}
                    />
                  ) : null}
                </FieldWrapper>
              )
            })}
          </FieldsGrid>
        </CardContent>
      </Card>
    </Wrapper>
  )
}
