import React from 'react'
import styled from 'styled-components'
import { useFormContext, Controller } from 'react-hook-form'
import { DesignSystemStatusCard } from './DesignSystemStatusCard'
import { Book, Layout, Layers, FileCode, Puzzle } from 'lucide-react'

interface NestedSingleChoiceQuestionProps {
  question: {
    id: number
    text: string
    items: Array<{
      label: string
      options: string[]
    }>
  }
  fieldName: any
}

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
`

const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`

export function NestedSingleChoiceQuestion({
  question,
  fieldName
}: NestedSingleChoiceQuestionProps) {
  const { control } = useFormContext() // Access the form context

  const getIconForItem = (label: string) => {
    switch (label) {
      case 'Design Tokens & Foundation':
        return Book
      case 'Basic UI Components (buttons, inputs, etc.)':
        return Layout
      case 'Advanced Components (modals, tables, etc.)':
        return Layers
      case 'Page Patterns & Templates':
        return Puzzle
      case 'Documentation':
        return FileCode
      default:
        return Book
    }
  }

  return (
    <Wrapper>
      <Title>{question.text}</Title>
      <Grid>
        {question.items.map((item, index) => {
          const fieldNameText = `${fieldName}.${item.label}`
          console.log({ fieldNameText })
          return (
            <Controller
              key={index}
              name={fieldNameText}
              control={control}
              defaultValue="none"
              render={({ field: { value, onChange } }) => (
                <DesignSystemStatusCard
                  icon={getIconForItem(item.label)}
                  title={item.label}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          )
        })}
      </Grid>
    </Wrapper>
  )
}
