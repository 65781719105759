import styled from "styled-components";
import Button from "../Button/Button";
import useIsMobileDevice from "../../../hooks/useIsMobileDevice";

export interface ButtonGroupProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    buttonLabel1?: string;
    buttonLabel2?: string;
    buttonVariant1?: "primary" | "secondary" | "tertiary" | "danger";
    buttonVariant2?: "primary" | "secondary" | "tertiary" | "danger";
    buttonOnClick1?: () => void;
    buttonOnClick2?: () => void;
    showSecondButton?: boolean;
    buttonDisabled1?: boolean;
    buttonDisabled2?: boolean;
}

const StyledButtonGroup = styled.div<ButtonGroupProps>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media screen and (min-width: 834px){
        flex-direction: row;
    }
    width: 100%;
 
`;

const ButtonGroup: React.FC<ButtonGroupProps> = ({ buttonLabel1, buttonLabel2, buttonVariant1 = "primary", buttonVariant2 = "tertiary", buttonOnClick1, buttonOnClick2, showSecondButton = true, buttonDisabled1, buttonDisabled2, ...props }) => {

    const isMobile = useIsMobileDevice();

    return (
        <StyledButtonGroup {...props}>
            <Button type="button" disabled={buttonDisabled1} label={buttonLabel1} variant={buttonVariant1} size={isMobile ? "small" : "medium"} onClick={buttonOnClick1} style={{
                flex: "1 0 0"
            }} />
            {showSecondButton && (
                <Button type="button" disabled={buttonDisabled2} label={buttonLabel2} variant={buttonVariant2} size={isMobile ? "small" : "medium"} onClick={buttonOnClick2} style={{
                    flex: "1 0 0",
                    border: "none"
                }} />
            )}

        </StyledButtonGroup>
    );
};

export default ButtonGroup;