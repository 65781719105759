import {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DrawerContext } from '../../../context/DrawerContext'
import { RootState } from '../../../store'
import { theme } from '../../../styles'
import Logo from '../../assets/images/logo'
import AccountMenu from '../AccountMenu/AccountMenu'
import NavCategory from '../NavCategory/NavCategory'
import NavItem from '../NavItem/NavItem'
import { TemplateObj } from '../../../store/reducers/templateReducerSlice'
import { ComponentObj } from '../../../store/reducers/componentsReducerSlice'
import { PhaseObj } from '../../../store/reducers/phaseReducerSlice'
import BrandName from '../../assets/images/BrandName'
import MenuHeader from '../MenuHeader/MenuHeader'
import ToggleRecentlyViewed from '../ToggleRecentlyViewed/ToggleRecentlyViewed'
import NavSubItem from '../NavSubItem/NavSubItem'

interface NavProps {
  logout?: () => void
  showLogo?: boolean
  setLogoutConfirmModal: Dispatch<SetStateAction<boolean>>
  setSwitchTeamModal: Dispatch<SetStateAction<boolean>>
}

export const StyledNavMainContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.white};
  border-right: 1px solid ${theme.colors.borderDefault};
  width: 280px;
  height: 100vh;
`

export const StyledNavContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: ${theme.colors.white};
  border-right: 2px solid ${theme.colors.borderDefault};
  height: 100vh;
  max-height: 100vh;
  flex-shrink: 0;
  width: 280px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 80px;

  @media screen and (max-width: 768px) {
    height: 100%;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
`
export const StyledNavItems = styled.div`
  display: flex;
  padding: 16px;
  padding-bottom: 280px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  height: 100vh;
  max-height: 100vh;
  flex-shrink: 0;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-width: 834px) {
    padding-bottom: 160px;
  }
`

export const StyledNavCategoryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const StyledNavItemsContainer = styled.div<{ collapsed?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: fit-content;
  overflow-x: hidden;
  height: ${({ collapsed }) => (collapsed ? '0px' : 'auto')};
  overflow-y: ${({ collapsed }) => (collapsed ? 'hidden' : 'auto')};
  flex-shrink: 0;
  flex-grow: 1;
`

export const StyledNavAccountsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  flex-shrink: 0;

  @media screen and (max-width: 834px) {
    display: none;
  }
`

export const StyledMenuOverlay = styled.div`
  width: 99%;
  height: 80px;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
  position: absolute;
  bottom: 137px;
  left: 0;

  @media screen and (max-width: 834px) {
    display: none;
  }
`

export const StyledMenuBottomOverlay = styled.div`
  width: 100%;
  height: 16px;
  pointer-events: none;
  background: ${theme.colors.white};
  position: absolute;
`

export const StyledLogoContainer = styled.div<{ isScrolled: boolean }>`
  display: flex;
  width: 100%;
  padding: 24px 16px;
  align-items: center;
  gap: 16px;
  position: sticky;
  top: -16px;
  right: 0;
  z-index: 9999;
  border-bottom: ${({ isScrolled }) =>
    isScrolled ? `1px solid ${theme.colors.borderDefault}` : 'none'};
  background: ${({ isScrolled }) =>
    isScrolled ? theme.colors.white : 'transparent'};
  @media screen and (max-width: 834px) {
    display: none;
  }
`

export const StyledMenuHeaderContainer = styled.div`
  width: 100%;
  @media screen and (min-width: 834px) {
    display: none;
  }
`

export const StyledRecentlyViewedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

export const StyledRecentlyViewedItemsContainer = styled.div<{
  collapsed?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow-x: hidden;
  height: ${({ collapsed }) => (collapsed ? '0px' : 'auto')};
  overflow-y: ${({ collapsed }) => (collapsed ? 'hidden' : 'auto')};
  flex-shrink: 0;
  flex-grow: 1;
`

const Nav = ({
  logout,
  showLogo = true,
  setLogoutConfirmModal,
  setSwitchTeamModal
}: NavProps) => {
  const [recentViewTemplateToggle, setRecentViewTemplateToggle] =
    useState(false)
  const [recentViewComponentToggle, setRecentViewComponentToggle] =
    useState(false)
  // const [recentViewProjectToggle, setRecentViewProjectToggle] =
  //   useState(false)
  const navContainerRef = useRef<HTMLDivElement | null>(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const [recentComponents, setRecentComponents] = useState<ComponentObj[]>()
  const [recentProjects, setRecentProjects] = useState<ComponentObj[]>()
  const [recentlyViewedTemplates, setRecentlyViewedTemplates] =
    useState<TemplateObj[]>()

  const {
    data: templatesList,
    isLoading: isLoadingTemplates,
    recentlyViewedTemplateIds
  } = useSelector((store: RootState) => store.template)
  const { data: phases } = useSelector((store: RootState) => store.phase)
  const {
    data: components,
    isLoading: isLoadingComponents,
    recentlyViewedComponentIds
  } = useSelector((store: RootState) => store.component)
  const {
    data: projects,
    // isLoading: isLoadingProjects,
    recentlyViewedProjectIds
  } = useSelector((store: RootState) => store.project)
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { user } = useSelector((store: RootState) => store.auth)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { id, pageId } = useParams()
  const { setIsDrawerOpen } = useContext(DrawerContext)
  const componentTab = pathname?.includes('dashboard/components')
  const projectTab = pathname?.includes('dashboard/projects')
  const templateTab = pathname?.includes('dashboard/templates')

  // match active component btn
  const matchKey = (tempId: any = '', phaseID: any = '') => {
    return componentTab && id === tempId && pageId === phaseID
  }

  // match active project btn
  const matchProjKey = (projId: any = '') => {
    return projectTab && id === projId
  }

  // match active template btn
  const matchTempKey = (tempId: any = '') => {
    return templateTab && id === tempId
  }

  const getPhase = (comp: ComponentObj): PhaseObj | undefined => {
    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    const currentTemplate: TemplateObj | undefined = templatesList?.find(
      (v) => v?.teamId === teamId
    )

    if (!currentTemplate || !phases) {
      return undefined
    }

    return phases
      ?.filter((phase) => !phase?.archived)
      ?.find((v) => v?.id === comp?.activePhase)
  }

  const getCurrentTemplate = useMemo(() => {
    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    return templatesList?.find((template) => template?.teamId === teamId)
  }, [templatesList, currentTeam])

  //
  const handleScroll = (e: any) => {
    if (e?.currentTarget?.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  // close sidebar
  const closeSidebar = () => {
    setIsDrawerOpen(false)
  }

  const logoutHandler = () => {
    if (logout) {
      logout()
    }
    closeSidebar()
  }

  //
  useEffect(() => {
    if (!navContainerRef.current) {
      return
    }
    navContainerRef.current.addEventListener('scroll', handleScroll)
  }, [])

  // get recently viewed components from the recentlyViewedComponentIds
  useEffect(() => {
    if (recentlyViewedComponentIds?.length > 0) {
      const recentlyViewedComps = recentlyViewedComponentIds
        ?.map((recent) => {
          let item = components?.find(
            (comp) => comp?.id === recent?.pageId && recent?.id === comp?.fileId
          )
          return item
        })
        .filter((item) => Boolean(item))
      setRecentComponents(recentlyViewedComps as ComponentObj[])
    }
  }, [recentlyViewedComponentIds, components])

  // get recently viewed projects from the recentlyViewedProjectIds
  useEffect(() => {
    if (recentlyViewedProjectIds?.length > 0) {
      const recentlyViewedProj = recentlyViewedProjectIds
        ?.map(({ id }) => projects?.find((proj) => proj?.id === id))
        .filter(Boolean) as ComponentObj[]
      setRecentProjects(recentlyViewedProj)
    } else {
      setRecentProjects([])
    }
  }, [recentlyViewedProjectIds, projects])

  //get recently viewed templates from the recentlyViewedTemplateIds
  useEffect(() => {
    if (recentlyViewedTemplateIds?.length > 0) {
      const recentlyViewedTemplates = recentlyViewedTemplateIds
        ?.map(({ id }) => templatesList?.find((temp) => temp?.id === id))
        .filter(Boolean) as TemplateObj[]
      setRecentlyViewedTemplates(recentlyViewedTemplates)
    } else {
      setRecentlyViewedTemplates([])
    }
  }, [recentlyViewedTemplateIds, templatesList])

  // JSX
  return (
    <StyledNavMainContainer>
      {showLogo && (
        <StyledLogoContainer isScrolled={isScrolled}>
          <Logo width={40} height={40} />
          <BrandName />
        </StyledLogoContainer>
      )}
      <StyledMenuHeaderContainer>
        <MenuHeader title="Menu" handleClose={closeSidebar} />
      </StyledMenuHeaderContainer>
      <StyledNavItems ref={navContainerRef}>
        {/* Templates*/}
        <StyledNavCategoryContainer>
          <NavCategory title="Templates" />
          <Link
            to="/dashboard/templates"
            style={{ display: 'block', width: '100%' }}
            onClick={closeSidebar}
          >
            <NavItem
              navItemTitle="All Templates"
              count={templatesList?.length}
              icon="check-square"
              isActive={templateTab === true && !id ? true : false}
            />
          </Link>
          {/* Recently Viewed Templates */}
          {(recentlyViewedTemplates?.length ?? 0) > 0 && (
            <StyledRecentlyViewedContainer>
              <ToggleRecentlyViewed
                collapsed={recentViewTemplateToggle}
                onClick={() =>
                  setRecentViewTemplateToggle(!recentViewTemplateToggle)
                }
              />
              <StyledRecentlyViewedItemsContainer
                collapsed={recentViewTemplateToggle}
              >
                {!isLoadingTemplates &&
                  recentlyViewedTemplates?.map((temp) => {
                    return (
                      <Link
                        to={`/dashboard/templates/${temp?.id}`}
                        key={temp?.id}
                        style={{ display: 'block', width: '100%' }}
                        onClick={() => {
                          closeSidebar()
                        }}
                      >
                        <NavSubItem
                          navItemTitle={temp?.title}
                          isActive={matchTempKey(temp?.id)}
                          showPhaseType={false}
                        />
                      </Link>
                    )
                  })}
              </StyledRecentlyViewedItemsContainer>
            </StyledRecentlyViewedContainer>
          )}
        </StyledNavCategoryContainer>

        {/* Components*/}
        <StyledNavCategoryContainer>
          <NavCategory title="Components" />
          <Link
            to="/dashboard/components"
            style={{ display: 'block', width: '100%' }}
            onClick={closeSidebar}
          >
            <NavItem
              navItemTitle="All Components"
              count={components?.length}
              icon="box"
              isActive={componentTab === true && !pageId ? true : false}
            />
          </Link>
          {/* <NavItem
            navItemTitle="Archived"
            showCounter={false}
            icon="folder"
            // disabled
          /> */}
          {/* <NavItem
            navItemTitle="Bin"
            icon="trash"
            showCounter={false}
            disabled
          /> */}

          {/* Recently Viewed Components */}
          {(recentComponents?.length ?? 0) > 0 && (
            <StyledRecentlyViewedContainer>
              <ToggleRecentlyViewed
                collapsed={recentViewComponentToggle}
                onClick={() =>
                  setRecentViewComponentToggle(!recentViewComponentToggle)
                }
              />
              <StyledRecentlyViewedItemsContainer
                collapsed={recentViewComponentToggle}
              >
                {!isLoadingComponents &&
                  recentComponents?.map((comp, i) => {
                    const phase = getPhase(comp)
                    const color =
                      getCurrentTemplate?.colors[phase?.colorId || '']
                    return (
                      <Link
                        to={`/dashboard/components/${comp?.fileId}/${comp?.id}`}
                        key={i}
                        style={{ display: 'block', width: '100%' }}
                        onClick={closeSidebar}
                      >
                        <NavSubItem
                          navItemTitle={
                            comp?.linkedElement?.name || comp?.title || '-'
                          }
                          isActive={matchKey(comp?.fileId, comp?.id || '')}
                          textColor={color?.text || '#000'}
                          showPhaseType={true}
                        />
                      </Link>
                    )
                  })}
              </StyledRecentlyViewedItemsContainer>
            </StyledRecentlyViewedContainer>
          )}
        </StyledNavCategoryContainer>
        {/* Projects */}
        {/* <StyledNavCategoryContainer>
          <NavCategory title="Projects" />
          <Link
            to="/dashboard/projects"
            style={{ display: 'block', width: '100%' }}
            onClick={closeSidebar}
          >
            <NavItem
              navItemTitle="All Projects"
              count={projects?.length}
              icon="kanban"
              isActive={projectTab === true && !pageId ? true : false}
            />
          </Link>
          <NavItem
            navItemTitle="Archived"
            showCounter={false}
            icon="folder"
            disabled
          />
          <NavItem
            navItemTitle="Bin"
            icon="trash"
            showCounter={false}
            disabled
          /> */}

        {/* Recently Viewed Projects */}
        {/* {(recentProjects?.length ?? 0) > 0 && (
            <StyledRecentlyViewedContainer>
              <ToggleRecentlyViewed
                collapsed={recentViewProjectToggle}
                onClick={() =>
                  setRecentViewProjectToggle(!recentViewProjectToggle)
                }
              />
              <StyledRecentlyViewedItemsContainer
                collapsed={recentViewProjectToggle}
              >
                {!isLoadingProjects &&
                  recentProjects?.map((proj, i) => {
                    const phase = getPhase(proj)
                    const color =
                      getCurrentTemplate?.colors[phase?.colorId || '']
                    return (
                      <Link
                        to={`/dashboard/projects/${proj?.id}`}
                        key={i}
                        style={{ display: 'block', width: '100%' }}
                        onClick={closeSidebar}
                      >
                        <NavSubItem
                          navItemTitle={proj?.title || '-'}
                          isActive={matchProjKey(proj?.id || '')}
                          textColor={color?.text || '#000'}
                          showPhaseType={true}
                        />
                      </Link>
                    )
                  })}
              </StyledRecentlyViewedItemsContainer>
            </StyledRecentlyViewedContainer>
          )} */}
        {/* </StyledNavCategoryContainer> */}
      </StyledNavItems>
      <StyledMenuOverlay />
      {/* Account Menu */}
      <StyledNavAccountsContainer>
        <AccountMenu
          teamName={currentTeam?.title ?? ''}
          profileEmail={user?.email ?? ''}
          profileImageSrc={user?.profileImg ?? ''}
          teamImageSrc={currentTeam?.icon || ''}
          logout={logoutHandler}
          onClick1={() => {
            navigate('/dashboard/team')
            closeSidebar()
          }}
          onClick2={() => {
            navigate('/dashboard/profile')
            closeSidebar()
          }}
          setLogoutConfirmModal={setLogoutConfirmModal}
          setSwitchTeamModal={setSwitchTeamModal}
        />
        <StyledMenuBottomOverlay />
      </StyledNavAccountsContainer>
    </StyledNavMainContainer>
  )
}

export default Nav
