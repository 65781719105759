import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { StyledHeaderLabel } from "../../components/ui";
import { StyledTeamsAndUsersDetailMainContainer } from "../../components/screens/TeamAndUserDetail/TeamAndUserDetail";
import TeamAndUserDetailBodyContainer from "../../components/screens/TeamAndUserDetail/TeamAndUserDetailBodyContainer";
import {
  StyledTeamsAndUsersOptionsContainer,
  StyledTeamsAndUsersOptionTitle,
  StyledUserEmail,
  StyledUserItem,
  StyledUserItemsContainer,
} from "../../components/screens/TeamAndUsers/TeamsAndUsers";
import Button from "../../components/ui/Button/Button";
import Label from "../../components/ui/Label/Label";
import SubNav from "../../components/ui/SubNav/SubNav";
import TopNavItem from "../../components/ui/TopNavItem/TopNavItem";
import { RootState } from "../../store";
import Spinner from "../../components/ui/Spinner";

const Teams: React.FC = () => {
  const navigate = useNavigate();
  const {
    currentTeam,
    isLoading: isTeamLoading,
    teams,
  } = useSelector((store: RootState) => store.team);
  const { user } = useSelector((store: RootState) => store.auth);
 
  //   change team selection
  const selectTeam = (id: string) => {
    if (!id) return;
    localStorage.setItem("checklist_team_id", id);
    toast.success("Team selected.")
    window.location.replace("/dashboard/team");
  };

  // JSX
  return (
    <StyledTeamsAndUsersDetailMainContainer>
      <SubNav title="Teams" onBtnClick={() => navigate("/dashboard/team")} />
      <TeamAndUserDetailBodyContainer>
        <StyledTeamsAndUsersOptionsContainer>
          <StyledTeamsAndUsersOptionTitle>
            Teams that you are a member of
          </StyledTeamsAndUsersOptionTitle>
          {/* ------------ Users list in the team ----------------- */}
          <StyledUserItemsContainer>
            {isTeamLoading ? (
              <div className="mx-auto">
                <Spinner type={"spinner"} />
              </div>
            ) : teams?.length > 0 ? (
              teams?.map((team, i) => (
                <StyledUserItem
                  key={i}
                  style={{ cursor: "default" }}
                  lastItem={teams?.length === i + 1}
                >
                  
                  <TopNavItem type="users" />
                  <StyledUserEmail>{team?.title || "no name"}</StyledUserEmail>
                  {team?.id === user?.id ? (
                    <Label text={"Your team"} type="success" />
                  ) : null}
                  {currentTeam?.id === team?.id ? (
                    <Label text={"Current team"} type="information" />
                  ) : (
                    <Button
                      onClick={() => selectTeam(team?.id || "")}
                      iconOnly
                      icon="share"
                      variant="tertiary"
                    />
                  )}
                </StyledUserItem>
              ))
            ) : (
              <StyledHeaderLabel>
                You aren’t part of any team yet.
              </StyledHeaderLabel>
            )}
          </StyledUserItemsContainer>
        </StyledTeamsAndUsersOptionsContainer>
      </TeamAndUserDetailBodyContainer>
    </StyledTeamsAndUsersDetailMainContainer>
  );
};

export default Teams;
