import { useState } from 'react'

declare global {
  interface Window {
    $crisp: any[]
    CRISP_WEBSITE_ID: string
  }
}

const CRISP_WEBSITE_ID = '6865cef3-bfd4-4056-b685-177e0e53445d' // Replace with your Crisp website ID
export const useCrisp = () => {
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)

  const loadCrisp = () => {
    if (isCrispLoaded) return

    if (!window.$crisp) {
      window.$crisp = []
    }

    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID

    const script = document.createElement('script')
    script.src = 'https://client.crisp.chat/l.js'
    script.async = true
    script.onload = () => setIsCrispLoaded(true)
    document.head.appendChild(script)
  }

  const openChat = () => {
    if (!isCrispLoaded) {
      loadCrisp()
    }
    window.$crisp?.push(['do', 'chat:open'])
  }

  const sendMessage = (message: string) => {
    if (!isCrispLoaded) {
      loadCrisp()
    }
    window.$crisp?.push(['do', 'message:send', ['text', message]])
  }

  const setUserDetails = (userDetails: { email?: string; name?: string }) => {
    if (!isCrispLoaded) {
      loadCrisp()
    }
    if (userDetails.email) {
      window.$crisp?.push(['set', 'user:email', [userDetails.email]])
    }
    if (userDetails.name) {
      window.$crisp?.push(['set', 'user:nickname', [userDetails.name]])
    }
  }

  return { openChat, sendMessage, setUserDetails, loadCrisp }
}
