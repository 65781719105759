import styled, { css } from "styled-components";
import { theme } from "../../../styles";

export interface CounterProps {
    isActive?: boolean;
    disabled?: boolean;
  count?: number;
}

const StyledCounter = styled.div<Pick<CounterProps, "isActive" | "disabled">>`
  display: inline-flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background-color: ${theme.colors.backgroundLight};
  color: ${theme.colors.copy};

  ${({ disabled }) =>
        !disabled &&
        css`
      &:hover {
        background-color: ${theme.colors.actionPrimaryHover};
        color: ${theme.colors.white};
      }
    `}

  ${({ disabled }) =>
        disabled &&
        css`
      cursor: not-allowed;
      background: ${theme.colors.actionDisabled};
    `}

${({ isActive, disabled }) =>
    isActive && !disabled &&
        css`
      background: ${theme.colors.actionPrimaryActive};
      color: ${theme.colors.white};
    `}
`;

const StyledCounterText = styled.p`
  font-family: "rooney-sans";
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: ${theme.font.regular};
  line-height: 140%;
`;
const Counter = ({
    isActive = false,
    disabled = false,
    count,
}: CounterProps) => {
    return (
      <StyledCounter className="counter" isActive={isActive} disabled={disabled}>
            <StyledCounterText>{count}</StyledCounterText>
        </StyledCounter>
    );
};

export default Counter;