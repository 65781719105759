import { Link } from "react-router-dom"
import { StyledDescription, StyledHeaderAction, StyledHeaderContainer, StyledHeaderText, StyledMainContainer, StyledPageContainer } from "../../components/screens/TermsOfService/TermsOfService"
import Button from "../../components/ui/Button/Button"


const TermsOfServicePage = () => {
    return (
        <StyledMainContainer>
            <StyledPageContainer style={{ margin: 'auto' }}>
                <StyledHeaderAction>
                    <Link to="/dashboard/profile"><Button variant="tertiary" icon="chevron-left" size="medium">Go back</Button></Link>
                </StyledHeaderAction>

                <StyledHeaderContainer>
                    <StyledHeaderText>Terms and Conditions</StyledHeaderText>
                </StyledHeaderContainer>
                <StyledDescription>
                    <p>Compfeed is owned and operated by Paksa.</p><br/>

                    <p>These are the terms and conditions for: </p><br/>

                    <ul>
                        <li><strong>Compfeed Web App (SaaS)</strong></li>
                        <li><a  href="https://www.govern.design">https://www.govern.design</a></li>
                    </ul>

                    <p>You agree to these terms and conditions and our privacy policy by using the platform and our services. In these terms and conditions, the words &quot;platform&quot; refers to the Compfeed web application, &quot;we,” &quot;us,” &quot;our,&quot; and &quot;Compfeed&quot; refers to Compfeed, and &quot;you&quot; and &quot;user&quot; refers to you, the user of Compfeed.</p><br/>

                    <p>The following terms and conditions apply to your use of the platform. This includes mobile and tablet versions and any other version of Compfeed accessible via desktop, mobile, tablet, social media, or other devices.</p><br/>

                    <p>PLEASE READ THESE CONDITIONS CAREFULLY BEFORE USING THE FUNCTIONALITIES AVAILABLE ON THE PLATFORM.</p><br/>

                    <h3>1. ACCEPTANCE OF TERMS</h3>

                    <p>By using the platform, you agree to be bound by this agreement. If you do not agree to the terms of this agreement, you must not use our services and platform. We may modify this agreement occasionally, and such modification will be effective upon posting on the platform. You agree to be bound by any changes to these terms and conditions when you use Compfeed following the posting of such modification; therefore, you must review this agreement regularly.</p><br/>

                    <p>Access to the platform is available for all ages. Parents or legal guardians are responsible for determining whether the content available on the platform is appropriate for their children or minors in their care.</p><br/>

                    <p>By using the platform, you represent and warrant that you have the full right, power, and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you are not under any legal incapacity or contractual restriction that would prevent you from entering into this agreement.</p><br/>

                    <h3>2. NOTIFICATIONS</h3>

                    <p>By providing your email address to Compfeed, you agree that we may use your email address to send you notifications about Compfeed. We may also use your email address to send you notifications and other messages, such as changes to service features, news, and special content. If you do not wish to receive these emails, you may opt out of receiving them by submitting your unsubscribe request through the contact information or by using the &quot;unsubscribe&quot; option in the emails. Opting out may prevent you from receiving notifications and mailings about updates, news, or special content.</p><br/>

                    <h3>3. DESCRIPTION AND USE OF THE PLATFORM</h3>

                    <p>This SaaS platform is designed to facilitate and optimize the design process for users, offering a comprehensive suite of tools to improve efficiency and quality in creating and managing designs.</p><br/>

                    <p>The platform allows users to check the current status of each contribution made to the design system. This functionality is intended to provide detailed control and tracking of every aspect of the design project.</p><br/>

                    <p>With an intuitive interface, users can efficiently record the progress of their contributions with a single click. This feature is designed to minimize distractions, allowing designers to keep their focus on the task at hand.</p><br/>

                    <p>All design components are displayed in a single overview. This capability ensures constant monitoring and facilitates access to all elements of the project, regardless of their location in specific files or pages.</p><br/>

                    <p>The platform provides a standardized template that is automatically sent to all widget instances. This ensures that each designer follows a consistent and effective process for adding new contributions to the system.</p><br/>

                    <p>Users can create customized procedure checklists. These checklists guide designers in the creation of compliant contributions, allowing them to divide and order tasks into sequential phases for better organization and efficiency.
                    Users are expected to use the platform in accordance with these guidelines, respect the established quality and efficiency standards, and contribute positively to the collaborative environment of the design system.</p><br/>

                    <h3>4. ACCOUNT</h3>

                    <p>Users will be able to register on the platform. When registering on the platform, the user must choose a password and may be asked for additional information, such as email address. You are responsible for maintaining the confidentiality of your password and account information and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Compfeed of any unauthorized use of your password or account or any other security breach and (b) ensure that you log out of your account at the end of each session. You may not use another user's account without prior authorization from Compfeed. Compfeed will not be liable for any loss or damage arising from your failure to comply with this agreement.
                    Users may terminate their accounts anytime and for any reason by sending us their request through our contact information. Such cancellation will only result in the account’s deletion and all personal data provided to Compfeed. Compfeed reserves the right to terminate your account or your access immediately, with or without notice, and without liability to you, if Compfeed believes that you have violated any of these terms.</p><br/>

                    <h3>5. SUBSCRIPTIONS</h3>

                    <p>Users will be able to use the platform through a free subscription or paid subscriptions that will allow the user to access special functionalities on the platform. When a user purchases a subscription, Compfeed will send a confirmation email. This confirmation email will be produced automatically so that the user will have confirmation of the payment and the start of the subscription. If the user does not receive the email confirmation of the purchase and the start of the subscription, it may have been sent to your spam folder.</p><br/>

                    <p>Compfeed may change or discontinue the availability of subscriptions at any time at its sole discretion. If a subscription purchase is canceled, the payment made for the subscription will be refunded for the applicable billing period; this does not affect your statutory rights.</p><br/>

                    <p>The subscriptions we offer are based on monthly payments with automatic billing. By subscribing, you grant Compfeed permission to automatically renew your subscription and charge your registered payment method each billing cycle. This cycle begins on the date you first purchase and pay for your subscription. Each billing cycle, the subscription fee will be deducted automatically. Your subscription will remain active until you decide to cancel it or we terminate the service. To avoid charges in the next billing cycle, it is necessary to cancel the subscription before automatic renewal. The subscription fee will be charged to the payment method selected during registration and purchase of the subscription.</p><br/>

                    <p>Unless canceled in advance, subscriptions will automatically renew for another period. Users can cancel the subscription through the account settings in the billing section, or they can send a cancellation request through our contact details, and the subscription will be canceled for the next billing cycle. In case of cancellation, the user will be able to continue enjoying the benefits of the subscription until the end of the current billing period.</p><br/>

                    <h3>6. PAYMENTS</h3>

                    <p>The following payment methods will pay subscriptions:</p><br/>

                    <ul>
                    <li>Credit/debit card (Visa, Master, Discover, Amex, Diners, etc.)</li>
                    </ul>

                    <p>With the exception of the free subscription, the subscription payment will be charged to your credit or debit card upon completion of the subscription payment and registration process. The subscription will be activated upon completion of the payment and registration process and will be automatically charged on each subscription billing date. Once the transaction is processed, we will send an electronic receipt via email.</p><br/>

                    <p>Should you detect any anomaly in your billing, we invite you to contact us using the contact information provided or to file a claim through the customer service of the payment processor used. If your card is declined, you will receive an error notification, and your order will not be charged or processed. A pending transaction may appear on your account until the card issuing bank cancels the authorization, which usually takes 2-5 business days. Your card may be declined for a variety of reasons, such as insufficient funds, Address Verification System (AVS) discrepancies, or entering an incorrect security code.
                    In the event that your payment is declined, you will need to provide an alternate payment method or a different card to make and process the payment. Your payment method information will be handled and stored securely and exclusively for processing the purchase of your subscriptions. Compfeed reserves the right to employ the services of any available payment processor.</p><br/>

                    <h3>7. USER CONTENT</h3>

                    <p>Some features of the platform may allow users to provide content and information to the platform. You retain any copyright or other rights you may have in the content you provide through the platform. Compfeed is not responsible for the accuracy, safety, or legality of content posted on the platform by users. Users are solely and exclusively responsible for their content and the consequences of its publication. By providing content and information through the platform, the user grants Compfeed a worldwide, non-exclusive, royalty-free, fully paid-up right and license to host, store, transfer, display, perform, reproduce, and modify the user content for the sole purpose of providing the functionalities of the platform properly.</p><br/>

                    <p>Compfeed is not responsible for the content of the user. The user is solely responsible for the content and the consequences of providing content through the platform. By providing content through the platform, you affirm, represent, and warrant that:</p><br/>

                    <ul>
                    <li>You are the creator and owner of the content you provide through the platform or have the licenses, rights, consents, and permissions to authorize Compfeed to publish and display your content through the platform.</li>
                    <li>Your content does not infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, right of privacy, right of publicity, or any other intellectual property or proprietary right.</li>
                    <li>Your content does not defame, libel, or invade any other person’s privacy, publicity, or other proprietary rights.</li>
                    <li>Your content does not cause Compfeed to violate any law or regulation.</li>
                    <li>Your content could not be considered by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate.</li>
                    </ul>

                    <h3>8. LIMITATION OF LIABILITY</h3>

                    <p>Paksa does not guarantee that the platform will be infallible and free from errors or interruptions. Paksa makes every effort to ensure proper functioning but does not guarantee constant availability and accessibility.</p><br/>

                    <p>Paksa shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, business opportunities, reputation, or any other intangible loss resulting from the use or inability to use the platform.</p><br/>

                    <p>Paksa shall not be liable for any failure or delay in performance due to circumstances beyond its reasonable control, including, without limitation, acts of nature, war, terrorism, riots, embargoes, acts of civil or military authorities, fires, floods, accidents, strikes, or shortages of transportation, fuel, power, labor or materials.</p><br/>

                    <p>Paksa is not responsible for problems arising from the user’s improper platform use, including violating the rules outlined in the terms and conditions or any other instructions provided by Paksa.</p><br/>

                    <p>Paksa assumes no responsibility for content created, stored, or managed by the user through the platform. The responsibility for such content’s legality, accuracy, and appropriateness rests entirely with the user.</p><br/>

                    <p>Any modification, alteration, or intervention in the platform by the user or third parties not authorized by Paksa, which results in damage or malfunction, releases Paksa from any liability related to such effects or damage.</p><br/>

                    <h3>9. LICENSE TO USE THE PLATFORM</h3>

                    <p>Compfeed grants you a personal, worldwide, royalty-free, non-assignable, non-exclusive license to use the Compfeed platform. This license is for the sole purpose of allowing you to use the features available on the platform, including the functionality to analyze the dreams provided by the user through Artificial Intelligence, as permitted by these terms and conditions. You may not copy, modify, distribute, sell, or rent any part of our platform or the included software, nor may you reverse engineer or attempt to extract the source code of such software unless such restrictions are prohibited by law, or you have our written permission.</p><br/>

                    <p>The user agrees not to use the platform in a negligent, fraudulent, or unlawful manner. The user also agrees not to engage in any conduct or action that could damage the image, interests, or rights of Compfeed or third parties. Compfeed reserves the right to terminate the user's access to the platform immediately, with or without notice, and without liability to the user if Compfeed believes that the user has violated any of these terms.</p><br/>

                    <h3>10. COPYRIGHT</h3>

                    <p>All materials on Compfeed, including, without limitation, names, logos, trademarks, games, images, text, columns, graphics, videos, photographs, illustrations, software, and other elements, are protected by copyrights, trademarks, and/or other intellectual property rights owned and controlled by Compfeed or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all Materials on Compfeed are made available only for limited, non-commercial, personal use, except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way or otherwise used for any purpose by any person or entity without Compfeed prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize Compfeed or any part of the material for any purpose other than its intended purposes is strictly prohibited.</p><br/>

                    <h3>11. COPYRIGHT INFRINGEMENT</h3>

                    <p>Compfeed will respond to all inquiries, complaints, and claims relating to alleged infringement by breach or violation of the provisions of European and international copyright and intellectual property laws and regulations. Compfeed respects the intellectual property of others and expects users to do the same. If you believe, in good faith, that any material provided on the platform infringes your copyright or other intellectual property rights, please submit your request using our contact information with the following information: </p><br/>

                    <ul>
                    <li>Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.</li>
                    <li>A statement specifically identifying the location of the infringing material.</li>
                    <li>Your name, address, telephone number, and email address.</li>
                    <li>A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, its agents, or by law.</li>
                    <li>A statement by you, made under penalty of perjury, that the information in your notification is accurate and that you are the copyright owner or authorized to act on its behalf.</li>
                    <li>An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner's behalf.</li>
                    </ul>

                    <h3>12. PROHIBITED ACTIVITIES</h3>

                    <p>The following activities are prohibited:</p><br/>

                    <ul>
                    <li>Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy, or otherwise exploit any content of the services, including but not limited to using any robot, spider, scraper, or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.</li>
                    <li>Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.</li>
                    <li>Take any action that imposes, or may impose, at our discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
                    <li>Deep-link to any portion of the services for any purpose without our express written permission.</li>
                    <li>&quot;Frame,” &quot;mirror,&quot; or otherwise incorporate any part of the platform into any other platform or service without our prior written authorization.</li>
                    <li>Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Compfeed in connection with the platform.</li>
                    <li>Circumvent, disable, or otherwise interfere with security-related features of the platform or features that prevent or restrict the use or copying of any content.</li>
                    </ul>

                    <h3>13. DISCLAIMER OF WARRANTIES</h3>

                    <p>Except as provided above, we can give no other warranties, conditions, or other terms, express or implied, statutory or otherwise, and all such terms are hereby excluded to the maximum extent permitted by law.</p><br/>

                    <p>You will be responsible for any breach of these terms by you, and if you use the platform in breach of these terms, you will be liable to and will reimburse Compfeed for any loss or damage caused as a result.</p><br/>

                    <p>Compfeed shall not be liable for any amount for failure to perform any obligation under this Agreement if such failure is due to the occurrence of any unforeseen event beyond its reasonable control, including, without limitation, Internet outages, communications outages, fire, flood, or any uncontrollable act of nature.</p><br/>

                    <p>Subject as aforesaid, to the maximum extent permitted by law, Compfeed excludes liability for any loss or damage of any kind, howsoever arising, including without limitation any direct, indirect, or consequential loss whether or not such arises out of any problem you notify to Compfeed and Compfeed shall have no liability to pay any money by way of compensation, including without limitation all liability concerning:</p><br/>

                    <ul>
                    <li>Any incorrect or inaccurate information on the platform.</li>
                    <li>The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the platform.</li>
                    <li>Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.</li>
                    <li>Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.</li>
                    <li>Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from Compfeed.</li>
                    <li>All representations, warranties, conditions and other terms that but for this notice would have effect.</li>
                    </ul>

                    <h3>14. ELECTRONIC COMMUNICATIONS</h3>

                    <p>Compfeed will not accept any responsibility for failed, partial, or garbled computer transmissions, for any breakdown, failure, connection, or availability of computer, telephone, network, electronic or Internet hardware or software, for Internet accessibility or availability or traffic congestion or any unauthorized human acts, including any errors or mistakes.</p><br/>

                    <h3>15. INDEMNIFICATION</h3>

                    <p>You agree to defend and indemnify Compfeed from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties, or other costs or expenses of any kind or nature, including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:</p><br/>

                    <ul>
                    <li>Your breach of this agreement or the documents referenced herein.</li>
                    <li>Your violation of any law or the rights of a third party.</li>
                    <li>Your use of the Compfeed platform.</li>
                    </ul>

                    <h3>16. CHANGES</h3>

                    <p>We may change the platform and these terms at any time, at our sole discretion and without notice. You are responsible for remaining knowledgeable about these terms. Your continued use of the platform constitutes your acceptance of any changes to these terms, and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these terms apply to all users take effect. </p><br/>

                    <h3>17. INTEGRATION CLAUSE</h3>

                    <p>This agreement, the privacy policy, and any other legal notices published by Compfeed shall constitute the entire agreement between you and Compfeed concerning and governs your use of the platform.</p><br/>

                    <h3>18. SEVERABILITY</h3>

                    <p>If any section of these terms is held invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions shall not in any way be affected or impaired. Our failure to enforce or delay any provision of these terms at any time does not waive our right to enforce the same or any other provision in the future.</p><br/>

                    <h3>19. FORCE MAJEURE</h3>

                    <p>Compfeed shall not be liable for any failure due to causes beyond its reasonable control, including but not limited to acts of civil authorities, acts of military authorities, riots, acts of nature and natural disasters, and other acts which may be due to unforeseen circumstances. </p><br/>

                    <h3>20. TERMINATION</h3>

                    <p>Both the user and Compfeed acknowledge that failure to comply with these terms and conditions may result in termination of the agreement between both parties.</p><br/>

                    <p><strong>In case of non-compliance by the user:</strong> If the user fails to comply with any of the terms outlined in these terms and conditions, Compfeed reserves the right to unilaterally terminate the user's access to and use of the platform. Compfeed may take this action without prior notice, and the user will automatically lose all rights to access and use the platform. Furthermore, Compfeed shall not be liable for any data loss or information associated with the user's account.</p><br/>

                    <p><strong>In case of non-compliance by Compfeed:</strong> If Compfeed fails to comply with its obligations under the agreed terms and conditions, the user shall be entitled to terminate the agreement and stop using the platform. The user shall notify Compfeed in writing of the breach and allow a reasonable time for Compfeed to remedy the situation. If Compfeed does not remedy the violation within the set period, the user may proceed with the termination of the agreement. </p><br/>

                    <p>Termination of the agreement shall not release either party from any obligations and liabilities that have arisen before the termination date. </p><br/>

                    <p>Continued use of the platform after termination of the agreement by either party shall imply acceptance of the conditions outlined in the terms and conditions in effect at that time.</p><br/>

                    <h3>21. DISPUTES</h3>

                    <p>The user agrees that any dispute, claim, or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation, or validity thereof or the use of the platform, shall be resolved by binding arbitration between the user and Compfeed, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.</p><br/>

                    <p>In the event that a dispute arises in connection with the use of the platform or breach of these terms and conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.</p><br/>

                    <p>To the fullest extent permitted by law, you agree that you will not file, join, or participate in any class action lawsuit in connection with any claim, dispute, or controversy that may arise in connection with your use of the platform.
                    The courts of the Netherlands shall have jurisdiction over any dispute, controversy, or claim relating to Compfeed and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of the Netherlands. </p><br/>

                    <h3>22. GOVERNING LAW AND JURISDICTION</h3>

                    <p>Although the Platform and the Services will be available internationally, these terms of Use shall be governed by and construed in accordance with the laws of the Netherlands and the European Union. Any dispute relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of the Netherlands and the European Union.</p><br/>

                    <h3>23. FINAL PROVISIONS</h3>

                    <p>Use of the Compfeed platform is restricted to jurisdictions where all provisions of these terms and conditions are observed and enforced. Our compliance with these terms operates within the framework of applicable laws and legal processes, and nothing herein restricts our ability to comply with legal compliance requests or governmental or judicial requirements associated with your use of our platform or the information we have collected or provided about such use.
                    In the event that any part of these terms shall be declared invalid, illegal, or unenforceable, such declaration shall not affect the validity, legality, and enforceability of the remaining provisions. Failure to exercise or delay in enforcing any provision of these terms does not waive our right to enforce such provision or any other provision in the future.
                    All rights not expressly granted in this agreement are reserved.</p><br/>

                    <h3>24. CONTACT INFORMATION</h3>

                    <p>If you have questions or concerns about these terms, please contact us using the contact information below:</p><br/>

                    <p>Paksa</p>
                    <br/>
                    <p>
                    <a  href="mailto:support@govern.design">support@govern.design</a>
                    </p><br/><p>+1 (929) 577-4084</p><br/>
                 </StyledDescription>
            </StyledPageContainer>
        </StyledMainContainer>

    )
}

export default TermsOfServicePage