import { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import { toast } from 'react-toastify'
import { RootState } from '../../../store'
import { MenuBottomSheet } from '../../ui/BottomSheet/BottomSheet'
import PopOverMenu from '../../ui/PopOverMenu/PopOverMenu'
import { StyledPhaseDeleteContentContainer } from '../../ui/Modal/ModalSlotContents'
import Modal from '../../ui/Modal/Modal'
import MenuItem from '../../ui/MenuItem/MenuItem'
import { ComponentTypes } from '../../../store/reducers/componentsReducerSlice'
import { useNavigate } from 'react-router-dom'
import { generateProjectLinks } from '../../../utils/generateProjectLinks'
import { db } from '../../../config/config'

interface IUpdateComponentProps {
  width?: string
  componentId?: string
  componentType?: ComponentTypes
}

const StyledUpdateComponent = styled.div<
  Omit<Partial<IUpdateComponentProps>, 'phaseItemName'>
>`
  display: flex;
  width: ${({ width }) => width};
  max-width: 100%;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease;
  border-radius: 4px;
`

const UpdateComponent = ({
  width = '100%',
  componentId,
  componentType
}: IUpdateComponentProps) => {
  const navigate = useNavigate()
  const isMobile = useIsMobileDevice()
  const [inProgress, setInProgress] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { data: allComponents } = useSelector(
    (store: RootState) => store.component
  )
  const { data: allProjects } = useSelector((store: RootState) => store.project)
  const isProject = componentType === 'project'
  const listItems = [...(isProject ? allProjects : allComponents)]
  const component = listItems?.find((c) => c?.id === componentId)
  const { currentTeam } = useSelector((store: RootState) => store.team)


  // Delete component
  const handleDeleteComponent = async () => {
    if (!componentId || inProgress || !component) return
    setInProgress(true)
    const project = await generateProjectLinks(
      db,
      currentTeam?.id ?? component.teamId ?? '',
      component?.id
    )
    try {
      const response = await fetch(
        `${import.meta.env.VITE_APP_SERVER_URL}/components/${component?.teamId}/${component?.id}?auth=${component?.teamId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.ok) {
        if (isProject) {
          const path = `${import.meta.env.VITE_APP_SERVER_URL}/email/project-remove`
          const teamAdmins = currentTeam?.members?.filter(
            (v) => v?.role === 'owner' || v?.role === 'admin'
          )
          await fetch(path, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              projectName: project?.projectName,
              links: project?.links,
              teamAdmins
            })
          })
        }
        navigate(isProject ? '/dashboard/projects' : '/dashboard/components', {
          replace: true
        })
      } else {
        toast.error('Something went wrong.')
      }
    } catch (error) {
      toast.error('Something went wrong.')
      console.log(
        'Error while deleting a task from the phase task list.',
        error
      )
    } finally {
      setInProgress(false)
    }
  }

  return (
    <>
      <StyledUpdateComponent width={width}>
        {isMobile ? (
          <MenuBottomSheet
            title="Options"
            menuItems={
              <MenuItem
                title="Remove"
                icon="trash"
                type="danger"
                onClick={() => setShowModal(true)}
                closeMenu={() => {}}
              />
            }
          />
        ) : (
          <PopOverMenu
            menuItems={
              <MenuItem
                title="Remove"
                icon="trash"
                type="danger"
                onClick={() => setShowModal(true)}
                closeMenu={() => {}}
              />
            }
          />
        )}
      </StyledUpdateComponent>
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          signal="alert-triangle"
          signalIconType='warning'
          title={isProject ? 'Remove Project' : 'Remove Component'}
          slotContent={<ComponentDeleteContent componentType={componentType} />}
          buttonLabel1={
            inProgress
              ? 'REMOVING'
              : isProject
                ? 'REMOVE PROJECT'
                : 'REMOVE COMPONENT'
          }
          buttonDisabled1={inProgress}
          buttonDisabled2={inProgress}
          buttonLabel2="CANCEL"
          buttonVariant1="danger"
          buttonVariant2="tertiary"
          buttonOnClick1={handleDeleteComponent}
        />
      )}
    </>
  )
}

export default UpdateComponent

export const ComponentDeleteContent = ({
  componentType
}: {
  componentType?: ComponentTypes
}) => {
  return (
    <StyledPhaseDeleteContentContainer>
      <p>
        Are you sure you want to delete this {componentType}? You cannot restore
        the progress after removing.
        <br />
        <br />
        {componentType !== 'project' &&
          'Don’t forget to remove the widget too.'}
      </p>
    </StyledPhaseDeleteContentContainer>
  )
}
