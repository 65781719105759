import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { theme } from '../../../styles'
import styled from 'styled-components'
import { StyledMenuItems } from '../MenuItems/MenuItems'
import { Popover } from 'react-tiny-popover'
import Button from '../Button/Button'

interface IPopOverMenuProps {
    menuItems?: React.ReactNode
    disabled?: boolean;
    customContentWrapper?: boolean;
    zIndex?: string;
    buttonProps?: any;
    customOpener?: ({ref, handleOpen}:{ref:React.RefObject<HTMLButtonElement>, handleOpen: Dispatch<SetStateAction<boolean>>}) => React.ReactElement
}

const StyledPopOverMenuContainer = styled.div`
  display: flex;
  width: 216px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.borderDefault};
  background: ${theme.colors.white};
  box-shadow: 0px 4px 8px 0px rgba(211, 211, 211, 0.57);
`

const PopOverMenu = ({
    menuItems,
    disabled = false,
    customContentWrapper = false,
    customOpener,
    zIndex,
    buttonProps
}: IPopOverMenuProps) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const buttonRef = useRef<HTMLButtonElement>(null)

    const handleClickOutside = (e: MouseEvent) => {
        if (buttonRef.current && buttonRef.current.contains(e.target as Node)) {
            return
        }
        setIsPopoverOpen(false)
    }

    const menuItemsWithProps = React.Children.map(menuItems, (child) => {
        return React.cloneElement(child as React.ReactElement, {
            closeMenu: () => setIsPopoverOpen(false)
        })
    })

    // Create a valid ReactElement from the customOpener or provide a default element
    const openerElement = customOpener ? customOpener({ ref: buttonRef, handleOpen: () => setIsPopoverOpen(!isPopoverOpen) }) : (
        <Button
            ref={buttonRef}
            iconOnly
            icon="more-horizontal"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            variant="tertiary"
            size="small"
            disabled={disabled}
            {...buttonProps}
        />
    );

    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={["bottom", "top", "left", "right"]}
            align="end"
            padding={0}
            onClickOutside={handleClickOutside}
            reposition={true}
            containerStyle={{
                zIndex: zIndex || ""
            }}
            content={() => (
                customContentWrapper ? <> {menuItemsWithProps} </> :
                <StyledPopOverMenuContainer>
                    <StyledMenuItems>
                        {menuItemsWithProps}
                    </StyledMenuItems>
                </StyledPopOverMenuContainer>
            )}
        >
            {openerElement}
        </Popover>
    )
}

export default PopOverMenu