import React, { useState, useRef, useEffect, ReactNode } from "react";
import { styled } from "styled-components";

interface DropdownProps {
  isOpen: boolean;
  onClose?: () => void;
  isOverlay?: boolean;
  showCarrot?: boolean;
  mobileOverlayOnly?: boolean;
  color?: string;
  actionTrigger: ReactNode;
  children: ReactNode;
}

const StyledDropdown = styled.div<{ isOpen: boolean }>`
  position: relative;
`;

const DropdownMenu = styled.div<{ isOpen: boolean; menuPosition: string }>`
  position: absolute;
  ${({ menuPosition }) =>
    menuPosition === "bottom" ? "top: 100%;" : "bottom: 100%;"}
  left: 50%;
  transform: translateX(-50%);
  z-index: 22;
  max-width: 280px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0 15px 1px;
  border-radius: 8px;
  background: #fff;
  margin-top: 7px;

  & .block-picker {
    border-bottom: 1px solid #f2ece6;
    box-shadow: none !important;
    border-radius: 6px 6px 0 0 !important;
    width: 210px !important;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 50% !important;
    bottom: unset !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: max-content;
    max-width: 70vw;
    background: #fff;
    margin-top: 0;
    z-index: 9;
    
    & .block-picker {
      width: 100% !important;
    }
  }
`;

const Carrot = styled.div<{ menuPosition: string; color?: string }>`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent ${({ color }) => color || "#000"}
    transparent;
  border-width: ${({ menuPosition }) =>
    menuPosition === "top" ? "5px 5px 0 5px" : "0 5px 5px 5px"};
  // border-width: 0 5px 5px 5px;
  top: ${({ menuPosition }) => (menuPosition === "bottom" ? "-5px;" : "unset")};
  bottom: ${({ menuPosition }) => (menuPosition === "top" ? "-5px;" : "unset")};
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledOverlay = styled.div<{ mobileOverlayOnly: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);

  display: ${({ mobileOverlayOnly }) => mobileOverlayOnly ? "none" : "block"};

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  onClose,
  isOverlay = false,
  mobileOverlayOnly = false,
  showCarrot = false,
  actionTrigger,
  color,
  children,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [menuPosition, setMenuPosition] = useState<"top" | "bottom">("bottom");
  // const [menuWidth, setMenuWidth] = useState<number>(0);
  // const prevWindowHeight = useRef<number | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose?.();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);


  // FOR later
  // useEffect(() => {
  //   const handleResize = () => {
  //     if (isOpen && menuRef.current) {
  //       setMenuWidth(menuRef.current.offsetWidth);
  //     }
  //     if (window.innerWidth <= 768) return;

  //     const currentWindowHeight = window.innerHeight;

  //     if (prevWindowHeight.current !== null) {
  //       if (currentWindowHeight > prevWindowHeight.current) {
  //         setMenuPosition("bottom");
  //       } else {
  //         setMenuPosition("top");
  //       }
  //     }

  //     prevWindowHeight.current = currentWindowHeight;
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [isOpen]);

  //
  return (
    <StyledDropdown isOpen={isOpen} ref={menuRef}>
      {actionTrigger}
      {isOverlay && isOpen && <StyledOverlay onClick={onClose} mobileOverlayOnly={mobileOverlayOnly} />}
     {isOpen && <DropdownMenu isOpen={isOpen} menuPosition={menuPosition}>
        {children}
       {showCarrot && <Carrot menuPosition={menuPosition} color={color} />}
      </DropdownMenu>}
    </StyledDropdown>
  );
};

export default Dropdown;
