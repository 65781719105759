import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { ref, update } from 'firebase/database'
import { db } from '../../../config/config'
import { RootState } from '../../../store'
import {
  BY_DEFAULT_TEMPLATE_COLORS
  //   BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_2,
  //   BY_DEFAULT_TEMPLATE_COLORS_TEMPLATE_3
} from '../../../constant'
import { TemplateColorObj } from '../../../store/reducers/templateReducerSlice'
import { PhaseObj } from '../../../store/reducers/phaseReducerSlice'
import { toast } from 'react-toastify'
import {
  getTimeStamp,
  lightenColor,
  generateRandomNumber
} from '../../../utils/helpers'
import useGetCurrentPhases from '../../../hooks/useGetCurrentPhases'
import AddColor from './AddColor'
import ColorManager from './ColorManager'
import ColorState from './ColorState'
import { isColorInUseInTeam } from '../../../utils/isColorUseInSameTeam'
// import { determineTemplateNumber } from '../../../utils/determineTemplateNumber'

// --------Color Picker Component ------------------

const StyledColorPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`

interface IAddColorPickerProps {
  formData: PhaseObj
  handleColorSelect?: (colorObj: TemplateColorObj) => void
  setIsAddColorOptionOpen?: (arg: boolean) => void
  templateId?: string
}

const ColorPicker = ({
  formData,
  handleColorSelect,
  setIsAddColorOptionOpen,
  templateId
}: IAddColorPickerProps) => {
  const [showAddColor, setShowAddColor] = useState(false)
  const initialHexCode = {
    hexCode: '#0DB691',
    bg: lightenColor('#0DB691', 40)
  }
  const [hexCode, setHexCode] = useState(initialHexCode)
  const [colorList, setColorList] = useState<{
    [key: string]: TemplateColorObj
  }>(BY_DEFAULT_TEMPLATE_COLORS as any)
  const [lockedColors, setLockedColors] = useState<{ [key: string]: boolean }>(
    {}
  )

  const { data: templatesList } = useSelector(
    (store: RootState) => store.template
  )
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { data: allPhases } = useSelector((store: RootState) => store.phase)

  const { phases } = useGetCurrentPhases(allPhases, templateId)

  // Load colors from the current template or the default color list
  useEffect(() => {
    const currentTemplate = templatesList?.find(
      (template) => template?.id === templateId
    )
    setColorList(currentTemplate?.colors || (BY_DEFAULT_TEMPLATE_COLORS as any))
  }, [templateId, templatesList])

  // Check if a color is locked and update the lockedColors state
  useEffect(() => {
    const updateLockedColors = async () => {
      if (!currentTeam?.id) return

      const lockedStatus = await Promise.all(
        Object.values(colorList).map(async (color) => {
          if (typeof color === 'string') return null
          const isUsed = await isColorInUseInTeam(
            currentTeam.id,
            color.text,
            ''
          )
          return { id: color.text, isLocked: isUsed }
        })
      )

      const lockedStatusMap = lockedStatus.reduce(
        (acc, status) => {
          if (status) {
            acc[status.id] = status.isLocked
          }
          return acc
        },
        {} as { [key: string]: boolean }
      )

      setLockedColors(lockedStatusMap)
    }

    updateLockedColors()
  }, [colorList, currentTeam?.id])

  // Pass `showAddColor` state to parent component
  useEffect(() => {
    setIsAddColorOptionOpen && setIsAddColorOptionOpen(showAddColor)
  }, [showAddColor, setIsAddColorOptionOpen])

  const findUsedPhase = (obj: TemplateColorObj) => {
    return phases?.find((v) => v?.colorId === obj?.id)
  }

  const handleDeleteColor = async (
    colorObj: TemplateColorObj,
    isSelected: boolean
  ) => {
    if (isSelected) {
      toast.info('Unable to delete, the color is in use.')
      return
    }

    const newColorList = { ...colorList }
    delete newColorList[colorObj?.id]

    try {
      await update(ref(db, `/templates/${currentTeam?.id}/${templateId}`), {
        colors: newColorList,
        updatedAt: getTimeStamp()
      })
      setColorList(newColorList)
    } catch (error) {
      toast.error('Failed to delete color.')
    }
  }

  const handleAddColor = async () => {
    if (!hexCode?.hexCode) {
      toast.info('Please select a color.')
      return
    }

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team id not found')
      return
    }

    const isAlreadyInUse = await isColorInUseInTeam(
      teamId,
      hexCode?.hexCode,
      ''
    )

    if (isAlreadyInUse) {
      toast.info('This color is already added.')
      return
    }

    const timestamp = getTimeStamp()
    const newColorId = generateRandomNumber(7)
    const newColorObj = {
      id: newColorId,
      text: hexCode?.hexCode,
      defaultColor: false,
      bg: hexCode?.bg
    }

    try {
      await update(ref(db, `/templates/${teamId}/${templateId}`), {
        colors: { ...colorList, [newColorId]: newColorObj },
        updatedAt: timestamp
      })
      setColorList({ ...colorList, [newColorId]: newColorObj })
      setHexCode(initialHexCode)
      setShowAddColor(false)
      handleColorSelect && handleColorSelect(newColorObj)
    } catch (error) {
      toast.error('Failed to add color.')
    }
  }

  const handleColorPickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    if (inputValue.length > 7) return

    let hexCodeText = ''

    if (inputValue && !inputValue.startsWith('#')) {
      hexCodeText = `#${inputValue.replace(/#/g, '')}`
    } else {
      const sanitizedValue = inputValue.replace(/#/g, '')
      hexCodeText = sanitizedValue ? `#${sanitizedValue}` : ''
    }
    setHexCode({ hexCode: hexCodeText, bg: lightenColor(hexCodeText, 40) })
  }

  return (
    <StyledColorPickerContainer>
      <ColorManager
        onAddClick={() => setShowAddColor(true)}
        isHidden={showAddColor}
      >
        {Object.values(colorList)?.map((color) => {
          const isSelected = formData?.colorId === color?.id

          if (typeof color === 'string') return null

          return (
            <ColorState
              key={color?.id}
              color={color?.text}
              locked={lockedColors[color?.text] || !!findUsedPhase(color)?.id}
              selected={isSelected}
              removable={!(color?.defaultColor || findUsedPhase(color)?.id)}
              handleDeleteColor={() => handleDeleteColor(color, isSelected)}
              onClick={() => handleColorSelect && handleColorSelect(color)}
              disabled={showAddColor}
            />
          )
        })}
      </ColorManager>

      {showAddColor && (
        <AddColor
          onAddColorHandler={handleAddColor}
          onCancelHandler={() => setShowAddColor(false)}
          hexCode={hexCode}
          onChangeHexCode={handleColorPickerChange}
          setHexCode={setHexCode}
          formData={formData}
          templateId={templateId}
        />
      )}
    </StyledColorPickerContainer>
  )
}

export default ColorPicker
