import { css, styled } from "styled-components";
import { theme } from "../../../styles";
import IconComponent from "../IconComponent/IconComponent";
import { useState } from "react";

export interface ISelectorStepperProps {
    fullWidth: boolean;
    phaseTitles: string[];
    currentPhase: number;
    border?: boolean;
}

interface INavigationButtonProps {
    disabled: boolean
    onClick?: () => void
}

const Previous = ({ disabled, onClick }: INavigationButtonProps) => {
    return <IconComponent icon="chevron-left" color={!disabled ? theme.colors.actionPrimary : theme.colors.actionDisabled} iconSize="m" disabled={disabled} onClick={onClick} />
}

const Next = ({ disabled, onClick }: INavigationButtonProps) => {
    return <IconComponent icon="chevron-right" color={!disabled ? theme.colors.actionPrimary : theme.colors.actionDisabled} iconSize="m" disabled={disabled} onClick={onClick} />
}

const StyledSelectorStepper = styled.div < Omit<ISelectorStepperProps, 'currentPhase' | 'phaseTitles'>>`
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 32px;

    border: ${({ border }) => border ? `1px solid ${theme.colors.borderDefault}` : "none"};
    border-radius:24px;
    
    ${({ fullWidth }) => {
        return css`
            width: ${fullWidth ? "100%!important" : "100%"};`
    }}

    @media screen and (min-width: 430px) {
        width:430px;
    }
`

const StyledPhaseTitle = styled.p`
    height: 36px;
    overflow: hidden;
    flex: 1 0 0;
    color: ${theme.colors.copy};
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: ${theme.font.sizes.subtitle};
    font-weight: 400;
    line-height: 140%;
    width: 266px;
    user-select: none;
`


const SelectorStepper = ({ currentPhase, fullWidth, phaseTitles, border }: ISelectorStepperProps) => {

    const [currentState, setCurrentState] = useState(currentPhase)

    const handleNext = () => {
        if (currentState < phaseTitles.length - 1) {
            setCurrentState((prev) => prev + 1)
        }
    }

    const handlePrevious = () => {
        if (currentState > 0) {
            setCurrentState((prev) => prev - 1)
        }
    }

    return (
        <StyledSelectorStepper fullWidth={fullWidth} border={border}>
            <Previous disabled={currentState <= 0} onClick={handlePrevious} />
            <StyledPhaseTitle>{phaseTitles[currentState]}</StyledPhaseTitle>
            <Next disabled={currentState >= phaseTitles.length - 1} onClick={handleNext} />
        </StyledSelectorStepper>
    )
}

export default SelectorStepper