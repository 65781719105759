import styled from "styled-components";
import { theme } from "../../../styles";
import IconComponent from "../IconComponent/IconComponent";

export interface ITopNavItemProps {
  type: "user" | "menu" | "users";
  onClick?: () => void;
  disabled?: boolean
  active?: boolean
}

const StyledTopNavItem = styled.div<Pick<ITopNavItemProps, 'disabled'>>`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  cursor: ${({ disabled }) => !disabled ? 'pointer' : 'not-allowed'};
  opacity: ${({ disabled }) => disabled ? 0.42 : 1};


 &:hover {
    outline: ${({ disabled }) => !disabled ? `3px solid ${theme.colors.copy}` : 'none'} ;
    background-color: ${theme.colors.backgroundSelected};
  }
  
`;

const TopNavItem = ({ type, onClick, disabled, active }: ITopNavItemProps) => {

  const activeIcon = type === "user" ? "user-check" : "x"

  return (
    <StyledTopNavItem disabled={disabled}>
      <IconComponent icon={active ? activeIcon : type} iconSize="m" onClick={onClick} disabled={disabled} />
    </StyledTopNavItem>
  );
};

export default TopNavItem;
