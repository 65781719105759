import React, { useEffect } from 'react'
import styled from 'styled-components'
import { NumberStepper } from './NumberStepper'
import { useFormContext, useWatch } from 'react-hook-form'

interface StepperQuestionProps {
  question: {
    id: number
    text: string
  }
  fieldName: any
  currentQuestionIndex: number
}

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
`

export function StepperQuestion({
  question,
  fieldName,
  currentQuestionIndex
}: StepperQuestionProps) {
  const { setValue } = useFormContext()
  const value = useWatch({ name: fieldName })
  const [displayText, setDisplayText] = React.useState(question.text)

  useEffect(() => {
    if (value) {
      // check if text contains {number} and replace it with the value
      const hasNumber = question.text.includes('{number}')
      if (!hasNumber) return
      const fieldNameText =
        `sections[${question?.id - 1}].questions[${currentQuestionIndex}].text` as const
      setDisplayText(question.text.replace('{number}', value))
      setValue(fieldNameText, displayText)
    }
  }, [value, displayText, question, currentQuestionIndex])

  return (
    <Wrapper>
      <Title>{displayText}</Title>
      <NumberStepper
        name={fieldName}
        min={1}
        max={100}
        label="Number of feature teams"
      />
    </Wrapper>
  )
}
