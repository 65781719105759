import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ref, push, set, child } from 'firebase/database'
import { db } from '../../config/config'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import {
  extractDetailFromLink,
  getFigmaFileTypes,
  getTimeStamp,
  isValidFigmaURL
} from '../../utils/helpers'
import {
  StyledCreateProjectContainer,
  StyledCreateProjectTitleContainer,
  StyledCreateProjectContentContainer
} from './StyledCreateProject.tsx'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import SubNav from '../../components/ui/SubNav/SubNav'
import TextArea from '../../components/ui/TextArea/TextArea'
import SelectField from '../../components/ui/Select/Select'
import AddItem from '../../components/ui/AddItem/AddItem.tsx'
import PopOverMenu from '../../components/ui/PopOverMenu/PopOverMenu.tsx'
import MenuItem from '../../components/ui/MenuItem/MenuItem.tsx'
import { StyledItemTitle } from '../Templates/StyledTemplates.tsx'
import useGetCurrentPhases from '../../hooks/useGetCurrentPhases.tsx'
import { v4 as uuidv4 } from 'uuid'
import FeatherIcon from 'feather-icons-react'
import {
  StyledPhaseContentContainer,
  StyledTaskContainer,
  StyledTemplatesPhaseContainer
} from '../../components/ui/PhaseComponent/PhaseComponent.tsx'
import { motion, AnimatePresence } from 'framer-motion'
import {
  StyledEditPhaseItem,
  StyledPhaseItemName,
  StyledRightActions
} from '../../components/ui/EditPhaseItem/EditPhaseItem.tsx'
import { LinkObj } from '../../store/reducers/componentsReducerSlice.ts'

// animation keyframes
export const fadeUp = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 }
}

const INITIAL_FORM_DATA = {
  title: '',
  description: '',
  templateId: ''
}

// ------------------ ADD PROJECT PAGE ---------------------------
const CreateProject: React.FC = () => {
  const navigate = useNavigate()
  const titleInput = useRef<HTMLInputElement>(null)
  const templateSelectInput = useRef<HTMLSelectElement>(null)
  const linksContainerRef = useRef<HTMLDivElement>(null)
  const [formData, setFormData] = useState(INITIAL_FORM_DATA)
  const [saving, setSaving] = useState(false)
  const { data: templatesList, isLoading } = useSelector(
    (store: RootState) => store.template
  )
  const [projectLinks, setProjectsLinks] = useState<LinkObj[]>([])
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { user } = useSelector((store: RootState) => store.auth)
  const { data: allPhases, isLoading: isPhaseLoading } = useSelector(
    (store: RootState) => store.phase
  )
  const { phases } = useGetCurrentPhases(allPhases, formData?.templateId)

  // template selection change
  const handleTemplateSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setFormData((prev) => {
      return {
        ...prev,
        templateId: value
      }
    })
  }

  const addNewLink = (newLink: string, clearFormData?: () => void) => {
    if (!newLink?.trim()) return

    if (!isValidFigmaURL(newLink)) {
      toast.info('Invalid Figma or FigJam URL.')
      return
    }
    const info = extractDetailFromLink(newLink)
    if (!info?.fileKey) {
      toast.info('Incomplete URL, File key not found.')
      return
    }

    // Check if a link with the same fileKey exists
    const existingNodeId = projectLinks?.some(
      (link) =>
        link?.fileKey === info?.fileKey &&
        link?.nodeId &&
        link?.nodeId?.length > 0 &&
        link?.nodeId === info?.nodeId
    )

    const existingFileWithoutNodeId = projectLinks?.some(
      (link) =>
        link?.fileKey === info?.fileKey &&
        !link?.nodeId && !info?.nodeId
    )

    if (existingNodeId || existingFileWithoutNodeId) {
      toast.info('This exact link already exists.')
      return
    }

    const uniqueId = uuidv4()

    const newLinkObj: LinkObj = {
      id: uniqueId,
      fileKey: info?.fileKey || '',
      title: info?.title || "",
      nodeId: info?.nodeId || '',
      url: newLink || "",
      type: info?.type || "",
      platform: 'figma',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    }
    setProjectsLinks((prev) => [...prev, newLinkObj])
    if (clearFormData) {
      clearFormData()
    }
    linksContainerRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  // save changes
  const handleSave = async () => {
    if (saving || isLoading) return

    if (!formData?.title?.trim()) {
      toast.info('Please name this project.')
      titleInput?.current?.focus()
      titleInput.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      return
    }
    if (!formData?.templateId?.trim()) {
      toast.info('Select a template.')
      templateSelectInput?.current?.focus()
      templateSelectInput.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      return
    }
    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team id not found')
      return
    }
    setSaving(true)
    const currentTime = getTimeStamp()
    try {
      const projectRef = ref(db, `components/${currentTeam?.id}`)
      const newId = push(projectRef).key
      if (!newId) {
        setSaving(false)
        return
      }
      const newProject = {
        id: newId,
        teamId: currentTeam?.id,
        templateId: formData?.templateId,
        pageId: '',
        fileId: '',
        title: formData?.title,
        type: 'project',
        activePhase: phases[0]?.id,
        completedTasks: [''],
        description: formData?.description,
        links: projectLinks,
        createdAt: currentTime,
        updatedAt: currentTime,
        createdBy: user?.id,
        isArchived: false,
        isDeleted: false
      }
      await set(child(projectRef, newId), newProject)
      toast.success('Changes Saved!')
      setSaving(false)
      setFormData(INITIAL_FORM_DATA)
      setProjectsLinks([])
      navigate('/dashboard/projects')
    } catch (error) {
      setSaving(false)
      toast.error('Something went wrong.')
      console.log(error)
    }
  }

  // JSX
  return (
    <StyledCreateProjectContainer>
      <SubNav
        title="Add New Project"
        onBtnClick={() => navigate(`/dashboard/projects`)}
      />
      <div style={{ width: '100%' }}>
        <StyledCreateProjectContentContainer>
          <StyledCreateProjectTitleContainer>
            <InputField
              disabled={isLoading || saving}
              inputRef={titleInput}
              autoFocus
              required
              fullWidth
              label="Project Name"
              placeholder="maximum of 120 characters"
              name="title"
              type="text"
              value={formData.title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({
                  ...formData,
                  title:
                    e.target?.value?.length < 120
                      ? e.target?.value
                      : formData?.title
                })
              }
            />
            <SelectField
              options={[
                { label: 'Select Template', value: '' },
                ...templatesList
                  ?.filter((t) => t?.type === 'project')
                  ?.map((v) => {
                    return {
                      label: v?.title,
                      value: v?.id
                    }
                  })
              ]}
              fullWidth
              value={formData?.templateId}
              onChange={handleTemplateSelect}
              selectRef={templateSelectInput}
            />
            <TextArea
              disabled={isLoading || saving}
              required
              width="100%"
              textLabel="Project Description"
              placeholder="maximum of 120 characters"
              name="description"
              value={formData.description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setFormData({
                  ...formData,
                  description:
                    e.target?.value?.length < 160
                      ? e.target?.value
                      : formData?.description
                })
              }
            />
            <StyledTemplatesPhaseContainer>
              <StyledPhaseContentContainer>
                {projectLinks?.length > 0 && (
                  <>
                    <StyledItemTitle>ASSOCIATED LINKS</StyledItemTitle>
                    <AnimatePresence>
                      {projectLinks?.map((link) => (
                        <motion.div
                          key={link.id}
                          variants={fadeUp}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          transition={{ duration: 0.3 }}
                          style={{ width: '100%' }}
                        >
                          <StyledTaskContainer>
                            <StyledEditPhaseItem visible={true} width={'100%'}>
                              <StyledRightActions
                                style={{
                                  alignItems: 'center',
                                  gap: '8px',
                                  paddingLeft: '8px',
                                }}
                              >
                                {link?.platform === 'figma' && (
                                  <>
                                    <FeatherIcon icon="figma" />
                                  </>
                                )}
                                <StyledPhaseItemName
                                  visible={true}
                                  style={{
                                    width: '100%',
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {link?.platform &&
                                    getFigmaFileTypes(link?.type) + ' - '}
                                  {(link?.title || "")?.slice(0, link?.nodeId ? 30 : 55)}
                                  {link?.nodeId && (" - Node Id: " + link?.nodeId)}
                                </StyledPhaseItemName>
                              </StyledRightActions>
                              <PopOverMenu
                                menuItems={
                                  <MenuItem
                                    title="Remove"
                                    icon="trash"
                                    type="danger"
                                    onClick={() =>
                                      setProjectsLinks((prev) =>
                                        prev?.filter((v) => v?.id !== link?.id)
                                      )
                                    }
                                    closeMenu={() => {}}
                                  />
                                }
                              />
                            </StyledEditPhaseItem>
                          </StyledTaskContainer>
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </>
                )}
                <div ref={linksContainerRef} style={{ width: '100%' }}>
                  <AddItem
                    type="link"
                    inputPlaceholder="Add Figma or Figjam link"
                    collapsed={true}
                    style={{ width: '100%' }}
                    onSave={addNewLink}
                    maxTitleLimit={999}
                    componentType="form"
                    saveBtnVariant='secondary'
                    index={0}
                  />
                </div>
              </StyledPhaseContentContainer>
            </StyledTemplatesPhaseContainer>
          </StyledCreateProjectTitleContainer>
          <Button
            disabled={isLoading || saving}
            type="button"
            label={isLoading ? 'Creating...' : 'Add Project'}
            variant="primary"
            onClick={handleSave}
          />
        </StyledCreateProjectContentContainer>
      </div>
    </StyledCreateProjectContainer>
  )
}

export default CreateProject
