import styled from "styled-components";
import { theme } from "../../styles";


export const StyledTemplateEditMainContainer = styled.section`
    width: 100%;
    background-color: ${theme.colors.white};
    overflow-y: scroll;
`

export const StyledTemplateEditBody = styled.div`
  display: flex;
  padding: 40px 40px 16px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  max-width: 832px;
  margin-inline: auto;
  width: 100%;

  @media screen and (max-width: 430px) {
    padding: 24px 16px 16px 16px;
  }
`

export const StyledTemplateEditContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background: ${theme.colors.white};
  margin-inline : auto;
  max-width: 832px;
`

export const StyledTemplateEditTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    max-width: 832px;
    gap:16px;
`

export const StyledDangerZoneContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(255, 0, 0, 0.1);
`

export const StyledDangerZoneHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
export const StyledDangerZoneTitle = styled.h4`
color: ${theme.colors.copy};
font-family: "rooney-sans";
font-size: 26px;
font-style: normal;
font-weight: 500;
line-height: 140%;
`

export const StyledDangerZoneDescription = styled.p`
color: ${theme.colors.copy};
font-family: "rooney-sans";
font-size: ${theme.font.sizes.medium};
font-style: normal;
font-weight: 400;
line-height: 140%;
`