import { FeatherIconNames } from "feather-icons";
import { HTMLAttributes } from "react";

import styled, { css } from "styled-components";
import { theme } from "../../../styles";
import { FeedbackMessage } from "../InputField/InputField";
import IconComponent from "../IconComponent/IconComponent";

interface ITextAreaProps extends HTMLAttributes<HTMLTextAreaElement> {
  messageType?: "information" | "error" | null;
  disabled?: boolean;
  icon?: FeatherIconNames | "";
  messageText?: string;
  width?: string;
  hasError?: boolean;
  textLabel?: string,
  required?: boolean;
  name?: string;
  value?: string;
  placeholder?: string;
}

const StyleTextAreaContainer = styled.div<ITextAreaProps>`
  width: ${({ width }) => width || "160px"};
  height: 160px;
  padding: 18px 16px;
  border-radius: 4px;
  border: 1px #ecf1f4 solid;
  gap: 8px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: ${({ disabled }) =>
    disabled ? theme.colors.actionDisabled : theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  ${({ hasError, disabled }) => {
    let outlineColor = disabled
      ? theme.colors.actionDisabled
      : hasError
        ? theme.colors.signalError
        : theme.colors.actionPrimary;

    if (disabled) {
      return
    }

    return css`
      &:focus-within,
      &:focus,
      &:active {
        outline: 1px solid ${outlineColor};
      }
    `;
  }}
`;

const StyledTextArea = styled.textarea<ITextAreaProps>`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: none;
  resize: none;
  border: none;
  color: ${theme.colors.copy};
  font-family: "rooney-sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  &:not(:disabled):focus-within,
  :focus,
  :active {
    outline: none;
    border: none;
  }
  &:disabled {
    background-color: ${theme.colors.actionDisabled};
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const TextArea = ({
  icon = "",
  messageType = null,
  messageText = "",
  disabled = false,
  width,
  hasError = false,
  textLabel = "",
  onChange,
  required,
  name = "",
  value
}: ITextAreaProps) => {
  return (
    <>
      <StyleTextAreaContainer disabled={disabled} width={width} hasError={hasError}>
        {icon && (
          <IconComponent
            icon={icon as FeatherIconNames}
            iconSize="s"
            disabled={disabled}
          />
        )}
        <StyledTextArea disabled={disabled} placeholder={textLabel} onChange={onChange} required={required} name={name} value={value} />
      </StyleTextAreaContainer>
      {messageType && (
        <FeedbackMessage messageText={messageText} messageType={messageType} />
      )}
    </>
  );
};

export default TextArea;
