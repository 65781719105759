import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ref, push, set } from 'firebase/database'
import { db } from '../../config/config'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setTemplatesData } from '../../store/reducers/templateReducerSlice'
import { setPhasesData } from '../../store/reducers/phaseReducerSlice'
import { getTimeStamp } from '../../utils/helpers'
import {
  StyledCreateTemplateContainer,
  StyledCreateTemplateTitleContainer,
  StyledCreateTemplateContentContainer
} from './StyledCreateTemplate'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import SubNav from '../../components/ui/SubNav/SubNav'
import TextArea from '../../components/ui/TextArea/TextArea'
import { assignAvailableColor } from '../../utils/assignAvailableColot'

// ------------------ TEMPLATE CREATE PAGE ---------------------------
const CreateTemplate: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    visible: true,
    colorId: '',
    color: {}
  })
  const [saving, setSaving] = useState(false)

  const { data: templatesList, isLoading } = useSelector(
    (store: RootState) => store.template
  )
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { user } = useSelector((store: RootState) => store.auth)

  const { data: phases, isLoading: isPhaseLoading } = useSelector(
    (store: RootState) => store.phase
  )
  // const [searchParams, setSearchParams] = useSearchParams()
  // const selectedType = searchParams.get('type') || 'project'

  // project type change
  // const handleProjectTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { value } = e.target
  //   setSearchParams({
  //     type: value
  //   })
  // }

  // save changes
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault()

    if (saving || isLoading || isPhaseLoading) return

    if (!formData?.title?.trim()) {
      toast.info('Please name this template.')
      return
    }

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team ID not found.')
      return
    }

    // Limit templates to 3
    if (templatesList.length >= 3) {
      toast.info("You've reached the limit of 3 templates per team.")
      return
    }

    setSaving(true)
    const currentTime = getTimeStamp()

    try {
      const newTemplateId = push(ref(db, `/templates/${teamId}`)).key
      const templateRef = ref(db, `/templates/${teamId}/${newTemplateId}`)

      const newColor = await assignAvailableColor(teamId)

      const assignedColorId = newColor?.currentColor.id
      const assignedColor = newColor?.palette
      const type = newColor?.colorType

      const newTemplateObject = {
        id: newTemplateId,
        title: formData?.title,
        description: formData?.description,
        teamId: teamId,
        visible: formData?.visible,
        colors: {
          type,
          ...assignedColor
        },
        order: templatesList.length,
        createdBy: user?.id,
        type: 'component',
        createdAt: currentTime,
        updatedAt: currentTime
      }

      await set(templateRef, newTemplateObject)

      const phaseRef = ref(db, `/phases/${newTemplateId}`)
      const newPhaseId = push(phaseRef).key ?? ''
      const newTaskId = push(ref(db, `/phases/${newPhaseId}/tasks`)).key || ''

      const newPhaseObject = {
        [newPhaseId]: {
          id: newPhaseId,
          title: 'Initial Phase',
          description: '',
          colorId: assignedColorId,
          color: assignedColor,
          order: 0,
          tasks: {
            [newTaskId]: {
              id: newTaskId,
              title: 'Initial Task',
              description: '',
              createdBy: user?.id || '',
              phaseId: newPhaseId,
              templateId: newTemplateId,
              order: 0,
              createdAt: currentTime,
              updatedAt: currentTime,
              mandatory: true,
              visible: true,
              archived: false
            }
          },
          isActive: true,
          mandatory: true,
          teamId,
          templateId: newTemplateId,
          createdBy: user?.id,
          createdAt: currentTime,
          updatedAt: currentTime,
          visible: true,
          archived: false
        }
      }

      // Save the initial phase and task
      await set(phaseRef, newPhaseObject)

      // Update Redux state
      const updatedData = [...templatesList, newTemplateObject]
      dispatch(setTemplatesData({ data: updatedData }))

      dispatch(
        setPhasesData({
          data: [
            ...phases,
            {
              ...newPhaseObject[newPhaseId],
              tasks: [newPhaseObject[newPhaseId]?.tasks[newTaskId]]
            }
          ]
        })
      )

      toast.success('Template created successfully.')
      setSaving(false)
      navigate(`/dashboard/templates/${newTemplateId}`)
    } catch (error) {
      setSaving(false)
      toast.error('Something went wrong.')
      console.error('Error creating template:', error)
    }
  }

  //   JSX
  return (
    <StyledCreateTemplateContainer>
      <SubNav
        title="Create Template"
        onBtnClick={() => navigate(`/dashboard/templates`)}
      />
      <form onSubmit={handleSave} style={{ width: '100%' }}>
        <StyledCreateTemplateContentContainer>
          <StyledCreateTemplateTitleContainer>
            {/* <SelectField
              options={TEMPLATE_TYPES}
              fullWidth
              value={selectedType}
              onChange={handleProjectTypeChange}
            /> */}
            <InputField
              disabled={isLoading || saving}
              autoFocus
              required
              fullWidth
              label="Template Name"
              placeholder="maximum of 120 characters"
              name="title"
              type="text"
              value={formData.title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({
                  ...formData,
                  title:
                    e.target?.value?.length < 120
                      ? e.target?.value
                      : formData?.title
                })
              }
            />
            <TextArea
              disabled={isLoading || saving}
              required
              width="100%"
              textLabel="Template Description"
              placeholder="maximum of 120 characters"
              name="description"
              value={formData.description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setFormData({
                  ...formData,
                  description:
                    e.target?.value?.length < 160
                      ? e.target?.value
                      : formData?.description
                })
              }
            />
          </StyledCreateTemplateTitleContainer>
          <Button
            disabled={isLoading || saving}
            type="submit"
            label={isLoading ? 'Creating...' : 'Create Template'}
            variant="primary"
          />
        </StyledCreateTemplateContentContainer>
      </form>
    </StyledCreateTemplateContainer>
  )
}

export default CreateTemplate
