import styled, { css } from "styled-components";
import { theme } from "../../../styles";
import PhaseType from "../PhaseType/PhaseType";

export interface INavSubItemProps {
    isActive?: boolean;
    disabled?: boolean;
    showPhaseType?: boolean;
    navItemTitle: string;
    textColor?: string
}

const StyledNavSubItem = styled.div<
  Pick<INavSubItemProps, "isActive" | "disabled" | "showPhaseType">
>`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
  align-self: stretch;
  color: ${theme.colors.copy};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus-within {
        background-color: ${theme.colors.actionPrimaryLightHover};
      }
    `}

  ${({ isActive, disabled }) =>
    isActive &&
    !disabled &&
    css`
      background: ${theme.colors.actionPrimaryLightActive};
      color: ${theme.colors.actionPrimaryActive};
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;
      color: ${theme.colors.actionDisabled};
    `}
`;

const StyledNavSubItemContainer = styled.div`
display: flex;
align-items: center;
gap: 8px;
`

const StyledNavTitle = styled.p`
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
overflow: hidden;
text-overflow: ellipsis;
font-family: "rooney-sans";
font-size: ${theme.font.sizes.medium};
font-style: normal;
font-weight: 340;
line-height: 140%;
`;

const NavSubItem = ({
    isActive = false,
    disabled = false,
    showPhaseType = false,
    navItemTitle,
    textColor
}: INavSubItemProps) => {
    return (
        <StyledNavSubItem isActive={isActive} disabled={disabled} showPhaseType={showPhaseType}>
            <StyledNavSubItemContainer>
                {showPhaseType && (
                    <PhaseType
                        disabled={disabled}
                        phaseProgress="none"
                        bgColor={textColor}
                    />
                )}
                <StyledNavTitle>{navItemTitle}</StyledNavTitle>
            </StyledNavSubItemContainer>
        </StyledNavSubItem>
    );
};

export default NavSubItem;
