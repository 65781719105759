/* eslint-disable @typescript-eslint/no-unused-vars */
import { ref, get, Database } from 'firebase/database'

interface LinkedElement {
  id: string
  name: string
  type: string
}

interface Element {
  linkedElement: LinkedElement
  fileId: string
  id: string
  title: string
  description: string
}

interface FigmaLink {
  name: string
  url: string
}

export const generateFigmaLinks = async (
  db: Database,
  teamId: string
): Promise<FigmaLink[]> => {
  try {
    const snapshot = await get(ref(db, `components/${teamId}`))
    const teamData = snapshot.val()

    if (!teamData) {
      console.log('currentTeam - No data found for the specified team.')
      return []
    }

    const figmaLinks: FigmaLink[] = []

    Object.entries(teamData).forEach(([_, elementData]) => {
      if (typeof elementData === 'object' && elementData !== null) {
        const element = elementData as Element
        const { linkedElement, id, fileId, title } = element

        if (linkedElement && linkedElement.name && linkedElement.type) {
          const figmaUrl = `https://www.figma.com/design/${fileId}/${linkedElement.name}?node-id=${id}&node-type=${linkedElement.type.toLowerCase()}`

          figmaLinks.push({
            name: linkedElement.name || title,
            url: figmaUrl
          })
        }
      }
    })

    return figmaLinks
  } catch (error) {
    console.log('createTeam - Error generating Figma links:', error)
    return []
  }
}
