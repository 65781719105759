import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppDispatch } from '../../store'
import Spinner from '../../components/ui/Spinner'
import {
  getAdditionalUserInfo,
  isSignInWithEmailLink,
  signInWithEmailLink
} from 'firebase/auth'
import { auth, db } from '../../config/config'
import { toast } from 'react-toastify'
import { push, ref, set, update } from 'firebase/database'
import { checkAuth } from '../../store/reducers/authReducerSlice'
import { BY_DEFAULT_TEMPLATE_COLORS, getPhaseSchema } from '../../constant'
import axios from 'axios'

// -------- Login Link verification page ------------
const VerifyLogin: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams, _] = useSearchParams()
  const verify_code = searchParams.get('verify_code')
  const redirectType = searchParams.get('redirectType')
  const redirectTo = searchParams.get('redirectTo')
  const teamId = searchParams.get('teamId')
  const receiptId = searchParams.get('receiptId')

  const AUTH_URL = `https://www.figma.com/oauth?client_id=${import.meta.env.VITE_FIGMA_CLIENT_ID}&redirect_uri=${import.meta.env.VITE_FIGMA_REDIRECT_URI}&scope=file_read&state=${verify_code}&response_type=code`

  // Confirm the link is a sign-in with email link.
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const emailKey =
        redirectType === 'widget' && verify_code
          ? 'widgetLoginEmail'
          : 'loginEmail'
      const existingEmail: string | null = window.localStorage.getItem(emailKey);
      // return
      if (!existingEmail) {
        toast.info(
          'Session expired or Make sure to use the same device where you have filled the email address'
        )
        navigate('/', { replace: true })
        return
      }
      // dispatch(
      //   setAuth({
      //     user: null,
      //     isAuthenticated: false,
      //     isLoading: true
      //   })
      // )
      signInWithEmailLink(auth, existingEmail || '', window.location.href)
        .then(async (result) => {
          // Clear email from storage.
          window.localStorage.removeItem(emailKey)
          // create a new use in DB if it's a new signIn
          const user = result.user
          const additionalUserInfo = getAdditionalUserInfo(result)
          const isNewUser = additionalUserInfo?.isNewUser
          const nowDate = new Date().toISOString()
          if (redirectType === 'widget' && verify_code) {
            await update(ref(db, `/oauth-session/${verify_code}`), {
              userId: user.uid
            })
          }
          if (isNewUser) {
            set(ref(db, `/users/${user.uid}`), {
              email: user.email,
              id: user.uid,
              displayName: user?.displayName || '',
              profileImg: user?.photoURL || '',
              status: 'active',
              createdAt: nowDate,
              teamsJoined: { [user.uid]: user.uid },
            })
              .then(async () => {
                // Create user team by default
                const userTeamRef = ref(db, `/teams/${user.uid}`)
                await set(userTeamRef, {
                  id: user.uid,
                  title: user?.displayName || 'Personal',
                  members: [
                    {
                      id: user.uid,
                      email: user.email,
                      role: 'owner',
                      invitationDate: nowDate,
                      profileImg: user?.photoURL || '',
                      pendingInvite: false,
                      invitationStatus: 'accepted',
                      invitedBy: user.uid
                    }
                  ],
                  createdAt: nowDate
                })

                // Create a template by default
                const tempID = push(ref(db, `/templates/${user.uid}`)).key
                const templateRef = ref(db, `/templates/${user.uid}/${tempID}`)
                await set(templateRef, {
                  id: tempID,
                  title: 'My first template',
                  description: 'Start editing your template',
                  teamId: user.uid,
                  visible: true,
                  colors: BY_DEFAULT_TEMPLATE_COLORS,
                  order: 0,
                  type: "component",
                  createdBy: user.uid,
                  createdAt: nowDate,
                  updatedAt: nowDate
                })

                // Create template phases by default
                const phaseRef = ref(db, `/phases/${tempID}`)
                await set(
                  phaseRef,
                  getPhaseSchema(tempID || '', user.uid, user.uid)
                )

                if (redirectType === 'widget' && verify_code) {
                  window.location.replace(AUTH_URL)
                } else {
                  if (redirectTo) {
                    if (redirectTo === 'invitations') {
                      dispatch(checkAuth())
                      navigate(
                        `/invitations/${teamId}/${receiptId === 'new' ? user?.uid : 'new'}`,
                        { replace: true }
                      )
                    } else {
                      dispatch(checkAuth())
                      navigate('/dashboard/templates', { replace: true })
                    }
                  } else {
                    dispatch(checkAuth())
                    navigate('/dashboard/templates', { replace: true })
                  }
                }
                // Send welcome email to the user after successful registration
                axios
                  .get(
                    `${import.meta.env.VITE_APP_SERVER_URL}/email/welcome?email=${user.email}`
                  )
                  .then((res) => {
                    if (res.status !== 200) {
                      console.log('Error sending welcome email!')
                    }
                  })
                  .catch((err) => {
                    console.log('Error sending welcome email!', err)
                  })
              })
              .catch((er: any) => {
                toast.error(er.message || 'Something went wrong!')
                console.log(er)
                dispatch(checkAuth())
                navigate('/dashboard/templates', { replace: true })
              })
          } else {
            if (redirectType === 'widget' && verify_code) {
              window.location.replace(AUTH_URL)
            } else {
              if (redirectTo) {
                if (redirectTo === 'invitations') {
                  dispatch(checkAuth())
                  navigate(
                    `/invitations/${teamId}/${receiptId === 'new' ? user?.uid : 'new'}`,
                    { replace: true }
                  )
                } else {
                  dispatch(checkAuth())
                  navigate('/dashboard/templates', { replace: true })
                }
              } else {
                dispatch(checkAuth())
                navigate('/dashboard/templates', { replace: true })
              }
            }
          }
        })
        .catch((error) => {
          if (error.code !== 'auth/email-already-in-use') {
            navigate('/', { replace: true })
            console.error('Error signing in with email link:', error)
          }
        })
    } else {
      navigate('/', { replace: true })
    }
  }, [])

  // JSX
  return (
    <div className="fill-available-height flex items-center justify-center">
      <Spinner type="spinner" />
    </div>
  )
}

export default VerifyLogin
