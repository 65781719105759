import { get, ref, update } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { StyledInputContainer, StyledParagraphText } from '../../components/ui'
import {
  StyledCreateFooterContainer,
  StyledHeaderContainer,
  StyledHeaderText,
  StyledMainContainer,
  StyledPageContainer
} from '../../components/ui'
import Button from '../../components/ui/Button/Button'
import { db } from '../../config/config'
import { RootState } from '../../store'
import { TeamObj } from '../../store/reducers/teamReducerSlice'
import { theme } from '../../styles'
import Spinner from '../../components/ui/Spinner'
import { StyledHeaderText2 } from '../Auth/GetAuthPage'
import axios from 'axios'
import { isInvitationExpired } from '../../utils/helpers'

// Invitations page
const Invitations: React.FC = () => {
  const navigate = useNavigate()
  const { user: authUser, isLoading } = useSelector(
    (store: RootState) => store.auth
  )
  let { teamId, receiptId } = useParams()
  receiptId = !receiptId || receiptId === 'new' ? authUser?.id : receiptId
  const [accepting, setAccepting] = useState<boolean>(false)
  const [declining, setDeclining] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [isChecking, setIsChecking] = useState<boolean>(true)
  const [invitedTeam, setInvitedTeam] = useState<TeamObj | null>(null)
  const { teams, isLoading: isLoadingTeams } = useSelector(
    (store: RootState) => store.team
  )

  // check user current team
  const crTeam = Object.values(teams)?.find((team) => team?.id === teamId)

  // check if the user is invited or not
  const memberIsInvited = invitedTeam?.members?.find(
      (member) => member?.email === authUser?.email
    )


  // check if the invitation link is expired or not. if 48hours has been past
  const isLinkExpired = isInvitationExpired(memberIsInvited?.invitationDate || '');
  // accept invite
  const acceptInvite = async () => {
    if(!memberIsInvited?.invitedBy) return
    if (accepting || declining || !teamId || !receiptId) return
    if (error) {
      toast.error("Oops! Looks like that invitation isn't valid.")
      return
    }
    setAccepting(true)
    // get(ref(db, `teams/${teamId}`))
    //   .then((v) => {
    //     if (v?.exists()) {
    //       let team: TeamObj = v?.val()
    //       let teamMembers = Object.values(team?.members || [])
    //       let avail = teamMembers?.find(
    //         (member) => member?.email === authUser?.email
    //       )
    // let avail = teamMembers?.find((member) => member?.id === receiptId)
    // if (avail) {
    // let updatedList = teamMembers?.map((member) => {
    let updatedList = invitedTeam?.members?.map((member) => {
      if (member?.email === authUser?.email) {
        // if (member?.id === receiptId) {
        return {
          ...member,
          id: authUser.id,
          profileImg: authUser.profileImg,
          pendingInvite: false,
          invitationStatus: 'accepted'
        }
      }
      return member
    }) 
    update(ref(db, `teams/${teamId}`), { members: updatedList || [] })
    .then(async () => {
      localStorage.setItem('checklist_team_id', teamId)
      try {
        await update(ref(db, `users/${authUser?.id}/teamsJoined`), { 
          [teamId]: teamId
         })
        toast.success('The invitation is accepted – hooray!')
        // Send email to the team owner and inviter
        const teamOwner = invitedTeam?.members?.find(
            (member) => member?.role === 'owner'
          )
        const recipientEmails = [memberIsInvited?.invitedBy]

          if (teamOwner?.email && teamOwner?.email !== memberIsInvited?.invitedBy) {
            recipientEmails.push(teamOwner?.email) //  add team owner email only if it's different from inviter
          }
          await axios.get(
            `${import.meta.env.VITE_APP_SERVER_URL}/email/team-invite-accepted-notify?to_email=${recipientEmails}&team_name=${invitedTeam?.title || ''}&acceptor_email=${authUser?.email}`
          )
          // navigate('/dashboard/team', { replace: true })
          setTimeout(() => {
            setAccepting(false)
            window.location.replace('/dashboard/team')
          }, 500)
        } catch (err) {
          console.log(err)
        }
      })
      .catch((err) => {
        setAccepting(false)
        console.log(err)
        toast.error('The invitation couldn’t be accepted.')
      })

    // } else {
    //   setError(true)
    //   setAccepting(false)
    //   toast.error(
    //     'The invitation has been expired. Please ask for a new one.'
    //   )
    // }
    //   } else {
    //     setAccepting(false)
    //     toast.error('Something went wrong, sorry.')
    //   }
    // })
    // .catch((err) => {
    //   setAccepting(false)
    //   console.log(err)
    //   toast.error('Sorry, something went wrong.')
    // })
  }

  // decline invite
  const declineInvite = () => {
    if (declining || accepting || !teamId || !receiptId) return
    if (error) {
      toast.error("Oops! Looks like that invitation isn't valid.")
      return
    }
    setDeclining(true)
    get(ref(db, `teams/${teamId}`))
      .then((v) => {
        if (v?.exists()) {
          const team: TeamObj = v?.val()
          const teamMembers = Object.values(team?.members || [])
          const updatedList = teamMembers?.filter(
            (member) => member?.email !== authUser?.email
            // (member) => member?.id !== receiptId
          )
          update(ref(db, `teams/${teamId}`), {
            members: updatedList
          })
            .then(() => {
              toast.success('The invitation is declined!')

              // Send email to the team owner and inviter to notify the decline
              const teamOwner = invitedTeam?.members?.find(
                (member) => member?.role === 'owner'
              )
              const recipientEmails = [memberIsInvited?.invitedBy]

              if (teamOwner?.email && teamOwner?.email !== memberIsInvited?.invitedBy) {
                recipientEmails.push(teamOwner?.email) //  add team owner email only if it's different from inviter
              }
              axios.get(
                `${import.meta.env.VITE_APP_SERVER_URL}/email/team-invite-declined-notify?to_email=${recipientEmails}&team_name=${invitedTeam?.title || ''}&decliner_email=${authUser?.email}`
              ).catch((err) => {
                console.log(err)
              })

              setTimeout(() => {
                window.location.replace('/dashboard')
              }, 500)
            })
            .catch((err) => {
              setDeclining(false)
              console.log(err)
              toast.error('The invitation couldn’t be accepted.')
            })
        } else {
          setDeclining(false)
          toast.error('Something went wrong, sorry.')
        }
      })
      .catch((err) => {
        setDeclining(false)
        console.log(err)
        toast.error('Sorry, something went wrong.')
      })
  }

  // check link
  useEffect(() => {
    if (isLoading || isLoadingTeams) return
    // check if team id and receipt id is available or not
    if (teamId && receiptId) {
      if (authUser?.id === teamId) {
        localStorage.setItem('checklist_team_id', teamId)
        window.location.replace('/dashboard/team')
      }
      // check if the current user id is matching with invited user ?
      if (authUser?.id !== receiptId) {
        // setError(true)
      } else {
        // check if user has already joined the team or not
        if (crTeam) {
          localStorage.setItem('checklist_team_id', crTeam?.id || '')
          window.location.replace('/dashboard/team')
        }
      }
    }
  }, [teamId, receiptId, isLoading, isLoadingTeams])

  // fetch team
  useEffect(() => {
    const teamRef = ref(db, `teams/${teamId}`)
    get(teamRef)
      .then((snap) => {
        if (snap.exists()) {
          // let team: TeamObj = snap?.val()
          // let teamMembers = Object.values(team?.members || [])
          // let avail = teamMembers?.find((member) => member?.id === receiptId)
          // if (!avail) {
          //   setError(true)
          //   setIsChecking(false)
          //   return
          // }
          setInvitedTeam({ ...snap.val()})
          setIsChecking(false)
        } else {
          setError(true)
          setIsChecking(false)
        }
      })
      .catch((err) => {
        setError(true)
        setIsChecking(false)
        console.log(err)
      })
  }, [])

  // JSX
  if (isChecking) {
    return (
      <div className="fill-available-height flex items-center justify-center">
        <Spinner type="spinner" />
      </div>
    )
  }
  // if (!teamId || !receiptId || isLinkExpired) {
  if (!teamId || !receiptId) {
    return (
      <StyledMainContainer>
        <StyledPageContainer style={{ margin: 'auto' }}>
          <StyledHeaderContainer>
            <StyledHeaderText>Oops!</StyledHeaderText>
            <StyledParagraphText>
              The link appears to be broken. Please double-check the URL and try
              again.
            </StyledParagraphText>
          </StyledHeaderContainer>
          <StyledCreateFooterContainer>
            <Button
              onClick={() => navigate('/dashboard/profile', { replace: true })}
              label={'Visit dashboard'}
              fullWidth
            />
          </StyledCreateFooterContainer>
        </StyledPageContainer>
      </StyledMainContainer>
    )
  }
  if (error || !memberIsInvited || memberIsInvited?.invitationStatus === "declined" || memberIsInvited?.invitationStatus === "expired" || isLinkExpired) {
    return (
      <StyledMainContainer>
        <StyledPageContainer style={{ margin: 'auto' }}>
          <StyledHeaderContainer>
            <StyledHeaderText>Oops!</StyledHeaderText>
            <StyledParagraphText>
              The invitation has been expired. Please ask for a new one.
            </StyledParagraphText>
          </StyledHeaderContainer>
          <StyledCreateFooterContainer>
            <Button
              onClick={() => navigate('/dashboard/profile', { replace: true })}
              label={'Visit dashboard'}
              fullWidth
            />
          </StyledCreateFooterContainer>
        </StyledPageContainer>
      </StyledMainContainer>
    )
  }
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <StyledHeaderContainer>
          <StyledHeaderText>Invitation Request</StyledHeaderText>
          <br />
          <StyledHeaderText2 style={{textAlign: "left"}}>
            You've have got an invitation to join <br />{' '}
            <b>
              {' '}
              {invitedTeam?.title}{' '}
            </b>{' '}
            team.
          </StyledHeaderText2>
          <br />
          <StyledParagraphText>
            You can accept or decline this invitation. To accept invitation
            click the{' '}
            <b>
              "Accept invitation"
            </b>{' '}
            button below.
          </StyledParagraphText>
          <StyledParagraphText>
            Once you accept the invitation, you'll have full access to join and
            manage the team.
          </StyledParagraphText>
        </StyledHeaderContainer>
        <StyledInputContainer>
          <Button
            onClick={acceptInvite}
            disabled={accepting || declining}
            label={accepting ? 'Accepting...' : 'Accept invitation'}
            fullWidth
          />
          <Button
            variant="secondary"
            onClick={declineInvite}
            disabled={declining || accepting}
            label={declining ? 'Declining...' : 'Decline invitation'}
            fullWidth
          />
        </StyledInputContainer>
      </StyledPageContainer>
    </StyledMainContainer>
  )
}

export default Invitations