import styled, { css } from 'styled-components'
import { theme } from '../../../styles'
import Button from '../Button/Button'
import Label from '../Label/Label'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import SignalIcon from '../SignalIcon/SignalIcon'

interface IChecklistTypeProps {
  active?: boolean
  border?: boolean
  disabled?: boolean
  title?: string
  description?: string
  type?: string
  onClick?: () => void
}

const StyledCheckListType = styled.div<IChecklistTypeProps>`
  display: flex;
  width: 100%;
  padding: 16px 16px 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;
  border-radius: 8px;
  background-color: ${theme.colors.surfaceNormalLightActive};
  user-select: none;
  ${({ disabled }) => {
    return (
      disabled &&
      css`
        & > div > h4,
        & > p {
          color: ${theme.colors.actionDisabled};
        }
      `
    )
  }}

  ${({ active, border, disabled }) => {
    if (border) {
      return css`
        border-bottom: 1px solid ${theme.colors.borderDefault};
      `
    }

    if (active && !disabled) {
      return css`
        outline: 1px solid ${theme.colors.actionPrimaryActive};
      `
    }

    if (!disabled) {
      return css`
        &:hover {
          outline: 1px solid ${theme.colors.actionPrimaryHover};
        }
      `
    }
  }}
    
    &:active,:focus-within {
    // outline:1px solid ${theme.colors.actionPrimary};
    box-shadow: none;
  }
`

const StyledChecklistTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex: 1;
  width: 100%;
`

const StyledTitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledTitleMobile = styled.h4`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;

  font-family: 'rooney';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  font-size: 30px;
`

const StyledTitleDesktop = styled.h2`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;

  font-family: 'rooney';
  font-size: ${theme.font.sizes.header3};
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  font-size: 48px;
`

const StyledDescription = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.colors.copySubtle};
  text-overflow: ellipsis;

  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 340;
  line-height: 140%;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.body};
    font-weight: 380;
  }
`

const ChecklistType = ({
  title,
  description,
  type,
  active,
  border,
  onClick,
  disabled
}: IChecklistTypeProps) => {
  const isMobile = useIsMobileDevice()

  return (
    <StyledCheckListType active={active} border={border} disabled={disabled}>
      <StyledChecklistTitleContainer>
        <StyledTitleIconContainer>
          <SignalIcon
            signal={type === 'project' ? 'kanban' : 'box'}
            background
            type="primary"
            size="m"
          />
          {isMobile ? (
            <StyledTitleMobile>{title}</StyledTitleMobile>
          ) : (
            <StyledTitleDesktop>{title}</StyledTitleDesktop>
          )}
        </StyledTitleIconContainer>
        <Button
          iconOnly
          size="medium"
          variant="tertiary"
          icon="chevron-right"
          disabled={disabled}
          onClick={onClick}
        />
      </StyledChecklistTitleContainer>
      <StyledDescription>{description}</StyledDescription>
    </StyledCheckListType>
  )
}

export default ChecklistType
