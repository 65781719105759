import styled, { css } from 'styled-components'
import { theme } from '../../../styles'
import AllSignalIcons from '../../assets/icons/AllSignalIcons'
import { FeatherIconNames } from 'feather-icons'
import FeatherIcon from 'feather-icons-react'

interface ISignalIconProps {
  background?: boolean
  type?: 'success' | 'warning' | 'danger' | 'info' | 'primary'
  signal?: FeatherIconNames
  size?: "s" | "m" | "l"
}

const StyledSignalIcon = styled.div<ISignalIconProps>`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;

  ${({ signal, background, type, size }) => {
    const getBgColorFromSignal = (signal: string) => {
      switch (signal) {
        case 'primary':
        case 'callout':
          return theme.colors.actionPrimaryLight
        case 'information':
        case 'info':
          return theme.colors.signalInformationLight
        case 'warning':
          return theme.colors.signalWarningLight
        case 'danger':
        case 'error':
        case 'alert-triangle':
        case 'alert-octagon':
          return theme.colors.signalErrorLight
        case 'success':
          return theme.colors.signalSuccessLight
        default:
          return theme.colors.actionPrimaryLight
      }
    }
    const getTextColorFromSignal = (signal: string) => {
      switch (signal) {
        case 'primary':
        case 'callout':
          return '#9747FF'
        case 'information':
        case 'info':
          return '#1F6BFF'
        case 'warning':
          return '#FF9508'
        case 'danger':
        case 'error':
        case 'alert-triangle':
        case 'alert-octagon':
          return '#F24822'
        case 'success':
          return '#2AB514'
        default:
          return '#9747FF'
      }
    }

    const bgColor = getBgColorFromSignal(type || signal || 'callout')
    const color = getTextColorFromSignal(type || signal || 'callout')
    const ratio = size === 's' ? '44px' : size === 'm' ? '56px' : '44px'
    
    return css`
      background-color: ${background ? bgColor : 'transparent'};
      color: ${color};
      width: ${ratio};
      min-width: ${ratio};
      height: ${ratio};
      min-height: ${ratio};
    `
  }};
`

const SignalIcon = ({
  background,
  signal = 'callout',
  type = 'primary',
  size
}: ISignalIconProps) => {
  return (
    <StyledSignalIcon background={background} signal={signal} type={type} size={size}>
      {AllSignalIcons[signal as keyof typeof AllSignalIcons] || (
        <FeatherIcon icon={signal} />
      )}
    </StyledSignalIcon>
  )
}

export default SignalIcon