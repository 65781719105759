import { theme } from "../../../styles"
const DragIcon = ({ size = 24, color = theme.colors.actionPrimary }: { size?: number, color?: string }) => {
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9 6.5C9.82843 6.5 10.5 5.82843 10.5 5C10.5 4.17157 9.82843 3.5 9 3.5C8.17157 3.5 7.5 4.17157 7.5 5C7.5 5.82843 8.17157 6.5 9 6.5ZM10.5 12C10.5 12.8284 9.82843 13.5 9 13.5C8.17157 13.5 7.5 12.8284 7.5 12C7.5 11.1716 8.17157 10.5 9 10.5C9.82843 10.5 10.5 11.1716 10.5 12ZM10.5 19C10.5 19.8284 9.82843 20.5 9 20.5C8.17157 20.5 7.5 19.8284 7.5 19C7.5 18.1716 8.17157 17.5 9 17.5C9.82843 17.5 10.5 18.1716 10.5 19ZM16.5 12C16.5 12.8284 15.8284 13.5 15 13.5C14.1716 13.5 13.5 12.8284 13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12ZM15 20.5C15.8284 20.5 16.5 19.8284 16.5 19C16.5 18.1716 15.8284 17.5 15 17.5C14.1716 17.5 13.5 18.1716 13.5 19C13.5 19.8284 14.1716 20.5 15 20.5ZM16.5 5C16.5 5.82843 15.8284 6.5 15 6.5C14.1716 6.5 13.5 5.82843 13.5 5C13.5 4.17157 14.1716 3.5 15 3.5C15.8284 3.5 16.5 4.17157 16.5 5Z"
                fill={color} />
        </svg>)

}

export default DragIcon