// File: NumberStepper.tsx

import React from 'react'
import styled from 'styled-components'
import { Minus, Plus } from 'lucide-react'
import { useFormContext, Controller } from 'react-hook-form'
import Button from '../../../components/ui/Button/Button'

interface NumberStepperProps {
  name: string
  min?: number
  max?: number
  label: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Label = styled.label`
  font-size: 1.125rem;
  font-weight: 500;
`

const StepperControls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const StyledInput = styled.input`
  width: 5rem;
  text-align: center;
`

export function NumberStepper({
  name,
  min = 0,
  max = 100,
  label
}: NumberStepperProps) {
  const { control } = useFormContext()

  return (
    <Wrapper>
      <Label htmlFor={`stepper-${label}`}>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={min}
        render={({ field: { value, onChange } }) => {
          const increment = () => {
            if (value < max) {
              onChange(value + 1)
            }
          }

          const decrement = () => {
            if (value > min) {
              onChange(value - 1)
            }
          }

          const handleInputChange = (
            e: React.ChangeEvent<HTMLInputElement>
          ) => {
            const newValue = parseInt(e.target.value, 10)
            if (!isNaN(newValue) && newValue >= min && newValue <= max) {
              onChange(newValue)
            }
          }

          return (
            <StepperControls>
              <Button
                type="button"
                variant="secondary"
                size="xs"
                onClick={decrement}
                disabled={value <= min}
                aria-label={`Decrease ${label}`}
              >
                <Minus className="h-4 w-4" />
              </Button>
              <StyledInput
                id={`stepper-${label}`}
                type="number"
                value={value}
                onChange={handleInputChange}
                min={min}
                max={max}
              />
              <Button
                type="button"
                variant="secondary"
                size="xs"
                onClick={increment}
                disabled={value >= max}
                aria-label={`Increase ${label}`}
              >
                <Plus className="h-4 w-4" />
              </Button>
            </StepperControls>
          )
        }}
      />
    </Wrapper>
  )
}
