import styled from "styled-components"
import { theme } from "../../styles"
import Button from "../../components/ui/Button/Button"

const StyledTemplatesHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    gap:16px;

    @media screen and (min-width: 834px) {
        flex-direction: row;
        align-items: center;
    }

`

const StyledTemplateTitle = styled.p`
    color: ${theme.colors.copy};
    font-family: "rooney";
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @media screen and (min-width: 430px) {
        font-size: 64px;
    }
  
`

const TemplateHeader = ({ title, onClick = () => {} }: { title?: string; onClick?: () => void }) => {
    return (
        <StyledTemplatesHeaderContainer>
            <StyledTemplateTitle>{title}</StyledTemplateTitle>
                <Button onClick={onClick} icon="plus" variant="secondary" iconPosition="before">
                    CREATE NEW TEMPLATE
                </Button>
        </StyledTemplatesHeaderContainer>
    )
}

export default TemplateHeader