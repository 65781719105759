import styled, { css } from 'styled-components'
import { theme } from '../../../styles'
import { FeatherIconNames } from 'feather-icons'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import IconComponent from '../IconComponent/IconComponent'

export interface IMenuItemProps {
  title: string
  type: 'primary' | 'danger'
  icon?: FeatherIconNames
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  isActive?: boolean
  onClick?: () => void
  closeMenu?: () => void
  customStyles?: React.CSSProperties // Optional custom styles for the component
}

const MenuItemColorsMap = {
  background: {
    primary: {
      default: theme.colors.actionPrimary,
      hover: theme.colors.actionPrimaryLightHover,
      active: theme.colors.actionPrimaryLightActive,
      disabled: theme.colors.actionDisabled
    },
    danger: {
      default: theme.colors.signalError,
      hover: theme.colors.signalErrorLightHover,
      active: theme.colors.signalErrorLightActive,
      disabled: theme.colors.actionDisabled
    }
  },
  children: {
    primary: {
      default: theme.colors.actionPrimary,
      hover: theme.colors.actionPrimaryHover,
      active: theme.colors.actionPrimaryActive,
      disabled: theme.colors.actionDisabled
    },
    danger: {
      default: theme.colors.signalError,
      hover: theme.colors.signalErrorNormalHover,
      active: theme.colors.signalErrorNormalActive,
      disabled: theme.colors.actionDisabled
    }
  }
}

const StyledMenuItem = styled.button<{ type: 'primary' | 'danger'; isMobile: boolean, isActive: boolean }>`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  width: 100%;
  background: ${({ isActive, type }) => isActive ? MenuItemColorsMap.background[type].active : "transparent"};
  border: none;

  & > p,
  svg {
    color: ${({ type }) => MenuItemColorsMap.children[type].default};
  }

  &:hover {
    background: ${({ isActive, type }) => isActive ? MenuItemColorsMap.background[type].active : MenuItemColorsMap.background[type].hover};

    & > p,
    svg {
      color: ${({ type }) => MenuItemColorsMap.children[type].hover};
    }
  }

  &:active {
    background: ${({ type }) => MenuItemColorsMap.background[type].active};

    & > p,
    svg {
      color: ${({ type }) => MenuItemColorsMap.children[type].active};
    }
  }

  &:disabled {
    background: transparent;
    cursor: not-allowed;

    & > p,
    svg {
      color: ${({ type }) => MenuItemColorsMap.children[type].disabled};
    }
  }
`

const StyledMenuItemTitle = styled.p<{ isMobile: boolean }>`
  color: ${theme.colors.actionPrimary};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  flex: 1;
  text-align: left;
`

const MenuItem = ({
  title,
  icon = '',
  type = 'primary',
  disabled,
  onClick,
  closeMenu,
  isActive = false,
  customStyles = {},
  iconPosition = "left"
}: IMenuItemProps) => {
  const isMobile = useIsMobileDevice()

  // Convert customStyles to a CSS string
  const customStylesString = Object.entries(customStyles)
    .map(([key, value]) => `${key}: ${value};`)
    .join(' ')

  return (
    <StyledMenuItem
      type={type}
      disabled={disabled}
      isActive={isActive}
      onClick={() => {
        if (onClick) onClick()
        if (closeMenu) closeMenu()
      }}
      isMobile={isMobile}
      style={{ ...customStyles }}
      className={customStylesString ? `custom-style-${Math.random()}` : undefined} // Apply custom styles as className if needed
    >
      {icon && iconPosition === "left" && <IconComponent icon={icon} iconSize={isMobile ? 's' : 'm'} />}
      <StyledMenuItemTitle isMobile={isMobile}>{title}</StyledMenuItemTitle>
      {icon && iconPosition === "right" && <IconComponent icon={icon} iconSize={isMobile ? 's' : 'm'} />}
    </StyledMenuItem>
  )
}

export default MenuItem