import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import Card from '../../components/ui/Card/Card'
import { RootState } from '../../store'
import {
  ComponentObj,
  setComponentSlice
} from '../../store/reducers/componentsReducerSlice'
import { PhaseObj } from '../../store/reducers/phaseReducerSlice'
import { StyledListBodyContainer } from '../../components/screens/Lister/ListBodyContainer'
import ListCardContainer from '../../components/screens/Lister/ListCardContainer'
import { StyledContentContainer } from '../../components/screens/Lister/Lister'
import Spinner from '../../components/ui/Spinner'
import ComponentsHeader from './componentsHeader'
import Banner from '../../components/ui/Banner/Banner'
import EmptyState from '../../components/ui/EmptyState/EmptyState'
import useLocalStorage from '../../hooks/useLocalStorage'
import { useDispatch } from 'react-redux'
import { onValue, ref } from 'firebase/database'
import { db } from '../../config/config'
import { useCrisp } from '../../hooks/useCrisp'
import CustomBanner from '../../components/ui/Banner/CustomBanner'

// Constants
const MAX_COMPONENT_LIMIT = 25

// ------------------- All components list page ------------
const AllComponents: React.FC = () => {
  const { openChat, sendMessage } = useCrisp()
  const dispatch = useDispatch()

  const { currentTeam } = useSelector((store: RootState) => store.team)

  const { data: components, isLoading: isLoadingComponents } = useSelector(
    (store: RootState) => store.component
  )
  const { data: templateList, isLoading: templateLoading } = useSelector(
    (store: RootState) => store.template
  )

  const { data: allPhases, isLoading: isLoadingPhases } = useSelector(
    (store: RootState) => store.phase
  )

  const [params] = useSearchParams()
  const templateId = params.get('template')

  const getTemplate = (templateId: string) => {
    if (templateLoading || isLoadingComponents || !templateList || !components)
      return
    return templateList.find((template) => template?.id === templateId)
  }

  // count the total number of un-archived tasks
  const totalPhasesTasks = (templateId: string) => {
    const phases =
      allPhases?.filter((phase) => phase?.templateId === templateId) || []

    const unArchivedPhases = Object.values(
      phases?.filter((phase) => !phase?.archived) || []
    )
    const totalUnArchivedTasks = unArchivedPhases?.reduce((acc, phase) => {
      const unArchivedTasksInPhase =
        phase?.tasks?.filter((task) => !task.archived).length || 0
      return acc + unArchivedTasksInPhase
    }, 0)

    return totalUnArchivedTasks
  }

  // count the total number of completed tasks that are not archived
  const totalCompletedTasks = (comp: ComponentObj) => {
    const allCompletedTasksIds = comp?.completedTasks

    if (!allCompletedTasksIds) return 0

    const phases =
      allPhases?.filter((phase) => phase?.templateId === comp?.templateId) || []

    const unArchivedPhases = Object.values(
      phases?.filter((phase) => !phase?.archived) || []
    )

    const allUnArchivedTasks = unArchivedPhases?.flatMap(
      (phase) => phase?.tasks?.filter((task) => !task?.archived) || []
    )

    const completedTasks = allUnArchivedTasks?.filter((task) =>
      allCompletedTasksIds.includes(task?.id)
    )
    return completedTasks?.length || 0
  }

  // get the phase of the component
  const getPhase = (
    templateId: string,
    comp: ComponentObj
  ): PhaseObj | undefined => {
    const phase = Object.values(allPhases)
      ?.filter((phase) => !phase?.archived && phase?.templateId === templateId)
      ?.find((phase) => phase?.id === comp?.activePhase)
    return phase
  }

  const [isOpen, setIsOpen] = useLocalStorage('add_more_components', true)
  useEffect(() => {
    if (templateId) {
      const componentsRef = ref(db, `components/${currentTeam?.id}`)
      const components: ComponentObj[] = []

      const componentListener = onValue(componentsRef, (snapshot) => {
        const data = snapshot.val()

        if (data) {
          // Filter components locally based on templateId
          Object.keys(data).forEach((key) => {
            const component = data[key]
            if (component.templateId === templateId) {
              components.push(component)
            }
          })

          dispatch(setComponentSlice({ data: components }))
        }
      })

      return () => {
        componentListener()
      }
    }
  }, [templateId, currentTeam?.id, dispatch])

  const handleRequestMoreSpace = () => {
    openChat()
    sendMessage('I would like to request more space for components.')
  }

  // JSX
  return (
    <StyledContentContainer>
      <ComponentsHeader />
      {components?.length === 0 || isLoadingComponents ? null : (
        <Banner
          body="To add more components, link more widgets to components in Figma."
          buttonLabel="LEARN MORE"
          handleButtonClick={() =>
            window.open('https://docs.compfeed.com/select-component')
          }
          variant="illustration"
          illustrationType="widget"
          type="callout"
          isOpen={isOpen}
          isCloseable
          handleClose={() => setIsOpen(false)}
        />
      )}
      {components?.length >= MAX_COMPONENT_LIMIT && (
        <>
          <CustomBanner
            title={`Maximum of 25 component reached`}
            body="Create space for new entries by removing existing ones, or reach out to us to request more space."
            buttonLabel="REQUEST MORE SPACE"
            type="warning"
            handleButtonClick={handleRequestMoreSpace}
            handleClose={() => setIsOpen(false)}
          />
        </>
      )}
      {components?.length > 0 ||
      isLoadingComponents ||
      isLoadingPhases ||
      templateLoading ? (
        <StyledListBodyContainer>
          {isLoadingComponents || isLoadingPhases || templateLoading ? (
            <div
              style={{ width: '100%', padding: '25px' }}
              className="flex justify-center"
            >
              <Spinner type="spinner" />
            </div>
          ) : (
            <ListCardContainer>
              {components?.map((comp, ind) => {
                const phase = getPhase(comp?.templateId || '', comp)
                const currentTemplate = getTemplate(comp?.templateId || '')
                const color = currentTemplate?.colors[phase?.colorId || '']
                return (
                  <Link
                    to={`/dashboard/components/${comp?.fileId}/${comp?.id}`}
                    key={ind}
                    style={{ display: 'block' }}
                  >
                    <Card
                      componentName={
                        comp?.linkedElement?.name || comp?.title || '-'
                      }
                      phaseTitle={phase?.title || ''}
                      tasksCompleted={totalCompletedTasks(comp)}
                      tasksTotal={totalPhasesTasks(comp?.templateId || '')}
                      bgColor={color?.bg || ''}
                      textColor={color?.text || '#000'}
                    />
                  </Link>
                )
              })}
            </ListCardContainer>
          )}
        </StyledListBodyContainer>
      ) : (
        <EmptyState
          title="There are no components here yet"
          body="To add components to Compfeed, add the Figma widget to your project."
          buttonLabel1="ADD FIGMA WIDGET"
          buttonLabel2="Need help?"
          buttonOnClick1={() =>
            window.open(
              'https://www.figma.com/community/widget/1331612974733327658/compfeed'
            )
          }
          buttonOnClick2={() =>
            window.open('https://docs.compfeed.com/getting-started')
          }
        />
      )}
    </StyledContentContainer>
  )
}

export default AllComponents
