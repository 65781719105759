import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect
} from 'react'
import BottomSheet from '../BottomSheet-Old/BottomSheet-Old'
import styled from 'styled-components'
import Button from '../Button/Button'
// import TopNavItem from '../TopNavItem/TopNavItem'
import { theme } from '../../../styles'
// import Label from '../Label/Label'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { useNavigate } from 'react-router'
import { DrawerContext } from '../../../context/DrawerContext'
// import Avatar from '../Avatar/Avatar'
import AccountMenu from '../AccountMenu/AccountMenu'
import { StyledRow } from '../../../pages/Team'

const StyledAccountMenuMobile = styled.div`
  display: flex;
  padding: 12px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 24px;
  align-self: stretch;
`

const StyledTopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid #f2ece6;
  padding-bottom: 12px;
`

const StyledTopBarUserContainer = styled.div`
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  @media screen and (max-width: 500px) {
    max-width: 82%;
  }
`

const StyledUserEmail = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: 100%;
  padding-right: 5px;
  text-overflow: ellipsis;
  color: ${theme.colors.black};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
`

const StyledAccountMenuMobileItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const StyledMenuOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-bottom: 1px solid #f2ece6;
  width: 100%;
  padding-bottom: 16px;
`

interface AccountMenuMobileProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setLogoutConfirmModal: Dispatch<SetStateAction<boolean>>
  setSwitchTeamModal: Dispatch<SetStateAction<boolean>>
  logout?: () => void
}

const AccountMenuMobile = ({
  isOpen,
  setIsOpen,
  logout,
  setLogoutConfirmModal,
  setSwitchTeamModal
}: AccountMenuMobileProps) => {
  const { user } = useSelector((store: RootState) => store.auth)
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const navigate = useNavigate()
  const { setIsDrawerOpen, setIsAccountMenuOpen } = useContext(DrawerContext)
  const userRole = currentTeam?.members?.find(
    (mem) => mem.id === user?.id
  )?.role

  const logoutHandler = () => {
    if (logout) {
      // logout()
      setLogoutConfirmModal(true)
    }
    setIsAccountMenuOpen(false)
  }

  //
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <BottomSheet open={isOpen} onClose={() => setIsOpen(false)}>
      <StyledAccountMenuMobile>
        <StyledRow>
          <Button
            variant="tertiary"
            style={{ paddingInline: 0 }}
          >
            MY ACCOUNT
          </Button>
          <Button
            icon="x"
            iconOnly
            variant="tertiary"
            size="small"
            onClick={() => setIsOpen(false)}
          />
        </StyledRow>
        <AccountMenu
          setLogoutConfirmModal={setLogoutConfirmModal}
          setSwitchTeamModal={setSwitchTeamModal}
          logout={logout}
          teamName={currentTeam?.title ?? ''}
          profileEmail={user?.email ?? ''}
          profileImageSrc={user?.profileImg ?? ''}
          teamImageSrc={currentTeam?.icon || ''}
          onClick1={() => {
            navigate('/dashboard/team')
            setIsOpen(false)
          }}
          onClick2={() => {
            navigate('/dashboard/profile')
            setIsOpen(false)
          }}
          fullWidth
        />
        {/* <StyledTopBar>
          <StyledTopBarUserContainer>
            {user?.profileImg ? (
              <Avatar src={user?.profileImg} />
            ) : (
              <TopNavItem type="user" />
            )}
            <StyledUserEmail>{user?.email || '-'}</StyledUserEmail>
          </StyledTopBarUserContainer>
          <Button
            icon="x"
            iconOnly
            variant="tertiary"
            size="small"
            onClick={() => setIsOpen(false)}
          />
        </StyledTopBar> */}
        {/* <StyledAccountMenuMobileItems>
          <StyledMenuOptionsContainer>
            <Button
              icon="user"
              variant="tertiary"
              style={{ paddingInline: 0 }}
              onClick={() => {
                navigate('/dashboard/profile')
                setIsAccountMenuOpen(false)
              }}
              size="s"
            >
              MY ACCOUNT
            </Button>
            <Button
              icon="users"
              variant="tertiary"
              style={{ paddingInline: 0 }}
              onClick={() => {
                navigate('/dashboard/team')
                setIsAccountMenuOpen(false)
              }}
              size="s"
            >
              TEAM & USERS
            </Button>
          </StyledMenuOptionsContainer>
          <Button
            style={{ paddingInline: 0, border: 'none' }}
            icon="log-out"
            variant="danger"
            color="danger"
            onClick={logoutHandler}
          >
            Logout
          </Button>
        </StyledAccountMenuMobileItems> */}
      </StyledAccountMenuMobile>
    </BottomSheet>
  )
}

export default AccountMenuMobile
