import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { child, ref, update, remove, get } from 'firebase/database'
import { db } from '../../config/config'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setTemplatesData } from '../../store/reducers/templateReducerSlice'
import { setPhasesData } from '../../store/reducers/phaseReducerSlice'
import {
  StyledTemplateEditMainContainer,
  StyledTemplateEditContentContainer,
  StyledTemplateEditTitleContainer,
  StyledTemplateEditBody,
  StyledDangerZoneContainer,
  StyledDangerZoneHeader,
  StyledDangerZoneTitle,
  StyledDangerZoneDescription
} from './StyledTemplateEdit'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import Spinner from '../../components/ui/Spinner'
import SubNav from '../../components/ui/SubNav/SubNav'
import TextArea from '../../components/ui/TextArea/TextArea'
import Modal from '../../components/ui/Modal/Modal'
import { TemplateDeleteContent } from '../../components/ui/Modal/ModalSlotContents'
// import SelectField from '../../components/ui/Select/Select'
// import { TEMPLATE_TYPES } from '../../constant'
import { ComponentTypes } from '../../store/reducers/componentsReducerSlice'
import { getTimeStamp } from '../../utils/helpers'
import styled from 'styled-components'
import { theme } from '../../styles'
import { assignAvailableColor } from '../../utils/assignAvailableColot'
import { isColorInUseInTeam } from '../../utils/isColorUseInSameTeam'

const StyledButton = styled.button`
  color: ${theme.colors.signalErrorNormalActive};
  background: ${theme.colors.phaseBackground3};
  border: none;
  background: none;
  width: 20%;
`

const TemplateEdit: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [formData, setFormData] = useState<{
    title: string
    description: string
    type: ComponentTypes
    visible: boolean
    color?: string
  }>({
    title: '',
    description: '',
    type: 'component',
    visible: true,
    color: ''
  })
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [connectedComponents, setConnectedComponents] = useState<any>([])
  const templateRef = ref(db, 'templates')
  const { data: allTemplates, isLoading } = useSelector(
    (store: RootState) => store.template
  )
  const templatesList = allTemplates?.filter(
    (tmp) => tmp?.type === formData?.type
  )

  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { data: componentList } = useSelector(
    (store: RootState) => store.component
  )
  const { data: phases, isLoading: isLoadingPhases } = useSelector(
    (store: RootState) => store.phase
  )

  const canDelete = templatesList?.length > 1

  // Fetch connected components when the template ID or component list changes
  useEffect(() => {
    if (!id || isLoading || !componentList) return

    const connected = componentList.filter(
      (component) => component.templateId === id
    )
    setConnectedComponents(connected)
  }, [id, componentList, isLoading])

  // const handleProjectTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { value } = e.target
  //   setFormData(({
  //     ...formData,
  //     type: "component",
  //     color: '#77F2E6',
  //     visible: true
  //   }))
  // }
  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault()

    if (saving || !id || isLoading) return

    if (!formData?.title?.trim()) {
      toast.info('Please name this template.')
      return
    }

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team ID not found.')
      return
    }

    setSaving(true)

    try {
      // Fetch current templates for the team to check for color conflicts
      const snapshot = await get(ref(db, `/templates/${teamId}`))
      const currentTemplates = snapshot.val() || {}

      const isColorInUse = await isColorInUseInTeam(
        teamId,
        formData.color as string,
        id
      )

      if (isColorInUse) {
        const newColor = await assignAvailableColor(currentTemplates)

        if (newColor) {
          toast.info(
            `The selected color is already in use. Assigned a new color: ${newColor.currentColor.text}.`
          )
          setFormData((prev) => ({
            ...prev,
            color: newColor.currentColor.text
          }))
        } else {
          throw new Error('No available colors to assign.')
        }
      }

      // Save the updated template data
      const tm = getTimeStamp()
      await update(child(templateRef, `${teamId}/${id}`), {
        ...formData,
        updatedAt: tm
      })

      // Update Redux state
      const updatedData = [...allTemplates]
      const index = allTemplates.findIndex((v) => v?.id === id)
      if (index !== -1) {
        updatedData[index] = {
          ...updatedData[index],
          ...formData,
          updatedAt: tm
        }
      }
      dispatch(setTemplatesData({ data: updatedData }))
      toast.success('Your changes are saved.')
      navigate(`/dashboard/templates/${id}`)
    } catch (err: any) {
      console.error('Error while saving template:', err)
      toast.error(err?.message || 'Something went wrong.')
    } finally {
      setSaving(false)
    }
  }

  const deleteTemplate = async (forceDelete = false) => {
    if (!id || saving || isLoading || deleting || isLoadingPhases) return

    if (connectedComponents.length > 0 && !forceDelete) {
      showToastWithStyles(connectedComponents.length)
      setShowModal(false)
      return
    }

    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Template id not found')
      return
    }

    setDeleting(true)
    try {
      await remove(child(templateRef, `${teamId}/${id}`))
      await remove(ref(db, `phases/${id}`))

      const updatedList = allTemplates.filter((v) => v?.id !== id)
      dispatch(setTemplatesData({ data: updatedList }))
      dispatch(
        setPhasesData({
          data: phases?.filter((phase) => phase?.templateId !== id)
        })
      )

      toast.success('The template has been removed.')
      navigate('/dashboard/templates')
    } catch (error) {
      toast.error('Something went wrong.')
      console.log('Error while deleting a template', error)
    } finally {
      setDeleting(false)
    }
  }

  const showToastWithStyles = (connectedComponentsLength: number) => {
    const toastId = toast.warning(
      `This template is connected to ${connectedComponentsLength} component(s). Please connect these components to another template.`,
      {
        autoClose: 4000,
        closeOnClick: false,
        pauseOnHover: true,
        style: {
          width: 'max-content',
          maxWidth: '800px'
        }
      }
    )

    toast.update(toastId, {
      closeButton: ({ closeToast }) => (
        <StyledButton
          onClick={(e) => {
            deleteTemplate(true)
            closeToast(e)
          }}
        >
          Delete Anyway
        </StyledButton>
      )
    })
  }

  useEffect(() => {
    if (!id || isLoading) return
    const templateInfo = allTemplates?.find((tmp) => tmp?.id === id)
    if (!templateInfo) return
    setFormData({
      title: templateInfo?.title,
      description: templateInfo?.description,
      type: (templateInfo?.type as ComponentTypes) || 'component',
      visible: templateInfo?.visible
    })
  }, [id, allTemplates])

  return (
    <StyledTemplateEditMainContainer id="edit-template-container">
      <SubNav
        title="Edit Template"
        onBtnClick={() => navigate(`/dashboard/templates/${id}`)}
      />
      <StyledTemplateEditBody>
        <form onSubmit={handleSave} style={{ width: '100%' }}>
          <StyledTemplateEditContentContainer>
            {isLoading ? (
              <div
                style={{ width: '100%', padding: '25px' }}
                className="flex justify-center"
              >
                <Spinner type="spinner" />
              </div>
            ) : null}
            <StyledTemplateEditTitleContainer>
              {/* <SelectField
                options={TEMPLATE_TYPES}
                fullWidth
                value={formData?.type}
                disabled
                onChange={handleProjectTypeChange}
              /> */}
              <InputField
                disabled={isLoading || saving}
                autoFocus
                required
                fullWidth
                label="Template Name"
                placeholder="maximum of 120 characters"
                name="title"
                type="text"
                value={formData.title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    title:
                      e.target?.value?.length < 120
                        ? e.target?.value
                        : formData?.title
                  })
                }
              />
              <TextArea
                disabled={isLoading || saving}
                required
                width="100%"
                textLabel="Template Description"
                placeholder="maximum of 120 characters"
                name="description"
                value={formData.description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setFormData({
                    ...formData,
                    description:
                      e.target?.value?.length < 160
                        ? e.target?.value
                        : formData?.description
                  })
                }
              />
            </StyledTemplateEditTitleContainer>

            <Button
              disabled={isLoading || saving}
              type="submit"
              label={isLoading ? 'Saving...' : 'UPDATE CHANGES'}
              variant="primary"
            />
          </StyledTemplateEditContentContainer>
        </form>
        <StyledDangerZoneContainer>
          <StyledDangerZoneHeader>
            <StyledDangerZoneTitle>Danger zone</StyledDangerZoneTitle>
            <StyledDangerZoneDescription>
              {!canDelete
                ? "You can't delete the last template. Please create a new template before deleting this one."
                : `Are you sure you want to delete this template? This action is irreversible and will permanently erase all your data. If you're sure, tap 'Remove Template' below.`}
            </StyledDangerZoneDescription>
          </StyledDangerZoneHeader>
          <Button
            type="button"
            size="small"
            disabled={!canDelete || saving || isLoading || deleting}
            label={deleting ? 'Removing...' : 'REMOVE TEMPLATE'}
            variant="danger"
            onClick={() => (canDelete ? setShowModal(true) : null)}
            fullWidth={false}
          />
          {showModal && (
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              signal="alert-triangle"
              signalIconType="warning"
              title="Remove Template"
              slotContent={
                <TemplateDeleteContent
                  componentNumber={connectedComponents.length}
                  type={formData?.type as ComponentTypes}
                  templateId={id as string}
                />
              }
              buttonLabel1="REMOVE TEMPLATE"
              buttonLabel2="CANCEL"
              buttonVariant1="danger"
              buttonVariant2="tertiary"
              buttonOnClick1={deleteTemplate}
            />
          )}
        </StyledDangerZoneContainer>
      </StyledTemplateEditBody>
    </StyledTemplateEditMainContainer>
  )
}

export default TemplateEdit
