import { useEffect, useState } from 'react';
import styled from "styled-components";
import { theme } from "../../../styles";
import IconComponent from "../IconComponent/IconComponent";

export interface IAvatarProps {
    src?: string;
    size?: number;
    onClick?: () => void;
}

const StyledAvatar = styled.div<Omit<IAvatarProps, 'src'>>`
  display: flex;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  overflow: hidden;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.borderDefault};
`;

const StyledAvatarImage = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: auto;
  flex-shrink: 0;
`;

const Avatar = ({ src, size = 24, onClick }: IAvatarProps) => {
    const [hasError, setHasError] = useState(false);

    // Reset `hasError` when `src` changes to allow retry
    useEffect(() => {
        setHasError(false);
    }, [src]);

    return (
        <StyledAvatar size={size} onClick={() => onClick && onClick()}>
            {!hasError && src ? (
                <StyledAvatarImage 
                    src={src} 
                    alt="Avatar" 
                    onError={() => setHasError(true)} 
                />
            ) : (
                <IconComponent icon="user" iconSize={size > 64 ? "l" : size > 24 ? "m" : "s"} />
            )}
        </StyledAvatar>
    );
};

export default Avatar;