import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided
} from 'react-beautiful-dnd/index'
import { sortedDataByOrder, sortDataByDate } from '../../../utils/helpers'
import { theme } from '../../../styles'
import { PhaseObj, TaskObj } from '../../../store/reducers/phaseReducerSlice'
import EditPhaseItem from '../EditPhaseItem/EditPhaseItem'
import Button from '../Button/Button'
import PhaseProgress from '../PhaseProgress/PhaseProgress'
import AddItem from '../AddItem/AddItem'
import Banner from '../Banner/Banner'

interface PhaseItemProps {
  templateId: string
  phaseId: string
  data: PhaseObj
  draggableId: string
  index: number
  phaseColor?: { bg: string; text: string }
  phaseReorderState?: boolean
}

export const StyledTemplatesPhaseContainer = styled.div<{ dragged?: boolean }>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${theme.colors.borderDefault};

  ${({ dragged }) =>
    dragged &&
    `
        outline:1px solid ${theme.colors.actionPrimaryActive};
        background-color: ${theme.colors.actionPrimaryLightHover};
    `}
`

export const StyledPhaseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

export const StyledTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

// Phase component wrapper
const PhaseComponent: React.FC<PhaseItemProps> = ({
  data,
  templateId,
  phaseId,
  draggableId,
  phaseColor,
  index,
  phaseReorderState
}) => {
  // check if any task component is dragging
  const [isAnyTaskDragging, setIsAnyTaskDragging] = useState(false)

  // check if task edit mode on any task component is on
  const [isTaskEditModeOnCheck, setIsTaskEditModeOnCheck] = useState(false)
  const [isPhaseEditModeOnCheck, setIsPhaseEditModeOnCheck] = useState(false)

  const [showArchivedTasks, setShowArchivedTasks] = useState(false)

  // sort the task list by order
  const unArchivedOrderedTask: TaskObj[] = useMemo(() => {
    const unArchivedTasks =
      Object.values(data?.tasks || []).filter((task) => !task?.archived) || []

    return sortedDataByOrder(unArchivedTasks, 'order', 'asc')
  }, [data])

  // sort archived tasks by updated date
  const sortedArchivedTaskByUpdatedDate: TaskObj[] = useMemo(() => {
    const archivedTasks =
      Object.values(data?.tasks || []).filter((task) => task?.archived) || []
    return sortDataByDate(archivedTasks || [], 'desc')
  }, [data])

  // JSX
  return (
    <Draggable draggableId={draggableId} index={index} key={draggableId}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <StyledTemplatesPhaseContainer dragged={snapshot?.isDragging}>
            <StyledPhaseContentContainer>
              <PhaseProgress
                dragProps={provided}
                hasProgressBar={false}
                phaseProgress={1}
                phaseTitle={data?.title}
                textColor={phaseColor?.text ?? ''}
                bgColor={phaseColor?.bg ?? ''}
                draggable={phaseReorderState}
                templateId={templateId}
                phaseId={phaseId}
                index={index}
                setIsTaskEditModeOnCheck={setIsPhaseEditModeOnCheck}
                isAnyTaskDragging={isAnyTaskDragging}
                setIsAnyTaskDragging={setIsAnyTaskDragging}
              />

              {!phaseReorderState && (
                <Droppable droppableId={draggableId} type="TASK">
                  {(provided1: DroppableProvided) => (
                    <StyledTaskContainer
                      {...provided1.droppableProps}
                      ref={provided1.innerRef}
                    >
                      {unArchivedOrderedTask?.map((task, ind) => (
                        <div style={{ width: '100%' }} key={task?.id}>
                          <Draggable
                            draggableId={task?.id}
                            index={ind}
                            key={task?.id}
                          >
                            {(
                              provided2: DraggableProvided,
                              snapshot: DraggableStateSnapshot
                            ) => (
                              <div
                                {...provided2.draggableProps}
                                ref={provided2.innerRef}
                              >
                                <EditPhaseItem
                                  phaseItemName={task?.title}
                                  visible
                                  dragged={snapshot?.isDragging}
                                  dragProps={provided2}
                                  isAnyTaskDragging={isAnyTaskDragging}
                                  setIsAnyTaskDragging={setIsAnyTaskDragging}
                                  templateId={templateId}
                                  phaseId={phaseId}
                                  taskId={task?.id}
                                  setIsTaskEditModeOnCheck={
                                    setIsTaskEditModeOnCheck
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided1.placeholder}
                    </StyledTaskContainer>
                  )}
                </Droppable>
              )}
            </StyledPhaseContentContainer>

            {!phaseReorderState && !isPhaseEditModeOnCheck && (
              <>
                {!isTaskEditModeOnCheck && (
                  <AddItem
                    type="task"
                    collapsed={true}
                    style={{ width: '100%' }}
                    index={index}
                    phaseId={phaseId}
                    templateId={templateId}
                    isAnyTaskDragging={isAnyTaskDragging}
                  />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch'
                  }}
                >
                  <Button
                    label={`${showArchivedTasks ? `HIDE` : `SHOW`} ARCHIVED TASKS`}
                    variant="utility"
                    size="xs"
                    iconPosition="after"
                    icon={!showArchivedTasks ? 'chevron-down' : 'chevron-up'}
                    onClick={() => setShowArchivedTasks(!showArchivedTasks)}
                    style={{ width: 'fit-content' }}
                  />
                  {showArchivedTasks &&
                    (sortedArchivedTaskByUpdatedDate?.length > 0 ? (
                      sortedArchivedTaskByUpdatedDate?.map((task) => {
                        return (
                          <StyledTaskContainer key={task?.id}>
                            <EditPhaseItem
                              phaseItemName={task?.title}
                              visible
                              isAnyTaskDragging={isAnyTaskDragging}
                              setIsAnyTaskDragging={setIsAnyTaskDragging}
                              templateId={templateId}
                              phaseId={phaseId}
                              taskId={task?.id}
                              setIsTaskEditModeOnCheck={
                                setIsTaskEditModeOnCheck
                              }
                              taskState="archived"
                            />
                          </StyledTaskContainer>
                        )
                      })
                    ) : (
                      <Banner
                        body="There are no archived tasks within this phase."
                        type="callout"
                        variant="icon"
                        showIcon={true}
                        showButton={false}
                        showTitle={false}
                      />
                    ))}
                </div>
              </>
            )}
          </StyledTemplatesPhaseContainer>
        </div>
      )}
    </Draggable>
  )
}

export default PhaseComponent
