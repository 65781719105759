import styled from 'styled-components'
import { theme } from '../../styles'

export const StyledPhaseDetailsMainContainer = styled.section`
  width: 100%;
  background-color: ${theme.colors.white};
  overflow-y: scroll;
  padding-bottom: 32px;
`

export const StyledPhaseDetailsBody = styled.div`
  display: flex;
  padding: 40px 40px 16px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  max-width: 832px;
  margin-inline: auto;
  width: 100%;

  @media screen and (max-width: 430px) {
    padding: 24px 16px 16px 16px;
  }
`

export const StyledPhaseDetailsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`

export const StyledPhaseDetailsFormDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    gap: 24px;
  }
`

export const StyledPhaseDetailsActionButtonContainer = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: 430px) {
    gap: 8px;
  }
`

export const StyledDangerZoneContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(255, 0, 0, 0.1);
`

export const StyledDangerZoneHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`
export const StyledDangerZoneTitle = styled.h4`
color: ${theme.colors.copy};
font-family: "rooney-sans";
font-size: 26px;
font-style: normal;
font-weight: 500;
line-height: 140%;
`

export const StyledDangerZoneDescription = styled.p`
color: ${theme.colors.copy};
font-family: "rooney-sans";
font-size: ${theme.font.sizes.medium};
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
`