import { HTMLAttributes } from 'react'
import { IPhaseType } from '../PhaseType/PhaseType'
import { css, styled } from 'styled-components'
import { theme } from '../../../styles'
import PhaseStatus from '../PhaseStatus/PhaseStatus'
import { FeatherIconNames } from 'feather-icons'
import IconComponent from '../IconComponent/IconComponent'
import { ComponentTypes } from '../../../store/reducers/componentsReducerSlice'

export interface ICardProps extends HTMLAttributes<HTMLButtonElement> {
  tasksCompleted: number
  tasksTotal: number
  componentName: string
  phaseTitle?: string
  fullWidth?: boolean
  disabled?: boolean
  figmaFileUrl?: string
  bgColor?: string
  textColor?: string
  componentId?: string
  phaseType?: IPhaseType
  type?: 'kanban' | 'default'
  date?: string
  activityIcon?: FeatherIconNames
  updatedAt?: string | Date
  componentType?: ComponentTypes
}

const StyledCard = styled.button<{ $fullWidth?: boolean }>`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: ${theme.colors.white};
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  outline: none;
  border: none;
  margin: 2px;
  outline: 1px solid ${theme.colors.borderDefault};

  ${({ $fullWidth }) => {
    const width = $fullWidth ? '100%' : 'fit-content'
    return css`
      width: ${width};
    `
  }}

  &:hover {
    outline: 2px solid ${theme.colors.actionPrimaryHover};
    box-shadow: none;
  }
  &:active,
  :focus-within {
    outline: 2px solid ${theme.colors.actionPrimaryActive};
    box-shadow: none;
  }

  &:disabled {
    background-color: ${theme.colors.actionDisabled};
    cursor: not-allowed;
    box-shadow: none;
    outline: none;
  }
`

const StyledCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
  max-width: 100%;
`

const StyledCountContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`

const StyledCount = styled.p`
  color: ${theme.colors.copy};
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
`

const StyledComponentName = styled.h3`
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-size: ${theme.font.sizes.header3};
  font-weight: 400;
  line-height: 140%;
  width: 100%;
  max-width: 100%;
`

interface ICardActivityProps {
  date: string
  icon?: FeatherIconNames
}

const StyledActivity = styled.div`
  display: flex;
  width: 76px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
`

const StyledActivityDate = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: ${theme.colors.copySubtle};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`

const Activity = ({ icon = 'activity', date }: ICardActivityProps) => {
  return (
    <StyledActivity>
      <IconComponent icon={icon} iconSize="s" />
      <StyledActivityDate>{date}</StyledActivityDate>
    </StyledActivity>
  )
}

const Card = ({
  tasksCompleted: completedTasks,
  tasksTotal: totalTasks,
  phaseTitle,
  phaseType,
  fullWidth = true,
  componentName,
  disabled,
  bgColor,
  textColor,
  type = 'default',
  date = '',
  activityIcon = 'activity'
}: ICardProps) => {
  return (
    <StyledCard $fullWidth={fullWidth} disabled={disabled}>
      <StyledCardDetails>
        <StyledCountContainer>
          <StyledCount>{completedTasks}</StyledCount>
          <StyledCount>/</StyledCount>
          <StyledCount>{totalTasks}</StyledCount>
        </StyledCountContainer>
        <StyledComponentName>{componentName}</StyledComponentName>
      </StyledCardDetails>
      {type === 'default' ? (
        <PhaseStatus
          phaseTitle={phaseTitle || ''}
          phaseType={phaseType}
          bgColor={bgColor}
          textColor={textColor}
          isActive={true}
        />
      ) : (
        <Activity date={date} icon={activityIcon} />
      )}
    </StyledCard>
  )
}

export default Card
