import styled, { css } from "styled-components";
import { theme } from "../../../styles";
import IconComponent from "../IconComponent/IconComponent";

interface IExpandCollapseProps {
  collapse?: boolean;
  type?: "default" | "border";
  onClick?: () => void;
  color?: string;
  disabled?: boolean;
}

const StyledExpandCollapseContainer = styled.div<IExpandCollapseProps>`
  display: flex;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  ${({ type }) =>
    type === "border" &&
    css`
      // outline: 2px solid ${theme.colors.copy};
      // width:24px;
      // height:24px;
      // border-radius: 4px;
    `}

  & > div {
    transition: all 250ms ease;
  }

  ${({ collapse }) =>
    !collapse
      ? css`
          & > div {
            transform: rotate(0deg);
          }
        `
      : css`
      & > div{
          transform: rotate(90deg);
      }`
  }
`;

const ExpandCollapse = ({
  collapse = false,
  type = "default",
  onClick,
  color = "",
  disabled = false,
}: IExpandCollapseProps) => {
  return (
    <StyledExpandCollapseContainer
      onClick={onClick}
      type={type}
      collapse={collapse}
      role="button"
      tabIndex={0}
    >
      <IconComponent
        icon={collapse ? "plus" : "minus"}
        iconSize="m"
        color={color}
        disabled={disabled}

      />
    </StyledExpandCollapseContainer>
  );
};

export default ExpandCollapse;
