import React, { useEffect } from 'react'
import Modal from '../Modal/Modal'
import { FileWithPreview, useImageUpload } from '../../../hooks/useImageUpload'
import { theme } from '../../../styles'
import FeatherIcon from 'feather-icons-react'
import Avatar from '../Avatar/Avatar'
import { formatBytes } from '../../../utils/helpers'
import { styled } from 'styled-components'

interface ModalProps {
  modalOpen: boolean
  modalWrapper?: boolean
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  btnLabel?: string
  isDisabled?: boolean
  onSubmit: (files: FileWithPreview[]) => void
  cancelUpload: () => void
  onFileChange?: (files: FileWithPreview[]) => void
  canCancel: boolean
  uploadPercent?: number
  hideCancelButton?: boolean
  isLoading?: boolean
  error?: string
}

const StyledModalUploadContent = styled.div`
  max-width: 100%;
  @media screen and (min-width: 834px) {
    min-width: 400px;
  }
  @media screen and (max-width: 834px) {
    min-width: 300px;
  }
  @media screen and (max-width: 350px) {
    min-width: 100%;
  }
`

const ModalUpload = ({
  modalOpen,
  setModalOpen,
  title,
  btnLabel = 'Upload',
  isDisabled = false,
  onSubmit,
  cancelUpload,
  canCancel = false,
  uploadPercent = 0,
  isLoading = false,
  modalWrapper = true,
  hideCancelButton = false,
  error = '',
  onFileChange = () => {}
}: ModalProps) => {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    reuploadFile,
    clearFiles,
    isDragActive
  } = useImageUpload({ disabled: isLoading || isDisabled })

  // trigger upload func
  const uploadImage = () => {
    if (acceptedFiles?.length === 0 || fileRejections?.length > 0 || isLoading)
      return
    if (onSubmit) {
      onSubmit(acceptedFiles)
    }
  }

  useEffect(() => {
    if (!modalOpen) {
      clearFiles()
    }
  }, [modalOpen])

  useEffect(() => {
    if (!modalOpen || !onFileChange) {
      onFileChange([])
      return
    }
    if (fileRejections?.length > 0) {
      onFileChange([])
      return
    }
    onFileChange(acceptedFiles)
  }, [fileRejections, acceptedFiles, modalOpen])

  // JSX
  return (
    <ContentWrapper
      modalWrapper={modalWrapper}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={title}
      btnLabel={btnLabel}
      isLoading={isLoading}
      uploadImage={uploadImage}
      clearFiles={clearFiles}
      fileRejections={fileRejections}
      acceptedFiles={acceptedFiles}
    >
      <StyledModalUploadContent>
        <div
          {...getRootProps()}
          style={{
            border: '2px dashed ' + theme.colors.actionPrimary,
            borderRadius: '8px',
            padding: '20px',
            margin: '0 0 5px 0',
            background: isDragActive
              ? theme.colors.actionPrimaryLightHover
              : 'none',
            textAlign: 'center',
            opacity: !isLoading ? '1' : '0.5'
          }}
        >
          <input {...getInputProps()} />
          <FeatherIcon
            color={theme.colors.actionPrimary}
            icon="upload-cloud"
            size={32}
          />
          <p>
            Drag your file(s) or{' '}
            <span
              style={{
                color: theme.colors.actionPrimary,
                cursor: 'pointer'
              }}
            >
              {' '}
              BROWSE
            </span>
          </p>
          <p style={{ color: theme.colors.copySubtle }}>
            160 x 160 px and max 700 kb file size is allowed
          </p>
        </div>
        <p>Only support .jpg and .png.</p>
        <div style={{ margin: '8px 0' }}>
          {acceptedFiles?.length > 0 && (
            <ul>
              {acceptedFiles?.map((file, i) => (
                <li
                  key={i}
                  style={{
                    border: '2px solid #f3f3f3',
                    borderRadius: '8px',
                    padding: '8px',
                    marginBottom: '5px'
                  }}
                >
                  <div className="flex items-center" style={{ gap: '10px' }}>
                    <Avatar src={file?.preview} />
                    <div style={{ maxWidth: '76%', flex: '1' }}>
                      <span
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          display: 'block'
                        }}
                      >
                        {file?.name}
                      </span>
                      <span>{formatBytes(file?.size)}</span>
                    </div>
                    {isLoading ? (
                      canCancel && uploadPercent < 100 && !hideCancelButton ? (
                        <button
                          onClick={cancelUpload}
                          type="button"
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            color: theme.colors.signalError
                          }}
                        >
                          <FeatherIcon icon="x" size={18} />
                        </button>
                      ) : null
                    ) : (
                      <button
                        onClick={reuploadFile}
                        disabled={isLoading}
                        type="button"
                        style={{
                          border: 'none',
                          background: 'none',
                          color: theme.colors.signalError
                        }}
                      >
                        <FeatherIcon icon="refresh-ccw" size={18} />
                      </button>
                    )}
                  </div>
                  {isLoading ? (
                    <div className="flex items-center" style={{ gap: '6px' }}>
                      <div
                        style={{
                          width: '100%',
                          height: '5px',
                          borderRadius: '50px',
                          background: theme.colors.actionDisabled
                        }}
                      >
                        <div
                          style={{
                            width: uploadPercent + '%',
                            height: '5px',
                            borderRadius: '50px',
                            background: theme.colors.actionPrimary,
                            transition: 'linear',
                            transitionProperty: 'width'
                          }}
                        ></div>
                      </div>
                      <span style={{ color: theme.colors.copySubtle }}>
                        {uploadPercent}%
                      </span>
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>
          )}
          {error && <p style={{ color: theme.colors.signalError }}>{error}</p>}
          {fileRejections?.length > 0 && (
            <ul>
              {fileRejections?.map(({ errors }, i) => (
                <li
                  key={i}
                  style={{
                    color: theme.colors.signalErrorNormalActive,
                    margin: '4px 0',
                    display: 'block'
                  }}
                >
                  {errors?.map((e, i) => (
                    <span key={i} style={{ display: 'block' }}>
                      {e?.message}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          )}
        </div>
      </StyledModalUploadContent>
    </ContentWrapper>
  )
}

interface ContentWrapperProps {
  modalWrapper?: boolean
  children: React.ReactNode
  modalOpen?: boolean
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  btnLabel?: string
  isLoading?: boolean
  uploadImage: () => void
  clearFiles: () => void
  fileRejections?: any
  acceptedFiles?: FileWithPreview[]
}

const ContentWrapper = ({
  modalWrapper = true,
  modalOpen = false,
  setModalOpen,
  title,
  btnLabel,
  isLoading,
  uploadImage,
  clearFiles,
  fileRejections,
  acceptedFiles = [],
  children
}: ContentWrapperProps) => {
  if (modalWrapper) {
    return (
      <Modal
        showModal={modalOpen}
        setShowModal={setModalOpen}
        title={title}
        signal="upload"
        buttonVariant1="primary"
        buttonLabel1={btnLabel}
        buttonVariant2="tertiary"
        buttonLabel2="Cancel"
        buttonOnClick1={uploadImage}
        buttonOnClick2={() => clearFiles()}
        buttonDisabled1={
          isLoading || fileRejections?.length > 0 || acceptedFiles?.length < 1
        }
        buttonDisabled2={isLoading}
        onclose={() => {
          if (isLoading) return
          if (setModalOpen) {
            setModalOpen(false)
          }
          clearFiles()
        }}
        isLoading={isLoading}
        slotContent={children}
      />
    )
  }
  return children
}

export default ModalUpload
