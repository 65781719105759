import { useCallback, useState } from 'react'
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
  FileRejection,
  FileError
} from 'react-dropzone'
import { formatBytes } from '../utils/helpers'

interface UseImageUploadReturn {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  acceptedFiles: FileWithPreview[]
  fileRejections: FileRejection[]
  reuploadFile: () => void
  clearFiles: () => void
  open: () => void
  isDragActive: boolean
}

export interface FileWithPreview extends File {
  preview: string
}

const maxSize = 700 * 1024
const maxCharLength = 25
const minImageSize = 64
const maxImageSize = 160

const useImageUpload = ({
  disabled = false
}: {
  disabled: boolean
}): UseImageUploadReturn => {
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPreview[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])

  const onDrop = useCallback(
    (acceptedFilesNew: File[], fileRejectionsNew: FileRejection[]) => {
      const validFiles: FileWithPreview[] = []
      const rejections: FileRejection[] = []

      acceptedFilesNew.forEach((file) => {
        // Check file type
        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
          rejections.push({
            file,
            errors: [{ code: 'file-invalid-type', message: `Invalid file type selected` }]
          })
          return
        }

        // Check file size
        if (file.size > maxSize) {
          rejections.push({
            file,
            errors: [{ code: 'file-too-large', message: `File is larger than ${formatBytes(maxSize)}` }]
          })
          return
        }

        // Check file name length
        if (file.name.length > maxCharLength) {
          rejections.push({
            file,
            errors: [{ code: 'name-too-large', message: `File name is larger than ${maxCharLength} characters` }]
          })
          return
        }

        // Check image dimensions asynchronously
        const image = new Image()
        image.src = URL.createObjectURL(file)

        image.onload = () => {
          const { width, height } = image
          if (width < minImageSize || height < minImageSize) {
            rejections.push({
              file,
              errors: [{ code: 'image-too-small', message: `Image dimensions must be at least ${minImageSize}x${minImageSize} pixels` }]
            })
          } else if (width > maxImageSize || height > maxImageSize) {
            rejections.push({
              file,
              errors: [{ code: 'image-too-large', message: `Image dimensions must be at most ${maxImageSize}x${maxImageSize} pixels` }]
            })
          } else {
            validFiles.push(Object.assign(file, { preview: URL.createObjectURL(file) }))
          }

          // Update state after dimension checks
          setAcceptedFiles(validFiles)
          setFileRejections([...fileRejectionsNew, ...rejections])
        }
      })
    },
    []
  )

  const clearFiles = useCallback(() => {
    setAcceptedFiles([])
    setFileRejections([])
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg']
    },
    multiple: false,
    maxSize,
    disabled
  })

  const reuploadFile = useCallback(() => {
    open()
  }, [open])

  return {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    reuploadFile,
    clearFiles,
    isDragActive,
    open
  }
}

export { useImageUpload }