import React from 'react'
import { surveyData } from './constants'
import styled from 'styled-components'
import StepperSidebar from './_components/Sidebar'
import { MultiStepForm } from './contexts/MultiStepContext'
import { Footer } from './_components/Footer'
import { FormProvider, useForm } from 'react-hook-form'
import { SingleChoiceQuestion } from './_components/SingleChoiceQuestion'
import { StepperQuestion } from './_components/StepperQuestion'
import { GroupQuestion } from './_components/GroupQuestion'
import { ScaleQuestion } from './_components/ScaleQuestion'
import { NestedSingleChoiceQuestion } from './_components/NestedSingleChoiceQuestion'
import { MultipleChoiceQuestion } from './_components/MultipleChoiceQuestion'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormValues, initialValues, surveyZodSchema } from './form.validations'
import { ConditionallyRender } from '../../utils/ConditionallyRender'
import Choices from './_components/Choices'
import httpRequest from '../../utils/fetcher'
import { auth } from '../../config/config'
import { useMutation } from '@tanstack/react-query'
import { nest } from '../../utils/axios'
import {
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithPopup,
  User
} from 'firebase/auth'
import { toast } from 'react-toastify'

const AppContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'sidebar content'
    'footer footer';
  height: 100vh;
`

const StyledSidebar = styled.div`
  grid-area: sidebar;
  padding: 1rem;
`

const StyledContent = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 1rem;
`

const StyledFooter = styled.div`
  grid-area: footer;
  background-color: aquamarine;
  grid-column: span 2;
  width: 100%;
`

const TemplateBuilderPage = () => {
  const steps = surveyData.survey.sections.map((section) => ({
    title: section.title
  }))

  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0)

  const allQuestions = surveyData.survey.sections.flatMap(
    (section) => section.questions
  )

  const currentQuestion = allQuestions[currentQuestionIndex]
  const currentSection = surveyData.survey.sections.find((section) =>
    section.questions.some((question) => question.id === currentQuestion.id)
  ) as any

  const methods = useForm<Partial<FormValues>>({
    defaultValues: initialValues,
    resolver: zodResolver(surveyZodSchema)
  })

  console.log('methods', methods.watch())

  const renderQuestion = (question: any) => {
    const fieldName =
      `sections[${currentSection?.id - 1}].questions[${currentQuestionIndex}].answer` as const
    switch (question.type) {
      case 'single_choice':
        return (
          <SingleChoiceQuestion question={question} fieldName={fieldName} />
        )
      case 'stepper':
        return (
          <StepperQuestion
            question={question}
            fieldName={fieldName}
            currentQuestionIndex={currentQuestionIndex}
          />
        )
      case 'group':
        return <GroupQuestion question={question} fieldName={fieldName} />
      case 'scale':
        return <ScaleQuestion question={question} fieldName={fieldName} />
      case 'nested_single_choice':
        return (
          <NestedSingleChoiceQuestion
            question={question}
            fieldName={fieldName}
          />
        )
      case 'multiple_choice':
        return (
          <MultipleChoiceQuestion question={question} fieldName={fieldName} />
        )
      default:
        return null
    }
  }

  const { handleSubmit } = methods

  // const handleGoogleLogin = async () => {
  //   const provider = new GoogleAuthProvider()
  //   try {
  //     const result = await signInWithPopup(auth, provider)
  //     const user = result.user

  //     if (user) {
  //       console.log('User signed in:', user)
  //       return user as any
  //     }
  //   } catch (error: any) {
  //     console.error('Error during Google login:', error.message)
  //     throw new Error('Google login failed')
  //   }
  // }

  const sigInWithGoogle = async (): Promise<User | null> => {
    const provider = new GoogleAuthProvider()

    try {
      const result = await signInWithPopup(auth, provider)
      const user = result.user
      const additionalUserInfo = getAdditionalUserInfo(result)
      const isNewUser = additionalUserInfo?.isNewUser

      if (isNewUser) {
        try {
          const response = await nest.post('auth/register', user, {
            params: { isFromAiPage: true }
          })
          if (response.status === 200 || response.status === 201) {
            toast.success('Successfully registered!')
            return user
          }
        } catch (error: any) {
          console.error('Registration Error:', error.message)
          toast.error(error.message || 'Registration failed!')
          return null
        }
      } else {
        try {
          const response = await nest.post('auth/login', user)
          if (response.status === 200 || response.status === 201) {
            toast.success('Successfully logged in!')
            return user
          }
        } catch (error: any) {
          console.error('Login Error:', error.message)
          toast.error(error.message || 'Login failed!')
          return null
        }
      }
    } catch (error: any) {
      if (
        error.code !== 'auth/cancelled-popup-request' &&
        error.code !== 'auth/popup-closed-by-user'
      ) {
        toast.error(error.message || 'Google login failed!')
      }
      console.error('Google Login Error:', error.message)
      return null
    }

    return null
  }

  const { isPending, mutateAsync } = useMutation({
    mutationFn: async (data: any) => {
      let user = auth.currentUser

      // If user is not logged in, trigger Google login
      if (!user) {
        user = await sigInWithGoogle()
        if (!user) {
          throw new Error('User authentication failed!')
        }
      }

      // Get the ID token of the authenticated user
      const token = await user.getIdToken()

      // Call the API with the authenticated user's token
      return httpRequest({
        url: '/langchain/generate-workflow',
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          survey: data
        }
      })(nest)
    }
  })

  const onSubmit = handleSubmit(async (data) => {
    try {
      await mutateAsync(data)
      toast.success('Workflow generated successfully!')
    } catch (error: any) {
      console.error('Error submitting workflow:', error.message)
      toast.error(error.message || 'Failed to generate workflow!')
    }
  })

  return (
    <FormProvider {...methods}>
      <ConditionallyRender
        condition={!methods.formState.isSubmitted || isPending}
        show={
          <MultiStepForm totalSections={steps.length}>
            <AppContainer>
              <StyledSidebar>
                <StepperSidebar
                  currentSection={currentSection}
                  surveyData={surveyData}
                />
              </StyledSidebar>
              <StyledContent>
                <h1>{currentSection?.title}</h1>
                {renderQuestion(currentQuestion)}
              </StyledContent>
              <StyledFooter>
                <Footer
                  allQuestions={allQuestions}
                  currentQuestionIndex={currentQuestionIndex}
                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                  isPending={isPending}
                  onSubmit={onSubmit}
                />
              </StyledFooter>
            </AppContainer>
          </MultiStepForm>
        }
        elseShow={<Choices />}
      />
    </FormProvider>
  )
}

export default TemplateBuilderPage

export { default as TemplateBuilderLayout } from './_components/Layout'
