import { FeatherIconNames } from 'feather-icons'
import styled, { css } from 'styled-components'
import IconComponent from '../IconComponent/IconComponent'
import { theme } from '../../../styles'
import Avatar from '../Avatar/Avatar'

interface IAccountItemProps {
  disabled?: boolean
  icon?: FeatherIconNames | ''
  accountItemTitle?: string
  onClick?: () => void
  onClickIcon?: () => void
  isActive?: boolean
  profile?: boolean
  src?: string
  team?: boolean
}

const StyledAccountItem = styled.div<
  Pick<IAccountItemProps, 'disabled' | 'isActive' | 'team'>
>`
  display: flex;
  min-width: 98px;
  padding: 4px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;

  ${({ disabled }) => {
    if (!disabled) {
      return css`
        &:hover {
          color: ${theme.colors.actionPrimaryHover};
        }

        &:hover > div > svg {
          stroke: ${theme.colors.actionPrimaryHover};
        }

        &:hover > p {
          color: ${theme.colors.actionPrimaryHover};
        }
      `
    } else {
      return css`
        color: ${theme.colors.actionDisabled};
        cursor: not-allowed;

        & > div > svg {
          color: ${theme.colors.actionDisabled};
        }

        & > p {
          color: ${theme.colors.actionDisabled};
        }
      `
    }
  }}

  ${({ isActive, disabled }) =>
    isActive &&
    !disabled &&
    css`
      color: ${theme.colors.actionPrimaryActive};
      & > div > svg {
        color: ${theme.colors.actionPrimaryActive};
      }

      & > p {
        color: ${theme.colors.actionPrimaryActive};
      }
    `}
`

const StyledIconContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #f5edff;
`

const StyledAccountItemTitle = styled.p<Pick<IAccountItemProps, 'disabled'>>`
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
`

const StyledTeam = styled.div<Pick<IAccountItemProps, 'disabled' | 'isActive'>>`
  display: flex;
  padding: 4px 4px 4px 0px;
  align-items: center;
  gap: 8px;

  ${({ disabled }) => {
    if (!disabled) {
      return css`
        &:hover {
          color: ${theme.colors.actionPrimaryHover};
        }

        &:hover > div > svg {
          stroke: ${theme.colors.actionPrimaryHover};
        }

        &:hover > p {
          color: ${theme.colors.actionPrimaryHover};
        }
      `
    } else {
      return css`
        color: ${theme.colors.actionDisabled};
        cursor: not-allowed;

        & > div > svg {
          color: ${theme.colors.actionDisabled};
        }

        & > p {
          color: ${theme.colors.actionDisabled};
        }
      `
    }
  }}

  ${({ isActive, disabled }) =>
    isActive &&
    !disabled &&
    css`
      color: ${theme.colors.actionPrimaryActive};
      & > div > svg {
        color: ${theme.colors.actionPrimaryActive};
      }

      & > p {
        color: ${theme.colors.actionPrimaryActive};
      }
    `}
`

const AccountItem = ({
  accountItemTitle = '',
  disabled = false,
  icon = 'users',
  onClick,
  isActive,
  profile = false,
  src,
  team = false,
  onClickIcon
}: IAccountItemProps) => {
  return (
    <StyledAccountItem
      disabled={disabled}
      onClick={onClick}
      isActive={isActive}
      team={team}
    >
      {profile && (
        <>
          <Avatar src={src} />
          <StyledAccountItemTitle disabled={disabled}>
            {accountItemTitle}
          </StyledAccountItemTitle>
        </>
      )}
      {team && (
        <>
          <StyledTeam disabled={disabled} isActive={isActive}>
            {src ? (
              <Avatar src={src} />
            ) : (
              <IconComponent
                icon="users"
                iconSize="s"
                color={
                  disabled
                    ? theme.colors.actionDisabled
                    : isActive
                      ? theme.colors.actionPrimaryActive
                      : theme.colors.copy
                }
                disabled={disabled}
              />
            )}
            <StyledAccountItemTitle disabled={disabled}>
              {accountItemTitle}
            </StyledAccountItemTitle>
          </StyledTeam>
          <StyledIconContainer>
            <IconComponent
              icon="repeat"
              iconSize="s"
              color={
                disabled
                  ? theme.colors.actionDisabled
                  : isActive
                    ? theme.colors.actionPrimaryActive
                    : theme.colors.actionPrimary
              }
              disabled={disabled}
              type='button'
              onClick={(e?:any) => {
                e.preventDefault();
                e.stopPropagation();
                onClickIcon && onClickIcon();
              }}
            />
          </StyledIconContainer>
        </>
      )}

      {!profile && !team && (
        <>
          <IconComponent
            icon={icon}
            iconSize="s"
            color={
              disabled
                ? theme.colors.actionDisabled
                : isActive
                  ? theme.colors.actionPrimaryActive
                  : theme.colors.copy
            }
            disabled={disabled}
          />
          <StyledAccountItemTitle disabled={disabled}>
            {accountItemTitle}
          </StyledAccountItemTitle>
        </>
      )}
    </StyledAccountItem>
  )
}

export default AccountItem
