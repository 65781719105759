import styled from 'styled-components'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import { theme } from '../../../styles'
import Button from '../Button/Button'
import IllustrationComponent, {
  IIllustrationType
} from '../IllustrationComponent/IllustrationComponent'
import SignalIcon from '../SignalIcon/SignalIcon'

interface IBannerProps {
  variant?: 'icon' | 'illustration'
  type?: 'callout' | 'information' | 'warning' | 'error' | 'success'
  body: string
  title?: string
  showButton?: boolean
  showTitle?: boolean
  showIcon?: boolean
  showIllustration?: boolean
  illustrationType?: IIllustrationType
  illustrationSize?: 'xs' | 's' | 'm' | 'l' | 'xl'
  buttonLabel?: string
  handleButtonClick?: () => void
  handleClose?: () => void
}

const StyledBanner = styled.div<{ $bgColor: string }>`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: ${({ $bgColor }) => $bgColor};

  @media screen and (min-width: 834px) {
    flex-direction: row;
    padding: 24px;
    align-items: start;
    padding: 24px 16px;
  }

  & > svg {
    fill: ${({ $bgColor }) => $bgColor};
  }
`

const StyledContainer = styled.div<{ $variant?: 'icon' | 'illustration' }>`
  display: flex;
  align-items: ${({ $variant }) =>
    $variant === 'icon' ? 'flex-start' : 'center'};
  gap: 16px;
  align-self: stretch;

  @media screen and (min-width: 834px) {
    flex: 1 0 0;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`

const StyledContentButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`

const StyledTitle = styled.h3`
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.small};
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
  align-self: stretch;

  @media screen and (min-width: 834px) {
    font-size: ${theme.font.sizes.medium};
  }
`

const StyledBodyContent = styled.p`
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  align-self: stretch;

  @media screen and (min-width: 834px) {
    font-size: ${theme.font.sizes.large};
  }
`

const StyledCloseButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  @media screen and (min-width: 834px) {
    position: relative;
  }
`

const StyledActionButton = styled(Button)`
  background-color: transparent;
  color: ${theme.colors.signalWarning};
  border: 1px solid ${theme.colors.actionPrimary};
  &:hover {
    background-color: transparent;
  }
`

const Banner = ({
  type = 'success',
  body,
  title,
  variant = 'icon',
  showButton = true,
  showTitle = true,
  showIcon = true,
  showIllustration = true,
  illustrationType = 'govern',
  illustrationSize = 's',
  buttonLabel,
  handleButtonClick,
  handleClose
}: IBannerProps) => {
  const isMobile = useIsMobileDevice(834)

  const bgColorMap = {
    callout: theme.colors.actionPrimaryLight,
    information: theme.colors.signalInformationLight,
    warning: theme.colors.signalWarningLight,
    error: theme.colors.signalErrorLight,
    success: theme.colors.signalSuccessLight
  }

  const illustrationColorMap = {
    callout: theme.colors.actionPrimaryLightActive,
    information: theme.colors.signalInformationLightHover,
    warning: theme.colors.signalWarningLightHover,
    error: theme.colors.signalErrorLightHover,
    success: theme.colors.signalSuccessLightHover
  }

  return (
    <StyledBanner $bgColor={bgColorMap[type]}>
      {variant === 'illustration' && showIllustration && (
        <IllustrationComponent
          size={illustrationSize}
          type={illustrationType}
          bgColor={illustrationColorMap[type]}
        />
      )}
      <StyledContainer $variant={variant}>
        {variant === 'icon' && showIcon && (
          <SignalIcon signal={type} background={false} />
        )}
        <StyledContentButtonContainer>
          <StyledContent>
            {showTitle && title && <StyledTitle>{title}</StyledTitle>}
            <StyledBodyContent>{body}</StyledBodyContent>
          </StyledContent>
          {showButton && (
            <StyledActionButton
              size={isMobile ? 's' : 's'}
              label={buttonLabel}
              fullWidth={isMobile}
              onClick={handleButtonClick}
              variant="secondary"
              warn
            />
          )}
        </StyledContentButtonContainer>
      </StyledContainer>
      <StyledCloseButtonContainer>
        <Button
          iconOnly
          icon="x"
          size="s"
          label="close"
          fullWidth={isMobile}
          onClick={handleClose}
          variant="tertiary"
        />
      </StyledCloseButtonContainer>
    </StyledBanner>
  )
}

export default Banner
