import React from 'react'
import { css, styled } from 'styled-components';
import IconComponent from '../IconComponent/IconComponent';

interface ICheckMarkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    checked: boolean;
}

const StyledCheckMark = styled.button <ICheckMarkProps>`
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius:16px;
    cursor: pointer;
    border:none;
    background-color: transparent;

    ${({ checked, theme }) => {


        let backgroundColor = checked ? theme.backgroundColor : "transparent";
        let color = checked ? theme.colors.signalSuccess : theme.colors.actionDisabled


        return css`
                color: ${color};
                background-color: ${backgroundColor};
                
            &:hover {
                background-color:  ${theme.colors.white} ;
                border: 1px solid ${theme.colors.actionPrimary};
                color: ${theme.colors.actionPrimary};
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
            }
            
            &:active{
                background-color:  ${theme.colors.white} ;
                border: 1px solid ${theme.colors.actionPrimary};
                color: ${theme.colors.actionPrimary};
                transform: scale(0.98);
                box-shadow: none;
            }
        `
    }}

    
`

const CheckMark = ({ checked,onClick }: ICheckMarkProps) => {
    return (
        <StyledCheckMark checked={checked} onClick={onClick}>
            <IconComponent iconSize='s' icon='check' />
        </StyledCheckMark>
    )
}

export default CheckMark