/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { DroppableProvided, DropResult } from 'react-beautiful-dnd/index'
import {
  sortedDataByOrder,
  reorderList,
  sortDataByDate
} from '../../utils/helpers'
import { ref, update } from 'firebase/database'
import { db } from '../../config/config'
import {
  setPhasesData,
  PhaseObj
  // TaskObj
} from '../../store/reducers/phaseReducerSlice'
import { TemplateObj } from '../../store/reducers/templateReducerSlice'
import useSetRecentlyViewedTemplate from '../../hooks/useSetRecentlyViewedTemplate'
import useGetCurrentPhases from '../../hooks/useGetCurrentPhases'
import {
  StyledSingleTemplateContainer,
  StyledSingleTemplateBody,
  StyledSingleTemplateHeader,
  StyledPhaseListContainer
} from './StyledSingleTemplate'
import SubNav from '../../components/ui/SubNav/SubNav'
import ChecklistType from '../../components/ui/ChecklistType/ChecklistType'
import Spinner from '../../components/ui/Spinner'
import Button from '../../components/ui/Button/Button'
import AddItem from '../../components/ui/AddItem/AddItem'
import PhaseComponent from '../../components/ui/PhaseComponent/PhaseComponent'
import ArchivedPhaseComponent from '../../components/ui/ArchivedPhaseComponent/ArchivedPhaseComponent'
import Banner from '../../components/ui/Banner/Banner'
import useLocalStorage from '../../hooks/useLocalStorage'
import { toast } from 'react-toastify'

// ------------------- Template detail page ------------------------------
const SingleTemplate: React.FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { data: templatesList, isLoading } = useSelector(
    (store: RootState) => store.template
  )
  const { data: allPhases, isLoading: isPhaseLoading } = useSelector(
    (store: RootState) => store.phase
  )
  const navigate = useNavigate()
  const containerRef = useRef<HTMLElement>(null)

  // template details of template id
  const [templateDetails, setTemplateDetails] = useState<TemplateObj>()

  const [phaseReorderState, setPhaseReorderState] = useState<boolean>(false)

  const [showArchivedPhases, setShowArchivedPhases] = useLocalStorage(
    'showArchivedPhases',
    false
  )

  const { phases, setPhases } = useGetCurrentPhases(allPhases, id)

  // check for template details and set it as the current template
  useEffect(() => {
    if (!id || isLoading) return
    const templateInfo = templatesList?.find((tmp) => tmp?.id === id)
    if (!templateInfo) {
      navigate('/dashboard/templates')
    }
    setTemplateDetails(templateInfo)
  }, [templatesList, id, isLoading, navigate])

  const dragEnd = async (results: DropResult) => {
    const { source, destination, type } = results
    if (!destination) {
      return
    }

    // Check for no positional change
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return
    }

    try {
      const updates = {}

      if (type === 'PHASE') {
        console.log('Reordering phases...')
        const activePhases = phases.filter((phase) => !phase.archived)
        const reorderedPhases = reorderList(
          activePhases,
          source.index,
          destination.index
        ) as PhaseObj[]

        // Update local state
        setPhases(
          phases.map((phase) =>
            reorderedPhases.find((reordered) => reordered.id === phase.id)
              ? {
                  ...phase,
                  order: reorderedPhases.findIndex((p) => p.id === phase.id)
                }
              : phase
          )
        )

        console.log('Reordered phases:', reorderedPhases)

        // Prepare batch updates for Firebase
        reorderedPhases.forEach((phase, index) => {
          // @ts-ignore
          updates[`phases/${id}/${phase.id}/order`] = index
        })

        console.log('Prepared Firebase updates for phases:', updates)

        // Commit batch updates
        await update(ref(db), updates)
        console.log('Firebase successfully updated for phases.')
        toast.success('Phases reordered successfully.')
      } else if (type === 'TASK') {
        console.log('Reordering or moving tasks...')
        const sourcePhase = phases.find(
          (phase) => phase.id === source.droppableId
        )
        const destPhase = phases.find(
          (phase) => phase.id === destination.droppableId
        )

        if (!sourcePhase || !destPhase) {
          return
        }

        // Prepare task lists for source and destination phases
        const sourceTasks = [...(sourcePhase.tasks || [])]
        // Prevent moving the last task from the source phase
        if (sourcePhase && sourcePhase?.tasks?.length === 1) {
          return
        }

        const [movedTask] = sourceTasks.splice(source.index, 1)

        const destTasks =
          source.droppableId === destination.droppableId
            ? sourceTasks
            : [...(destPhase.tasks || [])]

        destTasks.splice(destination.index, 0, movedTask)

        // Update task orders
        const updatedSourceTasks = sourceTasks.map((task, index) => ({
          ...task,
          order: index
        }))
        const updatedDestTasks = destTasks.map((task, index) => ({
          ...task,
          order: index
        }))

        // Update local state
        setPhases(
          phases.map((phase) => {
            if (phase.id === sourcePhase.id) {
              return { ...phase, tasks: updatedSourceTasks }
            }
            if (phase.id === destPhase.id) {
              return { ...phase, tasks: updatedDestTasks }
            }
            return phase
          })
        )

        // Prepare Firebase updates
        // @ts-ignore
        updates[`phases/${id}/${sourcePhase.id}/tasks`] =
          updatedSourceTasks.reduce(
            (acc, task) => ({ ...acc, [task.id]: task }),
            {}
          )
        // @ts-ignore
        updates[`phases/${id}/${destPhase.id}/tasks`] = updatedDestTasks.reduce(
          (acc, task) => ({ ...acc, [task.id]: task }),
          {}
        )

        await update(ref(db), updates)
      }
    } catch (error) {
      toast.error('An unexpected error occurred during drag-and-drop.')
    }

    console.groupEnd()
  }

  // sort based on order number
  const sortedUnArchivedPhaseByOrder: PhaseObj[] = useMemo(() => {
    const unArchivedPhases = phases?.filter((phase) => !phase?.archived) || []
    return sortedDataByOrder(unArchivedPhases || [], 'order', 'asc')
  }, [phases])

  // sort archived phases by updated date
  const sortedArchivedPhaseByUpdatedDate: PhaseObj[] = useMemo(() => {
    const archivedPhases = phases?.filter((phase) => phase?.archived) || []
    return sortDataByDate(archivedPhases || [], 'desc')
  }, [phases])

  // set recently viewed template id
  useSetRecentlyViewedTemplate(id ?? '')

  const discardPhaseReorder = () => {
    setPhases(allPhases?.filter((phase) => phase?.templateId === id))
  }

  // save phase order to database and update redux store
  const handleSavePhaseOrder = () => {
    setPhaseReorderState(false)

    phases?.map((phase) => {
      update(ref(db, `phases/${id}/${phase?.id}`), {
        order: phase?.order
      })
    })

    dispatch(
      setPhasesData({
        data: allPhases?.map((phase) => {
          if (phase?.templateId === id) {
            return {
              ...phase,
              order: phases?.find((p) => p?.id === phase?.id)?.order
            }
          }
          return phase
        })
      })
    )
  }

  // JSX
  return (
    <StyledSingleTemplateContainer ref={containerRef}>
      <SubNav
        title="Template Details"
        onBtnClick={() => navigate('/dashboard/templates')}
      />
      <StyledSingleTemplateBody>
        <StyledSingleTemplateHeader>
          {isLoading || isPhaseLoading ? (
            <div className="mx-auto">
              <Spinner type="spinner" />
            </div>
          ) : (
            <>
              <NavLink
                to={`/dashboard/templates/edit/${id}`}
                style={{
                  display: 'block',
                  width: '100%'
                }}
              >
                <ChecklistType
                  title={templateDetails?.title}
                  description={templateDetails?.description}
                  type={templateDetails?.type || 'component'}
                  onClick={() => navigate(`/dashboard/templates/edit/${id}`)}
                />
              </NavLink>
              <AddItem
                containerRef={containerRef}
                type="phase"
                collapsed={true}
                style={{ width: '100%' }}
                disabled={phaseReorderState}
                templateId={id ?? ''}
              />
            </>
          )}
        </StyledSingleTemplateHeader>
        {phaseReorderState ? (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'flex-start',
              gap: '8px',
              alignSelf: 'flex-end'
            }}
          >
            <Button
              label="SAVE ORDER"
              variant="primary"
              size="xs"
              onClick={handleSavePhaseOrder}
            />
            <Button
              label="DISCARD CHANGES"
              variant="utility"
              size="xs"
              onClick={() => {
                discardPhaseReorder()
                setPhaseReorderState(false)
              }}
            />
          </div>
        ) : (
          <Button
            label="REORDER PHASES"
            size="xs"
            variant="utility"
            style={{
              alignSelf: 'flex-end'
            }}
            onClick={() => setPhaseReorderState(true)}
          />
        )}

        {/* Phase Items Container */}
        <>
          {phases?.length > 0 ? (
            <DragDropContext onDragEnd={dragEnd}>
              <Droppable droppableId="ROOT" type="PHASE">
                {(provided: DroppableProvided) => (
                  <StyledPhaseListContainer
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    phaseReorderState={phaseReorderState}
                  >
                    {sortedUnArchivedPhaseByOrder?.map((phase, ind) => {
                      const phaseColor = Object.values(
                        templateDetails?.colors || {}
                      ).find((c) => c?.id === phase?.colorId)
                      return (
                        <PhaseComponent
                          key={phase?.id}
                          templateId={id ?? ''}
                          phaseId={phase?.id}
                          draggableId={phase?.id}
                          data={phase}
                          phaseColor={phaseColor}
                          index={ind} // Pass the index for accurate ordering
                          phaseReorderState={phaseReorderState}
                        />
                      )
                    })}
                    {provided.placeholder}
                  </StyledPhaseListContainer>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            (isPhaseLoading || isLoading) && (
              <div style={{ marginInline: 'auto' }}>
                <Spinner type="spinner" />
              </div>
            )
          )}
        </>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch'
          }}
        >
          <Button
            label={`${showArchivedPhases ? `HIDE` : `SHOW`} ARCHIVED PHASES`}
            variant="utility"
            size="xs"
            iconPosition="after"
            icon={!showArchivedPhases ? 'chevron-down' : 'chevron-up'}
            onClick={() => setShowArchivedPhases(!showArchivedPhases)}
            style={{ width: 'fit-content' }}
          />
          {!showArchivedPhases ? null : (showArchivedPhases && isLoading) ||
            isPhaseLoading ? (
            <div className="mx-auto">
              <Spinner type="spinner" />
            </div>
          ) : sortedArchivedPhaseByUpdatedDate?.length > 0 ? (
            sortedArchivedPhaseByUpdatedDate?.map((phase) => {
              const phaseColor = Object.values(
                templateDetails?.colors || {}
              )?.find((c) => c?.id === phase?.colorId)
              return (
                <StyledPhaseListContainer key={phase?.id}>
                  <ArchivedPhaseComponent
                    templateId={id ?? ''}
                    phaseId={phase?.id}
                    data={phase}
                    phaseColor={phaseColor}
                  />
                </StyledPhaseListContainer>
              )
            })
          ) : (
            <Banner
              body="You haven't archived any phases."
              type="callout"
              variant="icon"
              showButton={false}
              showTitle={false}
            />
          )}
        </div>
      </StyledSingleTemplateBody>
    </StyledSingleTemplateContainer>
  )
}

export default SingleTemplate
