import { z } from 'zod'

const singleChoiceSchema = z.object({
  id: z.number().optional(),
  type: z.literal('single_choice').optional(),
  text: z.string().optional(),
  description: z.string().optional(),
  options: z
    .array(
      z
        .object({
          label: z.string().optional(),
          text: z.string().optional(),
          description: z.array(z.string()).optional(),
          type: z.enum(['stepper', 'textarea']).optional()
        })
        .optional()
    )
    .optional(),
  answer: z.string().optional()
})

const stepperSchema = z.object({
  id: z.number().optional(),
  type: z.literal('stepper').optional(),
  text: z.string().optional(),
  answer: z.number().optional()
})

const groupSchema = z.object({
  id: z.number().optional(),
  type: z.literal('group').optional(),
  text: z.string().optional(),
  fields: z
    .array(
      z
        .object({
          label: z.string().optional(),
          type: z.enum(['stepper', 'textarea']).optional(),
          max_length: z.number().optional(),
          placeholder: z.string().optional()
        })
        .optional()
    )
    .optional(),
  answer: z.record(z.string(), z.union([z.string(), z.number()])).optional()
})

const scaleSchema = z.object({
  id: z.number().optional(),
  type: z.literal('scale').optional(),
  text: z.string().optional(),
  scale: z
    .object({
      min: z.number().optional(),
      max: z.number().optional(),
      labels: z
        .array(
          z
            .object({
              label: z.string(),
              description: z.array(z.string()).optional()
            })
            .optional()
        )
        .optional()
    })
    .optional(),
  answer: z.number().optional()
})

const nestedSingleChoiceSchema = z.object({
  id: z.number().optional(),
  type: z.literal('nested_single_choice').optional(),
  text: z.string().optional(),
  items: z
    .array(
      z
        .object({
          label: z.string(),
          options: z.array(z.string())
        })
        .optional()
    )
    .optional(),
  answer: z.record(z.string(), z.string()).optional()
})

const multipleChoiceSchema = z.object({
  id: z.number().optional(),
  type: z.literal('multiple_choice').optional(),
  text: z.string().optional(),
  description: z.string().optional(),
  options: z
    .array(
      z
        .object({
          label: z.string().optional(),
          text: z.string().optional(),
          description: z.array(z.string()).optional()
        })
        .optional()
    )
    .optional(),
  answer: z.array(z.string()).optional()
})

// Schema for a single question
const questionSchema = z
  .union([
    singleChoiceSchema.optional(),
    stepperSchema.optional(),
    groupSchema.optional(),
    scaleSchema.optional(),
    nestedSingleChoiceSchema.optional(),
    multipleChoiceSchema.optional()
  ])
  .optional()

// Schema for a single section
const sectionSchema = z.object({
  id: z.number().optional(),
  title: z.string().optional(),
  questions: z.array(questionSchema.optional()).optional()
})

// Main survey schema
const surveySchema = z.object({
  sections: z.array(sectionSchema).optional()
})

export const surveyZodSchema = surveySchema

export type FormValues = z.infer<typeof surveyZodSchema>

export const initialValues: Partial<FormValues> = {
  sections: [
    {
      id: 1,
      title: 'Team and Collaboration',
      questions: [
        {
          id: 1,
          type: 'single_choice',
          text: 'I work in a',
          options: [
            {
              label: 'Large enterprise (1000+ employees)',
              description: [
                'Global corporation with multiple business units',
                'Large regional or national company'
              ]
            },
            {
              label: 'Medium enterprise (251-1000 employees)',
              description: [
                'Growing companies with established processes',
                'Regional market leaders'
              ]
            },
            {
              label: 'Small-medium business (51-250 employees)',
              description: [
                'Local or specialized companies',
                'Established agencies or consultancies'
              ]
            },
            {
              label: 'Small business (11-50 employees)',
              description: ['Boutique agencies', 'Specialized studios']
            },
            {
              label: 'Startup/Small team (1-10 employees)',
              description: [
                'Early-stage startups',
                'Independent consultants or freelancers'
              ]
            }
          ],
          answer: 'Startup/Small team (1-10 employees)'
        },
        {
          id: 2,
          type: 'stepper',
          text: 'With {number} feature teams',
          answer: 1
        },
        {
          id: 3,
          type: 'group',
          text: 'A typical team looks like this',
          fields: [
            { label: 'Designers', type: 'stepper' },
            { label: 'Developers', type: 'stepper' },
            { label: 'Managers', type: 'stepper' },
            { label: 'Other Specialists', type: 'stepper' },
            {
              label: 'No, completely different',
              type: 'textarea',
              placeholder: 'Type your answer here',
              max_length: 250
            }
          ],
          answer: {
            Designers: 1,
            Developers: 1,
            Managers: 1,
            'Other Specialists': 1,
            'No, completely different': ''
          }
        },
        {
          id: 4,
          type: 'single_choice',
          text: 'When it comes to building new features, my team is',
          options: [
            {
              text: 'Business-Driven',
              description: [
                'Decisions are mainly based on budget and revenue goals'
              ]
            },
            {
              text: 'Developer-Driven',
              description: ['Technical constraints guide what gets built']
            },
            {
              text: 'Design-Driven',
              description: ['User experience is the main priority']
            }
          ],
          answer: 'Business-Driven'
        },
        {
          id: 5,
          type: 'scale',
          text: 'On a scale of 0-10, how involved are stakeholders in design decisions?',
          scale: {
            min: 0,
            max: 10,
            labels: [
              {
                label: 'Hands-off',
                description: ['Outcome focused']
              },
              {
                label: 'Light touch',
                description: ['Quarterly check-ins']
              },
              {
                label: 'Balanced',
                description: ['Regular involvement']
              },
              {
                label: 'Active',
                description: ['Weekly feedback']
              },
              {
                label: 'Detailed',
                description: ['Daily input']
              },
              {
                label: 'Micromanaging',
                description: ['Every detail']
              }
            ]
          },
          answer: 5
        }
      ]
    },
    {
      id: 2,
      title: 'Design System State',
      questions: [
        {
          id: 6,
          type: 'single_choice',
          text: 'What is the primary driver for your design system initiative?',
          options: [
            {
              label: 'Developer Efficiency',
              description: [
                'Reducing technical debt and streamlining development'
              ]
            },
            {
              label: 'Design Consistency',
              description: [
                'Improving user experience through unified design patterns'
              ]
            },
            {
              label: 'Strategic Foundation',
              description: [
                'Building infrastructure for rapid scaling and growth'
              ]
            }
          ],
          answer: 'Developer Efficiency'
        },
        {
          id: 7,
          type: 'stepper',
          text: 'How many teams are using the design system?',
          answer: 1
        },
        {
          id: 8,
          type: 'nested_single_choice',
          text: 'What is the current state of your design system components?',
          items: [
            {
              label: 'Design Tokens & Foundation',
              options: ['done', 'some', 'none']
            },
            {
              label: 'Basic UI Components (buttons, inputs, etc)',
              options: ['done', 'some', 'none']
            },
            {
              label: 'Advanced Components (modals, tables, etc)',
              options: ['done', 'some', 'none']
            },
            {
              label: 'Page Patterns & Templates',
              options: ['done', 'some', 'none']
            },
            {
              label: 'Documentation',
              options: ['done', 'some', 'none']
            }
          ],
          answer: {
            'Design Tokens & Foundation': 'done',
            'Basic UI Components (buttons, inputs, etc)': 'done',
            'Advanced Components (modals, tables, etc)': 'done',
            'Page Patterns & Templates': 'done',
            Documentation: 'done'
          }
        },
        {
          id: 9,
          type: 'multiple_choice',
          text: 'Which technical and design capabilities does your design system support?',
          options: [
            {
              label: 'Accessibility',
              description: ['WCAG compliance, screen readers, etc.']
            },
            {
              label: 'Internationalization',
              description: ['Localization and multi-language support']
            },
            {
              label: 'Design Tokens',
              description: ['Theming variables for consistent styling']
            },
            {
              label: 'Multi-brand',
              description: ['Support for multiple brand identities']
            },
            {
              label: 'Dark Mode',
              description: ['Support for dark color schemes']
            },
            {
              label: 'RTL Layouts',
              description: ['Right-to-left language layout support']
            },
            {
              label: 'Responsive Design',
              description: ['Adaptive layouts for various screen sizes']
            }
          ]
        }
      ]
    },
    {
      id: 3,
      title: 'Governance and Contribution',
      questions: [
        {
          id: 10,
          type: 'multiple_choice',
          text: 'Contribution model',
          description: 'How is your contribution model set up?',
          options: [
            {
              text: 'Centralized',
              description: ['Only a dedicated core team can contribute']
            },
            {
              text: 'Hybrid',
              description: [
                'Teams can propose changes, but a core team reviews and approves'
              ]
            },
            {
              text: 'Distributed',
              description: [
                'All teams can directly contribute and maintain components'
              ]
            }
          ],
          answer: []
        },
        {
          id: 11,
          type: 'single_choice',
          text: 'Approval process',
          description: 'Who reviews and approves contributions?',
          options: [
            {
              text: 'Self-review',
              description: ['Individual contributors review their own work']
            },
            {
              text: 'Lead reviewer',
              description: ['A designated lead designer or developer']
            },
            {
              text: 'Dedicated team',
              description: ['A dedicated design systems team']
            },
            {
              text: 'Other',
              description: ['Custom review process'],
              type: 'textarea'
            }
          ]
        }
      ]
    }
  ]
}
