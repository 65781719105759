import styled, { css } from 'styled-components'
import { theme } from '../../../styles'
import Button from '../Button/Button'
import SignalIcon from '../SignalIcon/SignalIcon'

interface ITemplateListItemProps {
  active?: boolean
  disabled?: boolean
  title?: string
  description?: string
  type?: string
  onClick?: () => void
}

const StyledTemplateListItem = styled.div<ITemplateListItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  ${({ disabled }) => {
    return (
      disabled &&
      css`
        & > div > h4,
        & > p {
          color: ${theme.colors.actionDisabled};
        }
      `
    )
  }}

  ${({ active, disabled }) => {
    return (
      !disabled &&
      css`
        border-radius: 4px;
        border: ${active
          ? `1px solid ${theme.colors.actionPrimaryActive}`
          : `1px solid ${theme.colors.borderDefault}`};

        &:hover {
          outline: 1px solid ${theme.colors.actionPrimaryHover};
        }
      `
    )
  }}
    
    &:active,:focus-within {
    box-shadow: none;
  }
`

const StyledTemplateListItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  overflow: hidden;
`
export const StyledTemplateListItemTitle = styled.h4`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
`
const StyledTemplateListItemDescription = styled.p`
  color: ${theme.colors.copySubtle};
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
`

const TemplateListItem = ({
  title,
  description,
  type,
  active,
  onClick,
  disabled
}: ITemplateListItemProps) => {
  return (
    <StyledTemplateListItem active={active} disabled={disabled}>
      <SignalIcon
        signal={type === 'project' ? 'kanban' : 'box'}
        background
        type="primary"
        size="m"
      />
      <StyledTemplateListItemTitleContainer>
        <StyledTemplateListItemTitle>{title}</StyledTemplateListItemTitle>
        <StyledTemplateListItemDescription>
          {description}
        </StyledTemplateListItemDescription>
      </StyledTemplateListItemTitleContainer>
      {/* <Label text={type} /> */}
      <Button
        iconOnly
        size="medium"
        variant="tertiary"
        icon="chevron-right"
        disabled={disabled}
        onClick={onClick}
      />
    </StyledTemplateListItem>
  )
}

export default TemplateListItem
