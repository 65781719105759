import { HTMLAttributes } from "react"
import { css, styled } from "styled-components";


interface ILabelProps extends HTMLAttributes<HTMLDivElement> {
    type?: "active" | "information" | "success" | "warning" | "error" | "disabled";
    text?: string
    fullWidth?: boolean
    noEllipses?: boolean
}


const StyledLabelBackground = styled.div<Omit<ILabelProps, 'text'>>`
    display: flex;
    padding: 8px;
    border-radius: 8px;
    gap: 8px;
    overflow: hidden;

    ${({ theme, fullWidth, type }) => {
    const _width = fullWidth ? "100%" : "fit-content";
    const _textAlign = fullWidth ? "left" : "center";
    const _bg = type && theme.colors.label[type].background;

        return css`
        background:  ${_bg};
        width: ${_width};
        text-align: ${_textAlign};
        `
    }}
`

const StyledLabelText = styled.p<Omit<ILabelProps, 'text'>>`
    flex: 1 0 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${({ noEllipses }) => noEllipses ? "nowrap" : "normal"};

    
    ${({ theme, type }) => {
    const _color = type && theme.colors.label[type].labelText;
        return css`
        color:  ${_color};
        `
    }}
`



const Label = ({ type = "active", text = "label", fullWidth = false, noEllipses = false }: ILabelProps) => {
    return (
        <StyledLabelBackground type={type} fullWidth={fullWidth}>
            <StyledLabelText type={type} fullWidth={fullWidth} noEllipses={noEllipses}>{text}</StyledLabelText>
        </StyledLabelBackground>
    )
}

export default Label