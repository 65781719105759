import React, { FormEvent, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  StyledHeaderContainer,
  StyledHeaderText,
  StyledInputContainer,
  StyledMainContainer,
  StyledPageContainer
} from '../../components/ui'
import { StyledConfirmType } from '../../components/ui'
import Button from '../../components/ui/Button/Button'
import { toast } from 'react-toastify'
import { auth } from '../../config/config'
import Logo from '../../components/assets/images/logo'
import InputField from '../../components/ui/InputField/InputField'
import { useDispatch } from 'react-redux'
import {
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithPopup
} from 'firebase/auth'
import { checkAuth } from '../../store/reducers/authReducerSlice'
import { AppDispatch } from '../../store'
import GoogleSVG from '../../components/assets/images/googleIcon.svg'
import { StyledHeaderText2 } from './GetAuthPage'
import axios from 'axios'
import { nest } from '../../utils/axios'

type AuthProps = React.FC<{ isFromAIPage?: boolean }>

// ----------- Auth Requiring Page ---------------
const Auth: AuthProps = ({ isFromAIPage = false }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { teamId, receiptId } = useParams()
  const location = useLocation()
  const [continueWithEmail, setContinueWithEmail] = useState<boolean>(false)
  const [formSent, setFormSent] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [resent, setResent] = useState<boolean>(false)

  const invitationPage = location.pathname.includes('/invitations')

  // send login link to user email
  const handleLogin = async (e: FormEvent | null, resend?: boolean | null) => {
    if (e) {
      e.preventDefault()
    }
    if (loading) return

    const prevEmail = localStorage.getItem('loginEmail')
    const emailToUse = resend ? prevEmail : email

    if (resend && !prevEmail) {
      setLoading(false)
      setContinueWithEmail(true)
      setEmail('')
      setFormSent(false)
      setResent(false)
      return
    }

    if (!emailToUse) {
      toast.error('Please enter your email address')
      return
    }

    setLoading(true)
    try {
      const otherQueries = invitationPage
        ? `&redirectTo=invitations&teamId=${teamId}&receiptId=${receiptId}`
        : ''

      const response = await axios(
        `${import.meta.env.VITE_APP_SERVER_URL}/auth/sendEmail?email=${emailToUse}${otherQueries}`
      )
      if (response.status !== 200) {
        toast.error('Error sending email: ' + response.statusText)
        return
      }

      setFormSent(true)
      setLoading(false)
      setEmail('')
      setResent(false)
      window.localStorage.setItem('loginEmail', emailToUse)
      if (resend) {
        setResent(true)
      }
      toast.success(
        resend
          ? 'Email resent successfully!'
          : 'Email with login link sent successfully!'
      )
    } catch (error: any) {
      toast.error(
        'Error sending email: ' + (error?.response?.data?.message || '')
      )
      setLoading(false)
    }
  }

  // Google login
  const sigInWithGoogle = async () => {
    if (loading) return
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user
        const additionalUserInfo = getAdditionalUserInfo(result)
        const isNewUser = additionalUserInfo?.isNewUser
        if (isNewUser) {
          try {
            await nest.post('auth/register', user, {
              params: { isFromAiPage: isFromAIPage }
            })
            dispatch(checkAuth())
          } catch (error: any) {
            console.log(error)
            toast.error(error.message || 'Something went wrong!')
          }
        } else {
          try {
            const response = await nest.post('auth/login', user)
            console.log(response)
            dispatch(checkAuth())
          } catch (error: any) {
            console.log(error)
            toast.error(error.message || 'Something went wrong!')
          }
        }
      })
      .catch((error: any) => {
        if (
          error.code !== 'auth/cancelled-popup-request' &&
          error.code !== 'auth/popup-closed-by-user'
        ) {
          toast.error(error.message)
        }
        console.log(error)
      })
  }

  // JSX
  if (formSent) {
    return (
      <StyledMainContainer>
        <StyledPageContainer style={{ margin: 'auto' }}>
          <StyledHeaderContainer style={{ alignItems: 'flex-start' }}>
            <Logo />
            <StyledHeaderText2
              style={{ textAlign: 'left', width: '100%', marginTop: '25px' }}
            >
              Magic Awaits! 📩
            </StyledHeaderText2>
            <div style={{ width: '100%', marginTop: '20px' }}>
              <StyledConfirmType
                style={{ textAlign: 'left', fontSize: '21px' }}
              >
                We’ve sent a magic link to{' '}
                <b>{localStorage?.getItem('loginEmail') || ' - '}</b>. Please{' '}
                check it out.
              </StyledConfirmType>
              {resent ? null : (
                <StyledConfirmType
                  style={{
                    textAlign: 'left',
                    fontSize: '16px',
                    alignItems: 'center',
                    marginTop: '15px',
                    gap: '4px'
                  }}
                  className="flex"
                >
                  Can’t find it? Check Spam or
                  <Button
                    disabled={loading}
                    onClick={() => handleLogin(null, true)}
                    variant="tertiary"
                    color="primary"
                    style={{ padding: '0' }}
                    size="medium"
                  >
                    Resend Link.
                  </Button>
                </StyledConfirmType>
              )}
              <Button
                onClick={() => {
                  setContinueWithEmail(true)
                  setFormSent(false)
                }}
                fullWidth
                variant="secondary"
                style={{ marginTop: '20px', textTransform: 'uppercase' }}
              >
                Use another account
              </Button>
            </div>
          </StyledHeaderContainer>
        </StyledPageContainer>
      </StyledMainContainer>
    )
  }
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <StyledHeaderContainer>
          <Logo />
          <StyledHeaderText>Log in</StyledHeaderText>
        </StyledHeaderContainer>
        <StyledInputContainer>
          <Button
            fullWidth
            disabled={loading}
            onClick={sigInWithGoogle}
            customIcon={<img src={GoogleSVG} alt="google" />}
            variant="secondary"
          >
            CONTINUE WITH GOOGLE
          </Button>
          {continueWithEmail ? (
            <form
              onSubmit={handleLogin}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: '12px',
                marginTop: '8px',
                padding: '0 0 0 4px'
              }}
            >
              <InputField
                type="email"
                name="email"
                label="Type your email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  loading ? null : setEmail(e.target.value)
                }
                autoFocus
                disabled={loading}
                fullWidth
                required
              />
              <Button
                label="Send Link"
                disabled={loading}
                type="submit"
                variant="secondary"
              />
            </form>
          ) : (
            <Button
              label="CONTINUE WITH EMAIL"
              fullWidth
              disabled={loading}
              onClick={() => setContinueWithEmail(true)}
              icon="mail"
              variant="secondary"
            />
          )}
        </StyledInputContainer>
        <StyledConfirmType>
          By proceeding you agree to the{' '}
          <Link
            to="https://docs.compfeed.com/legal/terms_and_conditions"
            target="_blank"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            to="https://docs.compfeed.com/legal/privacy_policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          . We value your trust and ensure a safe, transparent experience.
        </StyledConfirmType>
      </StyledPageContainer>
    </StyledMainContainer>
  )
}

export default Auth
