import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRecentlyViewedTemplateIds } from '../store/reducers/templateReducerSlice';

const useSetRecentlyViewedTemplate = (id: string) => {
    const dispatch = useDispatch();
    // add the current template to the top of the recently viewed list
    useEffect(() => {
        let existingEntries = JSON.parse(localStorage.getItem('recent_template_ids') ?? "[]") || [];

        // remove the current entry if it exists
        existingEntries = existingEntries.filter((entry: { id: string }) => entry.id !== id);

        const updatedEntries = [{ id }, ...existingEntries];

        // keep only the last 4 entries
        const recentlyViewedComponents = updatedEntries.slice(0, 4);

        localStorage.setItem('recent_template_ids', JSON.stringify(recentlyViewedComponents))
        dispatch(setRecentlyViewedTemplateIds(recentlyViewedComponents))
    }, [id, dispatch])

}

export default useSetRecentlyViewedTemplate;