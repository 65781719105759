import { createSlice } from '@reduxjs/toolkit'

export type ComponentTypes = 'component' | 'project'

export interface LinkObj {
  id: string
  fileKey?: string
  url: string
  title?: string
  nodeId?: string
  createdAt: string | Date
  updatedAt: string | Date
  platform?: string // figma, github, google
  type?: string // design, board, proto etc...
}

export interface ComponentObj {
  id: string
  teamId?: string
  templateId?: string
  pageId?: string
  fileId?: string
  title?: string
  type?: ComponentTypes
  activePhase: string
  completedTasks?: string[]
  linkedElement?: {
    id?: string
    name?: string | undefined
    type?: string | undefined
  }
  description?: string
  createdAt?: string | Date | undefined | null
  updatedAt?: string | Date | undefined | null
  createdBy?: string
  isArchived?: boolean
  isDeleted?: boolean
  links?: LinkObj[]
}

interface ComponentState {
  data: ComponentObj[]
  isLoading: boolean
  recentlyViewedComponentIds: Array<{ id: string; pageId: string }>
}

//
const initialState: ComponentState = {
  data: [],
  recentlyViewedComponentIds: [
    {
      id: '',
      pageId: ''
    }
  ],
  isLoading: true
}

//
export const componentReducerSlice = createSlice({
  name: 'componentSlice',
  initialState,
  reducers: {
    setComponentSlice: (state, action) => {
      state.data = action.payload.data as ComponentObj[]
      state.isLoading = action.payload.isLoading
    },
    setComponentsData: (state, action) => {
      state.data = action.payload.data
    },
    resetComponents: (state) => {
      state.data = initialState.data
      state.isLoading = false
    },
    setRecentlyViewedComponentIds: (state, action) => {
      state.recentlyViewedComponentIds = action.payload
    }
  },
  extraReducers: (builder) => {}
})

export const {
  setComponentSlice,
  setComponentsData,
  resetComponents,
  setRecentlyViewedComponentIds
} = componentReducerSlice.actions

export default componentReducerSlice.reducer
