import { useContext } from "react";
import styled from "styled-components";
import { DrawerContext } from "../../../context/DrawerContext";
import { theme } from "../../../styles";
import Logo from "../../assets/images/logo";
import TopNavItem from "../TopNavItem/TopNavItem";

const StyledTopNavWrapper = styled.div`
    height: 56px;
    padding:8px 16px;
    background: ${theme.colors.white};
    border-bottom: 1px ${theme.colors.actionDisabled} solid;
    justify-content: space-between;
    align-items: center;
    display: flex;
    `;

const StyledTopNavItemsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
`

const StyledTopNavLogoContainer = styled.div`
    display: flex;
    height: 48px;
    padding: 6.928px;
    justify-content: center;
    align-items: center;
    gap: 8.877px;
`

// const StyledWordMark = styled.div`
//     display: none;

//     @media screen and (min-width:438px )  and (max-width:834px){
//         display: block;
//     }
// `


const TopNav = () => {
    const { setIsDrawerOpen,setIsAccountMenuOpen} = useContext(DrawerContext)

    return (
        <>
            <StyledTopNavWrapper>
                <StyledTopNavLogoContainer>
                   <Logo width={38} /> 
                    {/* <StyledWordMark>
                        <img src="/favicon.svg" alt="logo" width={244} height={16} />
                    </StyledWordMark> */}
                </StyledTopNavLogoContainer>
                <StyledTopNavItemsContainer>
                    <TopNavItem type="user" onClick={() => {
                        setIsAccountMenuOpen(true)
                    }} />
                    <TopNavItem type="menu" onClick={() => {
                        setIsDrawerOpen(true)
                    }} />
                </StyledTopNavItemsContainer>
            </StyledTopNavWrapper>
        </>
    )
};

export default TopNav;
