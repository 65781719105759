
import styled from "styled-components";
import { theme } from "../../styles";

export const StyledCreateTemplateContainer = styled.section`
  width: 100%;
  background: ${theme.colors.white};
  overflow-y: auto;
  max-height: 100%;
`;

export const StyledCreateTemplateContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding: 32px 40px 16px 40px;
  background: ${theme.colors.white};
  margin-inline : auto;
  max-width: 832px;

@media screen and (max-width: 430px) {
    padding: 24px 16px 16px 16px;
  }
`

export const StyledCreateTemplateTitleContainer = styled.div`
    display: flex;
    /* padding: 40px 40px 16px 40px; */
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap:16px;
`
