import React, { useEffect } from 'react'
import { auth, db } from '../../../config/config'
import { get, ref } from 'firebase/database'
import Button from '../../../components/ui/Button/Button'
import { ConditionallyRender } from '../../../utils/ConditionallyRender'
import withAuth from '../hoc/withAuth'
import { useMutation } from '@tanstack/react-query'
import httpRequest from '../../../utils/fetcher'
import { nest } from '../../../utils/axios'
import { useNavigate } from 'react-router-dom'

const Choices = withAuth(
  () => {
    const [choices, setChoices] = React.useState<Record<string, any>[]>([])
    const [choice, setChoice] = React.useState<any>({})
    const [isLoading, setIsLoading] = React.useState(true)

    console.log('choices', choice)

    const navigate = useNavigate()

    const fetchChoices = async () => {
      try {
        const userId = auth.currentUser?.uid
        const choices = await get(ref(db, `ai-templates/${userId}`))
        console.log('choices', choices.val())
        if (choices.exists()) {
          setChoices(Object.values(choices.val()))
        }
      } catch (error) {
        console.error(error)
        setIsLoading(false)
        setChoices([])
      } finally {
        setIsLoading(false)
      }
    }

    const { isPending, error, mutateAsync } = useMutation({
      mutationFn: async (data) => {
        const token = await auth.currentUser?.getIdToken()
        return httpRequest({
          url: '/webpage-publication/import-webpage',
          method: 'POST',
          data: {
            data: data
          },
          headers: {
            Authorization: `Bearer ${token}`
          }
        })<{ id: string; slug: string }>(nest)
      }
    })

    const publish = async () => {
      try {
        const response = await mutateAsync(choice)
        if (response.slug) {
          navigate(`/published/${response.slug}`)
        }
      } catch (error: any) {
        console.error('Publish Error:', error.message)
      }
    }

    //   const { data, isLoading } = useQuery({
    //     queryKey: ['choices'],
    //     queryFn: fetchChoices
    //   })

    useEffect(() => {
      fetchChoices()
    }, [])

    if (isLoading) return <div>Loading...</div>

    return (
      <ConditionallyRender
        condition={!!choice?.id}
        show={
          <RenderChoice
            publish={publish}
            isPending={isPending}
            error={error?.message}
            {...choice}
          />
        }
        elseShow={
          <div
            style={{
              display: 'flex',
              gap: '3rem',
              width: '100%',
              alignItems: 'baseline',
              justifyContent: 'center'
            }}
          >
            {choices?.map((choice: any, index: number) => (
              <div key={choice.id}>
                <RenderChoice {...choice} />
                <Button
                  variant="secondary"
                  onClick={() => setChoice(choice)}
                  fullWidth
                >
                  Choose this template {index + 1}
                </Button>
              </div>
            ))}
          </div>
        }
      />
    )
  },
  { isProtected: true }
)

export default Choices

const RenderChoice = (choice: any) => {
  return (
    <div style={{ maxWidth: '70rem' }}>
      <div>
        <h3>{choice.title}</h3>
        <p>{choice.description}</p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginTop: '1rem'
        }}
      >
        {choice.workflow.map((work: any) => (
          <div
            style={{
              border: '1px solid #e0e0e0',
              padding: '1rem',
              borderRadius: '8px',
              marginBottom: '1rem'
            }}
          >
            <h4>{work.title}</h4>
            <p>{work.description}</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                marginTop: '1rem'
              }}
            >
              {work.tasks.map((task: any) => (
                <div
                  key={task.id}
                  style={{
                    marginLeft: '1rem'
                  }}
                >
                  <h5>{task.title}</h5>
                  <p
                    style={{
                      marginLeft: '1rem'
                    }}
                  >
                    {task.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <ConditionallyRender
        condition={!!choice.publish}
        show={
          <div>
            {choice.error && <p style={{ color: 'red' }}>{choice.error}</p>}
            <Button
              onClick={choice.publish && choice.publish}
              fullWidth
              loading={choice.isPending}
            >
              Publish
            </Button>
          </div>
        }
      />
    </div>
  )
}
