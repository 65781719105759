import React, { createContext } from 'react'
import { surveyData } from '../constants'

type MultiStepFormContextProps = {
  currentSection: any
  currentQuestion: any
  allQuestions: any[]
  currentQuestionIndex: number
  setCurrentQuestionIndex: (index: number) => void
}

export const MultiStepFormContext = createContext<
  MultiStepFormContextProps | undefined
>(undefined)

export const MultiStepForm: React.FC<{
  totalSections: number
  children: React.ReactNode
}> = ({ children }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0)

  const allQuestions = surveyData.survey.sections.flatMap(
    (section) => section.questions
  )
  const currentQuestion = allQuestions[currentQuestionIndex]
  const currentSection = surveyData.survey.sections.find((section) =>
    section.questions.some((question) => question.id === currentQuestion.id)
  )

  return (
    <MultiStepFormContext.Provider
      value={{
        currentSection,
        currentQuestion,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        allQuestions
      }}
    >
      {children}
    </MultiStepFormContext.Provider>
  )
}
