import styled from "styled-components";
import { theme } from "../../../styles";

const DetailViewBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    margin-inline: auto;
    width: 100%;
    max-width: 832px;
    gap:16px;

    background: ${theme.colors.white};

    @media screen and (min-width: 430px) {
    gap: 40px;
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.borderDefault};
  }
`

export default DetailViewBodyContainer