import { createSlice } from "@reduxjs/toolkit";

export interface TemplateObj {
  id: string
  teamId: string
  title: string
  description: string
  type?: string
  visible: boolean
  order?: number | undefined | null
  createdAt?: Date | string | undefined | null
  updatedAt?: Date | string | undefined | null
  createdBy: string
  colors: {[key: string]: TemplateColorObj}
}

export interface TemplateColorObj {
  bg: string; 
  text: string; 
  id: string;
  defaultColor?: boolean  
}

interface TemplateState {
  data: TemplateObj[]
  isLoading: boolean
  recentlyViewedTemplateIds: { id: string }[]
}

//
const initialState: TemplateState = {
  data: [
    {
      id: '',
      teamId: '',
      title: '',
      description: '',
      createdBy: '',
      visible: true,
      colors: {}
    }
  ],
  isLoading: true,
  recentlyViewedTemplateIds: [
    {
      id: ''
    }
  ]
}

//
export const templateReducerSlice = createSlice({
  name: 'templateSlice',
  initialState,
  reducers: {
    setTemplateSlice: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
    setTemplatesData: (state, action) => {
      state.data = action.payload.data
    },
    resetTemplates: (state) => {
      state.data = initialState.data
      state.isLoading = false
    },
    setRecentlyViewedTemplateIds: (state, action) => {
      state.recentlyViewedTemplateIds = action.payload
    }
  },
  extraReducers: (builder) => {}
})

export const {
  setTemplateSlice,
  setTemplatesData,
  resetTemplates,
  setRecentlyViewedTemplateIds
} = templateReducerSlice.actions

export default templateReducerSlice.reducer;