import styled, { css } from 'styled-components'
import Button from '../Button/Button'


const StyledColorManager = styled.div<{ isHidden?: boolean }>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px 8px;
  flex-wrap: wrap;

  ${({ isHidden, theme }) =>
        isHidden &&
        css`
      opacity: 0.2;
      border-bottom: 1px solid ${theme.colors.borderDefault};
      padding-bottom: 8px;
    `}
`

interface IColorManagerProps {
    onAddClick?: () => void
    children?: React.ReactNode
    isHidden?: boolean
}

const ColorManager = ({
    children,
    onAddClick,
    isHidden = false
}: IColorManagerProps) => {
    return (
        <StyledColorManager isHidden={isHidden}>
            {children}
            <Button
                variant="secondary"
                size="small"
                iconOnly
                icon="plus"
                onClick={onAddClick}
            />
        </StyledColorManager>
    )
}

export default ColorManager