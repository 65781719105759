import styled from "styled-components"
import { theme } from "../../../styles"

export const StyledAddItemContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  max-width: 359px;
  padding: 16px;
  border-radius: 8px;
  background: ${theme.colors.surfaceNormalLightActive};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;

  @media screen and (min-width: 430px) {
    max-width: 752px;
    gap: 40px;
  }

  ${({ disabled }) =>
        disabled &&
        `
        background: ${theme.colors.white};
    `}
`

export const StyledAddItemHeader = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  cursor: pointer;
`

export const StyledAddItemTitle = styled.p<{ disabled?: boolean }>`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;

  ${({ disabled }) =>
        disabled &&
        `
        color: ${theme.colors.actionDisabled};
    `}
`

export const StyledAddItemContent = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  max-height: auto;
  height: auto;
  position: relative;
  width: 100%;

  ${({ collapsed }) =>
        collapsed &&
        `
        max-height: 0;
        overflow: hidden;
        position: absolute;
    `}
`