import styled from 'styled-components'
import IllustrationComponent, {
  IIllustrationType
} from '../IllustrationComponent/IllustrationComponent'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import ButtonGroup from '../ButtonGroup/ButtonGroup'

interface IEmptyStateProps {
  showActions?: boolean
  body?: string
  showBody?: boolean
  title?: string
  showTitle?: boolean
  showIllustration?: boolean
  illustrationType?: IIllustrationType
  buttonLabel1?: string
  buttonLabel2?: string
  buttonOnClick1?: () => void
  buttonOnClick2?: () => void
}

const StyledEmptyStateContainer = styled.div`
  display: flex;
  border-radius: 16px;
  border: ${({ theme }) => `2px solid ${theme.colors.borderDefault}`};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  // max-width: 358px;
  gap: 32px;

  @media screen and (min-width: 430px) {
    padding: 40px 70px;
    // max-width: 736px;
    gap: 48px;
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const StyledTitle = styled.h2`
  align-self: stretch;
  color: ${({ theme }) => theme.colors.copy};
  font-family: 'rooney';
  font-size: ${({ theme }) => theme.font.sizes.header3};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: left;

  @media screen and (min-width: 430px) {
    font-size: ${({ theme }) => theme.font.sizes.header2};
    text-align: center;
  }
`

const StyledBody = styled.p`
  align-self: stretch;
  color: ${({ theme }) => theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: left;

  @media screen and (min-width: 430px) {
    font-size: ${({ theme }) => theme.font.sizes.body};
    text-align: center;
  }
`

const EmptyState = ({
  showActions = true,
  title = 'There are no \ncomponents here yet',
  showIllustration = true,
  showTitle = true,
  showBody = true,
  body = 'To add components to Compfeed, add the Figma widget to your project.',
  illustrationType = 'widget',
  buttonLabel1,
  buttonLabel2,
  buttonOnClick1,
  buttonOnClick2
}: IEmptyStateProps) => {
  const isMobile = useIsMobileDevice()

  return (
    <StyledEmptyStateContainer>
      <StyledContentContainer>
        {showIllustration && (
          <IllustrationComponent
            size={isMobile ? 'm' : 'l'}
            type={illustrationType}
          />
        )}
        <StyledTitleContainer>
          {showTitle && <StyledTitle>{title}</StyledTitle>}
          {showBody && <StyledBody>{body}</StyledBody>}
        </StyledTitleContainer>
      </StyledContentContainer>
      {showActions && (
        <ButtonGroup
          buttonLabel1={buttonLabel1}
          buttonLabel2={buttonLabel2}
          buttonOnClick1={buttonOnClick1}
          buttonOnClick2={buttonOnClick2}
        />
      )}
    </StyledEmptyStateContainer>
  )
}

export default EmptyState
