import styled from 'styled-components';
import Layout from '../../Layout/Layout';
import SubNav from '../../ui/SubNav/SubNav';
import TeamAndUserDetailBodyContainer from './TeamAndUserDetailBodyContainer';
import { theme } from '../../../styles';
import TopNavItem from '../../ui/TopNavItem/TopNavItem';
import Label from '../../ui/Label/Label';
import Button from '../../ui/Button/Button';

export const StyledTeamsAndUsersDetailMainContainer = styled.div`
  width: 100%;
  overflow: auto;
  background: ${theme.colors.backgroundLight};
  max-height: 100%;
  @media screen and (max-width: 834px) {
    // min-height: 100%;
    flex-grow: 1;
  }
`;

export const StyledTeamsAndUsersOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding-bottom:24px;


  @media screen and (min-width:430px) {
    border-bottom: 1px solid ${theme.colors.borderDefault};
  }

`;

export const StyledTeamsAndUsersOptionTitle = styled.p`
  color: ${theme.colors.copy};
  font-family: "rooney-sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;




export const StyledUserItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const StyledUserItem = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const StyledUserEmail = styled.p`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: "rooney-sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
`;


const TeamAndUserDetail = () => {
    return (
        <Layout>
            <StyledTeamsAndUsersDetailMainContainer>
                <SubNav title='Teams & Users' />
                <TeamAndUserDetailBodyContainer>
                    <StyledTeamsAndUsersOptionsContainer>
                        <StyledTeamsAndUsersOptionTitle>
                            Users
                        </StyledTeamsAndUsersOptionTitle>
                        <StyledUserItemsContainer>
                            <StyledUserItem>
                                <TopNavItem type="user" />
                                <StyledUserEmail>john.doe@apple.com</StyledUserEmail>
                                <Label text="editor" type="disabled" />
                            </StyledUserItem>
                        </StyledUserItemsContainer>
                        <Button label="Make Admin" variant="secondary" />
                    </StyledTeamsAndUsersOptionsContainer>
                    <Button variant='danger' style={{
                        alignSelf: "center",
                        marginTop:"20vh"
                    }}>Delete User</Button>

                </TeamAndUserDetailBodyContainer>
            </StyledTeamsAndUsersDetailMainContainer>

        </Layout>
    )
}

export default TeamAndUserDetail