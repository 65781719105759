import React from 'react'
import {
  StyledComponentsTitle,
  StyledListHeaderContainer
} from '../../components/screens/Lister/ListHeader'
import { NavLink } from 'react-router-dom'
import Button from '../../components/ui/Button/Button'

interface ComponentProps {
  componentDetails?: string | boolean
}

const ProjectsHeader: React.FC<ComponentProps> = () => {
  return (
    <StyledListHeaderContainer>
      <StyledComponentsTitle>Projects</StyledComponentsTitle>
      <NavLink to="/dashboard/projects/create">
        <Button label="ADD NEW PROJECT" variant="secondary" icon="plus"  />
      </NavLink>
    </StyledListHeaderContainer>
  )
}

export default ProjectsHeader