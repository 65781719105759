import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { NavLink } from 'react-router-dom'
import {
  StyledConfirmType,
  StyledMainContainer,
  StyledPageContainer
} from '../../components/ui'
import { StyledHeaderText2 } from './GetAuthPage'
import Button from '../../components/ui/Button/Button'
import Logo from '../../components/assets/images/logo'
import { LinkOff } from '../../components/assets/icons/icons'

const WidgetAuthFailed: React.FC = () => {
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <div
          className="flex"
          style={{
            width: '100%',
            padding: '28px 0 18px 0',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px'
          }}
        >
          <FeatherIcon icon="figma" size={90} style={{ flexShrink: 0 }} />
          <LinkOff style={{ flexShrink: 0 }} />
          <Logo width={92} />
        </div>
        <StyledHeaderText2>Connection Fail</StyledHeaderText2>
        <StyledConfirmType>
          Something went wrong. We’re having trouble connecting to Figma. Please
          try again.
        </StyledConfirmType>
        <div style={{ width: '100%' }}>
          <NavLink
            to="/dashboard"
            replace
            style={{ display: 'block', width: '100%' }}
          >
            <Button
              fullWidth
              variant="primary"
              style={{ marginTop: '15px' }}
            >
              VIEW DASHBOARD
            </Button>
          </NavLink>
        </div>
        <StyledConfirmType>
          <a href={import.meta.env.VITE_APP_HELP_URL} target="_blank">
            Need help?
          </a>
        </StyledConfirmType>
      </StyledPageContainer>
    </StyledMainContainer>
  )
}

export default WidgetAuthFailed
