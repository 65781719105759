import CheckSvg from "./checkSvg";
import ClapSvg from "./clapSvg";
import GovernSvg from "./governSvg";
import HighFiveSvg from "./highFiveSvg";
import WidgetSvg from "./widgetSvg";

const ILLUSTRATIONS = {
  check: CheckSvg,
  clap: ClapSvg,
  govern: GovernSvg,
  highfive: HighFiveSvg,
  widget: WidgetSvg,
};
export default ILLUSTRATIONS;
