import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ref, remove, update } from 'firebase/database'
import { db } from '../../../config/config'
import { useSelector, useDispatch } from 'react-redux'
import { setPhasesData } from '../../../store/reducers/phaseReducerSlice'
import { theme } from '../../../styles'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import { toast } from 'react-toastify'
import { DraggableProvided } from 'react-beautiful-dnd/'
import { RootState } from '../../../store'
import { getTimeStamp } from '../../../utils/helpers'
import useGetCurrentPhases from '../../../hooks/useGetCurrentPhases'
import PopOverMenu from '../PopOverMenu/PopOverMenu'
import DragIcon from '../../assets/icons/DragIcon'
import Button from '../Button/Button'
import { MenuBottomSheet } from '../BottomSheet/BottomSheet'
import EditTask from '../EditTask/EditTask'
import { TaskMenuItems, ArchivedItemMenuItems } from '../MenuItems/MenuItems'
import Modal from '../Modal/Modal'
import { TaskDeleteContent } from '../Modal/ModalSlotContents'

interface IEditPhaseItemProps {
  disabled?: boolean
  dragged?: boolean
  lastItem?: boolean
  visible: boolean
  phaseItemName: string
  width?: string
  dragProps?: DraggableProvided
  isAnyTaskDragging?: boolean
  setIsAnyTaskDragging?: (isDragging: boolean) => void
  templateId?: string
  phaseId?: string
  taskId?: string
  setIsTaskEditModeOnCheck?: (isEditModeOn: boolean) => void
  taskState?: 'archived' | 'default'
}

export const StyledEditPhaseItem = styled.div<
  Omit<IEditPhaseItemProps, 'phaseItemName'>
>`
  display: flex;
  width: ${({ width }) => width};
  max-width: 100%;
  padding: 8px 0px;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease;
  border-radius: 4px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${theme.colors.actionPrimaryLightHover};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      & > div > p {
        color: ${theme.colors.actionDisabled};
      }
    `}
  ${({ dragged }) =>
    dragged &&
    `
        outline:1px solid ${theme.colors.actionPrimaryActive};
        background-color: ${theme.colors.actionPrimaryLightHover};
    `}

  ${({ visible }) =>
    !visible &&
    css`
      & > div > p {
        color: ${theme.colors.actionDisabled};
      }
    `}
`

export const StyledRightActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  overflow: hidden;
`

export const StyledPhaseItemName = styled.p<Pick<IEditPhaseItemProps, 'visible'>>`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 380;
  line-height: 140%;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.large};
    font-style: normal;
    line-height: 140%;
  }

  ${({ visible }) =>
    !visible &&
    css`
      color: ${theme.colors.actionDisabled};
    `}
`

const EditPhaseItem = ({
  disabled = false,
  visible = true,
  dragged = false,
  lastItem = false,
  phaseItemName = '',
  width = '100%',
  dragProps,
  isAnyTaskDragging,
  setIsAnyTaskDragging,
  templateId,
  phaseId,
  taskId,
  setIsTaskEditModeOnCheck,
  taskState = 'default'
}: IEditPhaseItemProps) => {
  const isMobile = useIsMobileDevice()
  const [inProgress, setInProgress] = useState(false)
  const [isEditModeOn, setIsEditModeOn] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()
  const { data: allPhases } = useSelector((store: RootState) => store.phase)

  const { phases } = useGetCurrentPhases(allPhases, templateId)

  const taskRef = ref(db, `phases/${templateId}/${phaseId}/tasks/${taskId}`)

  // if any task component is dragged, set the state of the parent component to true
  useEffect(() => {
    if (setIsAnyTaskDragging) {
      setIsAnyTaskDragging(dragged)
    }
  }, [dragged, setIsAnyTaskDragging])

  // if the edit mode is on, set the state of the parent component to true to hide add task component
  useEffect(() => {
    setIsTaskEditModeOnCheck && setIsTaskEditModeOnCheck(isEditModeOn)
  }, [isEditModeOn, setIsTaskEditModeOnCheck])

  const handleToggleArchiveTasks = async (action: 'archive' | 'unarchive') => {
    if (inProgress || !templateId || !phaseId || !taskId) return

    // check if it is the last task in the phase
    const phase = phases?.find((p) => p?.id === phaseId)
    const tasks = phase?.tasks?.filter((task) => !task?.archived) || [] // get all un-archived tasks

    // only apply this check for tasks with default (un-archived) state
    if (taskState === "default" && tasks?.length === 1) {
      toast.error('You can not archive the last task in a phase.')
      return
    }

    setInProgress(true)
    const timestamp = getTimeStamp()
    try {
      if (action === 'archive') {
        await update(taskRef, {
          archived: true,
          updatedAt: timestamp
        })

        dispatch(
          setPhasesData({
            data: allPhases?.map((phase) => {
              if (phase?.id === phaseId) {
                const prevTask = phase?.tasks || []
                return {
                  ...phase,
                  tasks: prevTask?.map((task) => {
                    if (task.id === taskId) {
                      return {
                        ...task,
                        archived: true
                      }
                    }
                    return task
                  })
                }
              }
              return phase
            })
          })
        )

        toast.success('Task archived successfully.')
      } else if (action === 'unarchive') {
        await update(taskRef, {
          archived: false,
          updatedAt: timestamp
        })

        dispatch(
          setPhasesData({
            data: allPhases?.map((phase) => {
              if (phase?.id === phaseId) {
                const prevTask = phase?.tasks || []
                return {
                  ...phase,
                  tasks: prevTask?.map((task) => {
                    if (task.id === taskId) {
                      return {
                        ...task,
                        archived: false
                      }
                    }
                    return task
                  })
                }
              }
              return phase
            })
          })
        )

        toast.success('Task un-archived successfully.')
      }
    } catch (error) {
      console.log('Error archiving the task', error)
      toast.error('Something went wrong.')
    } finally {
      setInProgress(false)
    }
  }

  // Delete task from the phase
  const handleRemoveTask = async () => {
    if (!templateId || !phaseId || !taskId || inProgress) return

    // check if it is the last un-archived task in the phase
    const phase = phases?.find((p) => p?.id === phaseId)
    const tasks = phase?.tasks?.filter((task) => !task?.archived) || [] // get all un-archived tasks

    // only apply this check for tasks with default (un-archived) state
    if (taskState === "default" && tasks?.length === 1) {
      toast.error('You can not delete the last task in a phase.')
      return
    }

    setInProgress(true)
    try {
      await remove(taskRef)
      dispatch(
        setPhasesData({
          data: allPhases?.map((phase) => {
            if (phase?.id === phaseId) {
              const prevTask = phase?.tasks || []
              return {
                ...phase,
                tasks: prevTask?.filter((task) => task?.id !== taskId)
              }
            }
            return phase
          })
        })
      )
      toast.success('Task is removed.')
    } catch (error) {
      toast.error('Something went wrong.')
      console.log(
        'Error while deleting a task from the phase task list.',
        error
      )
    } finally {
      setInProgress(false)
    }
  }

  const taskActionHandlers = {
    handleEdit: () => setIsEditModeOn(true),
    handleArchive: () => handleToggleArchiveTasks('archive'),
    handleRemove: () => setShowModal(true)
  }

  const archivedTaskActionHandlers = {
    handleUnArchive: () => handleToggleArchiveTasks('unarchive'),
    handleRemove: () => setShowModal(true)
  }

  return (
    <>
      {isEditModeOn ? (
        <EditTask
          templateId={templateId}
          phaseId={phaseId}
          taskId={taskId}
          collapsed={false}
          setIsEditModeOn={setIsEditModeOn}
          taskTitle={phaseItemName}
          isAnyTaskDragging={isAnyTaskDragging}
        />
      ) : (
        <StyledEditPhaseItem
          visible={visible}
          dragged={dragged}
          lastItem={lastItem}
          width={width}
          disabled={disabled}
        >
          <StyledRightActions>
            <Button
              disabled={disabled}
              iconOnly
              variant="tertiary"
              size="small"
              customIcon={
                <DragIcon
                  color={
                    disabled
                      ? theme.colors.actionDisabled
                      : theme.colors.actionTertiary
                  }
                />
              }
              {...dragProps?.dragHandleProps}
            />
            <StyledPhaseItemName visible={visible}>
              {phaseItemName}
            </StyledPhaseItemName>
          </StyledRightActions>

          {isMobile ? (
            <MenuBottomSheet
              disabled={disabled}
              title="Options"
              menuItems={
                taskState === 'archived' ? (
                  <ArchivedItemMenuItems
                    actionHandlers={archivedTaskActionHandlers}
                  />
                ) : (
                  <TaskMenuItems
                    actionHandlers={taskActionHandlers}
                    path={`/dashboard/templates/${templateId}/${phaseId}/edit/${taskId}`}
                  />
                )
              }
            />
          ) : (
            <PopOverMenu
              disabled={disabled}
              menuItems={
                taskState === 'archived' ? (
                  <ArchivedItemMenuItems
                    actionHandlers={archivedTaskActionHandlers}
                  />
                ) : (
                  <TaskMenuItems
                    actionHandlers={taskActionHandlers}
                    path={`/dashboard/templates/${templateId}/${phaseId}/edit/${taskId}`}
                  />
                )
              }
            />
          )}
        </StyledEditPhaseItem>
      )}
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          signal="alert-triangle"
          signalIconType="warning"
          title="Remove Task"
          slotContent={<TaskDeleteContent />}
          buttonLabel1="REMOVE TASK"
          buttonLabel2="CANCEL"
          buttonVariant1="danger"
          buttonVariant2="tertiary"
          buttonOnClick1={handleRemoveTask}
        />
      )}
    </>
  )
}

export default EditPhaseItem
