import { styled } from 'styled-components'
import { theme } from '../../../styles'
import Button from '../../ui/Button/Button'
import PhaseStatus from '../../ui/PhaseStatus/PhaseStatus'
import UpdateProject from './UpdateProject'
import { StyledRow } from '../../../pages/Team'
import moment from 'moment'
import ReactTimeago from 'react-timeago'
import { HTMLAttributes, useRef, useState } from 'react'
import {
  StyledPhaseContentContainer,
  StyledTaskContainer,
  StyledTemplatesPhaseContainer
} from '../../ui/PhaseComponent/PhaseComponent'
import {
  ComponentObj,
  ComponentTypes,
  LinkObj
} from '../../../store/reducers/componentsReducerSlice'
import {
  StyledEditPhaseItem,
  StyledPhaseItemName,
  StyledRightActions
} from '../../ui/EditPhaseItem/EditPhaseItem'
import {
  extractDetailFromLink,
  getFigmaFileTypes,
  getTimeStamp,
  isValidFigmaURL
} from '../../../utils/helpers'
import FeatherIcon from 'feather-icons-react'
import PopOverMenu from '../../ui/PopOverMenu/PopOverMenu'
import MenuItem from '../../ui/MenuItem/MenuItem'
import Banner from '../../ui/Banner/Banner'
import useLocalStorage from '../../../hooks/useLocalStorage'
import CircularProgress from '../../ui/CircularProgress/CircularProgress'
import { StyledAddItemTitle } from '../../ui/AddItem/StyledAddItem'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { fadeUp } from '../../../pages/CreateProject'
import AddItem from '../../ui/AddItem/AddItem'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { ref, update } from 'firebase/database'
import { db } from '../../../config/config'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { setProjectsData } from '../../../store/reducers/projectsReducerSlice'

const StyledDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`

const StyledComponentNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const StyledCountContainer = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
  gap: 9px;
  font-weight: 420;
  text-transform: uppercase;
`

const StyledText = styled.p`
  color: ${theme.colors.copySubtle};
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
  text-transform: uppercase;
`

const StyledHeaderComponentName = styled.h3`
  color: ${theme.colors.copy};
  font-size: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'rooney';
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  flex-grow: 1;
  max-width: 100%;

  @media screen and (min-width: 430px) {
    font-size: 48px;
  }
`

const StyledHeaderNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 430px) {
    justify-content: flex-start;
    align-items: start;
  }
`

export interface ICardProps extends HTMLAttributes<HTMLDivElement> {
  tasksCompleted: number
  tasksTotal: number
  componentName: string
  phaseTitle?: string
  fullWidth?: boolean
  disabled?: boolean
  figmaFileUrl?: string
  bgColor?: string
  textColor?: string
  componentId?: string
  updatedAt?: string | Date
  componentType?: ComponentTypes
  removeFile?: (linkId: string) => void
  details: ComponentObj
  setDetails?: (details: ComponentObj) => void
}

type IDetailHeaderProps = Omit<ICardProps, 'fullWidth' | 'disabled'>

const ProjectDetailHeader = ({
  tasksCompleted: completedTasks,
  tasksTotal: totalTasks,
  componentName,
  phaseTitle,
  figmaFileUrl,
  bgColor,
  textColor,
  componentId,
  componentType = 'component',
  updatedAt,
  removeFile = () => {},
  details,
  setDetails
}: IDetailHeaderProps) => {
  const dispatch = useDispatch()
  const linksContainerRef = useRef<HTMLDivElement>(null)
  const [showFiles, setShowFiles] = useLocalStorage('showProjectFiles', false)
  const [showInfoBanner, setShowInfoBanner] = useLocalStorage(
    'showProjectChecksInfoBanner',
    true
  )
  const projectLinks: LinkObj[] = details?.links || ([] as LinkObj[])
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const { data: projects } = useSelector((store: RootState) => store.project)
  const [saving, setSaving] = useState(false)

  //
  const addNewLink = async (newLink: string, clearFormData?: () => void) => {
    if (!newLink?.trim() || saving) return

    if (!isValidFigmaURL(newLink)) {
      toast.info('Invalid Figma or FigJam URL.')
      return
    }
    const info = extractDetailFromLink(newLink)
    if (!info?.fileKey) {
      toast.info('Incomplete URL, File key not found.')
      return
    }

    // Check if a link with the same fileKey exists
    const existingNodeId = details?.links?.some(
      (link) =>
        link?.fileKey === info?.fileKey &&
        link?.nodeId &&
        link?.nodeId?.length > 0 &&
        link?.nodeId === info?.nodeId
    )

    const existingFileWithoutNodeId = details?.links?.some(
      (link) =>
        link?.fileKey === info?.fileKey && !link?.nodeId && !info?.nodeId
    )

    if (existingNodeId || existingFileWithoutNodeId) {
      toast.info('This exact link already exists.')
      return
    }

    const uniqueId = uuidv4()
    const currentTime = getTimeStamp()

    setSaving(true)
    const newLinkObj: LinkObj = {
      id: uniqueId,
      fileKey: info?.fileKey || '',
      title: info?.title || '',
      nodeId: info?.nodeId || '',
      url: newLink || '',
      type: info?.type || '',
      platform: 'figma',
      createdAt: currentTime,
      updatedAt: currentTime
    }
    let updates: LinkObj[] = projectLinks
      ? [...projectLinks, newLinkObj]
      : [newLinkObj]

    dispatch(
      setProjectsData({
        data: projects?.map((proj) => {
          if (proj?.id === details?.id) {
            if (setDetails) {
              setDetails({
                ...(details || {}),
                updatedAt: currentTime,
                links: updates
              })
            }
            return {
              ...proj,
              links: updates
            }
          }
          return proj
        })
      })
    )

    linksContainerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })

    if (clearFormData) {
      clearFormData()
    }

    try {
      await update(ref(db, `components/${currentTeam?.id}/${details?.id}`), {
        links: updates,
        updatedAt: currentTime
      })
    } catch (err) {
      toast.error('Couldn’t save the link.')
      console.log('Error while adding a file to the project', err)
      dispatch(
        setProjectsData({
          data: projects?.map((proj) => {
            if (proj?.id === details?.id) {
              let updatedLinks = [...(proj?.links || [])]?.filter(
                (v) => v?.id !== newLinkObj?.id
              )
              if (setDetails) {
                setDetails({
                  ...(details || {}),
                  updatedAt: currentTime,
                  links: updatedLinks
                })
              }
              return {
                ...proj,
                links: updatedLinks
              }
            }
            return proj
          })
        })
      )
    } finally {
      setSaving(false)
    }
  }

  // JSX
  return (
    <StyledDetailHeader>
      <StyledComponentNameContainer>
        <StyledHeaderNameContainer>
          <StyledHeaderComponentName>{componentName}</StyledHeaderComponentName>
          <UpdateProject
            width="max-content"
            componentId={componentId}
            componentType={componentType}
          />
        </StyledHeaderNameContainer>
        <StyledRow
          style={{
            justifyContent: 'flex-start',
            gap: '16px'
          }}
        >
          <PhaseStatus
            phaseTitle={phaseTitle || 'NO STATUS'}
            bgColor={bgColor}
            textColor={textColor}
            isClickable={false}
            isActive={true}
            fullWidth={false}
            noStatus={!phaseTitle}
          />
          <StyledCountContainer>
            <CircularProgress
              maxValue={100}
              percent={(completedTasks / totalTasks) * 100}
              noBg
              size={28}
              positive
              // positive={((completedTasks / totalTasks) * 100) >= 50}
            />
            <span>
              {completedTasks}/{totalTasks}
              <span> Tasks</span>
            </span>
          </StyledCountContainer>
          <StyledText>
            Latest update at {updatedAt && moment(updatedAt)?.format('hh:mm')},{' '}
            {updatedAt && <ReactTimeago date={updatedAt || ''} />}
          </StyledText>
          <Button
            label={`${showFiles ? `HIDE` : `SHOW`} FILES`}
            variant="utility"
            size="xs"
            iconPosition="after"
            icon={!showFiles ? 'chevron-down' : 'chevron-up'}
            onClick={() => setShowFiles(!showFiles)}
            style={{ width: 'fit-content', marginLeft: 'auto' }}
          />
        </StyledRow>
      </StyledComponentNameContainer>
      {showFiles && (
        <>
          {!projectLinks ||
            (projectLinks?.length === 0 && (
              <Banner
                body="There are no files linked to this project."
                type="callout"
                variant="icon"
                showIcon={true}
                showButton={false}
                showTitle={false}
              />
            ))}
          <StyledTemplatesPhaseContainer
            style={{
              gap: '0',
              padding: '0'
            }}
          >
            <StyledTemplatesPhaseContainer>
              <StyledPhaseContentContainer>
                {projectLinks?.length > 0 && (
                  <>
                    <StyledAddItemTitle>ASSOCIATED LINKS</StyledAddItemTitle>
                    <AnimatePresence>
                      {projectLinks?.map((link) => (
                        <motion.div
                          key={link.id}
                          variants={fadeUp}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          transition={{ duration: 0.3 }}
                          style={{ width: '100%' }}
                        >
                          <StyledTaskContainer>
                            <StyledEditPhaseItem visible={true} width={'100%'}>
                              <StyledRightActions
                                style={{
                                  alignItems: 'center',
                                  gap: '8px',
                                  paddingLeft: '8px'
                                }}
                              >
                                {link?.platform === 'figma' && (
                                  <>
                                    <FeatherIcon icon="figma" />
                                  </>
                                )}
                                <StyledPhaseItemName
                                  visible={true}
                                  style={{
                                    width: '100%',
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {link?.platform &&
                                    getFigmaFileTypes(link?.type) + ' - '}
                                  {(link?.title || '')?.slice(
                                    0,
                                    link?.nodeId ? 30 : 55
                                  )}
                                  {link?.nodeId &&
                                    ' - Node Id: ' + link?.nodeId}
                                </StyledPhaseItemName>
                              </StyledRightActions>
                              <PopOverMenu
                                menuItems={
                                  <MenuItem
                                    title="Remove"
                                    icon="trash"
                                    type="danger"
                                    onClick={() => removeFile(link?.id)}
                                  />
                                }
                              />
                            </StyledEditPhaseItem>
                          </StyledTaskContainer>
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </>
                )}
                <div ref={linksContainerRef} style={{ width: '100%' }}>
                  <AddItem
                    type="link"
                    inputPlaceholder="Add Figma or Figjam link"
                    collapsed={true}
                    style={{ width: '100%' }}
                    onSave={addNewLink}
                    btnDisabled={saving}
                    maxTitleLimit={999}
                    componentType="form"
                    index={1}
                  />
                </div>
              </StyledPhaseContentContainer>
            </StyledTemplatesPhaseContainer>
          </StyledTemplatesPhaseContainer>
        </>
      )}
      {showInfoBanner && (
        <Banner
          body="Tasks sync automatically with the widget in Figma or FigJam. You can also check off tasks directly on this page."
          type="information"
          variant="icon"
          showIcon={true}
          showButton={false}
          showTitle={false}
          isCloseable
          handleClose={() => setShowInfoBanner(false)}
        />
      )}
    </StyledDetailHeader>
  )
}

export default ProjectDetailHeader
