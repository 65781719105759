import styled from 'styled-components'
import MenuItem from '../MenuItem/MenuItem'
import { theme } from '../../../styles/theme'
import { useNavigate } from 'react-router-dom'

export interface IMenuItemsProps {
  menuType?: 'phase' | 'task'
  showSlot1?: boolean
  showSlot2?: boolean
  actionHandlers?: {
    handleSetInitialPhase?: () => void
    handleEdit?: () => void
    handleArchive?: () => void
    handleRemove?: () => void
    handleUnArchive?: () => void
  }
  closeMenu?: () => void
  path?: string
}

export const StyledMenuItems = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: transparent;
  border: none;
  width: 100%;
`

export const StyledSlot = styled.div<{ hasBorderBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    `
    border-bottom: 1px solid ${theme.colors.borderDefault};
     padding-bottom: 16px;`};
`

const MenuItems = ({
  showSlot1 = true,
  showSlot2 = true,
  menuType,
  actionHandlers,
  closeMenu
}: IMenuItemsProps) => {
  return (
    <StyledMenuItems>
      {showSlot1 && (
        <StyledSlot hasBorderBottom={showSlot1 && showSlot2}>
          {menuType === 'phase' && (
            <MenuItem
              title="Set Initial Phase"
              icon="star"
              type="primary"
              onClick={actionHandlers?.handleSetInitialPhase}
              closeMenu={closeMenu}
            />
          )}
          <MenuItem
            title="Edit"
            icon="edit-3"
            type="primary"
            onClick={actionHandlers?.handleEdit}
            closeMenu={closeMenu}
          />
        </StyledSlot>
      )}
      {showSlot2 && (
        <StyledSlot hasBorderBottom={false}>
          <MenuItem
            title="Archive"
            icon="folder"
            type="primary"
            onClick={actionHandlers?.handleArchive}
            closeMenu={closeMenu}
          />
          <MenuItem
            title="Remove"
            icon="trash"
            type="danger"
            onClick={actionHandlers?.handleRemove}
            closeMenu={closeMenu}
          />
        </StyledSlot>
      )}
    </StyledMenuItems>
  )
}

const AccountMenuItems = ({
  showSlot1 = true,
  showSlot2 = true
}: IMenuItemsProps) => {
  return (
    <StyledMenuItems>
      {showSlot1 && (
        <StyledSlot hasBorderBottom={showSlot1 && showSlot2}>
          <MenuItem title="My Account" icon="user" type="primary" disabled />
          <MenuItem title="Teams and users" icon="users" type="primary" />
        </StyledSlot>
      )}
      {showSlot2 && (
        <StyledSlot hasBorderBottom={false}>
          <MenuItem title="Log out" icon="log-out" type="danger" />
        </StyledSlot>
      )}
    </StyledMenuItems>
  )
}

//Menu Items for Phase Option in Template Details
export const PhaseMenuItems = ({
  closeMenu,
  actionHandlers,
  path
}: IMenuItemsProps) => {
  const navigate = useNavigate()
  return (
    <>
      <StyledSlot hasBorderBottom>
        <MenuItem
          title="Edit Title"
          icon="edit-3"
          type="primary"
          onClick={actionHandlers?.handleEdit}
          closeMenu={closeMenu}
        />
        <MenuItem
          title="Edit Details"
          icon="edit-3"
          type="primary"
          closeMenu={closeMenu}
          onClick={() => path && navigate(path)}
        />
      </StyledSlot>
      <StyledSlot hasBorderBottom={false}>
        <MenuItem
          title="Archive"
          icon="folder"
          type="primary"
          onClick={actionHandlers?.handleArchive}
          closeMenu={closeMenu}
          // disabled
        />
        <MenuItem
          title="Remove"
          icon="trash"
          type="danger"
          onClick={actionHandlers?.handleRemove}
          closeMenu={closeMenu}
        />
      </StyledSlot>
    </>
  )
}

//Menu Items for Task Option in Template Details
export const TaskMenuItems = ({
  closeMenu,
  actionHandlers,
  path
}: IMenuItemsProps) => {
  const navigate = useNavigate()
  return (
    <>
      <StyledSlot hasBorderBottom>
        <MenuItem
          title="Edit Title"
          icon="edit-3"
          type="primary"
          onClick={actionHandlers?.handleEdit}
          closeMenu={closeMenu}
        />
        <MenuItem
          title="Edit Details"
          icon="edit-3"
          type="primary"
          closeMenu={closeMenu}
          onClick={() => path && navigate(path)}
        />
      </StyledSlot>
      <StyledSlot hasBorderBottom={false}>
        <MenuItem
          title="Archive"
          icon="folder"
          type="primary"
          onClick={actionHandlers?.handleArchive}
          closeMenu={closeMenu}
          // disabled
        />
        <MenuItem
          title="Remove"
          icon="trash"
          type="danger"
          onClick={actionHandlers?.handleRemove}
          closeMenu={closeMenu}
        />
      </StyledSlot>
    </>
  )
}

//Menu Items for Archived Phase Option in Template Details
export const ArchivedItemMenuItems = ({ closeMenu, actionHandlers }: IMenuItemsProps) => {
    return (
            <StyledSlot hasBorderBottom={false}>
                <MenuItem
                    title="Unarchive"
                    icon="folder"
                    type="primary"
                    onClick={actionHandlers?.handleUnArchive}
                    closeMenu={closeMenu}
                />
                <MenuItem
                    title="Remove"
                    icon="trash"
                    type="danger"
                    onClick={actionHandlers?.handleRemove}
                    closeMenu={closeMenu}
                />
        </StyledSlot>
    )
}

export default MenuItems

export { AccountMenuItems }
