import { NavLink, useNavigate } from 'react-router-dom'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import {
  // StyledItemContainer,
  // StyledItemDescription,
  // StyledItemTitle,
  // StyledListItemContainer,
  StyledTemplateListContainer,
  StyledTemplatesMainContainer
} from './StyledTemplates'
import Spinner from '../../components/ui/Spinner'
import TemplateListItem from '../../components/ui/TemplateListItem/TemplateListItem'
import TemplateHeader from './TemplateHeader'
import Banner from '../../components/ui/Banner/Banner'
// import { useState } from 'react'
// import { StyledInputLabelContainer } from '../../components/screens/MyAccount/MyAccount'
// import Modal from '../../components/ui/Modal/Modal'
// import { StyledRow } from '../Team'
// import IllustrationComponent from '../../components/ui/IllustrationComponent/IllustrationComponent'
import useLocalStorage from '../../hooks/useLocalStorage'
// import SignalIcon from '../../components/ui/SignalIcon/SignalIcon'

// ------------------ Dashboard, All templates tab --------------------------------
const Templates: React.FC = () => {
  const navigate = useNavigate()
  // const [templateTypeModal, setTemplateTypeModal] = useState<boolean>(false)
  const { data: templatesList, isLoading: isLoadingTemplates } = useSelector(
    (store: RootState) => store.template
  )
  // const [selectedTemplateType, setSelectedTemplateType] = useState<
  //   'component' | 'project'
  // >()

  // close template modal
  // const closeModal = () => {
  //   setTemplateTypeModal(false)
  // }

  const [isOpen, setIsOpen] = useLocalStorage('add_new_template', true)

  // JSX
  return (
    <StyledTemplatesMainContainer>
      <TemplateHeader
        title="All Templates"
        // onClick={() => setTemplateTypeModal(true)}
        onClick={() => navigate('/dashboard/templates/create?type=component')}
      />
      <Banner
        body="Create easy-to-follow checks to outline a process, govern contributions, and ensure quality."
        buttonLabel="SEE EXAMPLE"
        handleButtonClick={() =>
          window.open('https://docs.compfeed.com/example')
        }
        variant="illustration"
        illustrationType="govern"
        type="callout"
        isOpen={isOpen}
        isCloseable
        handleClose={() => setIsOpen(false)}
      />
      <StyledTemplateListContainer>
        {isLoadingTemplates ? (
          <div
            style={{ width: '100%', padding: '25px' }}
            className="flex justify-center"
          >
            <Spinner type="spinner" />
          </div>
        ) : (
          templatesList?.map((temp) => (
            <NavLink
              to={`/dashboard/templates/${temp?.id}`}
              style={{
                width: '100%'
              }}
              key={temp.id}
            >
              <TemplateListItem
                title={temp?.title}
                description={temp?.description}
                type={temp?.type || 'component'}
              />
            </NavLink>
          ))
        )}
      </StyledTemplateListContainer>
      {/* <Modal
        showModal={templateTypeModal}
        setShowModal={setTemplateTypeModal}
        title="Select template type"
        signal="check-square"
        buttonVariant1="primary"
        buttonLabel1="PROCEED"
        buttonVariant2="tertiary"
        buttonLabel2="CANCEL"
        buttonOnClick1={() =>
          navigate(`/dashboard/templates/create?type=${selectedTemplateType}`)
        }
        buttonOnClick2={closeModal}
        buttonDisabled1={!selectedTemplateType}
        onclose={closeModal}
        overflow="visible"
        slotContent={
          <StyledInputLabelContainer style={{ gap: '16px' }}>
            Choose a template type to create a structured checklist for
            governing your design process.
            <StyledRow style={{ flexDirection: 'column', gap: '12px' }}>
              <StyledListItemContainer
                active={selectedTemplateType === 'project'}
                onClick={() => setSelectedTemplateType('project')}
                role='button'
              >
                <SignalIcon
                  signal="kanban"
                  background
                  type="primary"
                  size="m"
                />
                <StyledItemContainer
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    gap: '2px',
                    cursor: 'pointer'
                  }}
                >
                  <StyledTemplateListItemTitle>Project</StyledTemplateListItemTitle>
                  <StyledItemDescription>
                    Create your project project checklist
                  </StyledItemDescription>
                </StyledItemContainer>
              </StyledListItemContainer>
              <StyledListItemContainer
                active={selectedTemplateType === 'component'}
                onClick={() => setSelectedTemplateType('component')}
                role='button'
              >
                <SignalIcon
                  signal="box"
                  background
                  type="primary"
                  size="m"
                />
                <StyledItemContainer
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    gap: '2px',
                    cursor: 'pointer'
                  }}
                >
                  <StyledTemplateListItemTitle>Component</StyledTemplateListItemTitle>
                  <StyledItemDescription>
                    Create your project component checklist
                  </StyledItemDescription>
                </StyledItemContainer>
              </StyledListItemContainer>
            </StyledRow>
          </StyledInputLabelContainer>
        }
      /> */}
    </StyledTemplatesMainContainer>
  )
}

export default Templates
