export const GoogleIcon = (props: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M11.99 13.9v-3.72h9.36c.14.63.25 1.22.25 2.05c0 5.71-3.83 9.77-9.6 9.77c-5.52 0-10-4.48-10-10S6.48 2 12 2c2.7 0 4.96.99 6.69 2.61l-2.84 2.76c-.72-.68-1.98-1.48-3.85-1.48c-3.31 0-6.01 2.75-6.01 6.12s2.7 6.12 6.01 6.12c3.83 0 5.24-2.65 5.5-4.22h-5.51z"
      />
    </svg>
  )
}

export const LinkOff = (props: any) => {
  return (
    <svg width="54" height="50" viewBox="0 0 54 50" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.6667 24.75C53.6667 16.6566 47.0933 10.0833 39 10.0833H35V14.0833H39C44.88 14.0833 49.6667 18.87 49.6667 24.75C49.6667 30.1633 45.6133 34.63 40.3867 35.31L31.8267 26.75H40.3333V22.75H27.8267L5.08 0.0032959L2.25334 2.82996L48.92 49.4966L51.7467 46.67L43.72 38.6433C49.5067 36.67 53.6667 31.19 53.6667 24.75ZM4.33335 24.7499C4.33335 20.9099 6.38668 17.5499 9.44001 15.6699L6.56001 12.7899C2.81335 15.4433 0.34668 19.8166 0.34668 24.7499C0.34668 32.8433 6.92001 39.4166 15.0133 39.4166H19.0133V35.4166H15.0133C9.13335 35.4166 4.34668 30.6299 4.34668 24.7499H4.33335ZM13.6667 26.75V22.75H16.52L20.52 26.75H13.6667Z"
        fill="#202023"
      />
    </svg>
  )
}
