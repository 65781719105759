const Logo = ({
  width = 85,
  height = 48
}: {
  width?: number
  height?: number
}) => {
  return (
    <svg
      width={width}
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2370_2032)">
        <path
          d="M56.3461 50.8933L47.3136 56.0942C46.0761 56.7927 44.5444 55.8981 44.5444 54.4773V44.2312C44.5444 42.8058 46.0826 41.9121 47.3209 42.6179L56.3525 47.6631C57.6055 48.3771 57.6019 50.1847 56.3461 50.8933ZM39.8669 40.5289V57.185C39.8669 58.6141 38.3196 59.5069 37.0821 58.7928L22.5517 50.4038C21.9779 50.072 21.624 49.4587 21.624 48.796V29.9959C21.624 29.2571 22.0182 28.5742 22.658 28.2048L38.9383 18.8047C39.5131 18.4729 40.2207 18.4729 40.7954 18.8047L55.3259 27.1937C56.5633 27.9087 56.5633 29.6952 55.3259 30.4093L40.9008 38.7378C40.261 39.1072 39.8669 39.7901 39.8669 40.5289ZM66.1476 11.9951L50.2358 2.80765C47.0533 0.971632 43.4289 0 39.7541 0C36.0803 0 32.455 0.97163 29.2669 2.8104L13.3588 11.996C6.89101 15.7303 2.87341 22.6903 2.87341 30.1581V48.5265C2.87341 55.9998 6.89193 62.9598 13.3597 66.6896L29.2651 75.8724C32.4495 77.7121 36.0766 78.6837 39.7541 78.6837C43.4317 78.6837 47.0579 77.7121 50.2404 75.8743L66.1403 66.6942C72.6136 62.9607 76.6348 55.9989 76.6348 48.5265V30.1581C76.6348 22.6894 72.6163 15.7294 66.1476 11.9951Z"
          fill="#241C15"
        />
      </g>
      <defs>
        <clipPath id="clip0_2370_2032">
          <rect
            width="78.6837"
            height="78.6837"
            fill="white"
            transform="translate(0.412231)"
          />
        </clipPath>
      </defs>
    </svg>
  )
  // old
  // return (
  //     <svg width={width}  viewBox={`0 0 55 48`
  //     } fill="none" xmlns="http://www.w3.org/2000/svg">
  //         <g id="logo">
  //             <path id="logo_2" d="M37.9421 14.6768C37.2993 15.0494 36.5071 15.0494 35.8642 14.6768L22.9488 7.19834C22.306 6.82578 21.5115 6.82578 20.8687 7.19834L7.95785 14.6813C7.31503 15.0539 6.91892 15.7422 6.91892 16.4896V31.4511C6.91892 32.1962 7.31503 32.8868 7.96012 33.2594L20.871 40.7379C21.5138 41.1104 22.306 41.1104 22.9488 40.7379L36.9032 32.6574L50.853 24.5724C51.4958 24.1998 51.8919 23.5115 51.8919 22.7641V8.40235C51.8919 7.59816 51.025 7.09611 50.3324 7.4982L37.9421 14.6768ZM20.8687 23.3661L11.4572 17.9163C10.7646 17.5142 10.7646 16.5101 11.4572 16.108L20.8664 10.6536C21.5093 10.281 22.3038 10.281 22.9466 10.6536L32.3558 16.108C33.0485 16.5101 33.0485 17.5142 32.3558 17.9163L22.9443 23.3661C22.3015 23.7387 21.5093 23.7387 20.8664 23.3661H20.8687Z" fill="#241C15" />
  //         </g>
  //     </svg>
  // )
}

export default Logo
