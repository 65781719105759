import { useEffect, useState } from 'react'
import { PhaseObj } from '../store/reducers/phaseReducerSlice'

const useGetCurrentPhases = (
    allPhases: PhaseObj[],
    templateId?: string
) => {
    const [phases, setPhases] = useState<PhaseObj[]>([])

    // add the current component to the top of the recently viewed list
    useEffect(() => {
        const templatePhases = allPhases.filter(
            (phase) => phase.templateId === templateId
        )
        setPhases(templatePhases)
    }, [allPhases, templateId])

    return { phases, setPhases }
}

export default useGetCurrentPhases
