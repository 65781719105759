import { useEffect, useState } from 'react'
import { PhaseObj, TaskObj } from '../store/reducers/phaseReducerSlice'

const useGetCurrentTasks = (allPhases: PhaseObj[], templateId?: string) => {
  const [tasks, setTasks] = useState<TaskObj[]>([])

  // add the current component to the top of the recently viewed list
  useEffect(() => {
    const templatePhases = allPhases.filter(
      (phase) => phase.templateId === templateId
    )
    const templatePhasesTasks = templatePhases
      .map((phase) => phase.tasks)
      .flat() as TaskObj[]
    setTasks(templatePhasesTasks)
  }, [allPhases, templateId])

  return { tasks, setTasks }
}

export default useGetCurrentTasks
