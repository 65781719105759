const CallOutSignalIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M19.25 3H4.75C3.235 3 2 4.235 2 5.75V16.25C2 17.765 3.235 19 4.75 19H8.46L11.15 21.485C11.39 21.705 11.695 21.815 12 21.815C12.305 21.815 12.61 21.705 12.85 21.485L15.54 19H19.25C20.765 19 22 17.765 22 16.25V5.75C22 4.235 20.765 3 19.25 3ZM11.635 13.925C11.39 14.17 11.07 14.29 10.75 14.29C10.43 14.29 10.11 14.17 9.865 13.925L7.47 11.53L8.53 10.47L10.75 12.69L16.22 7.22L17.28 8.28L11.635 13.925Z"
            fill="#9747FF"
        />
        <path
            d="M19.25 3H4.75C3.235 3 2 4.235 2 5.75V16.25C2 17.765 3.235 19 4.75 19H8.46L11.15 21.485C11.39 21.705 11.695 21.815 12 21.815C12.305 21.815 12.61 21.705 12.85 21.485L15.54 19H19.25C20.765 19 22 17.765 22 16.25V5.75C22 4.235 20.765 3 19.25 3ZM11.635 13.925C11.39 14.17 11.07 14.29 10.75 14.29C10.43 14.29 10.11 14.17 9.865 13.925L7.47 11.53L8.53 10.47L10.75 12.69L16.22 7.22L17.28 8.28L11.635 13.925Z"
            fill="black"
            fill-opacity="0.2"
        />
    </svg>
);

const InformationSignalIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M18.25 3H5.75C4.235 3 3 4.235 3 5.75V18.25C3 19.765 4.235 21 5.75 21H18.25C19.765 21 21 19.765 21 18.25V5.75C21 4.235 19.765 3 18.25 3ZM12.75 17H11.25V11H12.75V17ZM12 9.5C11.45 9.5 11 9.05 11 8.5C11 7.95 11.45 7.5 12 7.5C12.55 7.5 13 7.95 13 8.5C13 9.05 12.55 9.5 12 9.5Z"
            fill="#1F6BFF"
        />
    </svg>
);

const WarningSignalIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M22.225 19.13L13.085 3.14001C12.865 2.75001 12.445 2.51001 12 2.51001C11.555 2.51001 11.135 2.75001 10.915 3.14001L1.77497 19.13C1.54997 19.52 1.55497 19.99 1.77497 20.375C1.99997 20.765 2.40497 21 2.85497 21H21.14C21.59 21 21.995 20.765 22.22 20.375C22.445 19.985 22.45 19.52 22.22 19.13H22.225ZM11.25 8.50001H12.75V14.5H11.25V8.50001ZM12 18C11.45 18 11 17.55 11 17C11 16.45 11.45 16 12 16C12.55 16 13 16.45 13 17C13 17.55 12.55 18 12 18Z"
            fill="#FF9508"
        />
    </svg>
);

const ErrorSignalIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M16.14 2H7.86L2 7.86V16.145L7.86 22.005H16.145L22.005 16.145V7.86L16.145 2H16.14ZM11.25 7.5H12.75V13.5H11.25V7.5ZM12 17C11.45 17 11 16.55 11 16C11 15.45 11.45 15 12 15C12.55 15 13 15.45 13 16C13 16.55 12.55 17 12 17Z"
            fill="#F24822"
        />
    </svg>
);

const SuccessSignalIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M12 2C6.485 2 2 6.485 2 12C2 17.515 6.485 22 12 22C17.515 22 22 17.515 22 12C22 6.485 17.515 2 12 2ZM11.385 15.175C11.14 15.42 10.82 15.54 10.5 15.54C10.18 15.54 9.86 15.42 9.615 15.175L7.22 12.78L8.28 11.72L10.5 13.94L16.22 8.22L17.28 9.28L11.385 15.175Z"
            fill="#2AB514"
        />
    </svg>
);

const KanbanIcon = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="14.4609" y="4" width="8" height="8" rx="2" stroke="#9747FF" strokeWidth="2"/>
        <rect x="14.4609" y="4" width="8" height="8" rx="2" stroke="black" strokeOpacity="0.2" strokeWidth="2"/>
        <rect x="2.46094" y="4" width="8" height="16" rx="2" stroke="#9747FF" strokeWidth="2"/>
        <rect x="2.46094" y="4" width="8" height="16" rx="2" stroke="black" strokeOpacity="0.2" strokeWidth="2"/>
    </svg>
);

const AllSignalIcons = {
    callout: CallOutSignalIcon,
    information: InformationSignalIcon,
    warning: WarningSignalIcon,
    error: ErrorSignalIcon,
    success: SuccessSignalIcon,
    kanban: KanbanIcon,
};

export default AllSignalIcons;
