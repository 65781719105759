
import styled from 'styled-components'
import { PhaseObj } from '../../../store/reducers/phaseReducerSlice'

// ------------------------HexColorPreview Component ------------------------

const StyledHexColorPreview = styled.div<IHexColorPreviewProps>`
  display: flex;
  width: 100%;
  padding: 2px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border: 1px solid
    ${({ theme, selected }) =>
        selected ? theme.colors.actionPrimary : 'transparent'};

  height: 72px;
  background-color: ${({ hexCode }) => hexCode?.bg || '#0DB691'};

  @media screen and (min-width: 430px) {
    height: 130px;
  }
`

const StyledHexColorCodeText = styled.div<IHexColorPreviewProps>`
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ hexCode }) => hexCode?.hexCode || '#fff'};
  
  @media screen and (min-width: 430px) {
    font-size: 24px;
  }

  `

interface IHexColorPreviewProps {
    selected?: boolean
  hexCode?: { hexCode: string; bg: string }
  formData?: PhaseObj
}

const HexColorPreview = ({
    selected = false,
  hexCode,
  formData,
}: IHexColorPreviewProps) => {
    return (
      <StyledHexColorPreview selected={selected} hexCode={hexCode}>
        <StyledHexColorCodeText hexCode={hexCode}>{formData?.title || "Phase title"}</StyledHexColorCodeText>
        </StyledHexColorPreview>
    )
}

export default HexColorPreview