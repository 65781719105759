import React from 'react'
import styled from 'styled-components'
import { useFormContext, Controller } from 'react-hook-form'
import { FormValues } from '../form.validations'

interface MultipleChoiceQuestionProps {
  question: {
    id: number
    text: string
    description?: string
    options: Array<{
      label: string
      description: string[]
      text?: string
    }>
  }
  fieldName: any
}

// Styled components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Title = styled.h2`
  font-size: 2.125rem;
  font-weight: 600;
`

const Description = styled.p`
  font-size: 1rem;
  color: var(--muted-foreground, #6b7280);
`

const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

const StyledCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: white;
`

const CardContent = styled.div`
  padding: 0;
`

const StyledLabel = styled.label`
  display: flex;
  align-items: start;
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--muted-background, #f3f4f6);
  }
`

const CheckboxWrapper = styled.input.attrs({ type: 'checkbox' })`
  margin-top: 0.25rem;
`

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .option-label {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25;
  }

  .option-description {
    font-size: 1rem;
    color: var(--muted-foreground, #6b7280);
  }
`

export function MultipleChoiceQuestion({
  question,
  fieldName
}: MultipleChoiceQuestionProps) {
  const { control } = useFormContext<FormValues>()

  return (
    <Wrapper>
      <Title>{question.text}</Title>
      {question.description && (
        <Description>{question.description}</Description>
      )}
      <Grid>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={[]}
          render={({ field: { value, onChange } }) => (
            <>
              {question.options.map((option, index) => (
                <StyledCard key={index}>
                  <CardContent>
                    <StyledLabel htmlFor={`q${question.id}-option${index}`}>
                      <CheckboxWrapper
                        id={`q${question.id}-option${index}`}
                        checked={(value as string[]).includes(
                          option.label || option.text || ''
                        )}
                        onChange={(e) => {
                          const isChecked = e.target.checked
                          const optionValue = option.label || option.text || ''
                          const updatedValue = isChecked
                            ? [...(value as string[]), optionValue]
                            : (value as string[]).filter(
                                (v) => v !== optionValue
                              )
                          onChange(updatedValue)
                        }}
                      />
                      <OptionContent>
                        <p className="option-label">
                          {option.label || option.text}
                        </p>
                        {option.description.map((desc, i) => (
                          <p key={i} className="option-description">
                            {desc}
                          </p>
                        ))}
                      </OptionContent>
                    </StyledLabel>
                  </CardContent>
                </StyledCard>
              ))}
            </>
          )}
        />
      </Grid>
    </Wrapper>
  )
}
