/* eslint-disable @typescript-eslint/no-unused-vars */
import { ref, get } from 'firebase/database'
import { db } from '../config/config'

export const isColorInUseInTeam = async (
  teamId: string,
  colorText: string,
  excludeTemplateId: string
): Promise<boolean> => {
  try {
    const templatesRef = ref(db, `/templates/${teamId}`)
    const templatesSnapshot = await get(templatesRef)

    if (!templatesSnapshot.exists()) {
      console.error('No templates found for team:', teamId)
      return false
    }

    const templates = templatesSnapshot.val()

    const relevantTemplates = Object.entries(templates).filter(
      ([_, templateData]: any) => templateData.teamId === teamId
    )

    for (const [templateId] of relevantTemplates) {
      const phasesRef = ref(db, `/phases/${templateId}`)
      const phasesSnapshot = await get(phasesRef)

      if (phasesSnapshot.exists()) {
        const phases = phasesSnapshot.val()

        const isColorUsedInPhases = Object.values(phases).some(
          (phase: any) => phase?.color?.text === colorText
        )

        if (isColorUsedInPhases) {
          return true
        }
      }
    }

    return false
  } catch (error) {
    console.error('Error checking color usage:', error)
    return false
  }
}
