import styled from "styled-components"
import { theme } from "../../../styles"
import Button from "../Button/Button"

const StyledSubNav = styled.div`
    display: flex;
    width: 100%;
    padding: 16px 8px;
    align-items: center;
    position: sticky;
    top:0;
    right:0;
    border-bottom: 1px solid ${theme.colors.borderDefault};
    background: ${theme.colors.white}; 
    font-family: "rooney";
    z-index: 2;
`

const StyledSubNavTitle = styled.h3`
    color: ${theme.colors.copy};
    font-family: "rooney";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    

    @media screen and (min-width:430px){
        font-size:30px;
    }
`

const SubNav = ({ title = "Component Status", onBtnClick }: { title?: string, onBtnClick?: () => void }) => {
    return (
        <StyledSubNav>
            <Button onClick={onBtnClick} iconOnly variant="tertiary" icon="chevron-left" inverted={false} />
            <StyledSubNavTitle>{title}</StyledSubNavTitle>
        </StyledSubNav>
    )
}

export default SubNav