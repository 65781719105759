import React from 'react'
import Spinner from '../components/ui/Spinner'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { Outlet } from 'react-router-dom'
import Auth from '../pages/Auth'

interface ProtectedRouteProps {
  redirectPath?: string
  roles?: string[] // Adjust the type of roles as needed
  children?: React.ReactNode
}

const ProtectedRouter: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading } = useSelector(
    (store: RootState) => store.auth
  )

  if (isLoading) {
    return (
      <div className="fill-available-height flex items-center justify-center">
        <Spinner type="spinner" />
      </div>
    )
  }

  if (isAuthenticated === null || isAuthenticated === undefined) {
    // Show loading spinner while waiting for authentication to complete
    return (
      <div className="fill-available-height flex items-center justify-center">
        <Spinner type="spinner" />
      </div>
    )
  }

  if (!isAuthenticated) {
    return <Auth />
  }

  return <>{children || <Outlet />}</>
}

export default ProtectedRouter
