import styled, { css } from 'styled-components'
import ProfilePic, { IProfilePicProps } from '../ProfilePic/ProfilePic'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import Label from '../Label/Label'
import IconComponent from '../IconComponent/IconComponent'
import { theme } from '../../../styles'
import { MouseEvent } from 'react'

interface ITeamListItemProps {
  showCheck?: boolean
  profilePicProps?: Omit<IProfilePicProps, 'size' | 'showButton'>
  disabled?: boolean
  active?: boolean
  userLabel?: string
  name?: string
  description?: string
  clickAction?: (e: MouseEvent<HTMLDivElement>) => void
}

const StyledTeamListItemContainer = styled.div<{
  disabled?: boolean
  isActionable?: boolean
  active?: boolean
}>`
  display: flex;
  width: 100%;
  max-width: 320px;
  padding: 8px;
  gap: 16px;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid ${theme.colors.borderDefault};

  @media screen and (min-width: 430px) {
    max-width: 480px;
    padding: 16px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  ${({ active }) =>
    active &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colors.actionPrimaryLight};
    `}

  ${({ disabled, isActionable, active }) =>
    !disabled &&
    css`
      cursor: ${isActionable ? 'pointer' : 'default'};
      border-color: ${active
        ? theme.colors.actionPrimaryActive
        : theme.colors.borderDefault};
      &:hover {
        background-color: ${theme.colors.actionPrimaryLightHover};
        border-color: ${theme.colors.actionPrimaryLightHover};
      }

      &:active {
        background-color: ${theme.colors.actionPrimaryLightActive};
        border-color: ${theme.colors.actionPrimaryLightActive};
      }
    `}
`

const StyledProfileContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`

const StyledTagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledTeamInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  background: transparent;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledTeamName = styled.h4`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.colors.copy};
  font-family: 'rooney-sans';
  font-size: ${theme.font.sizes.medium};
  font-style: normal;
  font-weight: 380;
  line-height: 140%;
  text-overflow: ellipsis;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.body};
    font-weight: 420;
  }
`

const StyledTeamDescription = styled.p`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${theme.colors.copySubtle};
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 340;
  line-height: 140%;
  text-overflow: ellipsis;

  @media screen and (min-width: 430px) {
    font-size: ${theme.font.sizes.body};
    font-weight: 380;
  }
`

const TeamListItem = ({
  profilePicProps,
  showCheck = true,
  disabled = false,
  active = false,
  userLabel = 'User',
  description = 'Team Description',
  name = 'Team Name',
  clickAction = undefined
}: ITeamListItemProps) => {
  const isMobile = useIsMobileDevice()
  return (
    <StyledTeamListItemContainer
      disabled={disabled}
      onClick={(e) => (clickAction ? clickAction(e) : null)}
      isActionable={clickAction ? true : false}
      active={active}
    >
      <StyledProfileContainer disabled={disabled}>
        <ProfilePic
          {...profilePicProps}
          size={isMobile ? 'xs' : 's'}
          showButton={false}
        />
        <StyledTeamInfoContainer>
          <StyledTeamName>{name}</StyledTeamName>
          <StyledTeamDescription>{description}</StyledTeamDescription>
        </StyledTeamInfoContainer>
      </StyledProfileContainer>
      <StyledTagsContainer>
        <Label type={disabled ? 'disabled' : 'information'} text={userLabel} />
        {showCheck && !disabled && (
          <IconComponent
            icon="check"
            color={theme.colors.actionPrimary}
            iconSize="m"
            disabled={disabled}
          />
        )}
      </StyledTagsContainer>
    </StyledTeamListItemContainer>
  )
}

export default TeamListItem
