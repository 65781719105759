export const buttonVariantColors = {
  // btnActionPrimary: "#FF2B68",
  // btnActionHover:"#DC0D48",
  // btnActionActive: "#AB0B39",
  // btnActionDisabled: "#E8E8E8",
  btnActionPrimary: '#9747FF',
  btnActionHover: '#8840E6',
  btnActionActive: '#7939CC',
  btnActionDisabled: '#CCCCCC',
  btnLabelIconDisabled: '#B3B3B3',
  btnDangerPrimary: '#F24822',
  btnDangerHover: '#F42D01',
  btnDangerActive: '#D42700',
  btnActionPrimaryLightHover: '#EFE3FF',
  btnActionPrimaryLight: '#F5EDFF',
  btnActionPrimaryLightActive: '#DFC6FF'
}
export const theme = {
  font: {
    family: 'rooney-sans system-ui, Avenir, Helvetica, Arial, sans-serif',
    regular: 400,
    medium: 500,
    semiBold: 600,
    sizes: {
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '20px',
      xlarge: '24px',
      xxlarge: '32px',
      huge: '40px',
      title: '64px',
      header2: '48px',
      header3: '30px',
      subtitle: '26px',
      preTitle: '14px',
      bodySmall: '12px',
      body: '18px',
      bodyBold: '20px',
      button: '20px',
      link: '20px'
    }
  },
  colors: {
    inherit: 'inherit',
    current: 'currentColor',
    transparent: 'transparent',
    actionPrimary: '#9747FF',
    actionPrimaryHover: '#8840E6',
    actionPrimaryLightHover: '#EFE3FF',
    actionPrimaryActive: '#7939CC',
    actionPrimaryLight: '#F5EDFF',
    actionPrimaryLightActive: '#DFC6FF',
    actionDisabled: '#CCCCCC',
    actionSecondary: '#1FB8FF',
    actionSecondaryHover: '#1088BF',
    actionSecondaryActive: '#0E6790',
    actionTertiary: '#000000',
    signalSuccess: '#2AB514',
    signalSuccessLight: '#E5F2E4',
    signalSuccessLightHover: '#A5D6A2',
    signalWarning: '#FF9508',
    signalWarningLight: '#FFF0E4',
    signalWarningLightHover: '#FFCFA1',
    signalError: '#F24822',
    signalErrorLight: '#FCE6E5',
    signalErrorLightHover: '#FACBC7',
    signalErrorNormalHover: '#F42D01',
    signalErrorLightActive: '#F7ABA3',
    signalErrorNormalActive: '#D42700',
    signalInformation: '#1F6BFF',
    signalInformationLight: '#E4E9FF',
    signalInformationLightHover: '#A3B5FF',
    phaseBackground1: '#AEC7F8',
    phaseBackground2: '#F8D5A7',
    phaseBackground3: '#F4BCAF',
    phaseBackground4: '#CEFFC7',
    phaseBackground5: '#A9A9B3',
    backgroundDark: '#0E0E2C',
    backgroundSelected: '#EFEEEA',
    backgroundLight: '#FBF9F7',
    surfaceNormal: '#FEFDFD',
    surfaceNormalActive: '#FBF9F7',
    surfaceNormalLightActive: '#FCFAF9',
    borderDefault: '#F2ECE6',
    copy: 'rgba(36, 28, 21, 1)',
    copySubtle: 'rgba(36, 28, 21, 0.6)',
    copyAccent: '#ECF1F4',
    black: '#000',
    white: '#fff',
    gray: '#6a6d72',
    slate: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a'
    },
    button: {
      color: {
        primary: {
          nonInverted: {
            background: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            border: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            label: {
              default: '#FFF',
              hover: '#FFF',
              active: '#FFF',
              disabled: buttonVariantColors.btnActionPrimaryLightActive
            },
            icon: {
              default: '#FFF',
              hover: '#FFF',
              active: buttonVariantColors.btnLabelIconDisabled,
              disabled: buttonVariantColors.btnActionPrimaryLightActive
            }
          },
          inverted: {
            background: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            border: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionPrimaryLightActive
            },
            label: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionPrimary,
              disabled: buttonVariantColors.btnActionPrimaryLightActive
            },
            icon: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionDisabled
            }
          }
        },
        secondary: {
          nonInverted: {
            background: {
              default: '#FFF',
              hover: '#FFF',
              active: '#FFF',
              disabled: '#FFF'
            },
            border: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionDisabled
            },
            label: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionDisabled
            },
            icon: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionDisabled
            }
          },
          inverted: {
            background: {
              default: 'transparent',
              hover: 'transparent',
              active: 'transparent',
              disabled: 'transparent'
            },
            border: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            label: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLightHover
            },
            icon: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            }
          }
        },
        tertiary: {
          nonInverted: {
            background: {
              default: 'transparent',
              hover: 'transparent',
              active: 'transparent',
              disabled: 'transparent'
            },
            border: {
              default: 'transparent',
              hover: 'transparent',
              active: 'transparent',
              disabled: 'transparent'
            },
            label: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnLabelIconDisabled
            },
            icon: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnLabelIconDisabled
            }
          },
          inverted: {
            background: {
              default: 'transparent',
              hover: 'transparent',
              active: 'transparent',
              disabled: 'transparent'
            },
            border: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            label: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            icon: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            }
          }
        },
        danger: {
          nonInverted: {
            background: {
              default: 'transparent',
              hover: 'transparent',
              active: 'transparent',
              disabled: 'transparent'
            },
            border: {
              default: buttonVariantColors.btnDangerPrimary,
              hover: buttonVariantColors.btnDangerHover,
              active: buttonVariantColors.btnDangerActive,
              disabled: buttonVariantColors.btnActionDisabled
            },
            label: {
              default: buttonVariantColors.btnDangerPrimary,
              hover: buttonVariantColors.btnDangerHover,
              active: buttonVariantColors.btnDangerActive,
              disabled: buttonVariantColors.btnActionDisabled
            },
            icon: {
              default: buttonVariantColors.btnDangerPrimary,
              hover: buttonVariantColors.btnDangerHover,
              active: buttonVariantColors.btnDangerActive,
              disabled: buttonVariantColors.btnActionDisabled
            }
          },
          inverted: {
            background: {
              default: 'transparent',
              hover: 'transparent',
              active: 'transparent',
              disabled: 'transparent'
            },
            border: {
              default: '#FFF',
              hover: '#FFF',
              active: '#FFF',
              disabled: buttonVariantColors.btnLabelIconDisabled
            },
            label: {
              default: '#FFF',
              hover: '#FFF',
              active: '#FFF',
              disabled: buttonVariantColors.btnLabelIconDisabled
            },
            icon: {
              default: '#FFF',
              hover: '#FFF',
              active: '#FFF',
              disabled: buttonVariantColors.btnLabelIconDisabled
            }
          }
        },
        utility: {
          nonInverted: {
            background: {
              default: buttonVariantColors.btnActionPrimaryLight,
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLightActive,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            border: {
              default: 'transparent',
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionPrimaryLightActive
            },
            label: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionPrimary,
              disabled: buttonVariantColors.btnActionPrimaryLightActive
            },
            icon: {
              default: buttonVariantColors.btnActionPrimary,
              hover: buttonVariantColors.btnActionHover,
              active: buttonVariantColors.btnActionActive,
              disabled: buttonVariantColors.btnActionDisabled
            }
          },
          inverted: {
            background: {
              default: 'transparent',
              hover: 'transparent',
              active: 'transparent',
              disabled: 'transparent'
            },
            border: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            },
            label: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLightHover
            },
            icon: {
              default: '#FFF',
              hover: buttonVariantColors.btnActionPrimaryLightHover,
              active: buttonVariantColors.btnActionPrimaryLight,
              disabled: buttonVariantColors.btnActionPrimaryLight
            }
          }
        }
      }
    },
    label: {
      active: {
        labelText: '#9747FF',
        background: 'rgba(151, 71, 255, 0.20)'
      },

      information: {
        labelText: '#1F6BFF',
        background: 'rgba(31, 107, 255, 0.10)'
      },
      success: {
        labelText: '#2AB514',
        background: 'rgba(42, 181, 20, 0.10)'
      },
      warning: {
        labelText: '#FF9508',
        background: 'rgba(255, 149, 8, 0.10)'
      },
      error: {
        labelText: '#F24822',
        background: 'rgba(242, 72, 34, 0.10)'
      },
      disabled: {
        labelText: '#4A4A68',
        background: '#CCC'
      }
    },
    spacing: {
      /** 1px */
      px: '1px',
      /** 0px */
      0: '0px',
      /** 2px */
      0.5: '0.125rem',
      /** 4px */
      1: '0.25rem',
      /** 6px */
      1.5: '0.375rem',
      /** 8px */
      2: '0.5rem',
      /** 10px */
      2.5: '0.625rem',
      /** 12px */
      3: '0.75rem',
      /** 14px */
      3.5: '0.875rem',
      /** 16px */
      4: '1rem',
      /** 20px */
      5: '1.25rem',
      /** 24px */
      6: '1.5rem',
      /** 28px */
      7: '1.75rem',
      /** 32px */
      8: '2rem',
      /** 36px */
      9: '2.25rem',
      /** 40px */
      10: '2.5rem',
      /** 44px */
      11: '2.75rem',
      /** 48px */
      12: '3rem',
      /** 56px */
      14: '3.5rem',
      /** 64px */
      16: '4rem',
      /** 80px */
      20: '5rem',
      /** 96px */
      24: '6rem',
      /** 112px */
      28: '7rem',
      /** 128px */
      32: '8rem',
      /** 144px */
      36: '9rem',
      /** 160px */
      40: '10rem',
      /** 176px */
      44: '11rem',
      /** 192px */
      48: '12rem',
      /** 208px */
      52: '13rem',
      /** 224px */
      56: '14rem',
      /** 240px */
      60: '15rem',
      /** 256px */
      64: '16rem',
      /** 288px */
      72: '18rem',
      /** 320px */
      80: '20rem',
      /** 384px */
      96: '24rem'
    }
  }
}

export const phaseTypeColorMap = {
  1: theme.colors.signalInformation,
  2: theme.colors.signalWarning,
  3: theme.colors.signalError,
  4: theme.colors.signalSuccess,
  5: theme.colors.actionDisabled
}

export const phaseTypeBackgroundMap = {
  1: theme.colors.phaseBackground1,
  2: theme.colors.phaseBackground2,
  3: theme.colors.phaseBackground3,
  4: theme.colors.phaseBackground4,
  5: theme.colors.phaseBackground5
}
