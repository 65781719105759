import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  ${() => css`
    :root {
      --toastify-color-light: #fff;
      --toastify-color-dark: #241c15;
      --toastify-color-info: #1f6bff;
      --toastify-color-success: #2ab514;
      --toastify-color-warning: #ff9508;
      --toastify-color-error: #f24822;
      --toastify-color-transparent: rgba(255, 255, 255, 0.7);

      --toastify-icon-color-info: var(--toastify-color-info);
      --toastify-icon-color-success: var(--toastify-color-success);
      --toastify-icon-color-warning: var(--toastify-color-warning);
      --toastify-icon-color-error: var(--toastify-color-error);

      --toastify-toast-width: 320px;
      --toastify-toast-background: #fff;
      --toastify-toast-min-height: 64px;
      --toastify-toast-max-height: 800px;
      --toastify-font-family: sans-serif;
      --toastify-z-index: 9999;

      --toastify-text-color-light: #757575;
      --toastify-text-color-dark: #fff;

      //Used only for colored theme
      --toastify-text-color-info: #fff;
      --toastify-text-color-success: #fff;
      --toastify-text-color-warning: #fff;
      --toastify-text-color-error: #fff;

      --toastify-spinner-color: #616161;
      --toastify-spinner-color-empty-area: #e0e0e0;

      // Used when no type is provided
      // toast("**hello**")
      --toastify-color-progress-light: linear-gradient(
        to right,
        #4cd964,
        #5ac8fa,
        #007aff,
        #34aadc,
        #5856d6,
        #ff2d55
      );

      // Used when no type is provided
      --toastify-color-progress-dark: #bb86fc;
      --toastify-color-progress-info: var(--toastify-color-info);
      --toastify-color-progress-success: var(--toastify-color-success);
      --toastify-color-progress-warning: var(--toastify-color-warning);
      --toastify-color-progress-error: var(--toastify-color-error);

      // ****** Cookie styles ******
      // Change font
      --cc-font-family: "rooney-sans", sans-serif;

      // Change primary color
      // --cc-primary-color: #9747ff;

      // Change button primary color to black
      --cc-btn-primary-bg: #9747ff;
      --cc-btn-primary-border-color: #9747ff;
      --cc-btn-primary-hover-bg: #8840e6;
      --cc-btn-primary-hover-border-color: #8840e6;

      // Change button secondary color to black
      --cc-btn-secondary-bg: transparent;
      --cc-btn-secondary-border-color: transparent;
      --cc-btn-secondary-hover-bg: transparent;
      --cc-btn-secondary-hover-border-color: transparent;
      --cc-btn-secondary-color: #9747ff;
      --cc-btn-secondary-hover-color: #9747ff;

      // footer color
      --cc-footer-color: #9747ff;

      // Also make toggles the same color as the button
      --cc-toggle-on-bg: var(--cc-btn-primary-bg);

      // Make the buttons a bit rounder **/
      --cc-btn-border-radius: 8px;

      // Footer
      --cc-footer-bg: #fdfcfb;

    }
    
    #cc-main .cm__title {
      color: #000 !important
    }
    
    #cc-main .cm__footer {
      border-top: none;
    }
    #cc-main .cm__footer a {
      color: #9747ff;
    }

    #cc-main .cm--cloud .cm__btns {
      border-left: none;
    }

    #cc-main .cm {
      border: 1px solid #F2ECE6;
      // box-shadow: none;
    }

    #cc-main .pm__btn,
    #cc-main .cm__btn {
      border-radius: 50px;
    }

    .Toastify__toast-container {
      border-radius: 300px;
    }

    * {
      margin: 0;
      outline: 0;
      padding: 0;
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    html,
    body {
      height: 100%;
    }

    html {
      font-size: 62.5%;
      min-width: 320px;
      scroll-behavior: smooth;
      -moz-osx-font-smoothing: grayscale; /* Firefox */
      -webkit-font-smoothing: antialiased; /* WebKit */
    }

    @font-face {
      font-family: "rooney";
      src: local("rooney"), url("/fonts/RooneyWeb-Bold.woff") format("woff");
      font-weight: 700;
      font-style: normal;
    }

    @font-face {
      font-family: "rooney";
      src: local("rooney"), url("/fonts/RooneyWeb-Medium.woff") format("woff");
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: "rooney";
      src: local("rooney"), url("/fonts/RooneyWeb-Regular.woff") format("woff");
      font-weight: 400;
      font-style: normal;
    }

    @font-face {
      font-family: "rooney-sans";
      src: local("rooney-sans"),
        url("/fonts/RooneySansWeb-Bold.woff") format("woff");
      font-weight: 700;
      font-style: normal;
    }

    @font-face {
      font-family: "rooney-sans";
      src: local("rooney-sans"),
        url("/fonts/RooneySansWeb-Medium.woff") format("woff");
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: "rooney-sans";
      src: local("rooney-sans"),
        url("/fonts/RooneySansWeb-Regular.woff") format("woff");
      font-weight: 400;
      font-style: normal;
    }

    body {
      font-family: "rooney-sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      font-size: 1.6rem;
      line-height: 1.5;
      scroll-behavior: smooth;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    input,
    button,
    textarea,
    select {
      font: inherit;
    }

    button {
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    ul,
    li,
    ol {
      list-style: none;
    }

    h1 {
      font-family: "rooney";
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    h2 {
      font-family: "rooney";
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    h3 {
      font-family: "rooney";
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }

    /* ScrollBar  */

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      background-color: #00000000;
      width: 0;
      height: 0;
    }

    ::-webkit-scrollbar-thumb {
      width: 0;
      height: 0;
      background: #00000000;
    }
    #root {
      height: 100%;
      min-height: -webkit-fill-available;
    }
    .fill-available-height {
      min-height: 100vh;
    }
    .flex {
      display: flex;
    }
    .items-center {
      align-items: center;
    }
    .justify-center {
      justify-content: center;
    }
    .mx-auto {
      margin-left: auto;
      margin-right: auto;
    }
    .text-center {
      text-align: center;
    }
    .text-success {
      color: #2ab514;
    }
    .text-danger {
      color: #f24822;
    }
    .long-press-button {
      position: relative;
      padding: 10px;
      border: none;
      cursor: pointer;
      overflow: hidden;
      user-select: none;
    }

    .long-press-button.pressed * {
      z-index: 1;
    }

    .long-press-button::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #f24822;
      opacity: 0.7;
    }
    .long-press-button:active,
    .long-press-button.pressed {
      border: 1px solid #f42d01 !important;
    }

    .long-press-button.pressed::before {
      transition: width 5s linear;
    }

    .long-press-button.pressed::before {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      html,
      body,
      #root,
      .fill-available-height {
        height: 100%;
        min-height: -webkit-fill-available;
      }
      .mobile-drawer {
        height: 100% !important;
      }
    }
  `};
`;
