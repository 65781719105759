import styled from "styled-components";
import { theme } from "../../../styles";

const MyAccountBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    margin-inline: auto;
    width: 100%;
    max-width: 832px;
    gap:16px;

    background: ${theme.colors.white};

    @media screen and (min-width: 430px) {
    gap: 24px;
    padding: 40px 48px;
  }
`

export default MyAccountBodyContainer