import React from "react";
import { useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
import { StyledHeaderLabel } from "../../components/ui";
import { RootState } from "../../store";
import FeatherIcon from "feather-icons-react";
import {
  StyledComponentsTitle,
  StyledListHeaderContainer,
} from "../../components/screens/Lister/ListHeader";
// import { PhaseObj } from "../../store/reducers/phaseReducerSlice";
// import { theme } from "../../styles"

interface ComponentProps {
  componentDetails?: string | boolean;
}

const ComponentsHeader: React.FC<ComponentProps> = () => {
  const { data: components } = useSelector(
    (store: RootState) => store.component
  );
  // const { data: templatesList, isLoading: isLoadingTemplates } = useSelector(
  //   (store: RootState) => store.template
  // );
  // const navigate = useNavigate();
  // const { id, pageId } = useParams();

  // count the total number of phases
  // const totalPhases = templatesList.reduce((acc, item) => {
  //   let phs = Object.values(item?.phases || []);
  //   return acc + phs?.length;
  // }, 0);

  // combined array of all phases
  // const combinedPhases: PhaseObj[] = templatesList.reduce((acc, item) => {
  //   let phs: PhaseObj[] = Object.values(item?.phases || []);
  //   return acc.concat(phs);
  // }, [] as PhaseObj[]);

  // let currentIndex = components?.findIndex((v) => v?.id === id);
  // let nextItem = components ? components[currentIndex + 1] : null;
  // let prevItem = components ? components[currentIndex - 1] : null;

  //
  // const nextComp = () => {
  //   if(isLoadingComponents || !components?.length) return
  //   if (nextItem) {
  //     let hisPages = nextItem?.pages || [];
  //     navigate(`/dashboard/components/${nextItem?.id}/${hisPages[0]?.id}`);
  //   }
  // };

  //
  // const prevComp = () => {
  //   if(isLoadingComponents || !components?.length) return
  //   if (prevItem) {
  //     let hisPages = nextItem?.pages || [];
  //     navigate(`/dashboard/components/${nextItem?.id}/${hisPages[0]?.id}`);
  //   } else if (id && pageId) {
  //     navigate(`/dashboard/components/`);
  //   }
  // };
  return (
    <StyledListHeaderContainer>
      <StyledComponentsTitle>Components</StyledComponentsTitle>
      {components?.length > 0 &&
      <div
        style={{
          border: "1px solid #F2ECE6",
          borderRadius: "50px",
          padding: "3px 9px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          userSelect: "none",
          minWidth: "272px",
        }}
      >
        <span
          // onClick={prevComp}
          // style={{
          //   cursor: (prevItem && !id) || pageId ? "pointer" : "none",
          //   color:
          //     (prevItem && !id) || pageId
          //       ? theme?.colors?.actionPrimaryActive
          //       : "gray",
          //   pointerEvents: (prevItem && !id) || pageId ? "all" : "none",
          // }}
          style={{
            color: "gray",
          }}
        >
          <FeatherIcon style={{ marginBottom: "-7px" }} icon="chevron-left" />
        </span>
        <StyledHeaderLabel style={{ padding: "8px 29px" }}>
          {/* {isLoadingComponents
            ? "Loading..."
            : // components ?  components[currentIndex]?.pages[0]?.title || "All Components" :
            "All Components"} */}
              All Components
        </StyledHeaderLabel>
        <span
          // onClick={nextComp}
          // style={{
            //   cursor: nextItem ? "pointer" : "none",
            //   color: nextItem ? theme?.colors?.actionPrimaryActive : "gray",
            //   pointerEvents: nextItem ? "all" : "none",
            // }}
            style={{
              color: "gray",
            }}
          >
          <FeatherIcon style={{ marginBottom: "-7px" }} icon="chevron-right" />
        </span>
      </div>
      }
    </StyledListHeaderContainer>
  );
};

export default ComponentsHeader;
