import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRecentlyViewedComponentIds } from '../store/reducers/componentsReducerSlice';

const useSetRecentlyViewedComponent = (id: string, pageId: string) => {

    const dispatch = useDispatch();

    // add the current component to the top of the recently viewed list
    useEffect(() => {
        let existingEntries = JSON.parse(localStorage.getItem('recent_component_ids') ?? "[]") || [];

        // remove the current entry if it exists
        existingEntries = existingEntries.filter((entry: { id: string, pageId: string }) => entry.pageId !== pageId || entry.id !== id);

        const updatedEntries = [{ id, pageId }, ...existingEntries];

        // keep only the last 4 entries
        const recentlyViewedComponents = updatedEntries.slice(0, 4);

        localStorage.setItem('recent_component_ids', JSON.stringify(recentlyViewedComponents))
        dispatch(setRecentlyViewedComponentIds(recentlyViewedComponents))
    }, [id, pageId, dispatch])
}

export default useSetRecentlyViewedComponent;