import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { User as authUser } from 'firebase/auth'
import { User } from './authReducerSlice'
import { onValue, ref } from 'firebase/database'
import { db } from '../../config/config'

export interface MemberObj {
  email: string
  id: string
  role?: string
  pendingInvite?: boolean
  invitationDate: Date | string
  invitationStatus: string
  profileImg?: string
  invitedBy: string
  inviteSentCount: number
}

export interface TeamObj {
  id: string
  title?: string
  description?: string
  icon?: string
  members: MemberObj[]
  createdAt?: Date | string
}

interface TeamState {
  teams: TeamObj[]
  currentTeam: TeamObj | null
  isLoading: boolean
}

const initialState: TeamState = {
  teams: [],
  currentTeam: null,
  isLoading: true
}

interface ParamsType {
  userObj: User
  session: authUser
}

// Thunk to fetch and set teams in real-time
export const fetchAndSetTeamsRealTime = createAsyncThunk<
  void,
  ParamsType,
  { rejectValue: string }
>(
  'teamSlice/getTeams',
  async ({ userObj, session }, { rejectWithValue, dispatch }) => {
    try {
      const useTeamsJoined = Object.values(userObj?.teamsJoined || {})
      useTeamsJoined.forEach((teamId) => {
        const teamRef = ref(db, `teams/${teamId}`)
        onValue(teamRef, (teamSnap) => {
          if (teamSnap.exists()) {
            const team = teamSnap.val() as TeamObj
            const userExistInTeam = Object.values(team?.members || []).find(
              (member) =>
                member?.email === session?.email &&
                (member?.invitationStatus === 'accepted' ||
                  member?.role === 'owner')
            )
            if (userExistInTeam) {
              dispatch(updateTeamRealTime({ team }))
            } else {
              dispatch(removeTeam({ team, user: userObj }))
            }
          } else {
            dispatch(removeTeam({ team: { id: teamId }, user: userObj }))
          }
        })
      })
    } catch (error) {
      console.error('Error fetching teams:', error)
      return rejectWithValue('Failed to fetch teams')
    }
  }
)

// Reducer slice
export const teamReducerSlice = createSlice({
  name: 'teamSlice',
  initialState,
  reducers: {
    setTeams: (state, action) => {
      state.teams = action.payload.data
      state.isLoading = action.payload.isLoading
    },
    setTeamState: (state, action) => {
      state.teams = action.payload.teams
      state.isLoading = action.payload.isLoading
      state.currentTeam = action.payload.currentTeam
    },
    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload.data
    },
    resetTeam: (state) => {
      state.teams = []
      state.isLoading = false
    },
    resetAllTeams: (state) => {
      state.teams = []
      state.currentTeam = null
      state.isLoading = false
    },
    removeTeam: (state, action) => {
      const teamToRemove = action.payload.team
      const user = action.payload.user
      const userId = user?.id
      if (teamToRemove?.id) {
        state.teams = state.teams.filter((team) => team.id !== teamToRemove.id)
      }
      // also check if the team is the current team then set to user own team
      if (state.currentTeam?.id === teamToRemove?.id) {
        const currentTeam = state.teams.find(
          (team) => team.id === state.currentTeam?.id
        ) || {
          title: 'Personal',
          id: userId,
          members: [
            {
              id: userId,
              email: user?.email,
              role: 'owner',
              invitationDate: new Date().toISOString(),
              profileImg: user?.profileImg || user?.photoURL || '',
              pendingInvite: false,
              inviteSentCount: 0,
              invitationStatus: 'accepted',
              invitedBy: userId
            }
          ]
        }
        localStorage.setItem('checklist_team_id', userId)
        state.currentTeam = currentTeam
      }
    },
    updateTeamRealTime: (state, action) => {
      const updatedTeam = action.payload.team
      if (state.currentTeam?.id === updatedTeam?.id) {
        state.currentTeam = {
          ...updatedTeam,
          members: Object.values(updatedTeam?.members || [])
        }
      }
      state.teams = state.teams
        .map((team) => {
          if (team.id === updatedTeam.id) {
            return {
              ...updatedTeam,
              members: Object.values(updatedTeam?.members || [])
            }
          }
          return team
        })
        .filter(Boolean) as TeamObj[]
    }
  }
})

export const {
  setTeams,
  setTeamState,
  setCurrentTeam,
  resetTeam,
  resetAllTeams,
  updateTeamRealTime,
  removeTeam
} = teamReducerSlice.actions

export default teamReducerSlice.reducer
