import styled from 'styled-components'
import Nav from '../ui/Nav/Nav'
import TopNav from '../ui/TopNav/TopNav'
import { StyledLayout } from './BaseLayout.styled'
import { ReactNode, useContext, useState } from 'react'
import MobileMenuDrawer from '../ui/MobileMenuDrawer/MobileMenuDrawer'
import { DrawerContext } from '../../context/DrawerContext'
import AccountMenuMobile from '../ui/AccountMenuMobile/AccountMenuMobile'
// import StatefulNav from "../ui/Nav/StatefulNav";

export const StyledTopNavResponsiveContainer = styled.header`
  position: sticky;
  top: 0;
  right: 0;

  @media screen and (min-width: 834px) {
    display: none;
  }
`

export const StyledSideNavResponsiveContainer = styled.aside`
  position: sticky;
  top: 0;
  right: 0;
  @media screen and (max-width: 834px) {
    display: none;
  }
`

const Layout = ({ children }: { children: ReactNode }) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    isAccountMenuOpen,
    setIsAccountMenuOpen
  } = useContext(DrawerContext)
  const [logoutConfirmModal, setLogoutConfirmModal] = useState<boolean>(false)
  const [switchTeamModal, setSwitchTeamModal] = useState<boolean>(false)

  return (
    <>
      <StyledLayout className="root-layout">
        <StyledTopNavResponsiveContainer>
          <TopNav />
        </StyledTopNavResponsiveContainer>
        <StyledSideNavResponsiveContainer>
          <Nav
            setLogoutConfirmModal={setLogoutConfirmModal}
            setSwitchTeamModal={setSwitchTeamModal}
          />
          {/* <StatefulNav /> */}
        </StyledSideNavResponsiveContainer>
        {children}
        <MobileMenuDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
      </StyledLayout>
      <AccountMenuMobile
        isOpen={isAccountMenuOpen}
        setIsOpen={setIsAccountMenuOpen}
        setLogoutConfirmModal={setLogoutConfirmModal}
        setSwitchTeamModal={setSwitchTeamModal}
      />
    </>
  )
}

export default Layout
