import styled, { keyframes } from 'styled-components';
import FeatherIcon from 'feather-icons-react';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${rotation} 1.2s linear infinite;
`;

export interface ILoaderProps {
    size?: number
}

const Loader = ({ size = 24 }: ILoaderProps) => {
    return (
        <LoaderWrapper>
            <FeatherIcon icon="loader" size={size} />
        </LoaderWrapper>
    );
};

export default Loader;