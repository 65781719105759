import { HTMLAttributes, useMemo } from "react";
import PhaseProgress, { IPhaseProgress } from "../PhaseProgress/PhaseProgress";
import PhaseItem from "../PhaseItem/PhaseItem";
import { styled } from "styled-components";
import { theme } from "../../../styles";

interface DisplayTask {
  title: string;
  id?: string;
}

export interface IPhaseItemsProps extends HTMLAttributes<HTMLDivElement>,
  Omit<IPhaseProgress, "phaseProgress"> {
  checkedItems?: DisplayTask[];
  unCheckedItems?: DisplayTask[];
  onTaskToggle?: (id: string, uncheck?: any) => void;
  activatePhase?: () => void;
  textColor: string;
  bgColor: string;
  isActive: boolean,
  draggable?: boolean
}

const StyledPhaseItems = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.borderDefault};
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  overflow: hidden;
`;

const StyledCheckedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;
const StyledUnCheckedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const StyledDivider = styled.div`
  border-bottom: 1px solid ${theme.colors.borderDefault};
  width: 100%;
  margin-top: 8px;
`;

const PhaseItems = ({
  checkedItems = [],
  unCheckedItems = [],
  phaseTitle,
  onTaskToggle,
  activatePhase,
  textColor,
  bgColor,
  isActive
}: IPhaseItemsProps) => {
  const phaseProgress = useMemo(() => {
    const checkedLength = checkedItems.length;
    const unCheckedLength = unCheckedItems.length;
    const progress = checkedLength / (checkedLength + unCheckedLength);
    return Math.round(progress * 100);
  }, [checkedItems, unCheckedItems]);

  // handle checkbox event
  const handleCheckEvent = (id?: string, uncheck?: any) => {
    if (!id) return;
    if (onTaskToggle) {
      onTaskToggle(id, uncheck);
    }
  };

  // JSX
  return (
    <StyledPhaseItems>
      <PhaseProgress
        phaseProgress={phaseProgress}
        phaseTitle={phaseTitle}
        textColor={textColor || ""}
        bgColor={bgColor || ""}
        isActive={isActive}
        activatePhase={activatePhase}
        showButton={false}
        draggable={false}
      />
      {checkedItems && checkedItems?.length > 0 && (
        <StyledCheckedItemsContainer>
          {checkedItems.map((item) => (
            <PhaseItem
              onClick={() => handleCheckEvent(item?.id, "uncheck")}
              checkMarkChecked={true}
              phaseItemName={item?.title || ""}
            />
          ))}
          {checkedItems?.length > 0 && unCheckedItems?.length > 0 && (
            <StyledDivider />
          )}
        </StyledCheckedItemsContainer>
      )}

      {unCheckedItems && unCheckedItems?.length > 0 && (
        <StyledUnCheckedItemsContainer>
          {unCheckedItems.map((item) => (
            <PhaseItem
              onClick={() => handleCheckEvent(item?.id)}
              checkMarkChecked={false}
              phaseItemName={item?.title || ""}
            />
          ))}
        </StyledUnCheckedItemsContainer>
      )}
    </StyledPhaseItems>
  );
};

export default PhaseItems;