import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ref, child, get, update } from 'firebase/database'
import { db } from '../../config/config'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getTimeStamp } from '../../utils/helpers'
import {
  StyledCreateProjectContainer,
  StyledCreateProjectTitleContainer,
  StyledCreateProjectContentContainer
} from '../CreateProject/StyledCreateProject.tsx'
import Button from '../../components/ui/Button/Button'
import InputField from '../../components/ui/InputField/InputField'
import SubNav from '../../components/ui/SubNav/SubNav'
import TextArea from '../../components/ui/TextArea/TextArea'
import SelectField from '../../components/ui/Select/Select'
import Spinner from '../../components/ui/Spinner/index.tsx'
import {
  StyledDangerZoneContainer,
  StyledDangerZoneDescription,
  StyledDangerZoneHeader,
  StyledDangerZoneTitle
} from './StyledProjectEdit.tsx'
import Modal from '../../components/ui/Modal/Modal.tsx'
import { ComponentDeleteContent } from '../../components/screens/Detail/UpdateComponent.tsx'
import { generateProjectLinks } from '../../utils/generateProjectLinks.ts'

const INITIAL_FORM_DATA = {
  title: '',
  description: '',
  templateId: ''
}

// ------------------ PROJECT Edit PAGE ---------------------------
const ProjectEdit: React.FC = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const titleInput = useRef<HTMLInputElement>(null)
  const templateSelectInput = useRef<HTMLSelectElement>(null)
  const [formData, setFormData] = useState(INITIAL_FORM_DATA)
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const { data: templatesList, isLoading } = useSelector(
    (store: RootState) => store.template
  )
  const { currentTeam } = useSelector((store: RootState) => store.team)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)

  // template selection change
  const handleTemplateSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    setFormData((prev) => {
      return {
        ...prev,
        templateId: value
      }
    })
  }

  // save changes
  const handleSave = async () => {
    if (saving || isLoading || !projectId) return

    if (!formData?.title?.trim()) {
      toast.info('Please name this project.')
      titleInput?.current?.focus()
      titleInput.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      return
    }
    if (!formData?.templateId?.trim()) {
      toast.info('Select a template.')
      templateSelectInput?.current?.focus()
      templateSelectInput.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      return
    }
    const teamId = currentTeam?.id || localStorage.getItem('checklist_team_id')
    if (!teamId) {
      toast.info('Team id not found')
      return
    }
    setSaving(true)
    const currentTime = getTimeStamp()
    try {
      const projectRef = ref(db, `components/${currentTeam?.id}`)
      const updates = {
        templateId: formData?.templateId,
        title: formData?.title,
        type: 'project',
        description: formData?.description,
        updatedAt: currentTime
      }
      await update(child(projectRef, projectId), updates)
      toast.success('Project created successfully.')
      setSaving(false)
      setFormData(INITIAL_FORM_DATA)
      navigate('/dashboard/projects/' + projectId)
    } catch (error) {
      setSaving(false)
      toast.error('Something went wrong.')
      console.log(error)
    }
  }

  // Delete
  const handleDeletedProject = async () => {
    if (!projectId || deleting) return

    setDeleting(true)
    const project = await generateProjectLinks(
      db,
      currentTeam?.id || '',
      projectId
    )
    try {
      const res = await fetch(
        `${import.meta.env.VITE_APP_SERVER_URL}/components/${currentTeam?.id}/${projectId}?auth=${currentTeam?.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if(res.ok) {
          const path = `${import.meta.env.VITE_APP_SERVER_URL}/email/project-remove`
          const teamAdmins = currentTeam?.members?.filter(
            (v) => v?.role === 'owner' || v?.role === 'admin'
          )
          await fetch(path, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              projectName: project?.projectName,
              links: project?.links,
              teamAdmins
            })
          })
          toast.success('Project is removed.')
          navigate('/dashboard/projects', { replace: true })
      }
    } catch (error) {
      toast.error('Something went wrong.')
      console.log(
        'Error while deleting a task from the phase task list.',
        error
      )
    } finally {
      setDeleting(false)
    }
  }

  // fetch previous details
  useEffect(() => {
    if (!projectId || isLoading || !currentTeam) return

    const projectRef = ref(db, `components/${currentTeam?.id}`)
    // Fetch the project data from the database
    setLoading(true)
    get(child(projectRef, projectId))
      .then((snap) => {
        const data = snap.val()
        if (snap.val()) {
          setFormData({
            title: data?.title || '',
            description: data?.description || '',
            templateId: data?.templateId || ''
          })
        } else {
          setFormData(INITIAL_FORM_DATA)
          toast.error('Project not found!')
        }
        setLoading(false)
      })
      .catch((err) => {
        toast.error(err.message || 'Something went wrong!')
        console.log(
          'Error while fetching template details on the edit page',
          err
        )
      })
  }, [projectId, currentTeam, isLoading])

  // JSX
  return (
    <StyledCreateProjectContainer>
      <SubNav
        title="Edit Project"
        onBtnClick={() => navigate(`/dashboard/projects/${projectId || ''}`)}
      />
      <div style={{ width: '100%' }}>
        <StyledCreateProjectContentContainer>
          {isLoading || loading ? (
            <div
              style={{ width: '100%', padding: '25px' }}
              className="flex justify-center"
            >
              <Spinner type="spinner" />
            </div>
          ) : null}
          <StyledCreateProjectTitleContainer>
            <InputField
              disabled={isLoading || loading || saving}
              inputRef={titleInput}
              autoFocus
              required
              fullWidth
              label="Project Name"
              placeholder="maximum of 120 characters"
              name="title"
              type="text"
              value={formData.title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({
                  ...formData,
                  title:
                    e.target?.value?.length < 120
                      ? e.target?.value
                      : formData?.title
                })
              }
            />
            <SelectField
              options={[
                { label: 'Select Template', value: '' },
                ...templatesList
                  ?.filter((t) => t?.type === 'project')
                  ?.map((v) => {
                    return {
                      label: v?.title,
                      value: v?.id
                    }
                  })
              ]}
              fullWidth
              value={formData?.templateId}
              onChange={handleTemplateSelect}
              selectRef={templateSelectInput}
            />
            <TextArea
              disabled={isLoading || loading || saving}
              required
              width="100%"
              textLabel="Project Description"
              placeholder="maximum of 120 characters"
              name="description"
              value={formData.description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setFormData({
                  ...formData,
                  description:
                    e.target?.value?.length < 160
                      ? e.target?.value
                      : formData?.description
                })
              }
            />
          </StyledCreateProjectTitleContainer>
          <Button
            disabled={isLoading || loading || saving}
            type="button"
            label="UPDATE CHANGES"
            variant="primary"
            onClick={handleSave}
          />
          <StyledDangerZoneContainer>
            <StyledDangerZoneHeader>
              <StyledDangerZoneTitle>Danger zone</StyledDangerZoneTitle>
              <StyledDangerZoneDescription>
                Are you sure you want to delete this project? This action is
                irreversible and will permanently erase all your data. If you're
                sure, tap 'Remove Project' below.
              </StyledDangerZoneDescription>
            </StyledDangerZoneHeader>
            <Button
              type="button"
              size="small"
              disabled={
                saving || isLoading || loading || !projectId || deleting
              }
              label={deleting ? 'Removing...' : 'REMOVE PROJECT'}
              variant="danger"
              onClick={() => (!projectId ? null : setShowModal(true))}
              fullWidth={false}
            />
            {showModal && (
              <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                signal="alert-triangle"
                signalIconType="warning"
                title="Remove Project"
                slotContent={<ComponentDeleteContent componentType="project" />}
                buttonLabel1={deleting ? "REMOVING" : "REMOVE PROJECT"}
                buttonLabel2="CANCEL"
                buttonDisabled1={deleting}
                buttonDisabled2={deleting}
                buttonVariant1="danger"
                buttonVariant2="tertiary"
                buttonOnClick1={handleDeletedProject}
              />
            )}
          </StyledDangerZoneContainer>
        </StyledCreateProjectContentContainer>
      </div>
    </StyledCreateProjectContainer>
  )
}

export default ProjectEdit
