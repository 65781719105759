import React, { useEffect } from 'react'
import styled from 'styled-components'

interface CustomSliderProps {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  step?: number
  className?: string
}

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`

const Track = styled.div`
  position: relative;
  height: 8px;
  width: 100%;
  background-color: #ddd;
  border-radius: 9999px;
  overflow: hidden;
`

const Range = styled.div<{ percentage: number }>`
  position: absolute;
  height: 100%;
  background-color: #007bff;
  width: ${({ percentage }) => percentage}%;
`

const Thumb = styled.div<{ percentage: number }>`
  position: absolute;
  top: 50%;
  left: ${({ percentage }) => percentage}%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #007bff;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
  &:active {
    transform: translate(-50%, -50%) scale(1.2);
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`

const CustomSlider = React.forwardRef<HTMLInputElement, CustomSliderProps>(
  ({ value, onChange, min = 0, max = 100, step = 1, className }, ref) => {
    const [percentage, setPercentage] = React.useState(
      ((value - min) / (max - min)) * 100
    )

    useEffect(() => {
      setPercentage(((value - min) / (max - min)) * 100)
    }, [value, min, max])

    const handleThumbMove = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(+e.target.value)
    }

    return (
      <SliderWrapper className={className}>
        <Track>
          <Range percentage={percentage} />
          <Thumb
            percentage={percentage}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'ArrowRight') onChange(Math.min(value + step, max))
              if (e.key === 'ArrowLeft') onChange(Math.max(value - step, min))
            }}
          />
        </Track>
        <Input
          ref={ref}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleThumbMove}
        />
      </SliderWrapper>
    )
  }
)

CustomSlider.displayName = 'CustomSlider'

export { CustomSlider }
