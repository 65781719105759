import React, { useEffect, useRef, useState } from 'react'
import {
  StyledHeader,
  StyledModalOverlay,
  StyledTitleContainer
} from '../Modal/Modal'
import useIsMobileDevice from '../../../hooks/useIsMobileDevice'
import styled from 'styled-components'
import Button from '../Button/Button'
import { theme } from '../../../styles'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { motion } from 'framer-motion'
import Label from '../Label/Label'

const StyledModal = styled.div<{
  $overflow?: 'auto' | 'hidden' | 'visible' | 'scroll'
}>`
  max-width: 1200px;
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  max-height: 96vh;
  overflow: ${({ $overflow }) => $overflow};
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.25);
  margin: auto;
  @media screen and (max-width: 1200px) {
    max-width: 92%;
    min-height: 200px;
  }
`

const StyledFrameWrapper = styled.div`
  width: 100%;
  height: 675px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    height: 300px;
  }
`

export const StyledMobileTitle = styled.h4`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%; /* 22.4px */
  text-transform: uppercase;
`

export const StyledDesktopTitle = styled.h3`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${theme.colors.copy};
  text-overflow: ellipsis;
  font-family: 'rooney-sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%; /* 22.4px */
  text-transform: uppercase;
`

export const StyledVideoPreview = styled.div`
  position: fixed;
  bottom: 110px;
  right: 22px;
  width: 335px;
  overflow: hidden;
  border: 1px solid #f2ece6;
  border-radius: 8px;
  background-color: #fbf9f7;
  box-shadow: 0 3px 7px #6f6b671a;
  padding: 0 8px 8px 8px;
  z-index: 222;
  @media screen and (max-width: 768px) {
    bottom: 90px!important;
    right: 18px;
    width: 280px;
  }
`
export const StyledVideoButtonPreview = styled.div`
  position: fixed;
  bottom: 110px;
  right: 22px;
  width: 335px;
  overflow: hidden;
  border: 1px solid #f2ece6;
  border-radius: 8px;
  background-color: #fbf9f7;
  box-shadow: 0 3px 7px #6f6b671a;
  padding: 0 8px 8px 8px;
  z-index: 222;
  @media screen and (max-width: 768px) {
    bottom: 76px!important;
    right: 22px;
    width: 280px;
  }
`

const StyledVideoPreviewContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;
  height: 198px;
  width: 100%;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    height: 160px;
  }
`

const PreviewImage = styled.img`
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  // object-fit: cover;
  pointer-events: none;
  user-select: none;
  font-size: 16px;
`

const StyledLabelWrapper = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  left: 16px;
  bottom: 16px;
  background-color: #e4e9ff;
  border-radius: 8px;
`

interface DemoVideoProps {
  title?: string
  videoId?: string
  videoDuration?: string
}

const DemoVideo: React.FC<DemoVideoProps> = ({
  title = 'Quick Onboarding',
  videoId = '',
  videoDuration = ''
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const modalRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobileDevice(768)
  const [showOnboardingVideo, setShowOnboardingVideo] = useLocalStorage(
    'show-onboarding-video',
    true
  )

  const url = !videoId
    ? ''
    : `https://www.youtube.com/embed/${videoId}?controls=1&amp;autoplay=1&amp;rel=0&amp;showinfo=0&amp;modestbranding=0`
  const previewUrl = !videoId
    ? ''
    : `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`

  // close modal
  const onclose = () => {
    setShowModal(false)
    setShowOnboardingVideo(false)
  }

  // Close the bottom sheet when clicking outside the container
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        if (setShowModal) {
          setShowModal(false)
        }
      }
    }

    if (showModal) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showModal, setShowModal])

  if (!showModal && showOnboardingVideo) {
    return (
      <motion.div
        initial={{ opacity: 0, x: 300 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 2.5 }}
      >
        <StyledVideoPreview>
          <StyledHeader
            style={{
              padding: '4px 0px 4px 0',
              backgroundColor: 'transparent',
              borderBottom: '0'
            }}
          >
            <StyledTitleContainer>
              <StyledMobileTitle>{title}</StyledMobileTitle>
            </StyledTitleContainer>
            <Button
              variant="utility"
              size="small"
              iconOnly
              icon="x"
              style={{ scale: '0.8' }}
              onClick={() => {
                setShowOnboardingVideo(false)
              }}
            />
          </StyledHeader>
          <StyledVideoPreviewContainer
            role="button"
            onClick={() => setShowModal(true)}
          >
            <PreviewImage src={previewUrl} alt={title} />
            {videoDuration && (
              <StyledLabelWrapper>
                <Label text={videoDuration} type="information" />
              </StyledLabelWrapper>
            )}
          </StyledVideoPreviewContainer>
        </StyledVideoPreview>
      </motion.div>
    )
  }
  if (showModal) {
    return (
      <StyledModalOverlay>
        <StyledModal ref={modalRef} $overflow="hidden">
          <StyledHeader>
            <StyledTitleContainer>
              {isMobile ? (
                <StyledMobileTitle>{title}</StyledMobileTitle>
              ) : (
                <StyledDesktopTitle>{title}</StyledDesktopTitle>
              )}
            </StyledTitleContainer>
            <Button
              variant="tertiary"
              size="small"
              iconOnly
              icon="x"
              onClick={() => {
                if (onclose) {
                  onclose()
                } else if (setShowModal) {
                  setShowModal(false)
                }
              }}
            />
          </StyledHeader>
          <StyledFrameWrapper>
            {url && (
              <iframe
                src={url}
                title={title || 'YouTube video player'}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                style={{ width: '100%', height: '100%' }}
              ></iframe>
            )}
          </StyledFrameWrapper>
        </StyledModal>
      </StyledModalOverlay>
    )
  }
  return (
    <StyledVideoButtonPreview
      style={{
        width: 'max-content',
        bottom: '100px',
        background: '#fff',
        padding: '4px',
        borderRadius: '50px'
      }}
    >
      {isMobile ?
        <Button onClick={() => setShowModal(true)} size='xs' iconOnly icon='play' variant='utility' />
      :<Button
        style={{ fontFamily: 'rooney-sans' }}
        onClick={() => setShowModal(true)}
        variant="utility"
        size="small"
      >
        Watch onboarding
      </Button>}
    </StyledVideoButtonPreview>
  )
}

export default DemoVideo
