import { createSlice } from '@reduxjs/toolkit'

export interface TaskObj {
  id: string
  title: string
  description?: string
  applicationType?: string
  phaseId?: string
  templateId?: string
  visible: boolean
  mandatory: boolean
  order?: number | undefined | null
  createdBy?: string
  createdAt?: Date | string | undefined | null
  updatedAt?: Date | string | undefined | null
  archived?: boolean
}

export interface PhaseObj {
  id: string
  colorId: string
  templateId?: string
  teamId: string
  createdBy: string
  title: string
  visible: boolean
  mandatory: boolean
  order?: number | undefined | null
  createdAt?: Date | string | undefined | null
  updatedAt?: Date | string | undefined | null
  tasks?: TaskObj[] | null | undefined
  description?: string
  isActive: boolean
  archived?: boolean
}

interface PhaseState {
  data: PhaseObj[]
  isLoading: boolean
}

//
const initialState: PhaseState = {
  data: [],
  isLoading: true
}

//
export const phaseReducerSlice = createSlice({
  name: 'phaseSlice',
  initialState,
  reducers: {
    setPhasesSlice: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
    setPhasesData: (state, action) => {
      state.data = action.payload.data
    },
    resetPhases: (state) => {
      state.data = initialState.data
      state.isLoading = false
    }
  },
  extraReducers: (builder) => {}
})

export const { setPhasesSlice, setPhasesData, resetPhases } =
  phaseReducerSlice.actions

export default phaseReducerSlice.reducer