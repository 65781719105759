import { ref, onValue } from 'firebase/database'
import { db } from '../config/config'

export const determineTemplateNumber = async (
  teamId: string,
  templateId: string
) => {
  try {
    const templatesRef = ref(db, `templates/${teamId}`)
    let position = 0
    let templateNumber = 0

    onValue(templatesRef, (snapshot) => {
      if (!snapshot.exists()) {
        throw new Error(`No templates found for team ${teamId}`)
      }

      const templates = snapshot.val()
      const templateIds = Object.keys(templates)

      position = templateIds.indexOf(templateId)

      if (position === -1) {
        console.log('true')
        const totalTemplates = Object.keys(snapshot.val()).length + 1
        // template number should be 1, 2, or 3 and calculated based on the total number of templates
        templateNumber = totalTemplates % 3 || 3
        return { position: totalTemplates, templateNumber }
      }

      templateNumber = (position % 3) + 1
    })
    return { position, templateNumber }
  } catch (error) {
    console.error('Error finding template position:', error)
    throw error
  }
}
