import { Link } from "react-router-dom";
import {
  StyledDescription,
  StyledHeaderAction,
  StyledHeaderContainer,
  StyledHeaderText,
  StyledMainContainer,
  StyledPageContainer,
} from "../../components/screens/PrivacyPolicy/PrivacyPolicy";
import Button from "../../components/ui/Button/Button";

const PrivacyPolicy = () => {
  return (
    <StyledMainContainer>
      <StyledPageContainer style={{ margin: 'auto' }}>
        <StyledHeaderAction>
          <Link to="/dashboard/profile">
            <Button variant="tertiary" icon="chevron-left" size="medium">
              Go back
            </Button>
          </Link>
        </StyledHeaderAction>

        <StyledHeaderContainer>
          <StyledHeaderText>Privacy Policy</StyledHeaderText>
        </StyledHeaderContainer>
        <StyledDescription>
          <p>Thank you for your interest in learning about Govern's legal policies. These policies are important to us, and we want to ensure that you understand them fully. This is where you can find information on how we protect your privacy, what actions are allowed when using our products, and how we manage user accounts.</p><br/>
          <br/>
            <p>Compfeed is owned and operated by Paksa.</p><br/>
            <br/>
            <p>We value your privacy and the protection of your personal data. Our privacy policy details the information we collect from you, the methods of collection, how we use it, how we obtain your consent, how long we keep it, and, if necessary, with whom we share it.</p><br/>
            <br/>
            <p>By using our platform, you consent to the practices described in this policy. In addition, your use of the platform is also governed by our terms and conditions. In this document, the words &quot;platform&quot; refers to the Compfeed web application, &quot;we,” &quot;us,” &quot;our,&quot; and &quot;Compfeed&quot; refers to Compfeed, and &quot;you&quot; and &quot;user&quot; refers to you, the user of Compfeed.</p><br/>
            <br/>
            <p>This privacy policy may change from time to time. Your continued use of the platform after we make changes to this privacy policy will be deemed acceptance of those changes, so please check this policy periodically for updates. This privacy policy has been developed and is maintained by all applicable national and international laws and regulations, particularly with the General Data Protection Regulation (GDPR - European regulation).</p><br/>
            <br/>
            <h3>1. GENERAL INFORMATION</h3>

            <p>The personal data of users are collected and processed through:</p><br/>

            <ul>
              <li><strong>Compfeed Web App (SaaS)</strong></li>
              <li><a href="https://www.govern.design">https://www.govern.design</a></li>
              <li><a href="https://app.govern.design">https://app.govern.design</a></li>
            </ul>
            <br/>
            Will be under the responsibility and in charge of: <strong>Paksa</strong> (<a href="mailto:support@govern.design">support@govern.design</a>)
            <br/>
            <h3>2. HOW DO YOU OBTAIN MY CONSENT?</h3>
            <br/>
            <p>When you perform actions such as requesting access to the beta version of our platform, registering as a user, using our features, purchasing a subscription, subscribing to our newsletter, contacting us through our contact details or forms, or providing us with personal information to establish communication, you are giving your consent for us to collect, store and use your information as set out in our privacy policy. If at any time you wish to withdraw this consent, you may do so by sending a request through our contact information.</p><br/>
            <br/>
            <h3>3. TYPES OF INFORMATION COLLECTED</h3>
            <br/>
            <p>The information we collect from our users helps us continually improve the platform’s user experience. These are the types of information we collect:</p><br/>

            <p><strong>Information you provide to us.</strong> You provide information when you request access to the beta version of the platform, register as a user, purchase a subscription, use the functionalities available on the platform, subscribe to our newsletter and/or communicate with us through our contact details and contact form and provide us with personal information to communicate with you. As a result of those actions, you may provide us with the following information:</p><br/>

            <ul>
              <li>Name</li>
              <li>E-mail address</li>
              <li>Any additional information related to you that you provide to us directly or indirectly through our platform.</li>
            </ul>

            <p>Compfeed will not collect personally identifiable information about you unless you provide it.</p><br/>

            <p><strong>Information Collected Automatically:</strong> By accessing and using the platform, you automatically provide us with the following information:</p><br/>

            <ul>
            <li>Your IP address</li>
            <li>Referring URLs</li>
            </ul>

            <p>If you access the platform through a mobile phone, we will collect the following information:</p><br/>

            <ul>
            <li>Mobile device ID</li>
            <li>IP address</li>
            </ul>

            <p><strong>Payment Information:</strong> Your payment details will be processed by the payment processors available on this platform, who will process and store your data securely and solely to process the purchase of subscriptions. Compfeed reserves the right to engage any payment processor available.</p><br/>

            <p>See Stripe's privacy policy here:</p><br/>

            <ul>
            <li><a href="https://stripe.com/privacy">https://stripe.com/privacy</a></li>
            </ul>

            <p><strong>GOOGLE Analytics.</strong> We use Google Analytics provided by Google, Inc., USA (“Google”). These tools and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics data collection to enhance the platform and improve our service.</p><br/>

            <p>Please consult Google's privacy policy here:</p><br/>

            <ul>
            <li><a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></li>
            </ul>

            <p><strong>Social Media:</strong> Our website offers a variety of links and functionalities associated with various social networks, giving you the option to share your information on them. We recommend that you carefully review the privacy and data protection policies of each of the social networks you use through our website.</p><br/>

            <ul>
            <li>X (Twitter): <a href="https://twitter.com/privacy">https://twitter.com/privacy</a></li>
            </ul>

            <p><strong>Contact information:</strong> We may access personal information about the user, such as name and email address, when the user or any third party communicates with us through our contact information. Personal information provided through our contact information is not stored on any Compfeed server and will be stored on the respective server of our email service.</p><br/>

            <h3>4. HOW LONG WE KEEP YOUR DATA</h3>

            <p>Personal data provided by users through the platform will be retained for as long as necessary to deliver the functionalities available on the platform, provide subscriptions, fulfill the legitimate purposes described in this policy, or until the user closes the user account or requests the deletion of their data. Compfeed may retain personal data for a longer period provided the user has consented to such processing, as long as such consent is not withdrawn. Compfeed may need to retain personal data for an extended period if required to do so in order to comply with a legal obligation or by order of a competent authority. After this retention period, we will delete the personal data. Consequently, once this time has elapsed, it will no longer be possible to exercise the rights of access, deletion, rectification, and data portability.</p><br/>

            <h3>5. HOW WE USE YOUR INFORMATION (LEGITIMATE PURPOSES)</h3>

            <p>In general, we use the data we collect primarily to provide, maintain, protect, and improve our platform and appropriately provide the functionality available on the platform. We use the data collected through our platform as described below:</p><br/>

            <ul>
            <li>Provide the beta version of the platform.</li>
            <li>User registration.</li>
            <li>Provide the functionalities available in the platform.</li>
            <li>Provide the subscriptions.</li>
            <li>Processing payments through our payment processor.</li>
            <li>Understand and improve your experience using our platform.</li>
            <li>Respond to your comments or questions through our contact information.</li>
            <li>Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.</li>
            <li>Send you relevant information about Compfeed.</li>
            <li>Compfeed marketing purposes.</li>
            <li>Link or combine your information with other data we obtain from third parties to help us understand your needs and provide you with better service.</li>
            <li>Protect, investigate, and deter fraudulent, unauthorized, or illegal activities.</li>
            </ul>

            <h3>6. LEGAL BASIS</h3>

            <p>In accordance with European data protection regulations, the processing of users' personal data on our platform is based on specific legal grounds or legitimate purposes. These legitimate purposes are described in section 5 of this policy.</p><br/>

            <p>In certain situations, we request explicit user consent for specific data processing activities, such as sending marketing communications. In addition, we use our legitimate purposes to improve our services, conduct market analysis, and ensure the security of our platform and user information while ensuring that these purposes do not infringe on users' rights.</p><br/>

            <p>This data processing is carried out with full respect for the rights of users under the General Data Protection Regulation (GDPR) and other data protection laws applicable in Europe.</p><br/>

            <h3>7. HOW WE SHARE INFORMATION</h3>

            <p>Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below.</p><br/>

            <p><strong>Third-Party Service Providers.</strong> We use third-party services to perform certain functions on our platform. Some of these functions and services are Building and hosting the platform, processing payments (Stripe), sending emails (MailChimp), and analyzing data (Google Analytics).</p><br/>

            <p>These third-party services and tools may have access to personal information needed to perform their functions but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy.</p><br/>

            <p><strong>Business Transfers.</strong> If Compfeed creates, merges with, or is acquired by another entity, your information will likely be transferred. Compfeed will send you an email or post a prominent notice on our platform before your information becomes subject to another privacy policy.</p><br/>

            <p><strong>Protection of Compfeed and Others.</strong> We release personal information when we believe it is appropriate to comply with the law, enforce or apply our terms and conditions and other agreements, or protect the rights, property, or safety of Compfeed, our users, or others; this includes exchanging information for fraud protection and credit risk reduction with other companies and organizations.</p><br/>

            <p><strong>Anonymous Information.</strong> Compfeed uses anonymous browsing information collected automatically by our servers primarily to help us administer and improve the platform. We may also use aggregated anonymous information to provide information about the Platform to potential business partners and other unaffiliated entities. This information is not personally identifiable.</p><br/>

            <h3>8. DATA BREACH NOTIFICATIONS</h3>

            <p>In the event of a security breach that compromises the confidentiality of our user’s personal data, Compfeed undertakes to notify those affected promptly. This notification will be made through the means of contact that the user on our platform has provided. We will take all reasonable measures to protect the information and remedy any situation that compromises the security of your data.</p><br/>

            <h3>9. INTERNATIONAL DATA TRANSFER</h3>

            <p>By using our platform, you agree that your personal data may be transferred and processed outside the European Economic Area, where data protection legislation may differ from that of your country. Compfeed is committed to taking the necessary steps to ensure that your data is treated in accordance with applicable privacy protection rules and is adequately protected during any international transfer.</p><br/>

            <h3>10. PROTECTION OF YOUR INFORMATION</h3>

            <p>We grant access to your personal information only to those outside persons or services that have a legitimate need to know it and in accordance with our privacy policy. We adhere to industry-recognized security standards to protect your personal information during transmission and in storage. However, it is important to note that no method of transmission over the Internet or electronic storage is foolproof and 100% secure. Therefore, while we at Compfeed strive to implement commercially viable data protection methods, we cannot ensure the absolute security of your personal information. We undertake not to sell, distribute or transfer your personal data to unauthorized third parties unless we have your explicit consent or are required by law to do so.</p><br/>

            <h3>11. RIGHTS</h3>

            <p>Users who provide information through our platform as data subjects have the right to access, rectify, download, or delete their information and restrict and oppose specific processing of their information. While some of these rights apply generally, others only apply in certain limited circumstances. These rights are described below:</p><br/>

            <ul>
            <li><strong>Access and portability:</strong> to access and know what information is stored on our servers, you can send us your request through our contact information.</li>
            <li><strong>Rectification, restriction, limitation, and deletion:</strong> You may also rectify, restrict, limit or delete much of your information.</li>
            <li><strong>Right to be informed:</strong> Users of our platform will be informed, upon request, about what data we collect, how it is used, how long it is kept, and whether it is shared with third parties.</li>
            <li><strong>Object:</strong> Where we process your data based on our legitimate interests, as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have legitimate grounds to continue processing it or, where necessary, for legal reasons.</li>
            <li><strong>Withdraw consent:</strong> Where you have previously given your consent, for example, to allow us to process and store your personal information, you have the right to withdraw your consent to the processing and storing of your data at any time. For example, you can withdraw your consent by updating your settings. In some instances, we may continue to process your information after you have withdrawn your consent if we have a lawful basis for doing so or if your withdrawal of consent was limited to certain processing activities.</li>
            <li><strong>Complaint:</strong> If you wish to lodge a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users may exercise these rights by contacting us via the contact information or contact page.</li>
            <li><strong>Rights related to automated decision-making, including profiling:</strong> Users may request that we provide them with a copy of our automated processing activities if they believe that data is being unlawfully processed.</li>
            </ul>

            <p>Users or holders of personal data provided through the platform may exercise these rights over their personal data at any time and without limitation by sending their request through our contact information. The request to exercise their rights will be attended to and answered within a maximum period of 10 working days.</p><br/>

            <h3>12. PROTECTION OF CHILDREN'S ONLINE PRIVACY</h3>

            <p>We comply with the requirements of national and international data protection regulations regarding the protection of minors’ personal data. Although the platform is available to all ages, we do not collect any information from children under the age of 13 without the respective permission of their parents or legal guardians. If we become aware that a child under the age of 13 has provided us with personal information without the permission of his or her parent or legal guardian, we will take the necessary steps to delete that information.</p><br/>

            <h3>13. THIRD PARTIES</h3>

            <p>Except as expressly included in this privacy policy, this document addresses only the use and disclosure of information Compfeed collects from you. If you disclose your information to others, whether other users or Compfeed vendors, different rules may apply to their use or disclosure of the information you disclose. Compfeed does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Compfeed is not responsible for the privacy or security practices of other Internet platforms, including those linked to or from the Compfeed platform. Please review the privacy policies of any third-party websites or services you access through our platform.</p><br/>

            <h3>14. CONTACT</h3>

            <p>If you have questions or concerns about this privacy policy and the treatment and security of your data, please contact us using the contact information below:
            Paksa - Compfeed.
            (<a href="mailto:support@govern.design">support@govern.design</a> - +1 (929) 577-4084)</p><br/>
        </StyledDescription>
      </StyledPageContainer>
    </StyledMainContainer>
  );
};

export default PrivacyPolicy;