import { useState, useEffect } from "react";

const useIsMobileDevice = (minSize: number = 430): boolean => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const checkMobile = (): void => {
            // const userAgent = navigator.userAgent.toLowerCase();
            // const mobileDevices =
            //     /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

            // const isMobileDevice = mobileDevices.test(userAgent);
            const isSmallScreen = window.innerWidth < minSize;

            setIsMobile(isSmallScreen);
            // setIsMobile(isMobileDevice || isSmallScreen);
        };

        checkMobile();
        window.addEventListener("resize", checkMobile);

        return () => {
            window.removeEventListener("resize", checkMobile);
        };
    }, [minSize]);

    return isMobile;
};

export default useIsMobileDevice;
